
    module.exports = function cssToMuiLoader(theme) {
      
      return {
        
        'btn': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,},'btnPrimary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#a76e1b`,'color': `#ffffff`,
        },},'btnSecondary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#ffffff`,'color': `#d18a22`,'&:hover': {
          'backgroundColor': `#fff2e0`,'color': `#d18a22`,
        },},'btnTertiary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#70a0c3`,'color': `#ffffff`,
        },},'linkText': {'color': `#d18a22`,'cursor': `pointer`,'fontWeight': `bold`,'textDecoration': `underline`,'margin': `0 8px`,'fontSize': `0.75rem`,'fontFamily': `'Lato-Bold', sans-serif`,},'shoppingCartContainer': {'display': `flex`,},'shoppingCartLabel': {'display': `inherit`,'flexDirection': `row`,},'shoppingCartLabelMobile': {'display': `inherit`,'flexDirection': `row`,'flexDirection': `column`,},'redirectLink': {'textDecoration': `none`,},'cartBadge': {'color': `secondary`,},'checkoutContainer': {'display': `flex`,'flexWrap': `no-wrap`,'alignItems': `center`,'justifyContent': `center`,},'checkoutIcon': {'color': `#282828`,'paddingRight': `8px`,'paddingBottom': `5px`,},'checkoutIconMobile': {'color': `#282828`,'paddingRight': `8px`,'paddingBottom': `5px`,'margin': `auto`,'paddingRight': `0`,},'checkoutText': {'marginLeft': `10px`,'color': `#282828`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `16px`,},'checkoutTextMobile': {'marginLeft': `10px`,'color': `#282828`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `16px`,'marginLeft': `0`,},
        
      };
    };
  