import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Typography } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import withWidth from '@material-ui/core/withWidth';

import { withStyles } from '@material-ui/core/styles';
import Button from '../core/components/Button';
import DialogView from '../core/components/DialogView';
import TextBoxComponent from '../core/components/TextBoxComponent';
import CheckBoxComponent from '../core/components/CheckBoxComponent';
import * as FeatureFlags from '../../../feature-flags.json';
import styles from '../../css/authentication/LoginDialog.scss';
import * as Functions from '../../services/functions/Functions';

const pageStyles = typeof styles === 'function' ? styles() : styles;

const initialState = {
  email: '',
  password: '',
  rememberUser: false,
  error: '',
};
const loginFields = [
  {
    autoFocus: true,
    type: 'TextBox',
    title: 'E-mail',
    fieldKey: 'email',
    fieldType: '',
  },
  {
    autoFocus: false,
    type: 'TextBox',
    title: 'Password',
    fieldKey: 'password',
    fieldType: 'password',
  },
  {
    autoFocus: false,
    type: 'CheckBox',
    fieldKey: 'checkBoxUser',
    checkBoxes: [
      {
        title: 'Keep me signed in',
        fieldKey: 'rememberUser',
      },
    ],
  },
];

// TODO: Reset password
class LoginDialog extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  getProps = () => {
    const {
      classes,
      handleClickOptions,
      translation,
      width,
      cartIconWasPressed,
    } = this.props;

    const mobileFriendlyStyleProps = { classes, width };
    const actionButtonStyles = Functions.getMobileFriendlyStyle(mobileFriendlyStyleProps, 'actionBtnStyle');
    return (
      {
        dialogTitle: {
          dialogTitleStyle: classes.dialogTitleStyle,
          dialogTitleText: '',
          dialogTitleSubheader: '',
          dialogTitleSubheaderImageAlt: translation('CoreView.header.logoAlt'),
          subHeaderImageContainerStyle: classes.subHeaderImageContainerStyle,
          subHeaderImageStyle: classes.subHeaderImageStyle,
        },
        dialogBodyContainerStyle: classes.dialogBodyContainerStyle,
        dialogContentStyle: classes.dialogContentStyle,
        dialogContent: (
          <div>
            {
              FeatureFlags.LoginDialog.useCustomMessage
              && (
                <Typography>
                  {translation('LoginDialog.customMessage')}
                </Typography>
              )
            }
            <form className={classes.formContainer} noValidate autoComplete="off">
              {
                loginFields.map((field) => {
                  const FieldType = this.getComponentTypeTag(field.type);

                  if (field.type !== 'CheckBox') {
                    return (
                      <FieldType
                        key={field.fieldKey}
                        {...field}
                        field={field}
                        selectedValue={this.state[`${field.fieldKey}`]}
                        handleChange={
                          (fieldItem, selectedItems) => this.handleChange(fieldItem, selectedItems)
                        }
                        fieldAutoFocus={field.autoFocus}
                      />
                    );
                  }

                  return (
                    <FormGroup
                      row={pageStyles.row}
                      key={field.fieldKey}
                      className={classes.formGroupStyle}
                    >
                      <div className={classes.promptContainer}>
                        <Typography>
                          {translation('LoginDialog.dialogContent2.forgotPassword')}
                        </Typography>
                        &nbsp;
                        <Typography>
                          <a
                            id="reset"
                            href="#"
                            onClick={() => this.handleClickReset()}
                            className={classes.dialogContent2Link}
                          >
                            {translation('LoginDialog.dialogContent2.reset')}
                          </a>
                        </Typography>
                      </div>
                      {
                        field.checkBoxes.map(checkBox => (
                          <FieldType
                            id="keepSignedIn"
                            key={checkBox.fieldKey}
                            field={checkBox}
                            selectedValue={this.state[`${checkBox.fieldKey}`]}
                            handleChange={
                              (e, checkBoxField) => this.handleChangeCheckBox(e, checkBoxField)
                            }
                          />
                        ))
                      }
                    </FormGroup>
                  );
                })
              }
            </form>
          </div>
        ),
        dialogContent2: (
          <Fragment>
            <div className={classes.dialogContent2}>
              <div className={classes.promptContainer}>
                <Typography variant="body1">
                  {translation('HeaderComponent.nonMember')}
                </Typography>
                &nbsp;
                <Typography variant="body1">
                  <a
                    href="#"
                    onClick={() => handleClickOptions(translation('HeaderComponent.createAccount'))}
                    className={classes.dialogContent2Link}
                  >
                    {translation('HeaderComponent.createAccount')}
                  </a>
                </Typography>
              </div>
            </div>
            {
              (cartIconWasPressed && FeatureFlags.enableGuestCheckout)
              && (
                <div className={classes.guestUserContainer}>
                  <div className={classes.orSeparator}>
                    <div className={classes.separatorLine} />
                    <Typography variant="body1">
                      {translation('or')}
                    </Typography>
                    <div className={classes.separatorLine} />
                  </div>
                  <Button
                    id="guestCheckout"
                    type="primary"
                    onClick={() => this.handleGuestCheckout()}
                    fullWidth
                    text={translation('LoginDialog.dialogActions.guestCheckout')}
                  />
                </div>
            )
          }
          </Fragment>

        ),
        dialogActions: {
          actionBtnStyle: actionButtonStyles,
          actionButtons: (
            <Button
              id="signIn"
              type="primary"
              onClick={this.handleLogin}
              disabled={this.disableButton()}
              fullWidth
              text={translation('LoginDialog.dialogActions.signIn')}
            />
          ),
        },
      });
  };

  getComponentTypeTag = (fieldType) => {
    switch (fieldType) {
      case 'TextBox':
        return TextBoxComponent;
      case 'CheckBox':
        return CheckBoxComponent;
      default:
        return TextBoxComponent;
    }
  };

  handleChangeCheckBox = (event, field) => {
    this.setState({
      [field.fieldKey]: event.target.checked,
    });
    this.props.actions.setRememberUser(event.target.checked);
  }

  handleChange = (field, selectedItem) => {
    this.setState({
      [field.fieldKey]: selectedItem,
    });
  };

  handleClickReset = () => {
    const dialogObj = {
      dialog: 'login',
      openResetPasswordDialog: true,
      eventSource: 'clickResetFromLogin',
    };
    this.setState(initialState);
    this.props.handleClose(dialogObj);
  };

  handleGuestCheckout = () => {
    const dialogObj = {
      dialog: 'login',
      openGuestSignInDialog: true,
      eventSource: 'clickGuestCheckout',
    };
    this.setState(initialState);
    this.props.handleClose(dialogObj);
  }

  handleLogin = async () => {
    const { actions, rememberUser, currentOrder, sendUserToCheckout } = this.props;
    const { email, password } = this.state;
    const user = { email, password };

    try {
      const response = await actions.loginUser(user);

      if (response.error) {
        // TO-DO: Improve error result on API so we don't have to rely on the error message
        if (response.error.message === 'User is not verified') {
          const dialogObj = {
            dialog: 'login',
            userEmail: email,
            openVerifyDialog: true,
            eventSource: 'unverifiedUserOnLogin',
          };
          this.setState(initialState);
          this.props.handleClose(dialogObj);
        }
      } else {
        if (rememberUser) {
          localStorage.setItem('user', JSON.stringify(response.loggedInUser));
        }

        // Check if the user has something in the cart
        if (currentOrder.items) {
          await actions.createOrder(response.loggedInUser, currentOrder, currentOrder.price);
        } else {
          const getOrdersResponse = await actions.getAllResources(response.loggedInUser.token, ['users', response.loggedInUser.id, 'orders']);

          // If the user has an existing order, find the new order and set it in currentOrder
          if (!getOrdersResponse.error && getOrdersResponse.response.length > 0) {
            const order = Functions.getNewestOrder(getOrdersResponse.response);
            if (order.orderStatus === 'NEW') {
              actions.getItemsAndCreateOrder(response.loggedInUser, order, ['users', response.loggedInUser.id, 'orders', order.id, 'items']);
            }
          }
        }

        sendUserToCheckout();
        this.handleClose({ eventSource: 'loginSuccess' });
      }
    } catch (error) {
      console.log('API call error', error);
    }
  };

  handleClose = (closeDialogOptions) => {
    this.setState(initialState);
    this.props.handleClose(closeDialogOptions);
  }

  disableButton = () => {
    const { email, password } = this.state;
    return isEmpty(email && password);
  };

  render() {
    const { open, dialogLoading } = this.props;
    const stepPros = this.getProps();
    const {
      dialogTitle,
      dialogContent,
      dialogContent2,
      dialogActions,
      dialogBodyContainerStyle,
      dialogContentStyle,
    } = stepPros;
    return (
      <DialogView
        open={open}
        titleAlignClose={false}
        handleClose={() => this.handleClose()}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        dialogTitleStyle={dialogTitle.dialogTitleStyle}
        subHeaderImageContainerStyle={dialogTitle.subHeaderImageContainerStyle}
        subHeaderImageStyle={dialogTitle.subHeaderImageStyle}
        dialogBodyContainerStyle={dialogBodyContainerStyle}
        dialogContentStyle={dialogContentStyle}
        titleHasCloseBtn
        dialogTitleText={dialogTitle.dialogTitleText}
        dialogTitleSubheaderImage={dialogTitle.dialogTitleSubheaderImage}
        dialogTitleSubheaderImageAlt={dialogTitle.dialogTitleSubheaderImageAlt}
        hasDialogContent
        hasDialogContent2
        hasDialogErrorContent={false}
        renderDialogContent={() => dialogContent}
        renderDialogContent2={() => dialogContent2}
        hasDialogActions
        actionBtnStyle={dialogActions.actionBtnStyle}
        renderActionBtn={() => dialogActions.actionButtons}
        dialogCloseIconColor={dialogTitle.dialogTitleStyle.color}
        loading={!!dialogLoading}
      />
    );
  }
}

LoginDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  rememberUser: PropTypes.bool.isRequired,
  translation: PropTypes.func.isRequired,
  currentOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  sendUserToCheckout: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(withWidth()(LoginDialog)));

