
    module.exports = function cssToMuiLoader(theme) {
      
      return {
        
        'main': {'width': `100%`,'padding': `16px 0px 48px 0px`,'margin': `0px 0px 32px 0px`,},'heading': {'display': `flex`,'justifyContent': `center`,'width': `70%`,'margin': `0 auto`,},'noOrders': {'fontSize': `16px`,'marginTop': `36px`,'textAlign': `center`,'color': `#ffffff`,},'orders': {'display': `flex`,'flexWrap': `wrap`,'padding': `16px 0px 100px 36px`,'justifyContent': `flex-start`,'width': `95%`,'margin': `0 auto`,},'orderDate': {'color': `#282828`,},'orderStatus': {'color': `#282828`,'fontWeight': `bold`,'fontFamily': `'Lato-Regular', sans-serif`,'paddingTop': `0.875rem`,},'orderStatusType': {'color': `#d18a22`,'fontWeight': `bold`,'fontFamily': `'Lato-Regular', sans-serif`,'paddingTop': `0.875rem`,},'orderInfo': {'fontWeight': `bold`,'fontSize': `1.1rem`,'margin': `8px 0px 8px 0px`,'fontFamily': `'Lato-Regular', sans-serif`,'color': `#282828`,},'orderStatusContainer': {'display': `flex`,},'card': {'width': `auto`,'minWidth': `40vw`,'height': `auto`,'minHeight': `50vh`,'margin': `16px`,'backgroundColor': `#ffffff`,'boxShadow': `0 1px 2px 0 rgba(0, 0, 0, 0.3)`,'&:hover': {
          'cursor': `pointer`,
        },},'cardHeadingContainer': {'display': `flex`,'flexDirection': `row`,'justifyContent': `space-between`,},'cardHeadingText': {'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `1.25rem`,'fontWeight': `600`,'color': `#282828`,},'icon': {'color': `#282828`,'width': `20px`,'height': `25px`,'padding': `2px 8px 0px 0px`,},'smallIcon': {'color': `#282828`,'width': `0.75rem`,'height': `25px`,'padding': `2px 8px 0px 0px`,'fontSize': `1rem`,},'titleWithIconContainer': {'display': `flex`,'flexDirection': `row`,},'orderNumber': {'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `1.25rem`,'fontWeight': `400`,'color': `#282828`,},'sectionContainer': {'padding': `1rem 0rem`,},'addressSection': {'padding': `1rem 0rem`,'minHeight': `22vh`,},'instructionsSection': {'padding': `1rem 0rem`,'width': `25rem`,'padding': `1rem 2.5rem`,'textAlign': `center`,},'title': {'color': `#282828`,'fontSize': `0.75rem`,'padding': `1.5px 0px 0px 0px`,},'dateTimeContainer': {'padding': `0 0 0.5rem 0`,},'dateTime': {'color': `#282828`,'fontSize': `0.875rem`,},'orderItem': {'color': `#4a4a4a`,'fontSize': `0.875rem`,},'pickupAddressSection': {'display': `flex`,'justifyContent': `space-between`,},'addressContainer': {'padding': `1rem 0`,},'locationAddress': {'color': `#282828`,'width': `15rem`,'fontSize': `0.875rem`,},'callButtonContainer': {'display': `flex`,'alignItems': `flex-start`,'padding': `1rem 0rem`,'width': `6rem`,},'callButton': {'backgroundColor': `#d18a22`,'color': `#ffffff`,'fontWeight': `500`,'&:hover': {
          'backgroundColor': `#a76e1b`,'color': `#ffffff`,'fontWeight': `500`,
        },},'telLink': {'textDecoration': `none`,'width': `7rem`,},'buttonContainer': {'display': `flex`,'justifyContent': `center`,},'orderButton': {'width': `15rem !important`,'height': `3.25rem !important`,},'curbsideContainer': {'display': `flex`,'flexDirection': `column`,'alignItems': `center`,},'instructionsHeading': {'fontWeight': `600`,'fontSize': `0.875rem`,'fontFamily': `'Lato-Regular', sans-serif`,'color': `#282828`,},'instructionsBody': {'fontWeight': `400`,'fontSize': `0.875rem`,'fontFamily': `'Lato-Regular', sans-serif`,'color': `#282828`,},'notifyButtonContainer': {'display': `flex`,'justifyContent': `center`,'padding': `1.5rem 0rem 1rem 0rem`,},'notifyButton': {'width': `15rem !important`,'height': `3.25rem !important`,'fontWeight': `500`,},
        
      };
    };
  