import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

import * as Functions from '../../services/functions/Functions';
import { getResources, getCurrentOrder } from '../../selectors';
import ThankYouComponent from './subComponents/ThankYouComponent';
import StatusComponent from './subComponents/StatusComponent';
import LocationPrepComponent from './subComponents/LocationPrepComponent';
import styles from '../../css/orderStatusPage/OrderStatusPage.scss';
import * as Routes from '../../services/routes/Routes.json';
import * as ResponsiveStyles from '../../../jsonStyles/components/orderStatusPage/OrderStatusPage.style.json';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.content = {
  ...responsiveStyles.content,
  [theme.breakpoints.down('sm')]: {
    flexDirection: ResponsiveStyles.content.flexDirection,
    width: ResponsiveStyles.content.width,
  },
};

responsiveStyles.main = {
  ...responsiveStyles.main,
  [theme.breakpoints.down('sm')]: {
    alignItems: ResponsiveStyles.main.alignItems,
    boxSizing: ResponsiveStyles.main.boxSizing,
    justifyContent: ResponsiveStyles.main.justifyContent,
    padding: ResponsiveStyles.main.padding,
  },
};

responsiveStyles.info = {
  ...responsiveStyles.info,
  [theme.breakpoints.down('sm')]: {
    width: ResponsiveStyles.info.width,
    margin: ResponsiveStyles.info.margin,
  },
};

class OrderStatusPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderPlaced: {},
    };
  }

  async componentDidMount() {
    const { actions, user, history } = this.props;
    if (user) {
      try {
        const response = await actions.getAllResources(user.token, ['users', user.id, 'orders']);
        if (!response.error && response.response.length > 0) {
          const order = Functions.getNewestOrder(response.response);
          this.setState({ orderPlaced: order });
        }
      } catch (error) {
        console.log('There is an API error', error);
      }
    } else {
      // Redirect users that are not logged in to menu page
      history.push(Routes.path.menuPage);
    }
  }

  componentDidUpdate() {
    const { user, history } = this.props;
    if (!user) {
      history.push(Routes.path.menuPage);
    }
  }

  render() {
    const {
      classes,
      translation,
      user,
      loading,
    } = this.props;
    const { orderPlaced } = this.state;
    return (
      loading !== 0
        ? <LinearProgress />
        // TODO: redirect if user null or undefined
        : user && (
          <div className={classes.main}>
            <div className={classes.content}>
              <ThankYouComponent
                user={user}
                translation={translation}
                orderPlaced={orderPlaced}
              />
              <div className={classes.info}>
                <StatusComponent
                  translation={translation}
                  orderPlaced={orderPlaced}
                />
                <LocationPrepComponent
                  translation={translation}
                  orderPlaced={orderPlaced}
                />
              </div>
            </div>
          </div>
        )
    );
  }
}

OrderStatusPage.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  loading: PropTypes.number.isRequired,
  translation: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

OrderStatusPage.defaultProps = {
  user: null,
};

const mapStateToProps = state => ({
  resources: getResources(state),
  currentOrder: getCurrentOrder(state),
});

export default withStyles(responsiveStyles)(withRouter(connect(mapStateToProps)(OrderStatusPage)));
