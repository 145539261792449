import { combineReducers } from 'redux';
import user from './AuthReducer';
import message from './MessageReducer';
import loading from './LoadingReducer';
import resources from './ResourceReducer';
import currentOrder from './OrderReducer';
import component from './ComponentReducer';
import notifications from './NotificationReducer';
import translations from './TranslationsReducer';
import featureFlags from './FeatureFlagReducer';

const RootReducer = combineReducers({
  user,
  message,
  loading,
  resources,
  currentOrder,
  component,
  notifications,
  translations,
  featureFlags,
});

export default RootReducer;
