import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Immutable from 'seamless-immutable';
import {
  withStyles,
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import {
  TextField,
  Input,
  Typography,
  Drawer,
  Divider,
  Icon,
  IconButton,
  ButtonGroup,
  CircularProgress,
  Switch,
} from '@material-ui/core';
import {
  func,
  objectOf,
  string,
  bool,
  any,
  arrayOf,
} from 'prop-types';

import Button from '../core/components/Button';
import * as Routes from '../../services/routes/Routes.json';
import LinkText from '../core/components/LinkText';
import OrderItemList from '../core/components/OrderItemList';
import SubTotalSummary from '../core/components/SubTotalSummary';
import ProductFlowDialog from '../product/ProductFlowDialog';
import TableNumberDropdown from '../core/components/TableNumberDropdown';
import {
  getCurrentOrder,
  getCurrentOrderItems,
  getDollarRewardCoupons,
  getIsProductDialogVisible,
  getIsCheckoutDrawerVisible,
  getProducts,
  getCategories,
  getLoading,
  getLocations,
  getCompany,
} from '../../selectors';
import * as Actions from '../../actions/Actions';
import styles from '../../css/checkout/CheckoutDrawer.scss';
import * as ResponsiveStyles from '../../../jsonStyles/components/checkout/CheckoutDrawer.style.json';
import {
  getFormattedPrice,
  isDollarReward,
  isCouponRedeemed,
  getProductFamily,
  getTipAmount,
  getSelectedTipOptionId,
  isInMenuPage,
  isCurbsideEnabled,
  toFixedPrecision,
  calculatePrice,
  getTableNumbers,
} from '../../services/functions/Functions';
import {
  DINE_IN,
  PICKUP,
  CATERING,
  DELIVERY,
  deliveryOptionIcons,
} from '../../services/constants/Constants';
import {
  tipOptions,
  smartTipOptions,
  smartTipThreshold,
  instructionsBoxLines,
  curbsideBoxLines,
} from '../../services/constants/ResourceConstants.json';
import DateHelper from '../../services/helpers/DateHelper';
import * as FeatureFlags from '../../../feature-flags.json';

// Return styles object when testing
let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.checkoutDrawer = {
  ...responsiveStyles.checkoutDrawer,
  [theme.breakpoints.down('sm')]: ResponsiveStyles.checkoutDrawer,
};

const TimeLocationSelector = (props) => {
  const {
    classes,
    translation,
    currentOrder,
    actions,
    categories,
    isInMenuPage,
    locations,
  } = props;

  const {
    desiredTime,
    deliveryOption,
    isASAP,
  } = currentOrder;

  const atText = translation('at');

  const getLocationText = () => {
    if ([DINE_IN, PICKUP].includes(deliveryOption)) {
      return get(currentOrder, 'location.address', '');
    }
    return get(currentOrder, 'address.streetAddress', '');
  };

  const getDesiredTime = () => {
    if (desiredTime == null) return '';
    const desiredDateText = DateHelper.getSuggestedTimeIntervalString(desiredTime, '-').toUpperCase();
    return `${atText} ${desiredDateText}`;
  };

  const showOrderFlowDialog = () => {
    actions.toggleComponent('OrderFlowDialog');
  };

  const removeUnderscores = text => text && text.replace(/_/g, ' ');

  return (
    <div className={classes.timeLocationContainer}>
      <div className={classes.timeLocation}>
        <div className={classes.timeContainer}>
          <Icon>{deliveryOptionIcons[deliveryOption]}</Icon>
          <Typography className={classes.timeLocationTitle}>
            {`${removeUnderscores(deliveryOption) || translation('CheckoutDrawer.emptyDeliveryOption')} ${getDesiredTime()}`}
          </Typography>
        </div>
        <Typography className={classes.addressText}>
          {getLocationText()}
        </Typography>
      </div>
      {
        isInMenuPage
          && (
            <LinkText
              className={classes.linkText}
              onClick={showOrderFlowDialog}
              id="changeOrder"
              text={translation('CheckoutDrawer.orderLocationSelector.change')}
            />
        )
      }
    </div>
  );
};

const EmptyCart = (props) => {
  const {
    classes, translation,
  } = props;
  return (
    <div className={classes.emptyOrderContainer}>
      <div className={classes.iconContainer}>
        <Icon className={classes.emptyCartIcon}>shopping_cart</Icon>
      </div>
      <Typography className={classes.emptyCartText}>{translation('CheckoutDrawer.emptyCart')}</Typography>
      <Typography className={classes.addItemsText}>{translation('CheckoutDrawer.emptyCartSubTitle')}</Typography>
    </div>
  );
};

const CouponInput = (props) => {
  const {
    classes, isCouponInputVisible, applyCoupon, translation,
  } = props;
  const [couponCode, setCouponCode] = useState('');

  if (!isCouponInputVisible) return null;
  return (
    <Fragment>
      <Divider />
      <form className={classes.couponForm} noValidate autoComplete="off">
        <TextField
          className={classes.couponInput}
          id="coupon-input"
          variant="outlined"
          placeholder={translation('CheckoutDrawer.couponInput')}
          onChange={event => setCouponCode(event.target.value)}
        />
        <Button
          type="primary"
          id="applyCoupon"
          onClick={() => applyCoupon(couponCode)}
          text={translation('CheckoutDrawer.orderDetails.apply')}
        />
      </form>
      <Divider />
    </Fragment>
  );
};

const DollarRewardButton = (props) => {
  const {
    currentOrder, coupon, translation, removeCoupon, applyCoupon,
  } = props;

  if (!isDollarReward(coupon)) return null;
  const couponRedeemed = isCouponRedeemed(coupon, currentOrder);
  const buttonText = couponRedeemed
    ? translation('CheckoutDrawer.orderDetails.unredeem')
    : translation('CheckoutDrawer.orderDetails.use');

  const onClickButton = couponRedeemed
    ? () => removeCoupon(coupon)
    : () => applyCoupon(coupon.code);
  return (
    <Button
      type="secondary"
      onClick={onClickButton}
      text={buttonText}
    />
  );
};

const RemoveButton = (props) => {
  const {
    coupon, removeCoupon,
  } = props;
  const buttonText = 'Remove Coupon';
  if (isDollarReward(coupon)) return null;
  return (
    <Button
      type="tertiary"
      onClick={() => removeCoupon(coupon)}
      text={buttonText}
    />
  );
};

const Coupon = (props) => {
  const { coupon, classes } = props;
  const { code } = coupon;
  const couponText = isDollarReward(coupon)
    ? coupon.title
    : `Coupon code: ${code}`;
  return (
    <div className={classes.couponItem}>
      <Typography className={classes.couponText}>
        {couponText}
      </Typography>
      <RemoveButton {...props} coupon={coupon} />
      <DollarRewardButton {...props} coupon={coupon} />
    </div>
  );
};

const CouponList = (props) => {
  const {
    classes, currentOrder, actions, user,
  } = props;

  if (user) {
    useEffect(() => {
      actions.getAllResources(user.token, ['coupons']);
    }, []);
  }

  const getAvailableRewardCoupons = (coupons) => {
    const userPoints = user && user.points;
    return coupons.filter((coupon) => {
      const couponPoints = get(coupon, 'rewardTiers.points', null);
      return couponPoints && userPoints >= couponPoints;
    });
  };

  const orderCoupons = get(currentOrder, 'coupons', []).filter(coupon => !isDollarReward(coupon));
  const allDollarRewardCoupons = get(props, 'dollarRewardCoupons', []);
  const availableRewardCoupons = getAvailableRewardCoupons(allDollarRewardCoupons);
  const allCoupons = [...orderCoupons, ...availableRewardCoupons];

  return (
    <div className={classes.couponList}>
      {
        allCoupons.map(coupon => <Coupon {...props} key={coupon.id} coupon={coupon} />)
      }
    </div>
  );
};

const CouponSummary = (props) => {
  const {
    classes, currentOrder, actions, user, translation,
  } = props;

  const [isCouponInputVisible, setIsCouponInputVisible] = useState(false);

  const toggleCouponInput = () => {
    setIsCouponInputVisible(!isCouponInputVisible);
  };

  const applyCoupon = (couponCode) => {
    const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
    if (isEmpty(couponCode)) return null;
    const coupons = [...mutableOrder.coupons, { code: couponCode }];
    mutableOrder.coupons = coupons;
    actions.updateOrder(user, mutableOrder, currentOrder.id);
  };

  return (
    <Fragment>
      <CouponList
        {...props}
        applyCoupon={applyCoupon}
        removeCoupon={(coupon) => {
          actions.deleteOrderCoupon(user, currentOrder, coupon.id);
        }}
      />
      <CouponInput
        {...props}
        isCouponInputVisible={isCouponInputVisible}
        applyCoupon={couponCode => applyCoupon(couponCode)}
      />
      {
        !isCouponInputVisible && (
          <LinkText
            id="toggleCouponInput"
            onClick={toggleCouponInput}
            className={classes.addCouponLink}
            text={`+ ${translation('CheckoutDrawer.addCoupon')}`}
          />
        )
      }
    </Fragment>
  );
};

const TotalPrice = (props) => {
  const { classes, currentOrder, translation } = props;
  const orderTotalPrice = currentOrder.totalPrice || 0;
  const priceText = getFormattedPrice(orderTotalPrice);
  return (
    <div className={classes.totalPriceContainer}>
      <Divider />
      <div className={classes.totalPriceItem}>
        <Typography className={classes.totalPriceText}>
          {translation('CheckoutDrawer.summary.total')}
        </Typography>
        <Typography className={classes.totalPriceText} >{priceText}</Typography>
      </div>
      <Divider />
    </div>
  );
};

const InstructionsBox = (props) => {
  const {
    classes, actions, translation, user, currentOrder,
  } = props;

  const [orderNote, setOrderNote] = useState(currentOrder.note);

  const updateOrderNote = () => {
    const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
    mutableOrder.note = orderNote;
    actions.updateOrder(user, mutableOrder, currentOrder.id);
  };

  return (
    <div className={classes.instructionBox}>
      <Typography className={classes.instructionTitle}>{translation('CheckoutDrawer.orderDetails.note')}</Typography>
      <TextField
        className={classes.instructionsInput}
        id="instructions-input"
        variant="outlined"
        onChange={event => setOrderNote(event.target.value)}
        value={orderNote}
        onBlur={updateOrderNote}
        rows={instructionsBoxLines}
        multiline
      />
    </div>
  );
};

const getTipOptionText = (props) => {
  const { tipOption, translation } = props;
  if (tipOption.id === 0) {
    return translation('TipDialog.noTip');
  } else if (tipOption.id === 'customize') {
    return translation('custom');
  }
  return tipOption.key;
};

const TipOption = (props) => {
  const {
    tipOption, isSelected, setSelectedTip,
  } = props;

  // ButtonGroup from TipOptions passes these props to it's children
  const muiButtonProps = {
    className: props.className,
    disabled: props.disabled,
    color: props.color,
    disableFocusRipple: props.disableFocusRipple,
    disableRipple: props.disableRipple,
    fullWidth: props.fullWidth,
    size: props.size,
    variant: props.variant,
  };

  return (
    <Button
      {...muiButtonProps}
      overrideClass
      hasBorderRadius={false}
      hasBorderColor={false}
      type="secondary"
      styleOverride={isSelected ? responsiveStyles.selectedTipOverride : responsiveStyles.unSelectedTipOverride}
      selected={isSelected}
      onClick={() => setSelectedTip(tipOption.id)}
      text={getTipOptionText(props)}
    />
  );
};

const TipInput = (props) => {
  const {
    classes, updateTip, translation, currentOrder, user, actions,
  } = props;

  const [tipAmountInput, setTipAmountInput] = useState('');

  const updateOrderTipAmount = (tipAmount) => {
    const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
    mutableOrder.tipAmount = tipAmount;
    actions.updateOrder(user, mutableOrder, currentOrder.id);
  };

  return (
    <form noValidate autoComplete="off" className={classes.tipInputForm}>
      <div className={classes.tipFormContainer}>
        <Input
          className={classes.tipInput}
          id="tip-input"
          type="number"
          disableUnderline
          fullWidth
          placeholder={translation('TipDialog.enterAmount')}
          onChange={event => setTipAmountInput(event.target.value)}
        />
      </div>
      <Button
        type="primary"
        fullWidth
        onClick={() => updateOrderTipAmount(tipAmountInput)}
        text={translation('save')}
        overrideClass
        className={classes.tipInputButton}
      />
    </form>
  );
};

const TipOptions = (props) => {
  const {
    currentOrder,
    actions,
    user,
    classes,
  } = props;
  const useSmartTip = FeatureFlags.CheckoutDrawer.enableSmartTip && (currentOrder.totalPrice <= smartTipThreshold);

  const [selectedTip, setSelectedTip] = useState();

  useEffect(() => {
    setSelectedTip(getSelectedTipOptionId(currentOrder, useSmartTip));
  }, []);

  const options = useSmartTip
    ? smartTipOptions
    : tipOptions;

  const setTotalPriceOnOrder = () => actions.updateOrder(user, currentOrder, currentOrder.id);

  const updateOrderTip = async (tipOptionId) => {
    // Stops a bug where selecting the tip for the first time on the CheckoutDrawer leads to a 0 tip
    // set on the order, because accurate calculation of the tip amount depends on totalPrice, but
    // totalPrice itself starts at 0 before running actions.updateOrder once.
    if (currentOrder.totalPrice === 0) {
      const newOrderResponse = await setTotalPriceOnOrder();
      const { order: newOrder } = newOrderResponse;
      if (newOrder) {
        const mutableOrder = Immutable.asMutable(newOrder, { deep: true });
        mutableOrder.tipAmount = getTipAmount(tipOptionId, newOrder, useSmartTip);
        actions.updateOrder(user, mutableOrder, newOrder.id);
        return;
      }
    }
    const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
    mutableOrder.tipAmount = getTipAmount(tipOptionId, currentOrder, useSmartTip);
    actions.updateOrder(user, mutableOrder, currentOrder.id);
  };

  const [isCustomTipVisible, setIsCustomTipVisible] = useState(false);

  const handleClickTipOption = (tipOptionId) => {
    setSelectedTip(tipOptionId);
    if (tipOptionId === 'customize' && !isCustomTipVisible) {
      setIsCustomTipVisible(!isCustomTipVisible);
    } else if (tipOptionId !== 'customize' && isCustomTipVisible) {
      setIsCustomTipVisible(!isCustomTipVisible);
      updateOrderTip(tipOptionId);
    } else {
      updateOrderTip(tipOptionId);
    }
  };

  const generateKey = index => (`key${index}`);

  return (
    <Fragment>
      <ButtonGroup fullWidth className={classes.tipButtonGroup}>
        {
          options.map((tipOption, i) => (
            <TipOption
              {...props}
              tipOption={tipOption}
              isSelected={tipOption.id === selectedTip}
              setSelectedTip={handleClickTipOption}
              key={generateKey(i)}
            />
          ))
        }
      </ButtonGroup>
      {
        isCustomTipVisible && (
          <TipInput
            {...props}
          />
        )
      }
    </Fragment>
  );
};

const CurbsideInstructions = (props) => {
  const {
    classes,
    translation,
    currentOrder,
    actions,
    user,
  } = props;

  const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
  const showToggle = FeatureFlags.CheckoutDrawer.curbsidePickupToggleShow;
  const defaultCurbsideState = mutableOrder.isCurbsidePickUp !== null ? mutableOrder.isCurbsidePickUp : FeatureFlags.CheckoutDrawer.curbsidePickupToggleDefault;

  const [toggleState, setToggleState] = useState({
    curbsideChecked: defaultCurbsideState,
  });

  const handleToggleChange = (event) => {
    setToggleState({ ...toggleState, [event.target.name]: event.target.checked });
    mutableOrder.isCurbsidePickUp = !toggleState.curbsideChecked;
    actions.updateOrder(user, mutableOrder, currentOrder.id);
  };

  const [curbsideNote, setCurbsideNote] = useState(currentOrder.vehicleInformation);

  const updateCurbsideInstructions = () => {
    mutableOrder.vehicleInformation = curbsideNote;
    mutableOrder.isCurbsidePickUp = true;
    actions.updateOrder(user, mutableOrder, currentOrder.id);
  };

  return (
    <div>
      {
        <div className={classes.curbsideToggleContainer}>
          <Typography className={classes.curbsideToggleLabel}>
            {translation('CheckoutDrawer.curbsidePickup.curbsidePickup')}
          </Typography>
          {
            showToggle
            && (
              <Switch
                checked={toggleState.curbsideChecked}
                onChange={handleToggleChange}
                name="curbsideChecked"
                color="primary"
              />
            )
          }
        </div>
      }
      {
        toggleState.curbsideChecked
        && (
          <div className={classes.instructionBox}>
            <Typography className={classes.instructionTitle}>
              {translation('CheckoutDrawer.curbsidePickup.vehicleInformation')}
            </Typography>
            <TextField
              className={classes.instructionsInput}
              id="vehicle-input"
              variant="outlined"
              onChange={event => setCurbsideNote(event.target.value)}
              value={curbsideNote}
              onBlur={updateCurbsideInstructions}
              rows={curbsideBoxLines}
              multiline
              placeholder={translation('CheckoutDrawer.curbsidePickup.instructionsPlaceholder')}
            />
          </div>
        )
      }
    </div>
  );
};

const CartContent = (props) => {
  const {
    locations, currentOrder = {}, translation, actions, user,
  } = props;
  const curbsideEnabled = currentOrder.deliveryOption === PICKUP && isCurbsideEnabled(locations, currentOrder);
  // TODO: CRV-11813 Refactor to enable tipping by delivery option type (incl curbside pickup)
  // and this will require show/hide tipping if curbside is toggled on/off
  const orderItems = get(currentOrder, 'items');
  const tableNumbers = currentOrder.location ? getTableNumbers(currentOrder.location) : [];
  const isDineIn = currentOrder.deliveryOption === DINE_IN;

  if (isEmpty(orderItems)) return <EmptyCart {...props} />;
  return (
    <Fragment>
      <OrderItemList
        handleRemove={props.handleRemove}
        handleEdit={props.handleEdit}
        actions={props.actions}
        orderItems={props.orderItems}
        translation={props.translation}
        isBelowMinimum={props.isBelowMinimum}
      />
      <SubTotalSummary
        translation={props.translation}
      />
      <Divider />
      {
        FeatureFlags.CheckoutDrawer.enableTipping
        && (
          <TipOptions
            {...props}
          />
        )
      }
      <CouponSummary
        {...props}
      />
      <TotalPrice
        {...props}
      />
      {
        curbsideEnabled
        && (
          <CurbsideInstructions
            {...props}
          />
        )
      }
      {
        isDineIn && (tableNumbers.length > 0)
        && (
          <TableNumberDropdown
            value={currentOrder.tableNumber}
            onChange={(event) => { actions.updateOrder(user, { ...currentOrder, tableNumber: event.target.value }, currentOrder.id); }}
            tableNumbers={tableNumbers}
            translation={translation}
          />
        )
      }
      <InstructionsBox
        {...props}
      />
    </Fragment>
  );
};

const LoadingOverlay = (props) => {
  const { classes } = props;
  return (
    <div className={classes.loadingOverlay}>
      <div className={classes.circularProgressContainer}>
        <CircularProgress />
      </div>
    </div>
  );
};

const CheckoutDrawer = (props) => {
  const {
    actions,
    isDesktop,
    open,
    classes,
    currentOrder = {},
    history,
    handleClickAuthentication,
    user,
    translation,
    stateVisibleProductDialog,
    products,
    loading,
    company,
  } = props;
  const { deliveryZone, cateringZone, deliveryOption } = currentOrder;

  let isBelowMinimum = false;

  const calculateSubTotalWithoutRedeem = () => {
    let subTotalResult = 0;
    if (currentOrder && currentOrder.items && products) {
      currentOrder.items.forEach((item) => {
        const itemTotal = calculatePrice([item], products, null, company);
        subTotalResult = itemTotal + subTotalResult;
      });
    }
    return subTotalResult;
  };

  const calculateIfBelowMinimum = () => {
    const zoneMinimum = deliveryOption === DELIVERY
      ? deliveryZone && deliveryZone.minimum
      : cateringZone && cateringZone.minimum;
    const belowMinimumFee = deliveryOption === DELIVERY
      ? deliveryZone && deliveryZone.belowMinimumFee
      : cateringZone && cateringZone.belowMinimumFee;
    const subTotalWithoutRedeem = calculateSubTotalWithoutRedeem();
    // If belowMinimumFee is null, then the zone minimum must be met to place an order
    return subTotalWithoutRedeem < zoneMinimum && !belowMinimumFee;
  };

  if ([CATERING, DELIVERY].includes(deliveryOption)) isBelowMinimum = calculateIfBelowMinimum();

  const orderItems = get(currentOrder, 'items');
  const [selectedOrderItem, setSelectedOrderItem] = useState(null);

  useEffect(() => {
    // declare the data fetching function before call.
    const fetchOrder = async () => {
      if (currentOrder.id) {
        await actions.updateOrder(user, currentOrder, currentOrder.id);
      } else {
        const newOrder = await actions.createOrder(user, currentOrder, currentOrder.price);
        if (newOrder && newOrder.id) await actions.updateOrder(user, newOrder, newOrder.id);
      }
    };

    if (!user) return;
    if (open) {
      fetchOrder();
    }
  }, [open]);

  const removeItem = (orderItem) => {
    const orderItemId = orderItem.id;
    // TO-DO: Handle redeemed items being removed.
    actions.deleteOrderItem(user, orderItemId, currentOrder.id);
  };

  const toggleProductDialog = () => {
    actions.toggleComponent('ProductDialog');
  };

  const setEditItem = (orderItem) => {
    toggleProductDialog();
    setSelectedOrderItem(orderItem);
  };

  const getZoneMinimum = (zone) => {
    if (zone) return zone.minimum;
    return 0.0;
  };

  const renderOrderBelowMinimumMessage = () => {
    const orderLocationName = currentOrder.location ? currentOrder.location.name : '';
    const zoneMinimum = deliveryOption === DELIVERY
      ? getZoneMinimum(deliveryZone)
      : getZoneMinimum(cateringZone);
    return (
      <div className={classes.belowMinimumMessageContainer}>
        <span>
          <Typography className={classes.errorText}>
            {
              deliveryOption === DELIVERY
                ? translation('CheckoutDrawer.deliveryMinimumNotMet.warning')
                : translation('CheckoutDrawer.cateringMinimumNotMet.warning')
            }
          </Typography>
          <Typography className={classes.errorTextBold}>
            {` ${orderLocationName} `}
          </Typography>
          <Typography className={classes.errorText}>{translation('CheckoutDrawer.deliveryMinimumNotMet.minimum')}</Typography>
          <Typography className={classes.errorTextBold}>{` $${toFixedPrecision(zoneMinimum, 2)} ${translation('CheckoutDrawer.deliveryMinimumNotMet.beforeTax')} `}</Typography>
          <Typography className={classes.errorText}>{translation('CheckoutDrawer.deliveryMinimumNotMet.instruction')}</Typography>
        </span>
      </div>
    );
  };

  const getSelectedProductId = () => {
    const productFamily = getProductFamily(products, selectedOrderItem.productItem.id);
    return productFamily ? productFamily.id : null;
  };

  const goToPaymentPage = async () => {
    actions.toggleComponent('CheckoutDrawer');
    if (FeatureFlags.CoreView.PaymentDrawer.show) {
      actions.toggleComponent('PaymentDrawer');
    } else {
      history.push(Routes.path.paymentPage, { points: user.points });
    }
  };

  if (open && !user) {
    handleClickAuthentication(null, { cartIconWasPressed: true });
    return null;
  }

  const orderTotalPrice = get(currentOrder, 'totalPrice') || 0;

  return (
    <Drawer
      classes={{
        paper: classes.checkoutDrawer,
      }}
      open={open}
      onClose={() => actions.toggleComponent('CheckoutDrawer')}
      anchor={isDesktop ? 'right' : 'bottom'}
    >
      {
        isBelowMinimum
        && (
          <div className={classes.unavailableWarningOverlay}>
            <div className={classes.unavailableWarningHeader}>
              <Icon
                color="error"
                fontSize="small"
              >
                warning
              </Icon>
              <Typography
                className={classes.unavailableWarningTitle}
              >
                {
                  currentOrder.deliveryOption === DELIVERY
                    ? translation('CheckoutDrawer.deliveryMinimumNotMet.title')
                    : translation('CheckoutDrawer.cateringMinimumNotMet.title')
                }
              </Typography>
            </div>
            <Typography>
              {renderOrderBelowMinimumMessage()}
            </Typography>
          </div>
        )
      }
      {
        loading !== 0
        && (
          <LoadingOverlay {...props} />
        )
      }
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          <Typography className={classes.headerTitle}>
            {translation('CheckoutDrawer.drawerTitle')}
          </Typography>
          <IconButton className={classes.closeIcon} onClick={() => actions.toggleComponent('CheckoutDrawer')}>
            <Icon>close</Icon>
          </IconButton>
        </div>
        <Divider />
      </div>
      <div className={classes.body}>
        <CartContent
          {...props}
          orderItems={orderItems}
          handleEdit={setEditItem}
          handleRemove={removeItem}
          isBelowMinimum={isBelowMinimum}
        />
      </div>
      <div className={classes.footer}>
        <Divider className={classes.footerDivider} />
        <TimeLocationSelector
          {...props}
          isInMenuPage={isInMenuPage(props.history)}
        />
        <Button
          fullWidth
          id="goToPayment"
          onClick={goToPaymentPage}
          type="primary"
          disabled={isBelowMinimum}
          text={`${translation('CheckoutDrawer.orderDetails.makePayment')} (${currentOrder ? getFormattedPrice(orderTotalPrice) : ''})`}
        />
        <LinkText
          id="continueShopping"
          onClick={() => actions.toggleComponent('CheckoutDrawer')}
          className={classes.shoppingLinkText}
          text={translation('CheckoutDrawer.continueShopping')}
        />
        {
          stateVisibleProductDialog && selectedOrderItem
          && (
            <ProductFlowDialog
              open={stateVisibleProductDialog}
              user={user}
              actions={actions}
              handleClose={toggleProductDialog}
              translation={translation}
              history={history}
              productId={getSelectedProductId()}
              currentItemFromCheckout={selectedOrderItem}
              isEditFromCheckout
            />
          )
        }
      </div>
    </Drawer>
  );
};

CheckoutDrawer.propTypes = {
  classes: objectOf(string).isRequired,
  open: bool.isRequired,
  isDesktop: bool.isRequired,
  translation: func.isRequired,
  currentOrder: objectOf(any).isRequired,
  products: arrayOf(objectOf(any)).isRequired,
  company: objectOf(any),
};

CheckoutDrawer.defaultProps = {
  company: {},
};

CurbsideInstructions.propTypes = {
  classes: objectOf(string).isRequired,
  translation: func.isRequired,
  currentOrder: objectOf(any).isRequired,
  actions: objectOf(func).isRequired,
  user: objectOf(any),
};

CurbsideInstructions.defaultProps = {
  user: null,
};

const mapStateToProps = state => ({
  open: getIsCheckoutDrawerVisible(state),
  currentOrder: getCurrentOrder(state),
  currentOrderItems: getCurrentOrderItems(state),
  dollarRewardCoupons: getDollarRewardCoupons(state),
  loading: getLoading(state),
  stateVisibleProductDialog: getIsProductDialogVisible(state),
  products: getProducts(state),
  categories: getCategories(state),
  locations: getLocations(state),
  company: getCompany(state),
});

const mapDispatchToProps = dispatch => ({
  createOrder: (user, order) => dispatch(Actions.createOrder(user, order)),
});

const EnhancedCheckoutDrawer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(responsiveStyles),
)(CheckoutDrawer);
export default EnhancedCheckoutDrawer;
