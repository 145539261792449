import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import * as Functions from '../../../services/functions/Functions';
import * as ResponsiveStyles from '../../../../jsonStyles/components/paymentPage/subComponents/PaymentDetailsComponent.style.json';
import styles from '../../../css/paymentPage/subComponents/PaymentDetailsComponent.scss';
import * as FeatureFlags from '../../../../feature-flags.json';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

const PaymentDetailsComponent = (props) => {
  const { classes, translation, currentOrder } = props;
  const showDesiredTime = FeatureFlags.PaymentDetailsComponent
    && FeatureFlags.PaymentDetailsComponent.showDesiredTime;

  return (
    <div className={classes.detailsContainer}>
      <div className={classes.addressDetails}>
        {Functions.renderAddress(currentOrder, currentOrder.deliveryOption, classes, translation)}
      </div>
      <div className={classes.timeDetails}>
        {/* For ASAP orders, desiredTime is null - do not display */}
        { currentOrder.desiredTime && showDesiredTime
          && (
            <Typography className={classes.dateAndTime}>
              {
                `${translation('PaymentDetailsComponent.dateAndTime')}:
                ${Functions.formatDateString(currentOrder.desiredTime)} -
                ${Functions.formatDesiredTime(currentOrder.desiredTime)}`
              }
            </Typography>
          )
        }
      </div>
      <div className={classes.tableDetails}>
        { Functions.shouldShowTableNumber(currentOrder)
          && (
          <Typography className={classes.boldText}>
            {
              `${translation('PaymentDetailsComponent.tableNumber')}:
              ${currentOrder.tableNumber}`
            }
          </Typography>
          )
        }
      </div>
    </div>
  );
};

PaymentDetailsComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  currentOrder: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default (withStyles(responsiveStyles)(PaymentDetailsComponent));

