import { browserHistory } from 'react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import RootReducer from '../reducers';

const middleware = routerMiddleware(browserHistory);

const enhancers = [
  applyMiddleware(middleware, thunk),
];

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

const persistConfig = {
  key: 'root',
  storage: storageSession,
  blacklist: ['loading', 'dialogLoading'],
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

export const store = createStore(persistedReducer, composeEnhancers(compose(...enhancers)));
export const persistor = persistStore(store);
