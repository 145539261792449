import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl/FormControl';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import withWidth from '@material-ui/core/withWidth/withWidth';
import { Typography } from '@material-ui/core';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import styles from '../../../css/core/components/TableNumberDropdown.scss';
import * as ResponsiveStyles from '../../../../jsonStyles/components/core/components/TableNumberDropdown.json';
import { getResponsiveStyles } from '../../../services/functions/Functions';

let localTheme = createMuiTheme();
localTheme = responsiveFontSizes(localTheme);

const responsiveStyles = getResponsiveStyles(styles, localTheme, ResponsiveStyles);

class TableNumberDropdown extends React.Component {
  getFormControlClasses = classes => ({ root: classes.formControl })

  getSelectClasses = classes => ({ root: classes.select })

  getMenuPaddingClasses = scssClasses => ({
    classes: {
      list: scssClasses.menuListRoot,
    },
  })

  renderAllTableNumbers = () => {
    const { tableNumbers } = this.props;
    if (!tableNumbers || tableNumbers.length === 0) return null;
    return tableNumbers.map(tableNumber => <MenuItem value={tableNumber}>{tableNumber}</MenuItem>);
  }

  render() {
    const {
      classes, value, onChange, translation,
    } = this.props;
    const tableNumberTitle = translation('CheckoutDrawer.orderLocationSelector.tableNumber');
    return (
      <div className={classes.titleAndDropdownContainer}>
        <div className={classes.labelContainer}>
          <Typography className={classes.labelTitleText}>{tableNumberTitle}</Typography>
        </div>
        <div className={classes.dropdownContainer}>
          <FormControl variant="outlined" classes={this.getFormControlClasses(classes)}>
            <Select
              labelId="tableNumberDropdown"
              id="tableNumberDropdown"
              value={value}
              onChange={onChange}
              classes={this.getSelectClasses(classes)}
              MenuProps={this.getMenuPaddingClasses(classes)}
            >
              {this.renderAllTableNumbers()}
            </Select>
          </FormControl>
        </div>
      </div>
    );
  }
}

TableNumberDropdown.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  tableNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
};

export default withStyles(responsiveStyles)(withWidth()(TableNumberDropdown));
