import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const TextComponent = (props) => {
  const { title, style } = props;

  return (
    <Typography className={style}>
      {title}
    </Typography>
  );
};

TextComponent.propTypes = {
  title: PropTypes.string.isRequired,
  style: PropTypes.string,
};

TextComponent.defaultProps = {
  style: '',
};

export default TextComponent;
