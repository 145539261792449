
    module.exports = function cssToMuiLoader(theme) {
      
      return {
        
        'btn': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,},'btnPrimary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#a76e1b`,'color': `#ffffff`,
        },},'btnSecondary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#ffffff`,'color': `#d18a22`,'&:hover': {
          'backgroundColor': `#fff2e0`,'color': `#d18a22`,
        },},'btnTertiary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#70a0c3`,'color': `#ffffff`,
        },},'linkText': {'color': `#d18a22`,'cursor': `pointer`,'fontWeight': `bold`,'textDecoration': `underline`,'margin': `0 8px`,'fontSize': `0.75rem`,'fontFamily': `'Lato-Bold', sans-serif`,},'dialogTitleStyle': {'paddingTop': `0`,'paddingRight': `0`,'paddingLeft': `0`,'textAlign': `center`,'marginBottom': `0`,'fontSize': `30px`,'variant': `title`,},'dialogContentStyle': {'textAlign': `center`,'width': `100%`,'padding': `0`,'display': `flex`,'flexDirection': `column`,'alignItems': `center`,'color': `#282828`,},'dialogContentContainer': {'display': `flex`,'flexDirection': `column`,'alignItems': `center`,},'disableLineBreak': {'display': `inline-block`,},'emphasizedInlineText': {'display': `inline-block`,'fontWeight': `bold`,},'actionBtnStyle': {'margin': `25px auto 25px -10px`,'width': `100%`,},'formContainer': {'width': `100%`,},'dialogContent2': {'display': `flex`,'flexDirection': `row`,'justifyContent': `center`,'marginBottom': `50px`,},'dialogContent2Link': {'color': `#d18a22`,'fontFamily': `'Lato-Regular', sans-serif`,},'dialogBodyContainerStyle': {'width': `80%`,'maxWidth': `400px`,},'textFieldContainer': {'display': `flex`,'flexDirection': `row`,'justifyContent': `center`,'marginTop': `45px`,'width': `100%`,'height': `100%`,},'textFieldStyle': {'borderRadius': `1px`,'border': `2px solid black`,'width': `15%`,'height': `15%`,'margin': `0 5%`,'display': `flex`,'flexDirection': `column`,'alignItems': `center`,'justifyContent': `center`,},'inputStyle': {'color': `auto`,'fontSize': `20px`,'textAlign': `center`,'paddingTop': `0`,'margin': `auto`,},
        
      };
    };
  