import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { LinearProgress, Typography, TextField } from '@material-ui/core';

import Button from '../core/components/Button';
import styles from '../../css/settingsPage/SettingsPage.scss';
import * as Actions from '../../actions/Actions';
import * as Routes from '../../services/routes/Routes.json';
import * as ResponsiveStyles from '../../../jsonStyles/components/settingsPage/SettingsPage.style.json';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.pageContent = {
  ...responsiveStyles.pageContent,
  [theme.breakpoints.down('sm')]: {
    padding: ResponsiveStyles.pageContent.padding,
  },
};

responsiveStyles.body = {
  ...responsiveStyles.body,
  [theme.breakpoints.down('sm')]: {
    flexDirection: ResponsiveStyles.body.flexDirection,
    alignItems: ResponsiveStyles.body.alignItems,
  },
};

responsiveStyles.profileSection = {
  ...responsiveStyles.profileSection,
  [theme.breakpoints.down('sm')]: {
    height: ResponsiveStyles.profileSection.height,
    margin: ResponsiveStyles.profileSection.margin,
    padding: ResponsiveStyles.profileSection.padding,
    width: ResponsiveStyles.profileSection.width,
  },
};

responsiveStyles.userProfileForm = {
  ...responsiveStyles.userProfileForm,
  [theme.breakpoints.down('sm')]: {
    padding: ResponsiveStyles.userProfileForm.padding,
  },
};

responsiveStyles.formSubmitButton = {
  ...responsiveStyles.formSubmitButton,
  [theme.breakpoints.down('sm')]: {
    width: ResponsiveStyles.formSubmitButton.width,
    height: ResponsiveStyles.formSubmitButton.height,
  },
};

responsiveStyles.sectionTitle = {
  ...responsiveStyles.sectionTitle,
  [theme.breakpoints.down('sm')]: {
    margin: ResponsiveStyles.sectionTitle.margin,
    textAlign: ResponsiveStyles.sectionTitle.textAlign,
  },
};

responsiveStyles.passwordSection = {
  ...responsiveStyles.passwordSection,
  [theme.breakpoints.down('sm')]: {
    width: ResponsiveStyles.passwordSection.width,
    padding: ResponsiveStyles.passwordSection.padding,
    margin: ResponsiveStyles.passwordSection.margin,
    height: ResponsiveStyles.passwordSection.height,
  },
};

const userFormFields = [
  {
    key: 'userFirstName',
  },
  {
    key: 'userLastName',
  },
  {
    key: 'userPhone',
    disabled: true,
  },
  {
    key: 'userEmail',
  },
];

const initialState = {
  userFirstName: '',
  userLastName: '',
  userEmail: '',
  userPhone: '',
  userPassword: '',
  userPasswordConfirm: '',
  passwordConfirmError: false,
};

class SettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  async componentDidMount() {
    const { user, actions, history } = this.props;
    if (user && user.guestToken) {
      history.push(Routes.path.menuPage);
    }
    if (user) {
      try {
        const response = await actions.getAllResources(user.token, ['users', user.id]);
        if (response && !response.error) {
          this.setState({
            userFirstName: user.name.split(' ')[0],
            userLastName: user.name.split(' ')[1],
            userEmail: user.email,
            userPhone: user.telephone,
          });
        }
      } catch (error) {
        console.log('API call error', error);
      }
    } else {
      // Redirect users that are not logged in to menu page
      history.push(Routes.path.menuPage);
    }
  }

  componentDidUpdate(prevProps) {
    const { user, history } = this.props;
    if (prevProps.user !== user) {
      // Clear the fields if the user logs out and
      // Redirect users that log out to the menu page
      if (!user) {
        this.setState(initialState);
        history.push(Routes.path.menuPage);
      }
    }
  }

  handleChange(event, key) {
    this.setState({ [key]: event.target.value });
  }

  handleUserSubmit = () => {
    const {
      userFirstName,
      userLastName,
      userEmail,
      userPhone,
    } = this.state;
    const newUserObj = {
      name: `${userFirstName} ${userLastName}`,
      email: userEmail,
      // We have to send the password - in the backend we have a NotNull constraint which we might want to remove
      password: '',
      telephone: userPhone,
    };
    this.handleUpdateUser(newUserObj);
  }

  handlePasswordSubmit() {
    const { user } = this.props;
    const {
      userPassword,
      userPasswordConfirm,
    } = this.state;
    if (userPassword !== userPasswordConfirm) {
      this.setState({ passwordConfirmError: true });
    } else {
      if (user) {
        const newUserObj = {
          name: user.name,
          email: user.email,
          password: userPassword,
          telephone: user.telephone,
        };
        this.handleUpdateUser(newUserObj);
      }
      this.setState({ passwordConfirmError: false });
    }
  }

  handleUpdateUser = async (newUserObj) => {
    const { user, updateUser } = this.props;

    if (user && newUserObj) {
      try {
        await updateUser(user.token, newUserObj, ['users', user.id]);
      } catch (error) {
        console.log('Api call error', error);
      }
    }
  }

  render() {
    const {
      loading,
      classes,
      translation,
    } = this.props;

    return (
      loading !== 0
        ? <LinearProgress />
        : (
          <div className={classes.pageContent}>
            <div className={classes.body}>
              <div className={classes.profileSection}>
                <Typography className={classes.sectionTitle}>
                  {translation('SettingsPage.componentTitles.userProfile')}
                </Typography>
                <div className={classes.formContainer}>
                  <form className={classes.userProfileForm} noValidate autoComplete="off">
                    {
                      userFormFields.map(field =>
                        (<TextField
                          key={field.key}
                          id={`outlined-${field.key}`}
                          // label={translation(`SettingsPage.formFields.${field.key}`)}
                          placeholder={translation(`SettingsPage.formFields.${field.key}`)} // COMMENT this line and un-comment `label` line to use label instead of placeholder
                          className={classes.textField}
                          value={this.state[field.key]}
                          onChange={event => this.handleChange(event, field.key)}
                          margin={responsiveStyles.textField.margin}
                          variant={responsiveStyles.textField.variant}
                          disabled={field.disabled}
                        />))
                    }
                    <div className={classes.buttonContainer}>
                      <Button
                        onClick={() => this.handleUserSubmit()}
                        type="primary"
                        text={translation('SettingsPage.formFields.submitProfile')}
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className={classes.passwordSection}>
                <Typography className={classes.sectionTitle}>
                  {translation('SettingsPage.componentTitles.changePassword')}
                </Typography>
                <div className={classes.formContainer}>
                  <form className={classes.userProfileForm} noValidate autoComplete="off">
                    <TextField
                      id="outlined-password-input"
                      // label={translation('SettingsPage.formFields.newPassword')} // Un-comment to use label instead of placeholder
                      placeholder={translation('SettingsPage.formFields.newPassword')}
                      className={classes.textField}
                      value={this.state.userPassword}
                      onChange={event => this.handleChange(event, 'userPassword')}
                      type="password"
                      autoComplete="current-password"
                      margin={responsiveStyles.textField.margin}
                      variant={responsiveStyles.textField.variant}
                    />
                    <TextField
                      id="outlined-name"
                      //label={this.state.passwordConfirmError ? translation('SettingsPage.formFields.confirmPasswordError') : translation('SettingsPage.formFields.confirmPassword')}
                      placeholder={this.state.passwordConfirmError ? translation('SettingsPage.formFields.confirmPasswordError') : translation('SettingsPage.formFields.confirmPassword')}
                      className={classes.textField}
                      value={this.state.userPasswordConfirm}
                      type="password"
                      onChange={event => this.handleChange(event, 'userPasswordConfirm')}
                      error={this.state.passwordConfirmError}
                      margin={responsiveStyles.textField.margin}
                      variant={responsiveStyles.textField.variant}
                    />
                    <div className={classes.buttonContainer}>
                      <Button
                        onClick={() => this.handlePasswordSubmit()}
                        type="primary"
                        text={translation('SettingsPage.formFields.submitPassword')}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )
    );
  }
}

SettingsPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  loading: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
};

SettingsPage.defaultProps = {
  user: null,
};

const mapDispatchToProps = dispatch => ({
  updateUser: (apiToken, resource, resourcePath) =>
    dispatch(Actions.updateUser(apiToken, resource, resourcePath)),
});

export default connect(null, mapDispatchToProps)(withStyles(responsiveStyles)(withRouter(SettingsPage)));
