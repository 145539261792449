import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { getCurrentOrder } from '../../../selectors';
import styles from '../../../css/paymentPage/components/PayAtDoorComponent.scss';
import PaymentBoxComponent from '../subComponents/PaymentBoxComponent';
import DebitIcon from '../../../assets/images/interac-black@3x.jpg';
import CreditIcon from '../../../assets/images/credit-card.svg';
import CashIcon from '../../../assets/images/money-black.svg';

const pageStyles = typeof styles === 'function' ? styles() : styles;

const paymentOptions = [
  {
    id: 0,
    key: 'CASH',
    image: CashIcon,
    imageAlt: 'PayDoorComponent.imageAlt.cash',
    title: 'PayAtDoorComponent.cash',
  },
  {
    id: 1,
    key: 'DEBIT',
    image: DebitIcon,
    imageAlt: 'PayDoorComponent.imageAlt.debit',
    title: 'PayAtDoorComponent.debit',
  },
  {
    id: 2,
    key: 'CREDIT',
    image: CreditIcon,
    imageAlt: 'PayDoorComponent.imageAlt.credit',
    title: 'PayAtDoorComponent.credit',
  },
];

class PayAtDoorComponent extends Component {
  getPaymentOption = option => paymentOptions.find(paymentOption => paymentOption.key === option);

  getCardContent = (paymentOption, isSelected) => {
    const { classes, translation } = this.props;

    return (
      <div>
        <Typography
          classes={{ root: classes.paymentMethodName }}
          style={{ color: isSelected && pageStyles.selected.color }}
        >
          {translation(paymentOption.title)}
        </Typography>
      </div>
    );
  };

  render() {
    const {
      classes,
      translation,
      currentOrder,
      selectedCardId,
      handleClickSelect,
    } = this.props;
    const orderLocation = currentOrder.location;
    const locationPayAtDoorOptions = orderLocation && orderLocation.payAtDoorOptions
      ? orderLocation.payAtDoorOptions.split(',')
      : [];

    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={pageStyles.placeHolderTableCellStyle} />
            <TableCell className={classes.bodyTableCellStyle}>
              <div className={classes.existingPaymentOptions}>
                {
                  locationPayAtDoorOptions.map((option) => {
                    const paymentOption = this.getPaymentOption(option);
                    const isSelected = option === selectedCardId;

                    return (
                      <PaymentBoxComponent
                        key={option}
                        translation={translation}
                        paymentOptionId={option}
                        selectedCardId={selectedCardId}
                        handleClickSelect={handleClickSelect}
                        image={paymentOption && paymentOption.image}
                        imageAlt={paymentOption && translation(paymentOption.imageAlt)}
                        hasCardContent
                        cardContent={this.getCardContent(paymentOption, isSelected)}
                        type="payAtDoor"
                      />
                    );
                  })
                }
              </div>
            </TableCell>
            <TableCell style={pageStyles.placeHolderTableCellStyle} />
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

PayAtDoorComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  currentOrder: PropTypes.objectOf(PropTypes.any),
  selectedCardId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleClickSelect: PropTypes.func.isRequired,
};

PayAtDoorComponent.defaultProps = {
  selectedCardId: null,
  currentOrder: {},
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
});

export default connect(mapStateToProps)(withStyles(styles)(PayAtDoorComponent));
