import Immutable from 'seamless-immutable';
import camelCase from 'lodash/camelCase';
import difference from 'lodash/difference';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import * as types from '../actions/ActionTypes';
import { DELIVERY, DINE_IN, PICKUP, CATERING, deliveryOptionsArray } from '../services/constants/Constants';

const initialState = Immutable({
  products: [],
  users: {},
  locations: [],
});

const setResponseInState = (state, action, resource) => state.set(resource, action.response !== undefined ? action.response : []);

const isGetAllCategories = resourcePath => Array.isArray(resourcePath) && resourcePath.length === 1 && resourcePath[0] === 'categories';
const isGetAllProducts = resourcePath => Array.isArray(resourcePath) && resourcePath.length === 1 && resourcePath[0] === 'products';

const setUnavailableCategoriesByDeliveryOption = (state, action) => {
  const categories = action.response;

  let unavailableCategories = Immutable({
    [PICKUP]: {},
    [DELIVERY]: {},
    [CATERING]: {},
    [DINE_IN]: {},
  });

  categories.forEach((category) => {
    const deliveryOptions = get(category, 'orderTypes', []);
    const { id, name } = category;
    if (deliveryOptions.length === 0 || deliveryOptions.includes('ALL')) return;

    const unavailableDeliveryOptions = difference(deliveryOptionsArray, deliveryOptions).filter(val => val !== 'ALL');
    unavailableDeliveryOptions.forEach((deliveryOption) => {
      unavailableCategories = Immutable.setIn(unavailableCategories, [deliveryOption, id], name);
    });
  });

  return state.set('unavailableCategoriesByDeliveryOption', unavailableCategories);
};

const setItemToWithProductNameLookup = (state, action) => {
  const products = action.response;
  const itemIdToWithProductNameMap = {};
  products.forEach((product) => {
    const { name: productName = '', items = [] } = product;

    items.forEach((item) => {
      const { id: itemId, name: itemName = '' } = item;
      itemIdToWithProductNameMap[itemId] = `${productName} ∙ ${itemName}`;
    });
  });

  return state.set('itemIdToWithProductNameMap', itemIdToWithProductNameMap);
};

const updateState = (state, action, resource, resourceId) => {
  switch (action.type) {
    case types.DELETE_RESOURCE_SUCCESS:
      return state.update(resource, (resources) => {
        if (Array.isArray(resources)) {
          return resources.filter(resourceItem => resourceItem.id !== resourceId);
        }
        return null;
      });
    case types.ADD_RESOURCE_SUCCESS:
      return state.update(resource, resources => (resources ? resources.concat(action.response) : [action.response]));
    case types.UPDATE_RESOURCE_SUCCESS:
    case types.UPDATE_USER_SUCCESS:
      return state.update(resource, (resources) => {
        if (resources !== undefined) {
          if (!Array.isArray(resources)) {
            return Object.assign({}, resources, action.response);
          }
          return resources.map((resourceItem) => {
            if (resourceItem.id === resourceId) {
              return action.response;
            }
            return resourceItem;
          });
        }
        return resources;
      });
    case types.GET_RESOURCE_SUCCESS:

      return state.update(resource, (resources) => {
        if (!isEmpty(resources)) {
          const resourceExists = resources.find(resourceItem =>
            resourceItem.id.toString() === resourceId.toString());
          // Check if the resource already exists in the array
          if (!resourceExists) {
            // TODO: if we fetch locations again, it will add the list to the
            // end of the array of locations
            // -> we will have duplicate locations.
            // We should review this and make sure our logic is correct.
            return resources.concat(action.response);
          }

          return resources.map((resourceItem) => {
            if (resourceItem.id.toString() === resourceId.toString()) {
              return Object.assign({}, resourceItem, action.response);
            }
            return resourceItem;
          });
        } else {
          // If it's first time we are seeing this resource -> add it to resources in state.
          return action.response;
        }
        return resources;
      });
    case types.GET_ALL_RESOURCES_SUCCESS: {
      if (isGetAllCategories(action.resourcePath)) {
        const withCategories = setResponseInState(state, action, resource);
        return setUnavailableCategoriesByDeliveryOption(withCategories, action);
      }
      if (isGetAllProducts(action.resourcePath)) {
        const withProducts = setResponseInState(state, action, resource);
        return setItemToWithProductNameLookup(withProducts, action);
      }
      return setResponseInState(state, action, resource);
    }
    case types.LOGIN_USER_SUCCESS:
    case types.VERIFY_USER_SUCCESS:
    case types.RESET_PASSWORD_SUCCESS:
      return state.set('users', action.loggedInUser);
    case types.LOGOUT_USER:
      return Immutable.merge(state, { ...initialState });
    default:
      return state;
  }
};

const updateMyMap = (state, path, action) => {
  const resource = path[0];
  const resourceId = path[1];

  if (path.length > 2) {
    let subState;

    if (Array.isArray(state[resource]) && state[resource].length > 0) {
      subState = state[resource].filter(resourceItem => resourceItem.id === resourceId)[0];
    } else {
      subState = state[resource];
    }
    const subPath = path.slice(2);
    const updatedSubState = updateMyMap(Immutable(subState), subPath, action);

    return Immutable(state).update(resource, (resourceList) => {
      if (Array.isArray(resourceList)) {
        return resourceList.map((resourceItem) => {
          if (resourceItem.id === resourceId) {
            return Immutable(resourceItem).merge(updatedSubState);
          }
          return resourceItem;
        });
      } else {
        return Immutable(resourceList).merge(updatedSubState);
      }
    });
  } else {
    const camelCaseResource = camelCase(resource);
    return updateState(Immutable(state), action, camelCaseResource, resourceId);
  }
};

// action.resourcePath --> resource, resourceId, subResource, subResourceId,...
export default function ResourcesReducer(state = initialState, action) {
  if (!action.resourcePath) {
    return state;
  }
  return updateMyMap(state, action.resourcePath, action);
}
