import Immutable from 'seamless-immutable';
import * as types from './ActionTypes';
import ResourcesAPI from '../services/api/ResourcesApi';
import UsersAPI from '../services/api/UsersApi';
import NotificationsAPI from '../services/api/NotificationsApi';

// eslint-disable-next-line no-undef
const companyApiKey = window.companyApiKey;

function updateLoadingState(type) {
  return { type };
}

function dataRetrievalFailure(error) {
  return {
    type: types.ERROR, error,
  };
}

export function clearMessage() {
  return {
    type: types.CLEAR_MESSAGE,
  };
}

function loginUserSuccess(user) {
  const loggedInUser = user.data;
  loggedInUser.token = user.token;
  const resourcePath = ['users'];
  return {
    type: types.LOGIN_USER_SUCCESS, loggedInUser, resourcePath,
  };
}

function loginUserFailure(error) {
  return {
    type: types.LOGIN_USER_FAILURE, error,
  };
}

export function loginUser(user) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.LOGIN_USER_REQUEST));
    return UsersAPI.loginUser(user)
      .then(
        loggedInUser => dispatch(loginUserSuccess(loggedInUser))
        , error => dispatch(loginUserFailure(error)),
      );
  };
}

export function logoutUser(eventSource) {
  return {
    eventSource,
    type: types.LOGOUT_USER,
  };
}

export function setUser(user) {
  return {
    type: types.SET_USER, user,
  };
}

export function setRememberUser(rememberUser) {
  return {
    type: types.SET_REMEMBER_USER, rememberUser,
  };
}

function signUpUserSuccess(user) {
  const newUser = user.data;
  newUser.token = user.token;

  return {
    type: types.SIGNUP_USER_SUCCESS, newUser,
  };
}

function signUpUserFailure(error) {
  return {
    type: types.SIGNUP_USER_FAILURE, error,
  };
}

export function signUpUser(user) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.SIGNUP_USER_REQUEST));
    return UsersAPI.signUpUser(user)
      .then(
        signedUpUser => dispatch(signUpUserSuccess(signedUpUser))
        , error => dispatch(signUpUserFailure(error)),
      );
  };
}

function verifyUserSuccess(user, resourcePath) {
  const loggedInUser = user.data;
  loggedInUser.token = user.token;
  return {
    type: types.VERIFY_USER_SUCCESS,
    loggedInUser,
    resourcePath,
    successMsg: 'Your account has been verified.',
  };
}

function verifyUserFailure(error) {
  return {
    type: types.VERIFY_USER_FAILURE, error,
  };
}

export function verifyUser(userWithToken, resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.VERIFY_USER_REQUEST));
    return ResourcesAPI.addResource(companyApiKey, userWithToken, resourcePath)
      .then(
        verifiedRecoveredUser => dispatch(verifyUserSuccess(verifiedRecoveredUser, resourcePath))
        , error => dispatch(verifyUserFailure(error)),
      );
  };
}

function sendVerificationTokenSuccess() {
  return {
    type: types.SEND_VERIFICATION_TOKEN_SUCCESS,
    successMsg: 'A new verification token has been sent.',
  };
}

function sendVerificationTokenFailure(error) {
  return {
    type: types.SEND_VERIFICATION_TOKEN_FAILURE,
    error,
  };
}

export function sendVerificationToken(userObj, resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.SEND_VERIFICATION_TOKEN_REQUEST));
    return ResourcesAPI.addResource(companyApiKey, userObj, resourcePath)
      .then(
        () => dispatch(sendVerificationTokenSuccess())
        , error => dispatch(sendVerificationTokenFailure(error)),
      );
  };
}

function sendResetPasswordTokenSuccess(response) {
  return {
    user: response.data,
    type: types.SEND_RESET_PASSWORD_TOKEN_SUCCESS,
    successMsg: 'A new recovery token has been sent.',
  };
}

export function sendResetPasswordToken(userObj, path) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.SEND_RESET_PASSWORD_TOKEN_REQUEST));
    return ResourcesAPI.addResource(companyApiKey, userObj, path)
      .then(
        response => dispatch(sendResetPasswordTokenSuccess(response))
        , error => dispatch(dataRetrievalFailure(error)),
      );
  };
}

function resetPasswordSuccess(response, resourcePath) {
  const user = response.data;
  user.token = response.token;
  let loggedInUser;
  let unverifiedUser;

  if (user.isVerified) {
    loggedInUser = user;
  } else {
    unverifiedUser = user;
  }

  return {
    type: types.RESET_PASSWORD_SUCCESS,
    loggedInUser,
    unverifiedUser,
    resourcePath,
    successMsg: 'Successfully updated your password.',
  };
}

function resetPasswordFailure(error) {
  return {
    type: types.RESET_PASSWORD_FAILURE, error,
  };
}

export function resetPassword(user, resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.RESET_PASSWORD_REQUEST));
    return ResourcesAPI.addResource(companyApiKey, user, resourcePath)
      .then(
        response => dispatch(resetPasswordSuccess(response, resourcePath))
        , error => dispatch(resetPasswordFailure(error)),
      );
  };
}

export function updateUserSuccess(token, user, resourcePath) {
  let loggedInUser;
  if (resourcePath) {
    loggedInUser = user.data;
    loggedInUser.token = token;
  } else {
    loggedInUser = user; // This is for the case where no API calls are made and only the redux state is updated (e.g. update points of user)
  }

  // TO-DO: Translate Redux action messages.
  return {
    type: types.UPDATE_USER_SUCCESS,
    loggedInUser,
    resourcePath,
    response: user.data,
    successMsg: resourcePath ? 'Successfully updated user.' : null,
  };
}

export function updateUserFailure(error) {
  return {
    type: types.UPDATE_USER_FAILURE, error,
  };
}

// Need a separate update user (instead of just updateResource) so that we can update the user state in app
export function updateUser(apiToken, updatedUserObj, resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.UPDATE_USER_REQUESTED));
    if (!resourcePath) {
      return dispatch(updateUserSuccess(apiToken, updatedUserObj));
    }
    return ResourcesAPI.updateResource(apiToken, updatedUserObj, resourcePath)
      .then(
        updatedUser => dispatch(updateUserSuccess(apiToken, updatedUser, resourcePath)),
        error => dispatch(updateUserFailure(error)),
      );
  };
}

function getUserSuccess(response, resourcePath) {
  return {
    type: types.GET_USER_SUCCESS, response, resourcePath,
  };
}

function getUserFailure(error) {
  return {
    type: types.GET_USER_FAILURE, error,
  };
}

export function getUser(apiToken, resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.GET_USER_REQUESTED));
    return UsersAPI.getUser(apiToken, resourcePath)
      .then(
        response => dispatch(getUserSuccess(response.data, resourcePath))
        , error => dispatch(getUserFailure(error)),
      );
  };
}

// Add Resource
function addResourceSuccess(response, resourcePath) {
  return {
    type: types.ADD_RESOURCE_SUCCESS, response, resourcePath,
  };
}

function addResourceFailure(error) {
  return {
    type: types.ADD_RESOURCE_FAILURE, error,
  };
}

export function addResource(apiToken, addedResource, ...resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.ADD_RESOURCE_REQUESTED));
    return ResourcesAPI.addResource(apiToken, addedResource, resourcePath)
      .then(
        (response) => {
          dispatch(addResourceSuccess(response.data, resourcePath));
          return response.data;
        }
        , error => dispatch(addResourceFailure(error)),
      );
  };
}

// ADD ADDRESS FROM DIALOG VIEW
function addAddressSuccess(response, resourcePath) {
  return {
    type: types.ADD_ADDRESS_SUCCESS, response, resourcePath,
  };
}

function addAddressFailure(error) {
  return {
    type: types.ADD_ADDRESS_FAILURE, error,
  };
}

export function addAddress(apiToken, addedResource, ...resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.ADD_ADDRESS_REQUESTED));
    return ResourcesAPI.addResource(apiToken, addedResource, resourcePath)
      .then(
        (response) => {
          dispatch(addAddressSuccess(response.data, resourcePath));
          return response.data;
        }
        , error => dispatch(addAddressFailure(error)),
      );
  };
}

function getResourceSuccess(response, resourcePath) {
  return {
    type: types.GET_RESOURCE_SUCCESS, response, resourcePath,
  };
}

function getResourceFailure(error) {
  return {
    type: types.GET_RESOURCE_FAILURE, error,
  };
}

export function getResource(apiToken, resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.GET_RESOURCE_REQUESTED));

    return ResourcesAPI.getResource(apiToken, resourcePath)
      .then(
        response => dispatch(getResourceSuccess(response.data, resourcePath))
        , error => dispatch(getResourceFailure(error)),
      );
  };
}

export function getAllResourcesSuccess(response, resourcePath) {
  return {
    type: types.GET_ALL_RESOURCES_SUCCESS, response, resourcePath,
  };
}

export function getAllResourcesFailure(error) {
  return {
    type: types.GET_ALL_RESOURCES_FAILURE, error,
  };
}


export function getAllResources(apiToken, resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.GET_ALL_RESOURCES_REQUESTED));
    return ResourcesAPI.getAllResources(apiToken, resourcePath)
      .then(
        response => dispatch(getAllResourcesSuccess(response.data, resourcePath))
        , error => dispatch(getAllResourcesFailure(error)),
      );
  };
}

export function updateResourceSuccess(response, resourcePath) {
  return {
    type: types.UPDATE_RESOURCE_SUCCESS, response, resourcePath,
  };
}

export function updateResourceFailure(error) {
  return {
    type: types.UPDATE_RESOURCE_FAILURE, error,
  };
}

export function updateResource(apiToken, resource, resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.UPDATE_RESOURCE_REQUESTED));
    return ResourcesAPI.updateResource(apiToken, resource, resourcePath)
      .then(
        response => dispatch(updateResourceSuccess(response.data, resourcePath))
        , error => dispatch(updateResourceFailure(error)),
      );
  };
}

export function deleteResourceSuccess(resourcePath) {
  return {
    type: types.DELETE_RESOURCE_SUCCESS, resourcePath,
  };
}

export function deleteResourceFailure(error) {
  return {
    type: types.DELETE_RESOURCE_FAILURE, error,
  };
}

export function deleteResource(user, resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.DELETE_RESOURCE_REQUESTED));

    return ResourcesAPI.deleteResource(user.token, resourcePath)
      .then(
        dispatch(deleteResourceSuccess(resourcePath))
        , error => dispatch(deleteResourceFailure(error)),
      );
  };
}

// Orders
export function createOrderSuccess(order, orderPrice) {
  const orderWithPrice = order;
  if (orderPrice !== undefined) {
    orderWithPrice.price = orderPrice;
    orderWithPrice.totalPrice = orderPrice;
  }
  return {
    type: types.CREATE_ORDER_SUCCESS,
    order: orderWithPrice,
  };
}

export function createOrder(user, order, orderPrice) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.ADD_RESOURCE_REQUESTED));
    if (user !== undefined && user !== null) {
      const path = ['users', user.id, 'orders'];
      // First delete the existed NEW Order and then add New one
      return ResourcesAPI.getAllResources(user.token, path)
        .then((response) => {
          dispatch(updateLoadingState(types.RESOURCES_FOR_INNER_FUNCTION_SUCCESS));
          if (response.data.length > 0) {
            if (response.data[0].orderStatus === 'NEW') {
              dispatch(updateLoadingState(types.ADD_RESOURCE_REQUESTED));
              const deletePath = ['users', user.id, 'orders', response.data[0].id];
              return ResourcesAPI.deleteResource(user.token, deletePath)
                .then(
                  () => dispatch(updateLoadingState(types.RESOURCES_FOR_INNER_FUNCTION_SUCCESS)),
                  error => dispatch(deleteResourceFailure(error)),
                );
            }
          }
          return true;
        }, error => dispatch(getAllResourcesFailure(error)))
        .then(() => {
          dispatch(updateLoadingState(types.ADD_RESOURCE_REQUESTED));
          return ResourcesAPI.addOrder(user.token, order, path)
            .then((response) => {
              dispatch(createOrderSuccess(response.data, orderPrice));
              return response.data;
            }, error => dispatch(addResourceFailure(error)));
        });
    }

    dispatch(createOrderSuccess(order, orderPrice));
  };
}

export function updateOrderSuccess(order) {
  return {
    type: types.UPDATE_ORDER_SUCCESS,
    order,
  };
}

export function updateOrder(user, order, orderId) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.UPDATE_RESOURCE_REQUESTED));
    if (user !== undefined && user !== null && orderId) {
      const path = ['users', user.id, 'orders', orderId];
      return ResourcesAPI.updateOrder(user.token, order, path)
        .then(
          response => dispatch(updateOrderSuccess(response.data)),
          error => dispatch(updateResourceFailure(error)),
        );
    }

    dispatch(updateOrderSuccess(order));
  };
}

function createOrderItemSuccess(orderItem) {
  return {
    type: types.CREATE_ORDER_ITEM_SUCCESS,
    orderItem,
  };
}

export function createOrderItem(user, orderItem, orderId) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.ADD_RESOURCE_REQUESTED));

    if (user && orderId !== undefined) {
      const path = ['users', user.id, 'orders', orderId, 'items'];
      return ResourcesAPI.addOrderItem(user.token, orderItem, path)
        .then(
          response => dispatch(createOrderItemSuccess(response.data)),
          error => dispatch(addResourceFailure(error)),
        );
    }
    dispatch(createOrderItemSuccess(orderItem));
  };
}

export function getItemsAndCreateOrder(user, order, resourcePath) {
  const mutableOrder = Immutable.asMutable(order, { deep: true });
  return (dispatch) => {
    dispatch(updateLoadingState(types.GET_ALL_RESOURCES_REQUESTED));
    return ResourcesAPI.getAllResources(user.token, resourcePath)
      .then((response) => {
        mutableOrder.items = response.data;
        dispatch(updateLoadingState(types.ADD_RESOURCE_REQUESTED));
        dispatch(createOrderSuccess(mutableOrder));
        dispatch(getAllResourcesSuccess(response.data, resourcePath));
      }, error => dispatch(getAllResourcesFailure(error)));
  };
}

export function makePaymentSuccess(order) {
  return {
    type: types.MAKE_PAYMENT_SUCCESS,
    order,
  };
}

export function makePayment(user, method, path) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.ADD_RESOURCE_REQUESTED));
    return ResourcesAPI.addResource(user.token, method, path)
      .then(
        response => dispatch(makePaymentSuccess(response.data)),
        error => dispatch(addResourceFailure(error)),
      );
  };
}

function deleteOrderItemSuccess(response, orderItemId, user) {
  let orderResponse;
  if (response && response.data) {
    orderResponse = JSON.parse(response.data);
  }
  return {
    orderResponse,
    type: types.DELETE_ORDER_ITEM_SUCCESS,
    orderItemId,
    user,
  };
}

export function deleteOrderItem(user, orderItemId, orderId) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.DELETE_RESOURCE_REQUESTED));

    if (user && orderId && orderItemId) {
      const path = ['users', user.id, 'orders', orderId, 'items', orderItemId];
      return ResourcesAPI.deleteResource(user.token, path)
        .then(
          response => dispatch(deleteOrderItemSuccess(response, orderItemId, user)),
          error => dispatch(deleteResourceFailure(error)),
        );
    }
    dispatch(deleteOrderItemSuccess(null, orderItemId, user));
  };
}

function updateOrderItemSuccess(orderItem, orderItemId, user) {
  return {
    type: types.UPDATE_ORDER_ITEM_SUCCESS,
    orderItem,
    orderItemId,
    user,
  };
}

export function updateOrderItem(user, orderItem, orderId) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.UPDATE_RESOURCE_REQUESTED));

    if (user && orderId) {
      const path = ['users', user.id, 'orders', orderId, 'items', orderItem.id];
      return ResourcesAPI.updateOrderItem(user.token, orderItem, path)
        .then(
          response => dispatch(updateOrderItemSuccess(response.data, orderItem.id, user)),
          error => dispatch(addResourceFailure(error)),
        );
    }
    dispatch(updateOrderItemSuccess(orderItem, orderItem.id, user));
  };
}

export function deleteOrderCoupon(user, order, couponId) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.DELETE_RESOURCE_REQUESTED));
    if (user !== undefined && user !== null && order.id !== undefined) {
      const path = ['users', user.id, 'orders', order.id, 'coupons', couponId];
      return ResourcesAPI.deleteResource(user.token, path)
        .then(
          (response) => {
            const newOrder = JSON.parse(response.data);
            newOrder.items = order.items;
            dispatch(updateOrderSuccess(newOrder));
          },
          error => dispatch(deleteResourceFailure(error)),
        );
    }
  };
}

export function checkAddressSuccess(response) {
  return {
    location: response.data,
    type: types.CHECK_ADDRESS_SUCCESS,
  };
}

// Check address
export function checkAddress(user, address, resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.CHECK_ADDRESS_REQUESTED));

    return ResourcesAPI.addResource(user, address, resourcePath)
      .then(
        response => dispatch(checkAddressSuccess(response)),
        error => dispatch(addAddressFailure(error)),
      );
  };
}

export function getOfflineResourceSuccess(data) {
  return {
    type: types.GET_OFFLINE_RESOURCE_SUCCESS,
    data,
  };
}

export function getOfflineResourceFailure(error) {
  return {
    type: types.GET_OFFLINE_RESOURCE_FAILURE,
    error,
  };
}

// USE THIS ONLY IF YOU DON'T WANT TO STORE THE RECEIVED RESOURCE ON REDUX
export function getOfflineResource(apiToken, resourcePath) {
  return dispatch => ResourcesAPI.getResource(apiToken, resourcePath, true)
    .then(
      response => dispatch(getOfflineResourceSuccess(response.data))
      , error => dispatch(getOfflineResourceFailure(error)),
    );
}

export function setErrorSnackbarMessage(message) {
  return {
    type: types.SET_ERROR_SNACKBAR_MESSAGE,
    error: message,
  };
}

/*
 * Our API requires orderItem to be updated, then order, for the price to be updated
 */
export function updateOrderPrice(user, orderItem, orderId) {
  return (dispatch) => {
    dispatch(updateOrderItem(user, orderItem, orderId))
      .then(
        () =>
          dispatch(getOfflineResource(user.token, ['users', user.id, 'orders', orderId]))
            .then((orderResponse) => {
              const order = JSON.parse(orderResponse.data);
              dispatch(updateOrder(null, order, order.id));
            })
        , error => dispatch(getOfflineResourceFailure(error)),
      );
  };
}

export function updateOrderItemsRedeemedPoints(user, order, orderItem, newPoints) {
  if (!user || !order) return null;
  const item = Immutable.asMutable(orderItem, { deep: true });
  if (newPoints > 0) {
    item.redeemedPoints += newPoints;
  } else {
    item.redeemedPoints = 0;
  }

  return dispatch => dispatch(updateOrderPrice(user, item, order.id));
}

export function setOrderDeliveryOption(deliveryOption) {
  return {
    deliveryOption,
    type: types.SET_ORDER_DELIVERY_OPTION,
  };
}

const getComponentFlag = componentKey => `show${componentKey}`;

const hideComponent = componentKey => ({
  componentFlag: getComponentFlag(componentKey),
  type: types.HIDE_COMPONENT,
});

const showComponent = componentKey => ({
  componentFlag: getComponentFlag(componentKey),
  type: types.SHOW_COMPONENT,
});

const toggleComponent = componentKey => ({
  componentFlag: getComponentFlag(componentKey),
  type: types.TOGGLE_COMPONENT_VISIBILITY,
});

const getTimeSuggestionsSuccess = suggestions => ({
  suggestions,
  type: types.GET_TIME_SUGGESTIONS_SUCCESS,
});

const getTimeSuggestionsFailure = () => ({
  type: types.GET_TIME_SUGGESTIONS_FAILURE,
});

const getTimeSuggestions = (user, requestObj) => (dispatch) => {
  dispatch(updateLoadingState(types.GET_TIME_SUGGESTIONS));
  const path = ['orders', 'suggested-desired-time'];
  const handleSuccess = res => dispatch(getTimeSuggestionsSuccess(res.data));
  const handleError = error => dispatch(getTimeSuggestionsFailure(error));
  return ResourcesAPI.addResource(null, requestObj, path)
    .then(handleSuccess, handleError);
};

const checkDesiredTimeSuccess = () => ({
  type: types.CHECK_DESIRED_TIME_SUCCESS,
});

const checkDesiredTimesFailure = error => ({
  error,
  type: types.CHECK_DESIRED_TIME_FAILURE,
});

const checkDesiredTime = (user, requestObj) => async (dispatch) => {
  dispatch(updateLoadingState(types.CHECK_DESIRED_TIME));
  const path = ['orders', 'check-desired-time'];
  const handleSuccess = () => dispatch(checkDesiredTimeSuccess());
  const handleError = err => dispatch(checkDesiredTimesFailure(err));
  await ResourcesAPI.addResource(null, requestObj, path)
    .then(handleSuccess, handleError);
  return dispatch(getTimeSuggestions(user, requestObj));
};

const getUserNotificationsSuccess = notifications => ({
  notifications,
  type: types.GET_USER_NOTIFICATIONS_SUCCESS,
});

const getUserNotificationsFailure = () => ({
  type: types.GET_USER_NOTIFICATIONS_FAILURE,
});

export function getUserNotifications(apiToken, resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.GET_USER_NOTIFICATIONS_REQUEST));
    return NotificationsAPI.getNotifications(apiToken, resourcePath)
      .then(
        response => dispatch(getUserNotificationsSuccess(response.data, resourcePath)),
        error => dispatch(getUserNotificationsFailure(error)),
      );
  };
}

const updateNotificationSuccess = notification => ({
  notification,
  type: types.UPDATE_NOTIFICATION_SUCCESS,
});

const updateNotificationFailure = error => ({
  type: types.UPDATE_NOTIFICATION_FAILURE,
  error,
});

export function updateNotification(apiToken, updatedNotification, resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.UPDATE_NOTIFICATION_REQUEST));
    return NotificationsAPI.updateNotificationStatus(apiToken, updatedNotification, resourcePath)
      .then(
        response => dispatch(updateNotificationSuccess(response.data)),
        error => dispatch(updateNotificationFailure(error)),
      );
  };
}

export function updateCurbsideOrderStatus(user, order) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.ADD_RESOURCE_REQUESTED));
    const path = ['users', user.id, 'orders', order.id, 'curbside'];
    return ResourcesAPI.addResource(user.token, order, path)
      .then(
        response => dispatch(updateOrderSuccess(response.data)),
        error => dispatch(updateResourceFailure(error)),
      );
  };
}

export function setIsFetchedFeatureFlagsAvailable(isAvailable) {
  return {
    type: types.SET_IS_FETCHED_FEATURE_FLAGS_AVAILABLE,
    isAvailable,
  };
}

export function updateFeatureFlagsSuccess(updatedFeatureFlags) {
  return {
    type: types.SET_FEATURE_FLAGS_SUCCESS,
    updatedFeatureFlags,
  };
}

export function setFeatureFlags(updatedFeatureFlags) {
  return dispatch => dispatch(updateFeatureFlagsSuccess(updatedFeatureFlags));
}

export function updateTranslationsSuccess(updatedTranslations) {
  return {
    type: types.SET_TRANSLATIONS_SUCCESS,
    updatedTranslations,
  };
}

export function setTranslations(updatedTranslations) {
  return dispatch => dispatch(updateTranslationsSuccess(updatedTranslations));
}

export function setIsFetchedTranslationsAvailable(isAvailable) {
  return {
    type: types.SET_IS_FETCHED_TRANSLATIONS_AVAILABLE,
    isAvailable,
  };
}

export {
  checkDesiredTime,
  getTimeSuggestions,
  hideComponent,
  showComponent,
  toggleComponent,
};
