
    module.exports = function cssToMuiLoader(theme) {
      
      return {
        
        'btn': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,},'btnPrimary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#a76e1b`,'color': `#ffffff`,
        },},'btnSecondary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#ffffff`,'color': `#d18a22`,'&:hover': {
          'backgroundColor': `#fff2e0`,'color': `#d18a22`,
        },},'btnTertiary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#70a0c3`,'color': `#ffffff`,
        },},'linkText': {'color': `#d18a22`,'cursor': `pointer`,'fontWeight': `bold`,'textDecoration': `underline`,'margin': `0 8px`,'fontSize': `0.75rem`,'fontFamily': `'Lato-Bold', sans-serif`,'color': `#d18a22`,'cursor': `pointer`,'fontWeight': `bold`,'textDecoration': `underline`,'margin': `4px`,'fontSize': `0.75rem`,'fontFamily': `'Lato-Bold', sans-serif`,},'itemList': {'display': `flex`,'flexDirection': `column`,},'itemListTopMargin': {'display': `flex`,'flexDirection': `column`,'marginTop': `100px`,},'itemTitle': {'display': `flex`,'flexDirection': `row`,'justifyContent': `space-between`,},'productName': {'fontWeight': `bold`,'lineHeight': `1.75`,'color': `#282828`,},'productItemValue': {'fontWeight': `normal`,'lineHeight': `1.75`,'color': `#282828`,},'optionListText': {'fontStyle': `italic`,'color': `#282828`,},'itemLinks': {'display': `flex`,'padding': `0.75rem 0rem`,},'redeemButton': {'width': `90px`,'justifyContent': `space-evenly`,'fontSize': `0.5rem`,'backgroundColor': `#ffffff`,'color': `#d18a22`,'&:hover': {
          'backgroundColor': `#fff2e0`,'color': `#d18a22`,
        },},'priceText': {'fontSize': `1rem`,'fontFamily': `'Lato-Regular', sans-serif`,'color': `#282828`,},'crossedOutPriceText': {'fontSize': `1rem`,'fontFamily': `'Lato-Regular', sans-serif`,'color': `#282828`,'textDecoration': `line-through`,},'priceWithButton': {'display': `flex`,},
        
      };
    };
  