
    module.exports = function cssToMuiLoader(theme) {
      
      return {
        
        'btn': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,},'btnPrimary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#a76e1b`,'color': `#ffffff`,
        },},'btnSecondary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#ffffff`,'color': `#d18a22`,'&:hover': {
          'backgroundColor': `#fff2e0`,'color': `#d18a22`,
        },},'btnTertiary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#70a0c3`,'color': `#ffffff`,
        },},'linkText': {'color': `#d18a22`,'cursor': `pointer`,'fontWeight': `bold`,'textDecoration': `underline`,'margin': `0 8px`,'fontSize': `0.75rem`,'fontFamily': `'Lato-Bold', sans-serif`,},'dialogPaperStyle': {'width': `367px`,},'dialogImage': {'width': `50px`,'height': `50px`,'objectFit': `contain`,'color': `#9b9b9b`,},'dialogTitleStyle': {'paddingTop': `0`,'paddingRight': `0`,'color': `#282828`,},'dialogCloseIconStyle': {'color': `#000000`,},'dialogBodyContainerStyle': {'width': `80%`,'maxWidth': `400px`,},'dialogContentStyle': {'textAlign': `center`,'width': `100%`,'padding': `0`,'display': `flex`,'flexDirection': `column`,'alignItems': `center`,'color': `#282828`,'&:first-child': {
          'paddingTop': `0`,
        },},'dialogContentContainer': {'display': `flex`,'flexDirection': `column`,'alignItems': `center`,'justifyContent': `space-around`,},'emphasizedText': {'fontSize': `18px`,'fontWeight': `bold`,'marginTop': `10px`,},'normalText': {'fontSize': `14px`,'marginTop': `15px`,},'actionBtnStyle': {'margin': `35px auto 25px auto`,'width': `100%`,},'deleteButton': {'width': `200px`,'height': `50px`,'fontSize': `14px`,'margin': `auto`,'variant': `contained`,},
        
      };
    };
  