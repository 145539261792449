import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Immutable from 'seamless-immutable';
import {
  withStyles,
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';
import { arrayOf, objectOf, string, any, func, number } from 'prop-types';
import {
  Typography,
  List,
  ListItem,
  Card,
  withWidth,
} from '@material-ui/core';

import * as Routes from '../../services/routes/Routes.json';
import * as Actions from '../../actions/Actions';
import { getNotifications } from '../../selectors';
import {
  isMobileMode,
  isDesktopMode,
  compareValues,
} from '../../services/functions/Functions';
import { checkForNotifications } from '../../services/helpers/CustomHooks';
import DateHelper from '../../services/helpers/DateHelper';
import styles from '../../css/notificationsPage/NotificationsPage.scss';
import * as ResponsiveStyles from '../../../jsonStyles/components/notificationsPage/NotificationsPage.style.json';
import PageTitle from '../core/components/PageTitle';

const MESSAGE_READ = true;
const MESSAGE_UNREAD = false;

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.outerContainer = {
  ...responsiveStyles.outerContainer,
  [theme.breakpoints.down('sm')]: {
    width: ResponsiveStyles.outerContainer.width,
  },
};

responsiveStyles.listContainer = {
  ...responsiveStyles.listContainer,
  [theme.breakpoints.down('sm')]: {
    width: ResponsiveStyles.listContainer.width,
    backgroundColor: ResponsiveStyles.listContainer.backgroundColor,
  },
};

responsiveStyles.listItemStyle = {
  ...responsiveStyles.listItemStyle,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: ResponsiveStyles.listItemStyle.paddingLeft,
    height: ResponsiveStyles.listItemStyle.height,
    marginTop: ResponsiveStyles.listItemStyle.marginTop,
    paddingRight: ResponsiveStyles.listItemStyle.paddingRight,
  },
};

responsiveStyles.listItemUnread = {
  ...responsiveStyles.listItemUnread,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: ResponsiveStyles.listItemUnread.paddingLeft,
    height: ResponsiveStyles.listItemUnread.height,
    marginTop: ResponsiveStyles.listItemUnread.marginTop,
    paddingRight: ResponsiveStyles.listItemUnread.paddingRight,
  },
};

responsiveStyles.notificationTitleListView = {
  ...responsiveStyles.notificationTitleListView,
  [theme.breakpoints.down('sm')]: {
    margin: ResponsiveStyles.notificationTitleListView.margin,
  },
};

responsiveStyles.notificationBodyListView = {
  ...responsiveStyles.notificationBodyListView,
  [theme.breakpoints.down('sm')]: {
    width: ResponsiveStyles.notificationBodyListView.width,
    margin: ResponsiveStyles.notificationBodyListView.margin,
  },
};

responsiveStyles.notificationDateTimeListView = {
  ...responsiveStyles.notificationDateTimeListView,
  [theme.breakpoints.down('sm')]: {
    margin: ResponsiveStyles.notificationDateTimeListView.margin,
  },
};

responsiveStyles.iconStyle = {
  ...responsiveStyles.iconStyle,
  [theme.breakpoints.down('sm')]: {
    color: ResponsiveStyles.iconStyle.color,
  },
};

const SelectedMessage = (props) => {
  const { classes, translation, message } = props;

  return (
    <Card className={classes.messageContainer}>
      <Typography className={classes.notificationTitleMsgView}>
        {message.title}
      </Typography>
      <Typography className={classes.notificationDateTimeMsgView}>
        {DateHelper.getSuggestedTimeIntervalString(message.createdAt, translation('NotificationsPage.at'))}
      </Typography>
      <Typography className={classes.notificationBodyMsgView}>
        {message.body}
      </Typography>
    </Card>
  );
};

const Notification = (props) => {
  const {
    classes,
    translation,
    user,
    history,
    notifications,
    notification,
    setNotificationId,
    updateNotification,
    width,
    selectedNoteId,
  } = props;

  const getListItemStyle = (note) => {
    if (note.id === selectedNoteId && notification.isRead === MESSAGE_READ) {
      return classes.listItemSelected;
    } else if (note.isRead === MESSAGE_UNREAD) {
      return classes.listItemUnread;
    }
    return classes.listItemStyle;
  };

  const handleClickNotification = async (noteId) => {
    setNotificationId(noteId);

    const selectedNotification = notifications.find(note => note.id === noteId);
    const mutableNotification = Immutable.asMutable(selectedNotification, { deep: true });

    if (selectedNotification.isRead === false) {
      mutableNotification.isRead = true;
      try {
        await updateNotification(user.token, mutableNotification, ['push_notifications', noteId, 'users', user.id]);
      } catch (error) {
        console.log('Error updating message status', error);
      }
    }
    if (isMobileMode(width)) history.push(`${Routes.path.notificationsPage}/${selectedNotification.id}`);
    checkForNotifications(user);
  };

  return (
    <ListItem
      className={getListItemStyle(notification)}
      onClick={() => handleClickNotification(notification.id)}
    >
      <div>
        <Typography className={classes.notificationTitleListView}>
          {notification.title}
        </Typography>
        <Typography className={classes.notificationBodyListView}>
          {notification.body}
        </Typography>
        <Typography className={classes.notificationDateTimeListView}>
          {DateHelper.getSuggestedTimeIntervalString(notification.createdAt, translation('NotificationsPage.at'))}
        </Typography>
      </div>
      <div className={classes.iconContainerStyle}>
        <ChevronRight className={classes.iconStyle} />
      </div>
    </ListItem>
  );
};

const NotificationsComponent = (props) => {
  const {
    history,
    user,
    translation,
    notifications,
    classes,
    width,
  } = props;

  if (!user) history.push(Routes.path.menuPage);
  checkForNotifications(user);

  const [notificationId, setNotificationId] = useState(null);

  const getSelectedMessage = notificationId
    ? notifications.find(note => note.id === notificationId)
    : '';

  const generateKey = i => (`key${i}`);

  const mutableNotifications = Immutable.asMutable(notifications, { deep: true });
  const getMessages = isMessageRead => mutableNotifications.filter(note => note.isRead === isMessageRead).sort(compareValues('id', 'desc'));
  const sortedNotifications = getMessages(MESSAGE_UNREAD).concat(getMessages(MESSAGE_READ));

  return (
    <div className={classes.pageContent}>
      <PageTitle title={translation('NotificationsPage.title')} />
      <div className={classes.outerContainer}>
        <List className={classes.listContainer}>
          {
            sortedNotifications.map((notification, i) => {
              return (
                <Notification
                  {...props}
                  notification={notification}
                  index={i}
                  setNotificationId={setNotificationId}
                  key={generateKey(i)}
                  selectedNoteId={notificationId}
                />
              );
            })
          }
        </List>
        {
          getSelectedMessage && isDesktopMode(width)
          && (
            <SelectedMessage
              {...props}
              message={getSelectedMessage}
            />
          )
        }
      </div>
    </div>
  );
};

NotificationsComponent.propTypes = {
  classes: objectOf(string).isRequired,
  translation: func.isRequired,
  history: objectOf(any).isRequired,
  user: objectOf(any),
  notifications: arrayOf(objectOf(any)),
  width: string,
};

NotificationsComponent.defaultProps = {
  user: null,
  notifications: [],
  width: '',
};

Notification.propTypes = {
  classes: objectOf(string).isRequired,
  translation: func.isRequired,
  history: objectOf(any).isRequired,
  notification: objectOf(any).isRequired,
  setNotificationId: func.isRequired,
  updateNotification: func.isRequired,
  notifications: arrayOf(objectOf(any)),
  user: objectOf(any),
  width: string,
  selectedNoteId: number,
};

Notification.defaultProps = {
  user: null,
  notifications: [],
  width: '',
  selectedNoteId: null,
};

SelectedMessage.propTypes = {
  classes: objectOf(string).isRequired,
  translation: func.isRequired,
  message: objectOf(any).isRequired,
};

const mapStateToProps = state => ({
  notifications: getNotifications(state),
});

const mapDispatchToProps = dispatch => ({
  updateNotification: (apiToken, notification, resourcePath) =>
    dispatch(Actions.updateNotification(apiToken, notification, resourcePath)),
});

const NotificationsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(responsiveStyles),
)(withRouter(withWidth()(NotificationsComponent)));

export default NotificationsPage;
