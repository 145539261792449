import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { Paper, Tab, Tabs } from '@material-ui/core';
import * as FeatureFlags from '../../../../feature-flags.json';
import * as ResponsiveStyles from '../../../../jsonStyles/components/paymentPage/components/PaymentMethodComponent.style.json';
import styles from '../../../css/paymentPage/components/PaymentMethodComponent.scss';
import withWidth from '@material-ui/core/withWidth';
import {
  getMobileFriendlyStyle,
  isDesktopMode,
} from '../../../services/functions/Functions';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

const payTimeOptions = {
  payNow: {
    key: 'payNow',
    title: 'PaymentMethodComponent.payNow',
  },
  payAtDoor: {
    key: 'payAtDoor',
    title: 'PaymentMethodComponent.payAtDoor',
  },
};

const TabContainer = props => props.children;

class PaymentMethodComponent extends Component {
  handleChange = (event, value) => {
    // event is needed here as placeholder for value to be passed correctly
    this.props.setActiveTab(value);
  };

  render() {
    const {
      activeTab,
      classes,
      getContent,
      translation,
      width,
    } = this.props;
    
    const mobileFriendlyStyleProps = { classes, width };
    const tabContainerStyles = getMobileFriendlyStyle(mobileFriendlyStyleProps, 'tabContainer');
    const isDesktop = isDesktopMode(width);

    return (
      <div className={tabContainerStyles}>
        <Paper square={responsiveStyles.headerTabsPaper.square === 'true'} className={classes.headerTabsPaper}>
          <Tabs
            classes={{ 
              flexContainer: classes.headerTabsContainer,
              scroller: !isDesktop && classes.headerTabScroller
            }}
            value={activeTab}
            onChange={(event, tab) => this.handleChange(event, tab)}
            fullwidth={responsiveStyles.headerTabsContainer.fullWidth}
            indicatorColor={responsiveStyles.headerTabColors.indicatorColor}
            textColor={responsiveStyles.headerTabColors.textColor}
          >
            <Tab
              classes={{ root: classes.headerTabRoot, selected: classes.headerTabSelected }}
              value={payTimeOptions.payNow.key}
              label={translation(payTimeOptions.payNow.title)}
            />
            {
              FeatureFlags.PaymentPage.payAtDoorEnabled
                && (
                  <Tab
                    classes={{ root: classes.headerTabRoot, selected: classes.headerTabSelected }}
                    value={payTimeOptions.payAtDoor.key}
                    label={translation(payTimeOptions.payAtDoor.title)}
                  />
                )
            }
          </Tabs>
        </Paper>
        <Paper className={classes.tabContentPaper}>
          <TabContainer>{getContent(activeTab)}</TabContainer>
        </Paper>
      </div>
    );
  }
}

PaymentMethodComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  getContent: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  width: PropTypes.string,
};

PaymentMethodComponent.defaultProps = {
  width: null,
};

export default withStyles(responsiveStyles)(withWidth()(PaymentMethodComponent));
