import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import {
  Tabs,
  Tab,
  Menu,
  MenuItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';

import styles from '../../../css/core/components/TabBar.scss';
import {
  tabSplitThreshold,
  ITEM_HEIGHT,
  MAX_HEIGHT_MULTIPLIER,
  TAB_CHAR_LIMIT,
} from '../../../services/constants/Constants';
import * as ResponsiveStyles from '../../../../jsonStyles/components/core/components/TabBar.style.json';
import * as Functions from '../../../services/functions/Functions';

const theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;

responsiveStyles.tab = {
  ...responsiveStyles.tab,
  [theme.breakpoints.down('sm')]: {
    fontSize: ResponsiveStyles.tab.fontSize,
  },
};

responsiveStyles.root = {
  ...responsiveStyles.root,
  [theme.breakpoints.down('sm')]: ResponsiveStyles.root,
};

class TabBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.selected,
      menuListTabs: [],
      tabBarTabs: [],
      anchorEl: null,
    };
  }

  componentDidMount() {
    const { tabs } = this.props;
    if (tabs.length > tabSplitThreshold) this.divideTabs();
  }

  componentDidUpdate(prevProps) {
    const { selected, tabs } = this.props;
    if (selected !== prevProps.selected) {
      this.setState({ value: selected }, () => this.divideTabs());
    }
    if (tabs.length !== prevProps.tabs.length) {
      this.divideTabs();
    }
  }

  getTruncatedLabel = label => ((label.length > TAB_CHAR_LIMIT)
    ? `${label.substr(0, TAB_CHAR_LIMIT - 1)}...`
    : label)

  generateKey = index => (`key${index}`);

  handleMenuListOpen(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleMenuListClose() {
    this.setState({ anchorEl: null });
  }

  handleChange = (event, value) => {
    this.setState({ value });
    this.props.onClickSelected(value);
  };

  groupTabs = () => {
    const { tabs } = this.props;
    const splittedTabs = [];
    const threshold = tabSplitThreshold;
    if (tabs.length > threshold) {
      const folds = Math.ceil((tabs.length) / threshold);
      for (let i = 0; i < folds; i += 1) {
        const tab = tabs.slice(threshold * i, (threshold * i) + threshold);
        splittedTabs.push(tab);
      }
    } else {
      splittedTabs.push(tabs);
    }
    return splittedTabs;
  }

  divideTabs = () => {
    const { value } = this.state;
    const allTabs = this.groupTabs();
    const menuListTabs = [];
    const tabBarTabs = [];

    allTabs.forEach((tabGroup) => {
      if (tabGroup.some(tab => tab.id === value)) tabBarTabs.push(...tabGroup);
      else menuListTabs.push(...tabGroup);
    });
    this.setState({ menuListTabs, tabBarTabs });
  }

  renderMenuList = () => {
    const { menuListTabs, anchorEl } = this.state;
    const { classes, translation, onClickSelected, type } = this.props;

    return (
      <div>
        <Typography
          onClick={event => this.handleMenuListOpen(event)}
          className={classes.moreButton}
        >
          {translation('TabBar.more')}
        </Typography>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={Boolean(anchorEl)}
          onClose={() => this.handleMenuListClose()}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * MAX_HEIGHT_MULTIPLIER,
              width: classes.menu.width,
            },
          }}
        >
          {
            menuListTabs.map(tab => (
              <MenuItem
                id={`${type}${tab.id}`}
                key={tab.id}
                onClick={() => {
                  onClickSelected(tab.id);
                  this.handleMenuListClose();
                }}
              >
                <ListItemText primary={tab.label.toUpperCase()} />
              </MenuItem>
            ))
          }
        </Menu>
      </div>
    );
  }

  render() {
    const { classes, tabs, stickToTop, type } = this.props;
    const { tabBarTabs } = this.state;
    const isDesktop = Functions.isDesktopMode(this.props.width);

    return (
      <div className={stickToTop ? classes.sticky : classes.root}>
        {
          isDesktop
          ? (
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor={responsiveStyles.tabsComponent.indicatorColor}
              textColor={responsiveStyles.tabsComponent.textColor}
              className={classes.tabs}
              scrollButtons="on"
              variant="scrollable"
            >
              {
                tabs.map(tab => (
                  <Tab
                    id={`${type}${tab.id}`}
                    classes={{ root: classes.tab, selected: classes.selected }}
                    key={tab.id}
                    label={this.getTruncatedLabel(tab.label)}
                    value={tab.id}
                  />
                ))
              }
              {/* {(tabs.length > tabSplitThreshold) && this.renderMenuList()} */}
            </Tabs>
          )
          : (
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor={responsiveStyles.tabsComponent.indicatorColor}
              textColor={responsiveStyles.tabsComponent.textColor}
              centered={responsiveStyles.tabsComponent.centered === 'true'}
              variant={responsiveStyles.tabsComponent.scrollable ? 'scrollable' : 'standard'}
              className={classes.tabs}
              scrollButtons="on"
            >
              {
                tabs.map(tab => (
                  <Tab
                    id={`${type}${tab.id}`}
                    classes={{ selected: classes.selected, root: classes.tab }}
                    key={tab.id}
                    label={this.getTruncatedLabel(tab.label)}
                    value={tab.id}
                  />
                ))
              }
            </Tabs>
          )
        }
      </div>
    );
  }
}

TabBar.propTypes = {
  stickToTop: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  tabs: PropTypes.arrayOf(PropTypes.any).isRequired,
  onClickSelected: PropTypes.func.isRequired,
  selected: PropTypes.number,
  width: PropTypes.string,
  translation: PropTypes.func.isRequired,
  type: PropTypes.string
};

TabBar.defaultProps = {
  stickToTop: false,
  selected: 0,
  width: null,
  type:'',
};

export default withStyles(responsiveStyles)(withWidth()(TabBar));
