import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';
import isEmpty from 'lodash/isEmpty';
import HeaderComponent from './components/HeaderComponent';
import ContrastLogo from '../../assets/images/logoContrast.svg';
import PrimaryLogo from '../../assets/images/primary_logo.svg';
import MobileLogo from '../../assets/images/mobile_header_logo.svg';
import FooterComponent from './components/FooterComponent';
import SideBarComponent from './components/SideBarComponent';
import LoginDialog from '../authentication/LoginDialog';
import SignUpDialog from '../authentication/SignUpDialog';
import VerifyDialog from '../authentication/VerifyDialog';
import ResetPasswordDialog from '../authentication/ResetPasswordDialog';
import RewardDialog from '../core/components/RewardDialog';
import GuestSignInDialog from '../authentication/GuestSignInDialog';
import styles from '../../css/core/CoreView.scss';
import * as FeatureFlags from '../../../feature-flags.json';
import * as Routes from '../../services/routes/Routes.json';
import * as Functions from '../../services/functions/Functions';
import * as Constants from '../../services/constants/Constants';
import * as jsonValues from '../../../jsonStyles/components/core/CoreView.style.json';
import HeaderLinks from '../../config/header.json';

import BackgroundImage from '../../assets/images/background.png';

const innerContentImageDesktopStyle = {
  backgroundImage: `url(${BackgroundImage})`,
  backgroundRepeat: jsonValues.desktop.background.backgroundRepeat,
  backgroundSize: jsonValues.desktop.background.backgroundSize,
  backgroundAttachment: jsonValues.desktop.background.backgroundAttachment,
};

const innerContentImageMobileStyle = {
  backgroundImage: `url(${BackgroundImage})`,
  backgroundRepeat: jsonValues.mobile.background.backgroundRepeat,
  backgroundSize: jsonValues.mobile.background.backgroundSize,
  backgroundAttachment: jsonValues.mobile.background.backgroundAttachment,
  marginTop: jsonValues.mobile.innerContent.marginTop,
};

const innerContentMobileStyle = {
  marginTop: jsonValues.mobile.innerContent.marginTop,
};


const headerOptions = HeaderLinks.headerLinks;
let headerOptionsExtra = HeaderLinks.headerLinksExtra;
if (FeatureFlags.disableRewards) headerOptionsExtra = headerOptionsExtra.filter(opt => opt.key !== 'rewards');
headerOptionsExtra = FeatureFlags.enableAccountPage ? headerOptionsExtra.filter(opt => opt.key !== 'settings') : headerOptionsExtra.filter(opt => opt.key !== 'account');
const fullHeadersubMenuOptions = HeaderLinks.submMenuLinks;
const externalOptions = HeaderLinks.externalLinks;

const HeaderLogo = FeatureFlags.CoreView.HeaderComponent.usePrimaryLogo
  ? PrimaryLogo : ContrastLogo;

const logo = {
  src: HeaderLogo,
  alt: 'CoreView.header.logoAlt',
};

const mobileLogo = {
  src: MobileLogo,
  alt: 'CoreView.header.logoAlt',
};

const footerOptions = [
  {
    id: 1, title: 'CoreView.footer.option1', ref: Routes.path.termsPage,
  },
  {
    id: 2, title: 'CoreView.footer.option2', ref: Routes.path.privacyPage,
  },
];

class CoreView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPage: props.history.location.pathname,
      openLoginDialog: false,
      openSignUpDialog: false,
      openVerifyDialog: false,
      openResetPasswordDialog: false,
      openRewardsDialog: false,
      openGuestSignInDialog: false,
      userEmail: '',
      appDrawerOpen: false,
      cartIconWasPressed: false,
      headerOptionsExtraState: headerOptionsExtra,
    };
  }

  async componentDidMount() {
    const { actions, locations } = this.props;

    if (locations.length === 0) {
      try {
        // eslint-disable-next-line no-undef
        await actions.getAllResources(window.companyApiKey, ['locations']);
      } catch (error) {
        console.log('API call error', error);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      actions,
      currentOrder,
      locations,
      user,
    } = this.props;
    const orderObj = {};

    if (!FeatureFlags.MenuPage.showNewFlow) {
      if (!currentOrder.location && locations.length > 0) {
        // Set default location
        orderObj.location = locations[0];
      }

      // Set default deliveryOption
      if (!currentOrder.deliveryOption) {
        orderObj.deliveryOption = 'PICKUP';
      }

      // Set default isASAP as true only if isASAP is null or undefined
      // If user hits the back button between checkout and payment page and
      // isASAP is false, the currentOrder.isASAP should persist that false state
      if (currentOrder.isASAP === null || currentOrder.isASAP === undefined) {
        orderObj.isASAP = true;
      }
    }

    // If user has logged in, fetch user's addresses.
    if (user && (prevProps.user !== user)) {
      try {
        actions.getAllResources(
          user.token,
          ['users', user.id, 'addresses'],
        );
      } catch (error) {
        console.log('API call error', error);
      }
      // If user has logged in, fetch user's notifications
      try {
        actions.getUserNotifications(
          user.token,
          ['push_notifications', 'users', user.id],
        );
      } catch (error) {
        console.log('API error retrieving user notifications', error);
      }
      // Hide/show header options depending on guest status
      if (user.guestToken) {
        const guestHeaderOptions = Functions.filterGuestOptions(headerOptionsExtra);
        this.setState({ headerOptionsExtraState: guestHeaderOptions });
      } else {
        this.setState({ headerOptionsExtraState: headerOptionsExtra });
      }
    }

    const deliveryOptions = Functions.availableDeliveryOptions();
    if (!currentOrder.deliveryOption && deliveryOptions.length === 1) {
      orderObj.deliveryOption = deliveryOptions[0];
    }

    if (!isEmpty(orderObj)) {
      actions.createOrder(null, {
        ...currentOrder,
        ...orderObj,
      });
    }
  }

  onSandwichClick = () => {
    this.setState({ appDrawerOpen: true });
  }

  onNavigateClose = () => {
    this.setState({ appDrawerOpen: false });
  }

  getInnerContentStyle = () => {
    const { classes } = this.props;

    if (FeatureFlags.CoreView.SideBarComponent.show) return classes.innerContentSidebar;
    return classes.innerContent;
  }

  handleClickAuthentication = (user, updateParentStateObj) => {
    const { actions } = this.props;

    if (user) {
      // eslint-disable-next-line no-undef
      window.localStorage.clear();
      actions.logoutUser();
      return;
    }
    const newState = updateParentStateObj || {};
    newState.openLoginDialog = true;
    this.setState(newState);
  };

  handleAction = (type) => {
    const { translation, user } = this.props;
    if (type.toUpperCase() === translation('CoreView.header.option4.title')) {
      this.setState({
        openRewardsDialog: true,
      });
      return;
    }

    if (type === translation('HeaderComponent.createAccount')) {
      this.setState({
        openSignUpDialog: true,
      });
      return;
    }

    if (type === 'sign_out') {
      this.handleClickAuthentication(user);
      return;
    }

    this.setState({ selectedPage: type });
    this.props.history.push(`/${type}`);
  };

  handleDialogClose = (dialogObj = { dialog: null }) => {
    const { actions } = this.props;
    const { cartIconWasPressed } = this.state;

    const authenticationInProgress = Constants.authenticationStillInProgressEvents.includes(dialogObj.eventSource);
    const isStillFromLastCartIconPress = cartIconWasPressed && authenticationInProgress;

    this.setState({
      openLoginDialog: false,
      openSignUpDialog: false,
      openVerifyDialog: false,
      openResetPasswordDialog: false,
      openRewardsDialog: false,
      openGuestSignInDialog: false,
      userEmail: '',
      appDrawerOpen: false,
      cartIconWasPressed: isStillFromLastCartIconPress,
    });

    if (dialogObj.dialog === 'signup') {
      this.setState({
        openVerifyDialog: true,
      });
    } else if (dialogObj.dialog === 'login') {
      if (dialogObj.openVerifyDialog) {
        // Open verify dialog if the user is not verified
        this.setState({
          openVerifyDialog: dialogObj.openVerifyDialog,
          userEmail: dialogObj.userEmail,
        });
      } else if (dialogObj.openResetPasswordDialog) {
        // Open reset password dialog if the user clicks forget password
        this.setState({
          openResetPasswordDialog: dialogObj.openResetPasswordDialog,
        });
      } else if (dialogObj.openGuestSignInDialog) {
        this.setState({
          openGuestSignInDialog: dialogObj.openGuestSignInDialog,
        });
      }
    } else if (dialogObj.dialog === 'verify') {
      if (dialogObj.eventSource === 'closeIcon') {
        // Clear the state when the user closes the verify dialog so that they are not logged in
        // But keep the order so that if the item count is 1+, the items are not lost
        actions.logoutUser('verifyCloseIcon');
      }
    } else if (dialogObj.dialog === 'reset') {
      if (dialogObj.openVerifyDialog) {
        // Open verify dialog if the user is not verified
        this.setState({
          openVerifyDialog: dialogObj.openVerifyDialog,
          userEmail: dialogObj.userEmail,
        });
      }
    }
  };

  sendUserToCheckout = () => {
    const { actions } = this.props;
    const { cartIconWasPressed } = this.state;
    if (!cartIconWasPressed) return;
    actions.toggleComponent('CheckoutDrawer');
  }

  render() {
    const {
      classes, children, user, translation, actions, rememberUser, currentOrder, dialogLoading, history,
    } = this.props;
    const {
      selectedPage, openLoginDialog, openSignUpDialog, openVerifyDialog, openResetPasswordDialog, userEmail,
      openRewardsDialog, cartIconWasPressed, openGuestSignInDialog, headerOptionsExtraState,
    } = this.state;
    const headersubMenuOptions = (FeatureFlags.CoreView.HeaderComponent.hidePaymentOptions)
      ? fullHeadersubMenuOptions.filter(menu => menu.id !== 5) : fullHeadersubMenuOptions;

    const isDesktop = Functions.isDesktopMode(this.props.width);
    const innerContentImageStyle = (isDesktop) ? innerContentImageDesktopStyle : innerContentImageMobileStyle;
    const innerContentStyle = (isDesktop) ? {} : innerContentMobileStyle;
    const useMobileLogo = !isDesktop && FeatureFlags.CoreView.HeaderComponent.useMobileHeaderLogo;

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <main className={classes.content}>
            {
              FeatureFlags.CoreView.HeaderComponent.show
                && (
                  <HeaderComponent
                    handleClickOptions={type => this.handleAction(type)}
                    handleClickAuthentication={this.handleClickAuthentication}
                    options={user ? headerOptions.concat(headerOptionsExtraState) : headerOptions}
                    externalOptions={externalOptions}
                    headersubMenuOptions={[]}
                    logo={useMobileLogo ? mobileLogo : logo}
                    user={user}
                    selectedPage={selectedPage}
                    translation={translation}
                    actions={actions}
                    currentOrder={currentOrder}
                    handleDrawerOpen={() => this.onSandwichClick()}
                    handleDrawerClose={() => this.onNavigateClose()}
                    appDrawerOpen={this.state.appDrawerOpen}
                    history={history}
                  />
                )
            }
            {
              FeatureFlags.CoreView.SideBarComponent.show
                && (
                  <SideBarComponent
                    handleClickOptions={type => this.handleAction(type)}
                    handleClickAuthentication={currentUser => this.handleClickAuthentication(currentUser)}
                    headerOptions={user ? headerOptions.concat(headersubMenuOptions) : headerOptions}
                    externalOptions={externalOptions}
                    footerOptions={footerOptions}
                    logo={logo}
                    user={user}
                    total={0.00}
                    selectedPage={selectedPage}
                    translation={translation}
                  />
                )
            }
            {
              FeatureFlags.CoreView.innerContentBackground.useImage
                ? <div className={this.getInnerContentStyle()} style={innerContentImageStyle}>{children}</div>
                : <div className={this.getInnerContentStyle()} style={innerContentStyle} >{children}</div>
            }
            {
              FeatureFlags.CoreView.FooterComponent.show
                && (
                  <FooterComponent
                    options={footerOptions}
                    translation={translation}
                  />
                )
            }
          </main>
          <LoginDialog
            actions={actions}
            open={openLoginDialog}
            cartIconWasPressed={cartIconWasPressed}
            handleClickOptions={type => this.handleAction(type)}
            handleClose={type => this.handleDialogClose(type)}
            rememberUser={rememberUser}
            translation={translation}
            currentOrder={currentOrder}
            dialogLoading={dialogLoading}
            sendUserToCheckout={this.sendUserToCheckout}
          />
          <SignUpDialog
            actions={actions}
            open={openSignUpDialog}
            handleClose={type => this.handleDialogClose(type)}
            translation={translation}
          />
          <VerifyDialog
            actions={actions}
            open={(user && (!user.isVerified && !user.guestToken)) || openVerifyDialog}
            handleClose={type => this.handleDialogClose(type)}
            translation={translation}
            user={user}
            userEmail={userEmail}
            currentOrder={currentOrder}
            sendUserToCheckout={this.sendUserToCheckout}
          />
          <ResetPasswordDialog
            actions={actions}
            open={openResetPasswordDialog}
            handleClose={type => this.handleDialogClose(type)}
            translation={translation}
            dialogLoading={dialogLoading}
            sendUserToCheckout={this.sendUserToCheckout}
            currentOrder={currentOrder}
          />
          <RewardDialog
            open={openRewardsDialog}
            handleClose={type => this.handleDialogClose(type)}
            actions={actions}
            user={user}
            translation={translation}
          />
          <GuestSignInDialog
            open={openGuestSignInDialog}
            handleClose={type => this.handleDialogClose(type)}
            actions={actions}
            translation={translation}
          />
        </div>
      </div>
    );
  }
}

CoreView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
  translation: PropTypes.func.isRequired,
  rememberUser: PropTypes.bool.isRequired,
  currentOrder: PropTypes.objectOf(PropTypes.any),
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  dialogLoading: PropTypes.number,
};

CoreView.defaultProps = {
  user: null,
  history: null,
  currentOrder: {},
  dialogLoading: 0,
};

export default withStyles(styles)(withRouter(withWidth()(CoreView)));
