
    module.exports = function cssToMuiLoader(theme) {
      
      return {
        
        'btn': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,},'btnPrimary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#a76e1b`,'color': `#ffffff`,
        },},'btnSecondary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#ffffff`,'color': `#d18a22`,'&:hover': {
          'backgroundColor': `#fff2e0`,'color': `#d18a22`,
        },},'btnTertiary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#70a0c3`,'color': `#ffffff`,
        },},'linkText': {'color': `#d18a22`,'cursor': `pointer`,'fontWeight': `bold`,'textDecoration': `underline`,'margin': `0 8px`,'fontSize': `0.75rem`,'fontFamily': `'Lato-Bold', sans-serif`,},'dialogTitleStyle': {'padding': `0 0 10px 0`,'fontSize': `30px`,'variant': `title`,},'dialogContentStyle': {'textAlign': `left`,'width': `100%`,'padding': `0`,'display': `flex`,'flexDirection': `column`,'alignItems': `center`,'color': `#282828`,'&:first-child': {
          'paddingTop': `0`,
        },},'dialogContentContainer': {'width': `100%`,'display': `flex`,'flexDirection': `column`,'alignItems': `center`,},'dialogContentTextContainer': {'width': `100%`,'display': `flex`,'justifyContent': `flex-start`,},'dialogContentText': {'fontSize': `20px`,'fontWeight': `bold`,'margin': `0 auto 4% auto`,'textAlign': `center`,},'actionBtnStyle': {'margin': `2% auto 4% auto`,'padding': `0`,},'dialogBodyContainerStyle': {'width': `85%`,},'textFieldStyle': {'variant': `outlined`,'marginBottom': `2%`,},'textFieldStyleMobile': {'variant': `outlined`,'marginBottom': `2%`,'marginBottom': `16px`,},'textFieldInnerInputRoot': {'backgroundColor': `#f7f7f7`,},'textFieldHelperText': {'color': `#282828`,'fontSize': `0.65rem`,'marginLeft': `14px`,},'groupContainer': {'width': `100%`,'display': `flex`,'flexDirection': `row`,'flexWrap': `no-wrap`,'justifyContent': `space-between`,},'groupedFieldsContainer': {'display': `flex`,'flexDirection': `row`,'flexWrap': `no-wrap`,},'textFieldInputTextProps': {'height': `45px`,'textAlign': `left`,'color': `#282828`,},'textFieldInputBoxProps': {'disableUnderline': `true`,},'disabled': {'color': `#9e9e9e`,'background': `#F0F145`,},'addressInput': {'color': `#282828`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `0.8rem`,},'autoCompleteAddress': {'color': `#282828`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `0.8rem`,'margin': `0`,},'addressControl': {'padding': `2% 1%`,},'actionButtonContainer': {'display': `flex`,'flexDirection': `row`,'width': `100%`,'justifyContent': `space-between`,'alignItems': `center`,},'backButtonStyle': {'width': `75px`,'height': `45px`,'fontSize': `13px`,'fontFamily': `'Lato-Regular', sans-serif`,'margin': `0`,'paddingLeft': `0`,'backgroundColor': `transparent`,'borderColor': `transparent`,'color': `#000000`,},'backArrowIcon': {'color': `#000000`,},'addButtonOverride': {'width': `50%`,},
        
      };
    };
  