import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import {
  Typography,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import Button from '../../core/components/Button';
import styles from '../../../css/core/components/SideBarComponent.scss';
import ShoppingCart from './ShoppingCart';
import { addCurrentCopyrightYear } from '../../../services/functions/Functions';

const pageStyles = typeof styles === 'function' ? styles() : styles;

const SideBarComponent = (props) => {
  const {
    handleClickOptions,
    handleClickAuthentication,
    headerOptions,
    selectedPage,
    footerOptions,
    logo,
    user,
    classes,
    translation,
  } = props;
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classes.sideBar,
      }}
      anchor="left"
    >
      <div className={classes.drawerToolbarContainer}>
        <div className={classes.drawerToolbarBody}>
          <img
            src={logo.src}
            alt={translation(logo.alt)}
            className={classes.logo}
          />
          <div className={classes.drawerToolBarSection}>
            {
              user
              ? (
                <div className={classes.loggedInUserContainer}>
                  <Typography className={classes.loggedInUserWelcomeText}>
                    {translation('HeaderComponent.welcomeText')}
                  </Typography>
                  <Typography className={classes.loggedInUserText}>
                    {user.name}
                  </Typography>
                </div>
              )
              : (
                <div className={classes.createAccountContainer}>
                  <Typography className={classes.nonMemberText}>
                    {translation('HeaderComponent.nonMember')}
                  </Typography>
                  <Typography>
                    <a
                      href="#"
                      onClick={() => handleClickOptions(translation('HeaderComponent.createAccount'))}
                      className={classes.createAccountText}
                    >
                      {translation('HeaderComponent.createAccount')}
                    </a>
                  </Typography>
                </div>
              )
            }
          </div>
          {/* TODO: Change Sign Out design when doing the app menu/routing task */}
          <div className={classes.drawerToolBarSection}>
            <Button
              onClick={() => handleClickAuthentication(user)}
              type="secondary"
              text={user ? translation('HeaderComponent.signOut') : translation('HeaderComponent.signIn')}
            />
          </div>
          <Divider />
          <div className={classes.drawerToolBarSection}>
            <List>
              {
            headerOptions.map(option => (
              <ListItem
                button
                key={option.id}
                className={classes.drawerToolbarElement}
                onClick={() => handleClickOptions(translation(option.key))}
              >
                {option.src ? <img src={option.src} alt={translation(option.alt)} /> : null}
                <ListItemIcon>
                  <Icon classes={{ root: selectedPage === option.key ? classes.drawerToolbarElementTextSelected : classes.drawerToolbarElementText }}>{option.icon}</Icon>
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: selectedPage === option.key ? classes.drawerToolbarElementTextSelected : classes.drawerToolbarElementText }}
                  primary={translation(option.title)}
                />
              </ListItem>
            ))
            }
            </List>
          </div>
        </div>
        <div className={classes.drawerToolbarFooter}>
          <div className={classes.drawerToolBarSection}>
            <ShoppingCart />
          </div>
          <div className={classes.drawerToolBarSection}>
            {
              footerOptions.map(item => (
                <Typography key={item.id} className={classes.optionsText}>
                  <a href={item.ref} className={classes.optionsText}>{translation(item.title)}</a>
                </Typography>
              ))
            }
          </div>
          <div className={classes.drawerToolBarSection}>
            <Typography className={classes.copyrightText}>
              {addCurrentCopyrightYear(translation('FooterComponent.copyright'))}
            </Typography>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

SideBarComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleClickOptions: PropTypes.func.isRequired,
  handleClickAuthentication: PropTypes.func.isRequired,
  headerOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  footerOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  logo: PropTypes.objectOf(PropTypes.string).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  total: PropTypes.number.isRequired,
  translation: PropTypes.func.isRequired,
  selectedPage: PropTypes.string.isRequired,
};

SideBarComponent.defaultProps = {
  user: null,
};

export default withStyles(styles)(SideBarComponent);
