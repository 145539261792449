/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import isSameDay from 'date-fns/isSameDay';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import Immutable from 'seamless-immutable';
import {
  withStyles,
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import {
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  Typography,
  Drawer,
  Divider,
  Icon,
  IconButton,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import {
  func,
  objectOf,
  string,
  bool,
  any,
  arrayOf,
} from 'prop-types';

import {
  guestCheckoutIncentives,
  guestCheckoutOptions,
} from '../../../services/constants/ResourceConstants.json';
import * as Routes from '../../../services/routes/Routes.json';
import AmexIcon from '../../../assets/images/american-express-1.png';
import GiftCardIcon from '../../../assets/images/primary_logo.svg';
import MasterIcon from '../../../assets/images/master.svg';
import VisaIcon from '../../../assets/images/visa.svg';
import Button from './Button';
import LinkText from './LinkText';
import TabBar from './TabBar';
import OrderHelper from '../../../services/helpers/OrderHelper';
import SubTotalSummary from './SubTotalSummary';
import OrderItemList from './OrderItemList';
import DeleteDialog from '../../paymentPage/components/DeleteDialog';
import SquarePaymentComponent from '../../paymentPage/subComponents/SquarePaymentComponent';
import SquareGiftCardComponent from '../../paymentPage/subComponents/SquareGiftCardComponent';
import ToastPaymentComponent from '../../paymentPage/subComponents/ToastPaymentComponent';
import CheckBoxComponent from './CheckBoxComponent';
import CraverCreditCardForm from './CraverCreditCardForm';
import {
  getCompany,
  getCurrentOrder,
  getCurrentOrderItems,
  getCurrentUserPaymentOptions,
  getUser,
  getIsPaymentDrawerVisible,
  getLoading,
} from '../../../selectors';
import styles from '../../../css/core/components/PaymentDrawer.scss';
import * as ResponsiveStyles from '../../../../jsonStyles/components/core/components/PaymentDrawer.style.json';
import {
  getFormattedPrice,
  formatEpoch,
  getPayAtDoorOptionsObject,
  customDecode,
} from '../../../services/functions/Functions';
import * as FeatureFlags from '../../../../feature-flags.json';
import { DINE_IN } from '../../../services/constants/Constants';

// Return styles object when testing
let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.paymentDrawer = {
  ...responsiveStyles.paymentDrawer,
  [theme.breakpoints.down('sm')]: ResponsiveStyles.paymentDrawer,
};

responsiveStyles.halfWidthInput = {
  ...responsiveStyles.halfWidthInput,
  [theme.breakpoints.down('sm')]: ResponsiveStyles.halfWidthInput,
};

const goBack = (actions) => {
  actions.toggleComponent('PaymentDrawer');
  actions.toggleComponent('CheckoutDrawer');
};

const LoadingOverlay = ({ classes }) => (
  <div className={classes.loadingOverlay}>
    <div className={classes.circularProgressContainer}>
      <CircularProgress />
    </div>
  </div>
);

const DrawerTitle = ({ actions, classes, translation }) => (
  <div className={classes.titleContainer}>
    <IconButton
      className={classes.headerIcon}
      onClick={() => goBack(actions)}
    >
      <Icon>arrow_back_ios</Icon>
    </IconButton>
    <Typography className={classes.headerTitle}>
      {translation('PaymentPage.drawerTitle')}
    </Typography>
    <IconButton
      className={classes.headerIcon}
      onClick={() => actions.toggleComponent('PaymentDrawer')}
    >
      <Icon>close</Icon>
    </IconButton>
  </div>
);

const RadioLabel = ({
  paymentOption, classes, showDeleteDialog, translation,
}) => {
  const paymentOptionIcons = {
    visa: VisaIcon,
    mastercard: MasterIcon,
    amex: AmexIcon,
    gift_card: GiftCardIcon,
  };
  // eslint-disable-next-line no-undef
  const currencySymbol = window.companyCurrencySymbol ? customDecode(window.companyCurrencySymbol) : '$';

  if (!paymentOption.cardType) {
    return (
      <div className={classes.cardLabel}>
        <Icon
          className={classes.cardIcon}
        >
          {paymentOption.icon}
        </Icon>
        <Typography className={classes.drawerText}>
          {paymentOption.name}
        </Typography>
      </div>
    );
  }
  return (
    <div className={classes.cardLabel}>
      <img
        src={paymentOptionIcons[paymentOption.cardType.toLowerCase()]}
        alt={paymentOption.cardType}
        className={classes.cardIcon}
      />
      <div>
        <Typography className={classes.drawerText}>
          {paymentOption.cardNumber}
        </Typography>
        {
          paymentOption.cardType === 'GIFT_CARD' && (
            <Typography className={classes.drawerText}>
              {`${translation('PaymentPage.balance')} : ${currencySymbol}${paymentOption.balance}`}
            </Typography>
          )
        }
      </div>
      <IconButton
        className={classes.drawerText}
        onClick={() => showDeleteDialog(paymentOption)}
      >
        <Icon>delete</Icon>
      </IconButton>
    </div>
  );
};

const PayAtDoorOptions = ({
  classes,
  translation,
  currentOrder,
  paymentOptionId,
  setPaymentOptionId,
  showDeleteDialog,
}) => {
  let payAtDoorOptions = [];
  const orderLocation = get(currentOrder, 'location', {});
  const { payAtDoorAvailable } = orderLocation;

  if (payAtDoorAvailable) {
    payAtDoorOptions = getPayAtDoorOptionsObject(orderLocation, translation);
  }

  return (
    <div className={classes.sectionContainer}>
      <div className={classes.sectionTitle}>
        <Typography className={classes.sectionTitleText}>
          {translation('PaymentPage.drawerPayment')}
        </Typography>
      </div>
      <div className={classes.sectionContent}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="payment_option"
            name="payment_option"
            value={paymentOptionId}
            onChange={event => setPaymentOptionId(event.target.value)}
          >
            {
              payAtDoorOptions.map(paymentOption => (
                <FormControlLabel
                  key={paymentOption.id}
                  value={`${paymentOption.id}`}
                  control={<Radio />}
                  label={<RadioLabel showDeleteDialog={showDeleteDialog} classes={classes} paymentOption={paymentOption} translation={translation} />}
                />
              ))
            }
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

const PayNowOptions = ({
  actions,
  classes,
  translation,
  currentOrder,
  paymentOptions,
  user,
  paymentOptionId,
  setPaymentOptionId,
  giftCardOption,
  setGiftCardOption,
  showDeleteDialog,
}) => {
  const orderLocation = get(currentOrder, 'location', {});
  const { payNowAvailable } = orderLocation;

  useEffect(() => {
    if (user && payNowAvailable) {
      actions.getAllResources(user.token, ['users', user.id, 'payment_options']);
    }
  }, []);

  const giftCardTabs = [
    { id: 'CARD', label: translation('PaymentMethodComponent.creditCard') },
    { id: 'GIFT_CARD', label: translation('PaymentMethodComponent.giftCard') },
  ];

  const creditCards = paymentOptions.filter(opt => (opt.cardType !== 'GIFT_CARD'));
  const giftCards = paymentOptions.filter(opt => (opt.cardType === 'GIFT_CARD'));

  const allOptions = giftCardOption === 'CARD' ? creditCards : giftCards;

  return (
    <div className={classes.sectionContainer}>
      <div className={classes.sectionTitle}>
        <Typography className={classes.sectionTitleText}>
          {translation('PaymentPage.drawerPayment')}
        </Typography>
      </div>
      {
        FeatureFlags.CoreView.PaymentDrawer.enableGiftCard
        && (
          <TabBar
            selected={giftCardOption}
            onClickSelected={value => setGiftCardOption(value)}
            tabs={giftCardTabs}
            translation={translation}
          />
        )
      }
      <div className={classes.sectionContent}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="payment_option"
            name="payment_option"
            value={paymentOptionId}
            onChange={event => setPaymentOptionId(event.target.value)}
          >
            {
              allOptions.map(paymentOption => (
                <FormControlLabel
                  key={paymentOption.id}
                  value={`${paymentOption.id}`}
                  control={<Radio />}
                  label={<RadioLabel showDeleteDialog={showDeleteDialog} classes={classes} paymentOption={paymentOption} translation={translation} />}
                />
              ))
            }
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

const PaymentForm = ({
  classes, translation, user, actions, currentOrder, company, setPaymentOptionId, giftCardOption, handleProcessPayment,
}) => {
  const commonProps = {
    user, actions, currentOrder, translation, company,
  };

  const handleAddCard = async (newCard) => {
    if (!user) return;
    actions.getAllResources(user.token, ['users', user.id, 'payment_options']).then(() => {
      setPaymentOptionId(`${newCard.id}`);
    });
  };

  const renderCreditCardForm = (paymentIntegration) => {
    switch (paymentIntegration) {
      case 'SQUARE':
        return <SquarePaymentComponent handleAddCard={handleAddCard} handleProcessPayment={handleProcessPayment} {...commonProps} />;
      case 'TOAST':
        return <ToastPaymentComponent handleAddCard={handleAddCard} {...commonProps} />;
      case 'NONE':
      default:
        return <CraverCreditCardForm {...commonProps} />;
    }
  };

  const renderGiftCardForm = () => (
    <SquareGiftCardComponent
      translation={translation}
      user={user}
      actions={actions}
      currentOrder={currentOrder}
    />
  );

  const paymentIntegration = get(company, 'paymentIntegration', 'NONE');
  return (
    <div className={classes.sectionContainer}>
      <div className={classes.sectionTitle}>
        <Typography className={classes.sectionTitleText}>
          {translation('PaymentPage.drawerPaymentInfo')}
        </Typography>
      </div>
      {
        giftCardOption === 'CARD'
          ? renderCreditCardForm(paymentIntegration)
          : renderGiftCardForm()
      }
    </div>
  );
};

const OrderSummary = ({
  classes, actions, currentOrder, translation,
}) => (
  <div className={classes.sectionContainer}>
    <div className={classes.sectionTitle}>
      <Typography className={classes.sectionTitleText}>
        {translation('PaymentPage.drawerSummary')}
      </Typography>
      <LinkText
        onClick={() => goBack(actions)}
        className={classes.linkText}
        text={translation('edit')}
      />
    </div>
    <div className={classes.sectionContent}>
      <OrderItemList
        orderItems={currentOrder.items}
        translation={translation}
        enableEdit={false}
      />
    </div>
  </div>
);

const PickupInformation = ({
  actions, currentOrder, classes, translation,
}) => {
  const { deliveryOption, desiredTime, isASAP } = currentOrder;
  const isDeliveredOrder = OrderHelper.isDeliveredOrder(deliveryOption);
  const isDineIn = deliveryOption === DINE_IN;

  // Location text (name, address)
  const firstRowText = isDeliveredOrder
    ? get(currentOrder, 'address.nickname')
    : get(currentOrder, 'location.name');
  const secondRowText = isDeliveredOrder
    ? get(currentOrder, 'address.streetAddress')
    : get(currentOrder, 'location.address');
  let thirdRowText;
  if (isDineIn) thirdRowText = currentOrder.tableNumber;

  // Order Time text (day, hour)
  const desiredDate = formatEpoch(desiredTime);
  let dayOptionText = desiredDate.toLocaleDateString([], { weekday: 'long', month: 'long', day: '2-digit' });
  let hourText = desiredDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  if (isSameDay(desiredDate, new Date())) dayOptionText = translation('today');
  if (isASAP) {
    dayOptionText = translation('today');
    hourText = translation('ASAP');
  }
  const infoText = `${translation(deliveryOption)} ${translation('PaymentPage.drawerInfo')}`;
  return (
    <div className={classes.sectionContainer}>
      <div className={classes.sectionTitle}>
        <Typography className={classes.sectionTitleText}>
          {infoText}
        </Typography>
        <LinkText
          onClick={() => goBack(actions)}
          className={classes.linkText}
          text={translation('edit')}
        />
      </div>
      <div className={classes.sectionContent}>
        <Typography className={classes.drawerText}>
          {firstRowText}
        </Typography>
        <Typography className={classes.drawerText}>
          {secondRowText}
        </Typography>
        {
          thirdRowText
          && (
            <Typography className={classes.drawerText}>
              {thirdRowText}
            </Typography>
          )
        }
        <div className={classes.orderTime}>
          <div className={classes.iconText}>
            <Icon className={classes.drawerText}>event</Icon>
            <Typography className={classes.drawerText}>
              {dayOptionText}
            </Typography>
          </div>
          <div className={classes.iconText}>
            <Icon className={classes.drawerText}>access_time</Icon>
            <Typography className={classes.drawerText}>
              {hourText}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};


const OrderInstructions = ({
  actions, currentOrder, classes, translation,
}) => (
  <div className={classes.sectionContainer}>
    <div className={classes.sectionTitle}>
      <Typography className={classes.sectionTitleText}>{translation('PaymentPage.drawerInstructions')}</Typography>
      <LinkText
        onClick={() => goBack(actions)}
        className={classes.linkText}
        text={translation('edit')}
      />
    </div>
    <div className={classes.sectionContent}>
      <Typography className={classes.drawerText}>
        {get(currentOrder, 'note')}
      </Typography>
    </div>
    <Divider />
  </div>
);

const GuestCheckoutRegister = (props) => {
  const {
    translation,
    classes,
    setGuestPassword,
    setShowGuestCheckout,
    showGuestCheckout,
    setSavePaymentInfo,
    setGuestSpecialOffers,
  } = props;
  const guestSetterFunctions = {
    savePaymentInfo: setSavePaymentInfo,
    guestSpecialOffers: setGuestSpecialOffers,
  };

  return (
    <div className={classes.guestCheckoutContainer}>
      <div className={classes.sectionTitleContainer}>
        <Typography className={classes.sectionTitleText}>
          {`${translation('PaymentPage.guestCheckoutForm.optional')}:`}
        </Typography>
        <IconButton
          className={classes.headerIcon}
          onClick={() => setShowGuestCheckout(!showGuestCheckout)}
        >
          <Icon>close</Icon>
        </IconButton>
      </div>
      <Typography className={classes.sectionTitleText}>
        {translation('PaymentPage.guestCheckoutForm.checkoutFaster')}
      </Typography>
      <Typography className={classes.sectionSubtitleText}>
        {translation('PaymentPage.guestCheckoutForm.almostThere')}
      </Typography>
      <div className={classes.incentiveContainer}>
        {
          guestCheckoutIncentives.map(incentive => (
            <div className={classes.incentiveItem}>
              <Icon className={classes.incentiveIcon}>
                {incentive.icon}
              </Icon>
              <Typography className={classes.incentiveText}>
                {incentive.text}
              </Typography>
            </div>
          ))
        }
      </div>
      <TextField
        id="outlined-password-input"
        variant="outlined"
        type="password"
        placeholder={translation('PaymentPage.guestCheckoutForm.password')}
        onChange={event => setGuestPassword(event.target.value)}
        className={classes.textFieldStyle}
      />
      <div className={classes.passwordLabelContainer}>
        <Icon className={classes.incentiveIcon}>check_circle</Icon>
        <Typography className={classes.passwordLabel}>
          {translation('PaymentPage.guestCheckoutForm.passwordFieldLabel')}
        </Typography>
      </div>
      {
        guestCheckoutOptions.map(option => (
          <CheckBoxComponent
            id={option.id}
            key={option.id}
            field={{ ...option, title: option.label }}
            handleChange={() => guestSetterFunctions[option.title](!props[option.title])}
            selectedValue={props[option.title]}
            formControlLabelStyle={classes.formControlLabelStyle}
            labelStyle={classes.labelTextStyle}
            checkboxContentStyle={classes.checkboxContent}
          />
        ))
      }
    </div>
  );
};

const PlaceOrderButton = ({
  translation, currentOrder, processPayment, paymentOptionId, loading,
}) => {
  const orderTotalPrice = get(currentOrder, 'totalPrice') || 0;

  return (
    <Button
      disabled={paymentOptionId === '' || loading}
      fullWidth
      onClick={processPayment}
      type="primary"
      text={`${translation('OrderSummaryComponent.placeOrder')} (${currentOrder ? getFormattedPrice(orderTotalPrice) : ''})`}
    />
  );
};

const PaymentTypeSelector = (props) => {
  const {
    setPayNowOrPayAtDoor, setPaymentOptionId, payNowOrPayAtDoor, classes, translation,
  } = props;

  const handleTypeChange = (event) => {
    setPaymentOptionId('');
    setPayNowOrPayAtDoor(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <Typography className={classes.sectionTitleText}>
        {translation('PaymentPage.drawerPaymentSelection')}
      </Typography>
      <RadioGroup aria-label="paynow-or-at-door" name="paymentSelector" value={payNowOrPayAtDoor} onChange={handleTypeChange}>
        <FormControlLabel
          value="payNow"
          control={<Radio />}
          label={translation('PaymentMethodComponent.payNow')}
        />
        <FormControlLabel
          value="payAtDoor"
          control={<Radio />}
          label={translation('PaymentMethodComponent.payAtDoor')}
        />
      </RadioGroup>
    </FormControl>
  );
};

const PaymentDrawer = (props) => {
  const {
    actions,
    company,
    isDesktop,
    open,
    classes,
    currentOrder = {},
    translation,
    loading,
    history,
    paymentOptions,
    user,
  } = props;

  const hasPayAtDoor = get(currentOrder, 'location.payAtDoorAvailable');
  const hasPayNow = get(currentOrder, 'location.payNowAvailable');
  const usePaymentSelector = hasPayAtDoor && hasPayNow;
  let defaultPaymentType = 'payNow';
  if (!hasPayNow && hasPayAtDoor) defaultPaymentType = 'payAtDoor';

  const [payNowOrPayAtDoor, setPayNowOrPayAtDoor] = useState(defaultPaymentType);
  const [paymentOptionId, setPaymentOptionId] = useState('');
  const [giftCardOption, setGiftCardOption] = useState('CARD');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [paymentOptionToDelete, setPaymentOptionToDelete] = useState(null);
  const [savePaymentInfo, setSavePaymentInfo] = useState(false);
  const [guestPassword, setGuestPassword] = useState(null);
  const [guestSpecialOffers, setGuestSpecialOffers] = useState(false);
  const [showGuestCheckout, setShowGuestCheckout] = useState(true);

  useEffect(() => {
    if (!user) return;
    if (!company) {
      actions.getAllResources(user.token, ['companies']);
    }
    if (user.guestToken) {
      setSavePaymentInfo(false);
    } else {
      setSavePaymentInfo(true);
    }
  }, [open]);

  const payAtDoorOptionsString = get(currentOrder, 'location.payAtDoorOptions', '');
  const payAtDoorOptions = payAtDoorOptionsString ? payAtDoorOptionsString.split(',') : [];

  const type = payAtDoorOptions.includes(paymentOptionId) ? 'payAtDoor' : 'payNow';
  // TO-DO: Handle user points
  const points = get(user, 'points');

  // This comes directly from PaymentPage :P
  const processPayment = async (digitalWalletPaymentMethod = null) => {
    if (!digitalWalletPaymentMethod && !paymentOptionId) return;
    let paymentMethod;
    const selectedPaymentOption = paymentOptions.find(payOpt => payOpt.id === parseInt(paymentOptionId, 10));
    switch (type) {
      case 'payNow':
        if (digitalWalletPaymentMethod) {
          paymentMethod = digitalWalletPaymentMethod;
        } else {
          paymentMethod = {
            id: selectedPaymentOption.id,
            nonce: selectedPaymentOption.vaultKey,
            cvn: '123',
          };
        }
        break;
      case 'payAtDoor':
        paymentMethod = {
          name: paymentOptionId,
        };
        break;
      case 'newCard':
        paymentMethod = {
          name: selectedPaymentOption.name,
          cardNumber: selectedPaymentOption.cardNumber,
          cvc: selectedPaymentOption.cvc,
          expiryMonth: selectedPaymentOption.expiryDate.slice(0, 2),
          expiryYear: selectedPaymentOption.expiryDate.slice(2),
          streetNumber: selectedPaymentOption.streetNameNumber.split(/ (.*)/)[0],
          streetName: selectedPaymentOption.streetNameNumber.split(/ (.*)/)[1],
        };
        break;
      default: break;
    }

    if (user && paymentMethod && currentOrder.id) {
      try {
        // Create paymentOption if we're using a new card.
        if (type === 'newCard') {
          const newCardResponse = await actions
            .addResource(
              user.token, paymentMethod,
              'users', user.id,
              'payment_options',
            );
          if (!newCardResponse || newCardResponse.error) return;
          paymentMethod = {
            id: newCardResponse.id,
            nonce: newCardResponse.vaultKey,
            cvn: '123',
          };
        }
        if (loading) return;
        const paymentResponse = await actions
          .makePayment(user, paymentMethod, [
            'users', user.id,
            'orders', currentOrder.id,
            'place',
          ]);
        if (!paymentResponse || paymentResponse.error) return;
        // Update user's points (user.points) if order was successfully placed
        // Only update redux state, no API calls needed. Need to make a copy
        // of the existing user object to make sure no other changes are made.
        const updatedUserObj = Immutable.asMutable(user, { deep: true });
        updatedUserObj.points = points;
        const updateUserResponse = actions.updateUser(user.token, updatedUserObj);
        if (!updateUserResponse || updateUserResponse.error) return;
        actions.toggleComponent('PaymentDrawer');

        // Handle guest User
        if (guestPassword) {
          const newUserObj = {
            name: user.name,
            email: user.email,
            password: guestPassword,
            telephone: user.telephone,
          };
          await actions.updateUser(user.token, newUserObj, ['users', user.id]);
          await actions.sendVerificationToken({ email: user.email }, ['users', 'token']);
        }
        // Delete payment info for guest user
        if (!savePaymentInfo) {
          paymentOptions.forEach((paymentOption) => {
            actions.deleteResource(user, ['users', user.id, 'payment_options', paymentOption.id]);
          });
        }
        history.push(Routes.path.orderStatusPage);
      } catch (error) {
        console.log('API call error', error);
      }
    }
  };

  const showDeleteDialog = (payOpt) => {
    setPaymentOptionToDelete(payOpt);
    setDeleteDialogOpen(true);
  };

  const deletePaymentOption = () => {
    if (user) {
      actions.deleteResource(user, ['users', user.id, 'payment_options', paymentOptionToDelete.id]);
      setDeleteDialogOpen(false);
      setPaymentOptionId('');
    }
  };

  if (!currentOrder || !user) return null;
  return (
    <Drawer
      classes={{
        paper: classes.paymentDrawer,
      }}
      open={open}
      onClose={() => actions.toggleComponent('PaymentDrawer')}
      anchor={isDesktop ? 'right' : 'bottom'}
    >
      {
        loading !== 0 && (
          <LoadingOverlay {...props} />
        )
      }
      <div className={classes.header}>
        <DrawerTitle {...props} />
      </div>
      <Divider />
      <div className={classes.body}>
        {
          usePaymentSelector && (
            <PaymentTypeSelector
              payNowOrPayAtDoor={payNowOrPayAtDoor}
              setPayNowOrPayAtDoor={setPayNowOrPayAtDoor}
              setPaymentOptionId={setPaymentOptionId}
              {...props}
            />
          )
        }
        {
          payNowOrPayAtDoor === 'payAtDoor'
          && (
            <PayAtDoorOptions
              {...props}
              showDeleteDialog={showDeleteDialog}
              giftCardOption={giftCardOption}
              setGiftCardOption={setGiftCardOption}
              paymentOptionId={paymentOptionId}
              setPaymentOptionId={setPaymentOptionId}
            />
          )
        }
        {
          payNowOrPayAtDoor === 'payNow'
          && (
            <PayNowOptions
              {...props}
              showDeleteDialog={showDeleteDialog}
              giftCardOption={giftCardOption}
              setGiftCardOption={setGiftCardOption}
              paymentOptionId={paymentOptionId}
              setPaymentOptionId={setPaymentOptionId}
            />
          )
        }
        <Divider />
        {
          payNowOrPayAtDoor === 'payNow'
          && (
            <PaymentForm
              {...props}
              giftCardOption={giftCardOption}
              handleProcessPayment={processPayment}
              setPaymentOptionId={setPaymentOptionId}
            />
          )
        }
        <Divider />
        <OrderSummary {...props} />
        <Divider />
        <PickupInformation {...props} />
        <Divider />
        <OrderInstructions {...props} />
        <div className={classes.sectionContainer}>
          <SubTotalSummary translation={translation} showTotal />
        </div>
      </div>
      <Divider />
      {
        (FeatureFlags.enableGuestCheckout && user.guestToken && showGuestCheckout)
        && (
          <GuestCheckoutRegister
            {...props}
            setGuestPassword={setGuestPassword}
            savePaymentInfo={savePaymentInfo}
            setSavePaymentInfo={setSavePaymentInfo}
            guestSpecialOffers={guestSpecialOffers}
            setGuestSpecialOffers={setGuestSpecialOffers}
            setShowGuestCheckout={setShowGuestCheckout}
            showGuestCheckout={showGuestCheckout}
          />
        )
      }
      <div className={classes.footer}>
        <PlaceOrderButton
          loading={loading}
          paymentOptionId={paymentOptionId}
          processPayment={() => processPayment()}
          translation={translation}
          currentOrder={currentOrder}
        />
      </div>
      {
        deleteDialogOpen
        && (
          <DeleteDialog
            open={deleteDialogOpen}
            translation={translation}
            cardType={paymentOptionToDelete && paymentOptionToDelete.cardType}
            cardName={paymentOptionToDelete && paymentOptionToDelete.cardNumber}
            handleClick={() => deletePaymentOption(paymentOptionToDelete)}
            handleCloseDeleteDialog={() => setDeleteDialogOpen(false)}
          />
        )
      }
    </Drawer>
  );
};

PaymentDrawer.propTypes = {
  classes: objectOf(string).isRequired,
  open: bool.isRequired,
  isDesktop: bool.isRequired,
  translation: func.isRequired,
  currentOrder: objectOf(any),
  products: arrayOf(objectOf(any)),
  paymentOptions: arrayOf(objectOf(any)),
};

PaymentDrawer.defaultProps = {
  currentOrder: null,
  products: [],
  paymentOptions: [],
};

const mapStateToProps = state => ({
  open: getIsPaymentDrawerVisible(state),
  currentOrder: getCurrentOrder(state),
  company: getCompany(state),
  currentOrderItems: getCurrentOrderItems(state),
  paymentOptions: getCurrentUserPaymentOptions(state),
  loading: getLoading(state),
  user: getUser(state).user,
});

const EnhancedPaymentDrawer = compose(
  connect(mapStateToProps),
  withStyles(responsiveStyles),
)(PaymentDrawer);
export default EnhancedPaymentDrawer;
