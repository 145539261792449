import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, func, objectOf, string, any } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Card,
} from '@material-ui/core';

import * as Routes from '../../services/routes/Routes.json';
import { getNotifications } from '../../selectors';
import { isDesktopMode } from '../../services/functions/Functions';
import DateHelper from '../../services/helpers/DateHelper';
import RedirectLinkComponent from '../core/components/RedirectLinkComponent';
import styles from '../../css/notificationsPage/NotificationPage.scss';

const NotificationContent = (props) => {
  const { classes, translation, message } = props;
  return (
    <Card className={classes.messageContainer}>
      <Typography className={classes.title}>
        {message.title}
      </Typography>
      <Typography className={classes.dateTime}>
        {DateHelper.getSuggestedTimeIntervalString(message.createdAt, translation('NotificationsPage.at'))}
      </Typography>
      <Typography className={classes.messageBody}>
        {message.body}
      </Typography>
    </Card>
  );
};

const NotificationContainer = (props) => {
  const {
    history,
    user,
    translation,
    notifications,
    classes,
    width,
  } = props;

  const notification = notifications.find(note => note.id === parseInt(props.match.params.notificationId, 10));

  if (user && user.guestToken) {
    history.push(Routes.path.menuPage);
  }
  if (!user) history.push(Routes.path.menuPage);
  else if (isDesktopMode(width)) history.push(Routes.path.notificationsPage);

  return (
    <div className={classes.outerContainer}>
      <div className={classes.linkContainer}>
        <RedirectLinkComponent
          href="/notifications"
          linkText={translation('NotificationsPage.NotificationPage.back')}
        />
      </div>
      <NotificationContent
        {...props}
        message={notification}
      />
    </div>
  );
};

NotificationContainer.propTypes = {
  classes: objectOf(string).isRequired,
  translation: func.isRequired,
  history: objectOf(any).isRequired,
  user: objectOf(any),
  notifications: arrayOf(objectOf(any)),
  width: string,
};

NotificationContent.propTypes = {
  classes: objectOf(string).isRequired,
  translation: func.isRequired,
  message: objectOf(any).isRequired,
};

NotificationContainer.defaultProps = {
  user: null,
  notifications: [],
  width: '',
};

const mapStateToProps = state => ({
  notifications: getNotifications(state),
});

const NotificationPage = compose(
  connect(mapStateToProps),
  withStyles(styles),
)(withRouter(NotificationContainer));

export default NotificationPage;
