import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createMuiTheme, responsiveFontSizes, withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { Icon, IconButton } from '@material-ui/core';

import styles from '../../../css/core/components/DialogView.scss';
import * as Functions from '../../../services/functions/Functions';
import ResponsiveStyles from '../../../../jsonStyles/components/core/components/DialogView.json';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const responsiveStyles = typeof styles === 'function' ? styles() : styles;

responsiveStyles.dialogCloseButton = {
  ...responsiveStyles.dialogCloseButton,
  [theme.breakpoints.down('sm')]: {
    margin: ResponsiveStyles.dialogCloseButton.margin,
    padding: ResponsiveStyles.dialogCloseButton.padding,
  },
};

const DialogViewTitle = (props) => {
  const {
    handleClose, titleHasCloseBtn, dialogTitleText, dialogTitleSubheader2, dialogTitleStyle,
    dialogTitleSubheader2Style, dialogCloseIconColor, titleAlignClose, dialogHeaderStyle,
    classes, hasHeaderImage,
  } = props;

  const dialogTitleRootStyle = dialogTitleStyle || classes.dialogTitleRootBase;
  if (hasHeaderImage) {
    return (
      <IconButton
        onClick={handleClose}
        className={classes.floatingCloseIconButton}
      >
        <Icon
          style={{ ...responsiveStyles.floatingCloseIcon, color: dialogCloseIconColor }}
        >
          close
        </Icon>
      </IconButton>
    );
  }

  return (
    <DialogTitle className={dialogTitleRootStyle}>
      {
        titleHasCloseBtn
        && (
          <div
            className={classes.dialogHeaderContainer}
            style={{
              backgroundColor: dialogHeaderStyle.backgroundColor || responsiveStyles.dialogHeaderContainer.defaultBackgroundColor,
            }}
          >
            <div className={classes.dialogHeaderTitleContainer}>
              {
                titleAlignClose
                && (
                  <Typography
                    gutterBottom
                    className={dialogTitleStyle}
                    variant={dialogTitleStyle.variant}
                  >
                    {dialogTitleText}
                  </Typography>
                )
              }
            </div>
            <IconButton
              onClick={handleClose}
            >
              <Icon
                style={{ ...responsiveStyles.dialogCloseButton, color: dialogCloseIconColor }}
              >
                close
              </Icon>
            </IconButton>
          </div>
        )
      }
      <div>
        {
          !titleAlignClose
          && (
            <Typography
              gutterBottom
              className={dialogTitleStyle}
              variant={dialogTitleStyle.variant}
            >
              {dialogTitleText}
            </Typography>
          )
        }
        {
          dialogTitleSubheader2
            ? (
              <Typography gutterBottom className={dialogTitleSubheader2Style}>
                {dialogTitleSubheader2}
              </Typography>
            )
            : null
        }
      </div>
    </DialogTitle>
  );
};

class DialogView extends React.Component {
  state = {
    open: this.props.open,
  };

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open) {
      this.setState({
        open: this.props.open,
      });
    }
  }

  /*
  * Notes about the props
  *
  * dialogPaperStyle = style for the Paper which determines the dimensions of the DialogView
  */
  render() {
    const {
      handleClose, hasDialogActions, renderActionBtn, actionBtnStyle, hasDialogContent,
      hasDialogContent2, hasDialogErrorContent, renderDialogContent, renderDialogContent2,
      renderDialogErrorContent, dialogBodyContainerStyle, dialogContentStyle, dialogErrorContentStyle,
      disableBackdropClick, disableEscapeKeyDown, classes,
      dialogPaperStyle, loading,
    } = this.props;

    const isTablet = Functions.isTabletMode(this.props.width);
    return (
      <Dialog
        disableBackdropClick={disableBackdropClick}
        disableEscapeKeyDown={disableEscapeKeyDown}
        open={this.state.open}
        onClose={handleClose}
        scroll="paper"
        maxWidth={responsiveStyles.dialog.maxWidth === 'false' ? responsiveStyles.dialog.maxWidth === 'true' : responsiveStyles.dialog.maxWidth}
        className={classes.dialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: dialogPaperStyle || (
            isTablet ? responsiveStyles.dialogPaperStyleMobile : responsiveStyles.dialogPaperStyle
            ), // responsiveStyles.dialogPaperStyle has default width of 620px
        }}
      >
        <DialogViewTitle {...this.props} />
        <div className={classNames(classes.dialogBodyStyle, dialogBodyContainerStyle)}>
          {
            hasDialogErrorContent
            && (
              <DialogContent className={dialogErrorContentStyle}>
                {
                  loading
                    ? (
                      <CircularProgress
                        className={classes.circularProgress}
                      />
                    )
                    : renderDialogErrorContent()
                }
              </DialogContent>
            )
          }
          {
            hasDialogContent
            && (
              <DialogContent className={dialogContentStyle}>
                {
                  !loading && renderDialogContent()
                }
              </DialogContent>
            )
          }
          {
            hasDialogActions
            && (
              <DialogActions className={actionBtnStyle}>
                {
                  loading
                    ? (
                      <CircularProgress
                        size={responsiveStyles.circularProgress.size}
                        color={responsiveStyles.circularProgress.color}
                        className={classes.circularProgress}
                      />
                    )
                    : renderActionBtn()
                }
              </DialogActions>
            )
          }
          {
            hasDialogContent2
            && (
              <DialogContent className={dialogContentStyle}>
                {
                  loading
                    ? (
                      <CircularProgress
                        size={responsiveStyles.circularProgress.size}
                        color={responsiveStyles.circularProgress.color}
                        className={classes.circularProgress}
                      />
                    )
                    : renderDialogContent2()
                }
              </DialogContent>
            )
          }
        </div>
      </Dialog>
    );
  }
}

DialogView.propTypes = {
  open: PropTypes.bool.isRequired,
  disableBackdropClick: PropTypes.bool.isRequired,
  disableEscapeKeyDown: PropTypes.bool.isRequired,
  titleHasCloseBtn: PropTypes.bool.isRequired,
  handleClose: (props, propName) => {
    if ((!props.disableEscapeKeyDown || !props.disableEscapeKeyDown || props.titleHasCloseBtn) &&
      (props[propName] === undefined || typeof (props[propName]) !== 'function')) {
      return new Error('Please provide a handleClose function!');
    }
  },
  loading: PropTypes.bool,
  hasDialogActions: PropTypes.bool.isRequired,
  renderActionBtn: (props, propName) => {
    if ((props.hasDialogActions && (props[propName] === undefined || typeof (props[propName]) !== 'function'))) {
      return new Error('Please provide a renderActionBtn function!');
    }
  },
  actionBtnStyle: PropTypes.string,
  hasDialogContent: PropTypes.bool.isRequired,
  renderDialogContent: (props, propName) => {
    if ((props.hasDialogContent && (props[propName] === undefined || typeof (props[propName]) !== 'function'))) {
      return new Error('Please provide a renderDialogContent function!');
    }
  },
  hasDialogContent2: PropTypes.bool.isRequired,
  renderDialogContent2: (props, propName) => {
    if ((props.hasDialogContent2 && (props[propName] === undefined || typeof (props[propName]) !== 'function'))) {
      return new Error('Please provide a renderDialogContent2 function!');
    }
  },
  hasDialogErrorContent: PropTypes.bool.isRequired,
  renderDialogErrorContent: (props, propName) => {
    if ((props.hasDialogErrorContent && (props[propName] === undefined || typeof (props[propName]) !== 'function'))) {
      return new Error('Please provide a renderDialogErrorContent function!');
    }
  },
  hasHeaderImage: PropTypes.bool,
  dialogBodyContainerStyle: PropTypes.string,
  dialogContentStyle: PropTypes.string,
  dialogErrorContentStyle: PropTypes.string,
  dialogTitleText: PropTypes.string,
  dialogTitleSubheader2: PropTypes.string,
  dialogTitleStyle: PropTypes.string,
  dialogTitleSubheader2Style: PropTypes.string,
  dialogCloseIconColor: PropTypes.string,
  titleAlignClose: PropTypes.bool,
  dialogHeaderStyle: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  dialogPaperStyle: PropTypes.objectOf(PropTypes.any),
};

DialogView.defaultProps = {
  loading: null,
  handleClose: null,
  renderActionBtn: null,
  actionBtnStyle: '',
  renderDialogContent: null,
  renderDialogContent2: null,
  renderDialogErrorContent: null,
  dialogBodyContainerStyle: '',
  dialogContentStyle: '',
  dialogErrorContentStyle: '',
  dialogTitleText: '',
  dialogTitleSubheader2: '',
  dialogTitleStyle: '',
  dialogTitleSubheader2Style: '',
  dialogCloseIconColor: '',
  titleAlignClose: false,
  dialogHeaderStyle: '',
  dialogPaperStyle: null,
  hasHeaderImage: false,
};

export default withStyles(responsiveStyles)(withWidth()(DialogView));
