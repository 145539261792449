import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import {
  endOfDay,
  differenceInDays,
  differenceInMinutes,
  addMinutes,
  startOfDay,
  getMinutes,
} from 'date-fns';
import get from 'lodash/get';
import addDays from 'date-fns/addDays';
import range from 'lodash/range';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import {
  instanceOf,
  func,
  objectOf,
  string,
  any,
  number,
} from 'prop-types';

import { timeSelectorMinutes } from '../../../services/constants/ResourceConstants.json';
import { getPrepTime } from '../../../services/functions/Functions';
import { getCurrentOrder } from '../../../selectors';
import styles from '../../../css/menuPage/components/DateTimeDropdown.scss';

// eslint-disable-next-line no-unused-vars
let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

// Returns the first time option available, approximated to either an exact hour,
// or past the ammount of minutes selected with timeOptionInterval.
// For example: If TimeSelector minutes = 30 ; 3:45 -> 4:00 or 3:15 -> 3:30.
// Another example: If timeOptionInterval = 15 ; 3:40 -> 3:45 or 3:05 -> 3:15
const getFirstTimeOption = (order, timeOptionInterval) => {
  const prepTime = getPrepTime(order);
  const firstOptionDate = addMinutes(new Date(), prepTime);
  const dateOptionsMinutes = getMinutes(firstOptionDate);
  if (dateOptionsMinutes >= timeOptionInterval) return addMinutes(firstOptionDate, 60 - dateOptionsMinutes);
  return addMinutes(firstOptionDate, timeOptionInterval - dateOptionsMinutes);
};

const dateOptions = (maxDate, translation) => {
  const todayDateTime = new Date();
  const numberOfDays = differenceInDays(maxDate, todayDateTime);
  return range(numberOfDays).map((dayIndex) => {
    const DateOption = addDays(todayDateTime, dayIndex);
    let dayOptionText = DateOption.toLocaleDateString([], { weekday: 'long', month: 'long', day: '2-digit' });
    if (dayIndex === 0) dayOptionText = `${translation('today')}, ${dayOptionText}`;
    return (
      <MenuItem key={dayIndex} value={dayIndex}>
        {dayOptionText}
      </MenuItem>
    );
  });
};

const timeOptions = (selectedDateIndex, firstDateOption, timeOptionInterval) => {
  const numberOfMinutes = selectedDateIndex === 0 ? differenceInMinutes(endOfDay(firstDateOption), firstDateOption) : 1440;
  if (numberOfMinutes === 0) return null;
  // timeOptionInterval is the amount of minutes we'll be adding to each selectable interval.
  // for example: 12:00, 12:15, 12:30 is 15 minutes ; 12:30, 1:00, 1:30 is 30 minutes.
  return range(Math.floor(numberOfMinutes / timeOptionInterval)).map((minuteIndex) => {
    const startHour = selectedDateIndex === 0 ? firstDateOption : startOfDay(addDays(firstDateOption, selectedDateIndex));
    const hour = addMinutes(startHour, minuteIndex * timeOptionInterval);
    const hourText = hour.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return (
      <MenuItem key={minuteIndex} value={minuteIndex}>
        {hourText}
      </MenuItem>
    );
  });
};

const DateTimeDropdown = (props) => {
  const {
    classes, maxDate, translation, currentOrder, onChange,
    currentDateIndex, currentTimeIndex,
  } = props;

  const [selectedTimeIndex, setSelectedTimeIndex] = useState(currentTimeIndex);
  const [selectedDateIndex, setSelectedDateIndex] = useState(currentDateIndex);

  // Time option interval comes from the "timeSelectorMinutes" by default.
  // UNLESS the order location has them set up using the `configurations` table
  const orderLocationTimeSlot = get(currentOrder, 'location.ORDER_TIME_SLOT');
  const timeOptionInterval = orderLocationTimeSlot || timeSelectorMinutes;

  // We will later use this first option in our other functions.
  const firstDateOption = getFirstTimeOption(currentOrder, timeOptionInterval);

  const updateSelectedTime = () => {
    let selectedDay = addDays(firstDateOption, selectedDateIndex);
    if (selectedDateIndex !== 0) selectedDay = startOfDay(selectedDay);
    const selectedTime = addMinutes(selectedDay, timeOptionInterval * selectedTimeIndex);
    onChange(selectedTime, selectedDateIndex, selectedTimeIndex);
  };

  const dayTimeOptions = timeOptions(selectedDateIndex, firstDateOption, timeOptionInterval);

  useEffect(() => {
    updateSelectedTime();
  }, [selectedDateIndex, selectedTimeIndex]);

  return (
    <div className={classes.dropdownContainer}>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          labelId="date-select-outlined-label"
          id="date-select-outlined"
          value={selectedDateIndex}
          onChange={event => setSelectedDateIndex(event.target.value)}
        >
          {dateOptions(maxDate, translation)}
        </Select>
      </FormControl>
      {
        dayTimeOptions
          ? (
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="time-select-outlined-label"
                id="time-select-outlined"
                value={selectedTimeIndex}
                onChange={event => setSelectedTimeIndex(event.target.value)}
              >
                {dayTimeOptions}
              </Select>
            </FormControl>
          )
          : (
            <div className={classes.dayErrorContainer}>
              <Typography className={classes.dayErrorText}>
                {translation('CheckoutDrawer.orderTimeSelector.selectedDayError')}
              </Typography>
            </div>
          )
      }
    </div>
  );
};

DateTimeDropdown.defaultProps = {
  maxDate: null,
};

DateTimeDropdown.propTypes = {
  onChange: func.isRequired,
  currentOrder: objectOf(any).isRequired,
  maxDate: instanceOf(Date),
  classes: objectOf(string).isRequired,
  translation: func.isRequired,
  currentDateIndex: number.isRequired,
  currentTimeIndex: number.isRequired,
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
});

export default connect(mapStateToProps)(withStyles(responsiveStyles)(DateTimeDropdown));
