import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../css/locationsPage/subComponents/HoursComponent.scss';
import { PICKUP, DELIVERY, BUSINESS } from '../../../services/constants/Constants';
import * as FeatureFlags from '../../../../feature-flags.json';
import { getGroupedHoursForDeliveryType } from '../../../services/helpers/HoursUtils';

const responsiveStyles = typeof styles === 'function' ? styles() : styles;

const locationHoursOptions = [
  {
    type: BUSINESS,
    shouldShow: FeatureFlags.HoursComponent.showBusinessHours,
    headingText: 'HoursComponent.businessHours',
  },
  {
    type: DELIVERY,
    shouldShow: FeatureFlags.HoursComponent.showDeliveryHours,
    headingText: 'HoursComponent.deliveryHours',
  },
  {
    type: PICKUP,
    shouldShow: FeatureFlags.HoursComponent.showPickUpHours,
    headingText: 'HoursComponent.pickupHours',
  },
];

class HoursComponent extends Component {
  generateKey = index => (`key${index}`);

  showDeliveryOptionHours = (locationHours, type) => {
    let bool = true;
    if (type === DELIVERY) {
      bool = locationHours.some(hour => hour.deliveryOpen || hour.deliveryClose);
    } else if (type === PICKUP) {
      bool = locationHours.some(hour => hour.pickupOpen || hour.pickupClose);
    }
    return bool;
  }

  renderNoHours = () => {
    const { translation, classes, textcolorOverride } = this.props;
    return (
      <div className={classes.businessHours}>
        <Typography
          style={textcolorOverride ? { ...responsiveStyles.locationHoursHeading, color: `${textcolorOverride}` } : responsiveStyles.locationHoursHeading}
        >
          {translation('HoursComponent.locationUnavailableHours')}
        </Typography>
      </div>
    );
  };

  renderHours = ({
    hours, className, hoursKey, textcolorOverride,
  }) => (
    hours && (
      <Typography
        style={textcolorOverride ? { ...responsiveStyles[className], color: `${textcolorOverride}` } : responsiveStyles[className]}
        key={hoursKey}
      >
        {hours}
      </Typography>
    )
  );

  renderFormattedHours = (text, optionType, index = 0) => {
    const {
      classes, translation, location, textcolorOverride,
    } = this.props;
    const deliveryTypeTimes = getGroupedHoursForDeliveryType(location, optionType);
    return (
      <div key={this.generateKey(index)}>
        <Typography
          style={textcolorOverride ? { ...responsiveStyles.locationHoursHeading, color: `${textcolorOverride}` } : responsiveStyles.locationHoursHeading}
        >
          {`${translation(text)}:`}
        </Typography>
        <div className={classes.businessHours}>
          {
            deliveryTypeTimes.map((time, i) => (
              <div>
                { this.renderHours({
                    hours: time.hours, className: classes.regularHours, hoursKey: i, textcolorOverride,
                  }) }
                {
                  time.holidays.length > 0 && (
                    time.holidays.map((holiday, j) => (
                      this.renderHours({
                        hours: holiday, className: classes.holidayHours, hoursKey: j, textcolorOverride,
                      })
                    ))
                  )
                }
              </div>
            ))
          }
        </div>
      </div>
    );
  };

  render() {
    const {
      classes,
      hours,
      location,
      wrapperStyle,
      deliveryOption,
    } = this.props;
    let locationHoursOptionsIndex = locationHoursOptions.findIndex(option => option.type === deliveryOption);
    locationHoursOptionsIndex = locationHoursOptionsIndex >= 0 ? locationHoursOptionsIndex : 0;
    if (hours.length === 0) return this.renderNoHours();
    return (
      deliveryOption && this.showDeliveryOptionHours(location.hours, deliveryOption)
        ? this.renderFormattedHours(locationHoursOptions[locationHoursOptionsIndex].headingText, deliveryOption)
        : (
          <div className={wrapperStyle || classes.hours}>
            {
              locationHoursOptions.map((option, index) => (
                option.shouldShow
                && this.showDeliveryOptionHours(location.hours, option.type)
                && (
                  this.renderFormattedHours(option.headingText, option.type, index)
                )
              ))
            }
          </div>
        )
    );
  }
}

HoursComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  hours: PropTypes.arrayOf(PropTypes.any).isRequired,
  translation: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  wrapperStyle: PropTypes.string,
  deliveryOption: PropTypes.string,
  textcolorOverride: PropTypes.string,
};

HoursComponent.defaultProps = {
  wrapperStyle: '',
  deliveryOption: null,
  textcolorOverride: null,
};

export default withStyles(responsiveStyles)(HoursComponent);
