
    module.exports = function cssToMuiLoader(theme) {
      
      return {
        
        'btn': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,},'btnPrimary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#a76e1b`,'color': `#ffffff`,
        },},'btnSecondary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#ffffff`,'color': `#d18a22`,'&:hover': {
          'backgroundColor': `#fff2e0`,'color': `#d18a22`,
        },},'btnTertiary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#70a0c3`,'color': `#ffffff`,
        },},'linkText': {'color': `#d18a22`,'cursor': `pointer`,'fontWeight': `bold`,'textDecoration': `underline`,'margin': `0 8px`,'fontSize': `0.75rem`,'fontFamily': `'Lato-Bold', sans-serif`,},'appDrawer': {'width': `55vw`,'paddingTop': `0px`,'backgroundColor': `#ffffff`,'height': `100vh`,'overflow': `hidden`,},'staticMenu': {'backgroundColor': `#000000`,'paddingBottom': `25px`,'paddingLeft': `5vw`,'paddingRight': `5vw`,},'staticMenuText': {'color': `#ffffff`,},'variableMenu': {'height': `100vh`,'overflow': `hidden`,'paddingTop': `25px`,'paddingLeft': `5vw`,'paddingRight': `5vw`,},'loggedOut': {'display': `flex`,'alignItems': `center`,'flexDirection': `column`,'justifyContent': `space-between`,'height': `100px`,},'notMember': {'fontFamily': `'Lato-Regular', sans-serif`,'textAlign': `center`,'color': `#000`,},'createAccount': {'cursor': `pointer`,'textDecoration': `underline`,'fontFamily': `'Lato-Regular', sans-serif`,'textAlign': `center`,'color': `#000`,},'notificationIconStyle': {'color': `#000000`,'paddingRight': `12px`,'paddingBottom': `5px`,},
        
      };
    };
  