import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import { getMobileFriendlyStyle } from '../../../services/functions/Functions';

import styles from '../../../css/core/components/PageTitle.scss';

const PageTitle = (props) => {
  const { 
    classes,
    containerWidth,
    title,
    width,
  } = props;

  const mobileFriendlyStyleProps = { classes, width };
  const containerStyles = getMobileFriendlyStyle(mobileFriendlyStyleProps, 'container');

  return (
    <div className={containerStyles} style={{ width: containerWidth }}>
      <div className={classes.rectangle} />
      <Typography className={classes.headerText}>
        {title}
      </Typography>
      <div className={classes.rectangle} />
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  containerWidth: PropTypes.string,
};

PageTitle.defaultProps = {
  containerWidth: null,
};

export default withStyles(styles)(withWidth()(PageTitle));
