import React, { Fragment } from 'react';
import omit from 'lodash/omit';
import {
  Button as MUIButton,
  Icon,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  bool,
  func,
  node,
  objectOf,
  oneOf,
  string,
} from 'prop-types';

import styles from '../../../css/core/components/Button.scss';

const componentStyles = typeof styles === 'function' ? styles() : styles;

const Button = (props) => {
  const {
    children,
    classes,
    fullWidth,
    hasBorderColor,
    hasBorderRadius,
    icon,
    onClick,
    selected,
    type,
    text,
    disabled,
    overrideClass,
  } = props;

  const ButtonIcon = () => {
    if (!icon) return null;
    return <Icon className={classes.buttonIcon}>{icon}</Icon>;
  };

  const getButtonIconStyleKey = () => {
    if (disabled) return 'disabled';
    if (selected) return `${type}-selected`;
    return '';
  };

  const getButtonStyle = () => {
    const styleKey = getButtonIconStyleKey();
    const typeStyle = componentStyles[styleKey];
    const buttonStyle = {
      ...typeStyle,
      ...(fullWidth && componentStyles.fullWidth),
      ...(hasBorderRadius && componentStyles.borderRadius),
      ...(hasBorderColor && componentStyles.borderStyle),
      ...props.styleOverride,
    };
    return buttonStyle;
  };

  const onButtonClick = (event) => {
    // If the Button is inside a Form, it will refresh the page unexpectedly, because of js form submission
    event.preventDefault();
    if (onClick !== null) onClick();
  };

  const defaultContent = (
    <Fragment>
      <ButtonIcon />
      { text }
    </Fragment>
  );

  const usedContent = children || defaultContent;

  const muiButtonProps = omit(props, ['overrideClass', 'styleOverride', 'classes', 'hasBorderColor', 'hasBorderRadius', 'id']);
  const buttonProps = {
    ...muiButtonProps,
    type: 'button',
    style: getButtonStyle(),
    onClick: onButtonClick,
  };

  if (!overrideClass) {
    buttonProps.className = classes[type];
  }

  return (
    <MUIButton
      {...buttonProps}
    >
      { usedContent }
    </MUIButton>
  );
};

Button.defaultProps = {
  children: null,
  type: 'primary',
  variant: null,
  onClick: null,
  hasBorderColor: true,
  hasBorderRadius: true,
  icon: null,
  selected: false,
  text: '',
  fullWidth: false,
  overrideClass: false,
};

Button.propTypes = {
  children: node,
  classes: objectOf(string).isRequired,
  variant: oneOf(['contained', 'outlined', 'text']),
  selected: bool,
  type: oneOf(['primary', 'secondary', 'tertiary']),
  onClick: func,
  hasBorderRadius: bool,
  hasBorderColor: bool,
  icon: string,
  text: string,
  fullWidth: bool,
  overrideClass: bool,
};

export default withStyles(styles)(Button);
