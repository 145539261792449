import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Immutable from 'seamless-immutable';
import {
  Button as MUIButton,
  Typography,
  withWidth,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { defaultTo, get } from 'lodash';

/** Icon Imports */
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import WarningIcon from '@material-ui/icons/Warning';

import {
  getCurrentOrder,
  getCurrentUserAddresses,
  getDialogLoading,
  getLocations,
  getProducts,
  getTimeSlot,
  getUnavailableCategoriesByDeliveryOption,
} from '../../../selectors';
import Button from '../../core/components/Button';
import DialogView from '../../core/components/DialogView';
import * as FeatureFlags from '../../../../feature-flags.json';
import styles from '../../../css/menuPage/components/OrderFlowDialog.scss';
import OrderLocationDialog from '../../checkout/subComponents/OrderLocationDialog';
import DeliveryAddressDialog from '../../checkout/subComponents/DeliveryAddressDialog';
import * as Functions from '../../../services/functions/Functions';
import CategoryService from '../../../services/helpers/Categories';
import * as ItemUtils from '../../../services/helpers/ItemUtils';
import OrderHelper from '../../../services/helpers/OrderHelper';
import HoursComponent from '../../locationsPage/subComponents/HoursComponent';
import {
  getNearestTimeSlot,
  getOrderTimeSlot,
} from '../../../services/helpers/OrderThrottling';

import {
  DELIVERY,
  DINE_IN,
  CATERING,
  PICKUP,
  StepDirection,
} from '../../../services/constants/Constants';
import DateHelper from '../../../services/helpers/DateHelper';
import InvalidOrderItemsDialog from '../../checkout/subComponents/InvalidOrderItemsDialog';
import DateTimeDropdown from './DateTimeDropdown';

const pageStyles = typeof styles === 'function' ? styles() : styles;

const deliveryOptionArray = [
  {
    name: PICKUP,
    icon: 'person',
    flag: FeatureFlags.enablePickup,
  },
  {
    name: DELIVERY,
    icon: 'car',
    flag: FeatureFlags.enableDelivery,
  },
  {
    name: DINE_IN,
    icon: 'restaurant',
    flag: FeatureFlags.enableDineIn,
  },
  {
    name: CATERING,
    icon: 'room-service',
    flag: FeatureFlags.enableCatering,
  },
];

const orderFlowDialogSteps = {
  deliveryOption: {
    showNextButton: false,
    showOnSingleDeliveryOption: false,
  },
  invalidOrderItems: {
    showNextButton: true,
    showOnSingleDeliveryOption: false,
  },
  location: {
    // This is not actually used, as the location dialog is another DialogView overlaid over the OrderFlowDialog
    showNextButton: false,
    showOnSingleDeliveryOption: true,
    // This is not actually used, as the location dialog is another DialogView overlaid over the OrderFlowDialog
  },
  desiredTime: {
    showNextButton: false,
    showOnSingleDeliveryOption: true,
  },
  chooseDateTime: {
    showNextButton: true,
    showOnSingleDeliveryOption: true,
  },
  timeSuggestion: {
    showNextButton: false,
    showOnSingleDeliveryOption: true,
  },
};

const handleChooseDateTimeStep = (currentOrder, value) => {
  const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
  mutableOrder.isASAP = false;
  mutableOrder.desiredTime = Functions.formatEpochToSeconds(value.desiredTime);
  return { mutableOrder, moveStep: true, numStepsToMove: 1 };
};

const getUISideEffectsForInvalidItems = ({ deliveryOption }) => ({
  attemptedDeliveryOptionChange: deliveryOption,
});

// TODO: Reset password
class OrderFlowDialog extends Component {
  constructor(props) {
    super(props);
    const initialState = {
      currentStepIndex: 0,
      error: null,
      orderDesiredTime: new Date(),
      selectedTime: new Date(),
      isASAP: true,
      showDatePicker: false,
      timeErrorMsg: null,
      flowSteps: this.getFlowSteps(),
      forceShowNewAddressDialog: false,
      prevLocationId: null,
      invalidatedOrderItems: {},
      attemptedDeliveryOptionChange: '',
      throttledASAPMinutes: null,
      latitude: 0,
      longitude: 0,
      dateIndex: 0,
      timeIndex: 0,
    };
    this.state = initialState;

    this.moveBack = this.moveBack.bind(this);
    this.handleClickDeliveryOptionButton = this.handleClickDeliveryOptionButton.bind(this);
    this.getCurrentStepName = this.getCurrentStepName.bind(this);
    this.getCurrentStepInfo = this.getCurrentStepInfo.bind(this);
    this.followUpAfterRemoveInvalidItems = this.followUpAfterRemoveInvalidItems.bind(this);
    this.setInvalidItems = this.setInvalidItems.bind(this);
    this.updateOrderObject = this.updateOrderObject.bind(this);
    this.moveStep = this.moveStep.bind(this);
    this.handleAttemptToMoveForward = this.handleAttemptToMoveForward.bind(this);
    this.renderActionButtons = this.renderActionButtons.bind(this);
    this.renderDeliveryOptionProductError = this.renderDeliveryOptionProductError.bind(this);
    this.renderCurrentStep = this.renderCurrentStep.bind(this);
    this.goBackFromLocationToDeliveryOptionStep = this.goBackFromLocationToDeliveryOptionStep.bind(this);
    this.updateOrderDeliveryOption = this.updateOrderDeliveryOption.bind(this);
    this.updateOrderForInvalidOrderItemsStep = this.updateOrderForInvalidOrderItemsStep.bind(this);
    this.updateOrderForLocationStep = this.updateOrderForLocationStep.bind(this);
    this.handleDesiredTimeStep = this.handleDesiredTimeStep.bind(this);
    this.showDialogForLocationStep = this.showDialogForLocationStep.bind(this);
    this.renderDeliveryOptionStep = this.renderDeliveryOptionStep.bind(this);
    this.renderTimeSelector = this.renderTimeSelector.bind(this);
    this.renderChooseDateTime = this.renderChooseDateTime.bind(this);
    this.performUpdatesAfterCheckInvalidItems = this.performUpdatesAfterCheckInvalidItems.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line no-undef
    if (navigator.geolocation) {
      // eslint-disable-next-line no-undef
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
  }

  getDistancesObject(locations) {
    const { currentOrder } = this.props;
    const { latitude, longitude } = this.state;

    let distances;
    if (currentOrder.deliveryOption === PICKUP || currentOrder.deliveryOption === DINE_IN) {
      distances = {};
      locations.forEach((location) => {
        distances[location.id] = Functions.getDistance(location.latitude, location.longitude, latitude, longitude);
      });
    }
    return distances;
  }

  getCurrentStepName() {
    const { currentStepIndex, flowSteps } = this.state;
    return flowSteps[currentStepIndex];
  }

  getCurrentStepInfo() {
    const currentStepName = this.getCurrentStepName();
    return currentStepName && orderFlowDialogSteps[currentStepName];
  }

  getFlowSteps = () => {
    const deliveryOptions = Functions.availableDeliveryOptions();
    const allSteps = Object.keys(orderFlowDialogSteps);
    if (deliveryOptions.length === 1) return allSteps.filter(stepName => orderFlowDialogSteps[stepName].showOnSingleDeliveryOption);
    return allSteps;
  }

  getProps = () => {
    const { classes } = this.props;

    return (
      {
        dialogTitle: {
          dialogTitleStyle: classes.dialogTitleStyle,
          dialogTitleText: '',
          dialogTitleSubheader: '',
        },
        dialogBodyContainerStyle: classes.dialogBodyContainerStyle,
        dialogContentStyle: classes.dialogContentStyle,
        dialogContent: this.renderCurrentStep(),
      }
    );
  };

  getOnNextClickAction = () => {
    const currentStep = this.getCurrentStepName();
    return currentStep === 'chooseDateTime'
      ? this.handleTimeChosen()
      : this.handleAttemptToMoveForward;
  }

  getSelectedLocationOrAddressId = () => {
    const { currentOrder } = this.props;
    const locationOrAddress = OrderHelper.getUsedAddress(currentOrder);
    return locationOrAddress
      ? locationOrAddress.id
      : null;
  }

  setTimeErrorMsg = () => this.setState({ timeErrorMsg: this.getTimeErrorMessage() });

  getSelectedTime = () => {
    const { selectedTime } = this.state;
    return selectedTime.getTime();
  }

  getDeliveryOption = () => {
    const { currentOrder } = this.props;
    return currentOrder ? currentOrder.deliveryOption : '';
  }

  setChooseTime = () => {
    const {
      currentOrder,
      storeLocations,
    } = this.props;
    const selectedTime = getNearestTimeSlot(
      new Date(),
      getOrderTimeSlot(OrderHelper.getLocationWithConfigurations(currentOrder, storeLocations)),
    );
    this.setState({
      isASAP: false,
      selectedTime,
    }, () => this.moveStep(StepDirection.FORWARD));
  }

  getDesiredTimeObj = (currentOrderLocationId, selectedTime = null) => ({
    deliveryOption: this.getDeliveryOption(),
    desiredTime: selectedTime || this.getSelectedTime(),
    locationId: currentOrderLocationId || this.getSelectedLocationOrAddressId(),
  });

  getTimeErrorMessage = () => {
    const { translation } = this.props;

    let timeErrorMsg = null;
    if (!this.hasTimeWindow()) {
      timeErrorMsg = this.isDeliveredOrder()
        ? translation('CheckoutDrawer.orderTimeSelector.deliveryClosed')
        : translation('CheckoutDrawer.orderTimeSelector.pickupClosed');
    } else if (!this.hasValidTimeWindow()) {
      timeErrorMsg = translation('CheckoutDrawer.orderTimeSelector.timeError');
    }
    return timeErrorMsg;
  }

  getASAPTimeSuggestions() {
    const { actions, user, currentOrder } = this.props;
    if (!user) return;
    const currentOrderLocation = get(currentOrder, 'location', {});
    const minutesOffset = currentOrderLocation[this.isDeliveredOrder() ? 'deliveryTime' : 'pickupTime'];
    const throttledDesiredTime = Functions.addMinutesToEpoch(Date.now(), minutesOffset);
    const desiredTimeRequest = this.getDesiredTimeObj(get(currentOrder, 'location.id'), throttledDesiredTime);
    actions.getTimeSuggestions(user, desiredTimeRequest);
  }

  async setInvalidItems({
    currentOrder,
    deliveryOption,
    unavailableCategories,
    makeInvalidItemsUIStateFunction,
    setStateCallback,
  }) {
    const getNewInvalidItemsStateArgs = {
      deliveryOption,
      currentOrder,
      unavailableCategories,
      makeInvalidItemsUIStateFunction,
    };
    const newState = ItemUtils.getNewStateForInvalidItems(getNewInvalidItemsStateArgs);

    this.setState(newState, setStateCallback);
  }

  // eslint-disable-next-line react/sort-comp
  hasTimeWindow = () => {
    const { currentOrder } = this.props;
    const desiredTime = this.getSelectedTime();
    // the '!!' is so that this is treated as a boolean
    return !!Functions.getTimeWindows(this.getDeliveryOption(), { ...currentOrder, desiredTime });
  }

  hasValidTimeWindow = () => {
    const { currentOrder } = this.props;
    const desiredTime = this.getSelectedTime();
    return Functions.validateSelectedTime(currentOrder, desiredTime);
  }

  prepareOrderObject = (values) => {
    const { currentOrder } = this.props;

    return {
      ...currentOrder,
      ...values,
    };
  };

  sortLocations = (locations, distances) => {
    if (!locations) {
      return [];
    }

    if (!distances) {
      return locations;
    }

    const sortedLocations = Immutable.asMutable(locations, { deep: true });
    sortedLocations.sort((a, b) => distances[a.id] - distances[b.id]);
    return sortedLocations;
  }

  updateOrderDeliveryOption(currentOrder, value) {
    const { userAddresses } = this.props;
    const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
    mutableOrder.deliveryOption = value;

    const { orderNeedsAddress, lastAddress } = ItemUtils.shouldAddLastAddressToOrder(mutableOrder, value, userAddresses);
    if (orderNeedsAddress) mutableOrder.address = lastAddress;

    // Assumes that handleClickDeliveryOptionButton checked for invalid items already
    return { mutableOrder, moveHowManySteps: 2, moveStep: true };
  }

  updateOrderForInvalidOrderItemsStep(currentOrder, value) {
    const { userAddresses } = this.props;
    const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
    mutableOrder.deliveryOption = value;

    const { orderNeedsAddress, lastAddress } = ItemUtils.shouldAddLastAddressToOrder(mutableOrder, value, userAddresses);
    if (orderNeedsAddress) mutableOrder.address = lastAddress;

    return { mutableOrder, moveStep: true };
  }

  updateOrderForLocationStep(currentOrder, value) {
    const { user } = this.props;
    const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });

    if ([PICKUP, DINE_IN].includes(currentOrder.deliveryOption)) {
      mutableOrder.location = value;
    } else {
      mutableOrder.address = value;
      if (user && !currentOrder.location) {
        this.updateOrderLocationFromAddress(value);
      }
    }
    if (mutableOrder.deliveryOption === DINE_IN) {
      const orderLocation = get(mutableOrder, 'location');
      const tableNumbers = get(mutableOrder, 'location.tableNumbers');
      if (!tableNumbers || !orderLocation) return { mutableOrder, moveStep: true };
      // Make sure our order has a table number set.
      // If there's no table number, set the first one.
      if (!mutableOrder.tableNumber) mutableOrder.tableNumber = Functions.getFirstTableNumber(orderLocation);
      // If it already has one, check if the current table number is valid for the location.
      const selectedLocationTables = orderLocation.tableNumbers.split(',');
      if (!selectedLocationTables.includes(mutableOrder.tableNumber)) mutableOrder.tableNumber = Functions.getFirstTableNumber(orderLocation);
    }

    return { mutableOrder, moveStep: true };
  }

  goToProductPage() {
    const { productId, toggleProductDialog } = this.props;
    toggleProductDialog(productId);
  }

  handleDesiredTimeStep(currentOrder, value) {
    const { productId, toggleProductDialog } = this.props;
    const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });

    mutableOrder.isASAP = false;
    if (value.isASAP) {
      this.setState({ isASAP: true });
      mutableOrder.isASAP = true;
      mutableOrder.desiredTime = null;
      if (productId) {
        toggleProductDialog(productId);
      }
      return { mutableOrder, moveHowManySteps: 2, moveStep: true };
    }
    return { mutableOrder, moveHowManySteps: 1, moveStep: true };
  }

  stepUpdateOrderFunctions = {
    deliveryOption: this.updateOrderDeliveryOption.bind(this),
    invalidOrderItems: this.updateOrderForInvalidOrderItemsStep.bind(this),
    location: this.updateOrderForLocationStep.bind(this),
    desiredTime: this.handleDesiredTimeStep.bind(this),
    chooseDateTime: handleChooseDateTimeStep,
  }

  generateKey = index => (`key${index}`);

  followUpAfterRemoveInvalidItems(currentStepName) {
    if (currentStepName !== 'invalidOrderItems') return;

    const { attemptedDeliveryOptionChange } = this.state;
    if (attemptedDeliveryOptionChange) {
      this.setState({ attemptedDeliveryOptionChange: '' });
    }
  }

  handleItemsInvalidDeliveryOption(currentOrder, deliveryOption) {
    const { unavailableCategoriesByDeliveryOption } = this.props;
    const invalidatedOrderItems = ItemUtils.getItemsInvalidatedByDeliveryOption(
      currentOrder,
      deliveryOption,
      unavailableCategoriesByDeliveryOption,
    );

    const orderHasInvalidItems = Object.keys(invalidatedOrderItems).length > 0;
    if (orderHasInvalidItems) {
      this.setState({
        invalidatedOrderItems,
        attemptedDeliveryOptionChange: deliveryOption,
      });
    }

    return orderHasInvalidItems;
  }

  // Sometimes we want to update the order only in redux.
  shouldUpdateOrderInAPI = (stepName, value, currentOrder) => {
    // If we're setting desiredTime, we always want to update in API
    if (stepName === 'desiredTime') return true;
    // If our order already has an id, there's only one exception.
    // Which is if we change our delivery option.
    // See more on why here: https://aytech.atlassian.net/wiki/spaces/AYT/pages/1650425859/
    if (currentOrder.id && stepName === 'deliveryOption') {
      return (currentOrder.deliveryOption === value);
    }
    return false;
  }

  updateOrderObject(value) {
    const {
      actions,
      currentOrder,
      user,
    } = this.props;

    const currentStepName = this.getCurrentStepName();
    const moveHowManySteps = currentStepName === 'deliveryOption' ? 2 : 1;

    const stepFunction = this.stepUpdateOrderFunctions[currentStepName];

    const { mutableOrder, moveStep } = stepFunction(currentOrder, value);

    if (this.shouldUpdateOrderInAPI(currentStepName, value, currentOrder)) {
      actions.updateOrder(user, mutableOrder, currentOrder.id);
      this.followUpAfterRemoveInvalidItems(currentStepName);
    } else {
      actions.updateOrder(null, mutableOrder, currentOrder.id);
    }

    if (moveStep) {
      this.moveStep(moveHowManySteps);
    }
  }

  // This is a special case, when order has an address but no location set.
  async updateOrderLocationFromAddress(address) {
    const { actions, user, currentOrder } = this.props;
    if (!address || !user) return;
    try {
      const resourceRequest = await actions.getOfflineResource(user.token, ['users', user.id, 'addresses', address.id, 'location']);
      const location = JSON.parse(resourceRequest.data);
      if (location || location.id) {
        const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
        mutableOrder.location = location;
        actions.updateOrder(null, mutableOrder, currentOrder.id);
      }
    } catch (error) {
      console.log('API call error', error);
    }
  }

  handleClose = () => {
    this.props.handleClose();
  }

  isCatering = () => OrderHelper.isCatering(this.getDeliveryOption());

  isDeliveredOrder = () => OrderHelper.isDeliveredOrder(this.getDeliveryOption());

  isDelivery = () => OrderHelper.isDelivery(this.getDeliveryOption());

  handleDateChange = (selectedTimeValue, dateIndex, timeIndex) => {
    this.setState({
      selectedTime: selectedTimeValue,
      // We're setting this to null here because the time check will run when 'next' is pressed
      timeErrorMsg: null,
      dateIndex,
      timeIndex,
    });
  }

  hasTimeError() {
    const {
      currentOrder,
      user,
    } = this.props;

    // Pass the check if there's no user and the order is for delivery
    if (!user && this.isDeliveredOrder() && !currentOrder.location) return true;

    const hasTimeWindow = this.hasTimeWindow();
    const hasValidTimeWindow = this.hasValidTimeWindow();

    return !hasTimeWindow || !hasValidTimeWindow;
  }

  /**
   * Move backward or forward through the order flow
   * @param {*} numStepsToMove (positive if going forward, negative if going backwards)
   */
  moveStep(numStepsToMove) {
    const { handleClose, productId, toggleProductDialog } = this.props;
    const { currentStepIndex, flowSteps } = this.state;

    const tempIndex = currentStepIndex + numStepsToMove;
    const newStepIndex = tempIndex < 0 ? 0 : tempIndex;

    this.setState({ currentStepIndex: newStepIndex }, () => {
      const { shouldUseOrderThrottling } = FeatureFlags.MenuPage.OrderFlowDialog;
      if (this.getCurrentStepName() === 'desiredTime' && shouldUseOrderThrottling) this.getASAPTimeSuggestions();
      // if user is at the last step, close the dialog.
      if (newStepIndex > flowSteps.length - 1) {
        if (productId) {
          toggleProductDialog(productId);
        }
        handleClose();
      }
    });
  }

  updateOrderLocation = (locationId) => {
    const { storeLocations, userAddresses, currentOrder } = this.props;
    if ([PICKUP, DINE_IN].includes(currentOrder.deliveryOption)) {
      const location = storeLocations.find(loc => loc.id === locationId);
      if (location) {
        if (currentOrder.location) this.setState({ prevLocationId: currentOrder.location.id });
        this.updateOrderObject(location, 'location');
      }
    } else {
      // Handle new address
      if (!locationId) this.moveStep(StepDirection.FORWARD);
      // Handle existing addresses
      let address;
      if (locationId) address = userAddresses.find(addr => addr.id === locationId);
      if (address) {
        this.updateOrderObject(address, 'address');
      }
    }
  }

  /**
   * Reset location back to previously chosen one if user hits 'cancel' instead
   * of deleting out of stock items
   */
  resetLocation = () => {
    const { storeLocations, currentOrder, actions } = this.props;
    const { prevLocationId } = this.state;
    const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
    const prevLocation = storeLocations.find(loc => loc.id === prevLocationId);
    mutableOrder.location = prevLocation;
    try {
      actions.updateOrder(null, mutableOrder, currentOrder.id);
    } catch (error) {
      console.log('Update order error', error);
    }
  }

  shouldRenderLocationDialog = () => {
    const { forceShowNewAddressDialog } = this.state;
    const { currentOrder, user } = this.props;
    return (
      (
        [PICKUP, DINE_IN].includes(currentOrder.deliveryOption)
        || ([DELIVERY, CATERING].includes(currentOrder.deliveryOption) && user)
      )
      && !forceShowNewAddressDialog
    );
  }

  // if order isASAP, API will handle time issues
  // if errors should be displayed on choose time step, we shouldn't continue
  // if there are order throttling suggestions, we should move step and not continue
  shouldContinueWithTimeSelection = (isASAP) => {
    const {
      timeSlot: { showTimeSlotModal },
    } = this.props;

    // Don't handle ASAP case
    if (isASAP) return true;

    // Handle errors that are displayed on choose time page
    if (this.hasTimeError()) {
      this.setTimeErrorMsg();
      return false;
    }

    // Handle order throttling case
    const { shouldUseOrderThrottling } = FeatureFlags.MenuPage.OrderFlowDialog;
    if (shouldUseOrderThrottling && showTimeSlotModal) {
      this.moveStep(StepDirection.FORWARD);
      return false;
    }

    return true;
  }

  handleTimeChosen = (isASAP = false) => async () => {
    const {
      actions,
      user,
      productId,
      currentOrder,
    } = this.props;

    const { shouldUseOrderThrottling } = FeatureFlags.MenuPage.OrderFlowDialog;
    if (shouldUseOrderThrottling) {
      await actions.checkDesiredTime(user, this.getDesiredTimeObj(get(currentOrder, 'location.id')));
    }

    if (!this.shouldContinueWithTimeSelection(isASAP)) return;

    const desiredTime = isASAP
      ? null
      : this.getSelectedTime();
    const updatedValues = {
      desiredTime,
      isASAP,
    };
    this.updateOrderObject(updatedValues);
    if (productId) this.goToProductPage();
    this.handleClose();
  }

  moveBack() {
    const currentStepName = this.getCurrentStepName();

    if (currentStepName === 'invalidOrderItems') {
      this.setState({ invalidatedOrderItems: {} });
    }
    return this.moveStep(StepDirection.BACKWARD);
  }

  async handleAttemptToMoveForward() {
    const { currentOrder, actions, user } = this.props;
    const {
      invalidatedOrderItems,
      attemptedDeliveryOptionChange: newDeliveryOption,
    } = this.state;
    const currentStepName = this.getCurrentStepName();

    if (currentStepName !== 'invalidOrderItems') {
      this.moveStep(StepDirection.FORWARD);
      return;
    }

    const { undeletedItems } = await ItemUtils.removeItemsFromOrder(invalidatedOrderItems, currentOrder, actions, user);
    if (undeletedItems.length > 0) {
      this.moveStep(StepDirection.BACKWARD);
      return;
    }
    this.updateOrderObject(newDeliveryOption);
  }

  handleTimeSuggestionClicked = (time) => {
    const updatedValues = {
      desiredTime: time,
      isASAP: false,
    };
    this.updateOrderObject(updatedValues);
    this.handleClose();
  };

  performUpdatesAfterCheckInvalidItems(newDeliveryOption) {
    return () => {
      const { invalidatedOrderItems } = this.state;
      // Shows the Invalid Items Dialog
      if (ItemUtils.hasInvalidItems(invalidatedOrderItems)) return this.moveStep(StepDirection.FORWARD);
      return this.updateOrderObject(newDeliveryOption, 'deliveryOption');
    };
  }

  handleClickDeliveryOptionButton(newDeliveryOption) {
    return async () => {
      const { actions, apiToken, currentOrder } = this.props;
      // To check for invalid items, get updated unavailable delivery options on categories
      await CategoryService.fetchCategories(actions, apiToken);

      const { unavailableCategoriesByDeliveryOption: unavailableCategories } = this.props;
      const setInvalidItemsArgs = {
        deliveryOption: newDeliveryOption,
        currentOrder,
        unavailableCategories,
        makeInvalidItemsUIStateFunction: getUISideEffectsForInvalidItems,
        setStateCallback: this.performUpdatesAfterCheckInvalidItems(newDeliveryOption),
      };

      await this.setInvalidItems(setInvalidItemsArgs);
    };
  }

  /**
   * Goes back one extra step to skip past the invalidOrderItems step, which should only be rendered
   * after checking for invalid items
   */
  goBackFromLocationToDeliveryOptionStep() {
    this.moveStep(StepDirection.BACKWARD * 2);
  }

  getButtonId = (type) => {
    switch (type) {
      case DELIVERY:
        return 'deliveryOption';
      case PICKUP:
        return 'pickupOption';
      case CATERING:
        return 'cateringOption';
      default:
        return '';
    }
  };

  showDialogForLocationStep() {
    const { currentOrder } = this.props;
    const allowDialogToClose = Functions.allowDialogClose(currentOrder) === true;

    if (this.shouldRenderLocationDialog()) {
      return this.renderLocationDialog(allowDialogToClose);
    }
    return this.renderDeliveryAddressDialog(allowDialogToClose);
  }

  renderThrottledMinutes() {
    const {
      timeSlot: {
        suggestions: timeSlotSuggestions,
      },
    } = this.props;
    const firstSuggestion = timeSlotSuggestions[0] * 1000;
    return DateHelper.minutesBetweenEpochs(firstSuggestion, Date.now());
  }

  renderActionButtons() {
    const { classes, translation } = this.props;
    const {
      currentStepIndex,
      timeErrorMsg,
      showDeliveryOptionProductError,
    } = this.state;
    const currentStepInfo = this.getCurrentStepInfo();
    const { showNextButton } = !!currentStepInfo && currentStepInfo;

    if (currentStepIndex === 0 && !showDeliveryOptionProductError) {
      return (
        <div />
      );
    }

    const backButtonClass = showNextButton
      ? classes.actionButtonBackWithNext
      : classes.actionButtonBack;
    const nextButtonText = showDeliveryOptionProductError
      ? translation('REMOVE')
      : translation('NEXT');
    const nextButtonAction = this.getOnNextClickAction();

    const nextButton = (showNextButton || showDeliveryOptionProductError) && (
      <Button
        id="next"
        type="primary"
        onClick={nextButtonAction}
        disabled={!!timeErrorMsg}
        styleOverride={pageStyles.actionButtonNext}
      >
        {nextButtonText}
        {this.renderIcons('forward')}
      </Button>
    );

    return (
      <div className={classes.actionButtonContainer}>
        <MUIButton
          className={backButtonClass}
          onClick={this.moveBack}
        >
          { this.renderIcons('back') }
          { translation('BACK') }
        </MUIButton>
        {nextButton}
      </div>
    );
  }

  renderTimeEstimationText() {
    const {
      currentOrder,
      timeSlot: {
        suggestions: timeSlotSuggestions,
      },
      translation,
      storeLocations,
      user,
    } = this.props;
    const deliveryOption = get(currentOrder, 'deliveryOption');
    const location = get(currentOrder, 'location');
    if (!deliveryOption || !location) return '';
    const locationResource = storeLocations.find(loc => loc.id === location.id);
    const readyText = translation(`MenuPage.readyText.${deliveryOption}`);
    const minutesText = translation('CheckoutDrawer.orderTimeSelector.minutes');

    // Commenting this out to avoid confusion when suggested time stays the same and user checks back repeatedly
    // const { shouldUseOrderThrottling } = FeatureFlags.MenuPage.OrderFlowDialog;
    // if (user && shouldUseOrderThrottling && !isEmpty(timeSlotSuggestions)) return `${readyText} ${this.renderThrottledMinutes()} ${minutesText}`;
    const firstNumberKey = this.isDeliveredOrder() ? 'deliveryTime' : 'pickupTime';
    const firstNumber = defaultTo(locationResource[firstNumberKey], '');
    const timeWindowKey = this.isDeliveredOrder() ? 'DELIVERY_WINDOW' : 'PICKUP_WINDOW';
    const timeWindow = locationResource[timeWindowKey];
    if (!timeWindow) return `${readyText} ${firstNumber} ${minutesText}`;
    const secondNumber = firstNumber + timeWindow;
    return `${readyText} ${firstNumber}-${secondNumber} ${minutesText}`;
  }

  renderTimeSelector() {
    const { timeErrorMsg } = this.state;
    const {
      classes, translation, currentOrder, loading, width,
    } = this.props;

    const orderLocation = get(currentOrder, 'location');
    const hours = get(currentOrder, 'location.hours');
    const { useUppercaseTitle, useASAPTimeEstimationText } = FeatureFlags.MenuPage.OrderFlowDialog.TimeSelector;
    const title = translation('MenuPage.timeSelectorTitle');
    const mobileFriendlyStyleProps = { classes, width };
    const dateOptionButtonStyle = Functions.getMobileFriendlyStyle(mobileFriendlyStyleProps, 'dateOptionButtonStyle', true);
    const timeButtonContainerStyle = Functions.getMobileFriendlyStyle(mobileFriendlyStyleProps, 'timeButtonContainer');
    const timeSubTextStyle = Functions.getMobileFriendlyStyle(mobileFriendlyStyleProps, 'dialogText');

    if (loading !== 0) return <CircularProgress />;

    return (
      <div className={classes.timeSelectorContent}>
        <div className={classes.titleContainer}>
          <Typography className={timeSubTextStyle}>
            {useUppercaseTitle ? title.toUpperCase() : title}
          </Typography>
        </div>
        <div className={timeButtonContainerStyle}>
          {
            Functions.shouldShowTimePickerButton(currentOrder, 'ASAP')
            && (
              <Button
                id="asapPlease"
                type="primary"
                styleOverride={pageStyles[dateOptionButtonStyle]}
                onClick={this.handleTimeChosen(true)}
              >
                <Typography className={classes.asapButtonTitle}>
                  {translation('ASAP PLEASE')}
                </Typography>
                {
                  useASAPTimeEstimationText
                  && (
                    <Typography className={classes.dateOptionSubtitle}>
                      {this.renderTimeEstimationText()}
                    </Typography>
                  )
                }
              </Button>
            )
          }
          {
            Functions.shouldShowTimePickerButton(currentOrder, 'DatePicker')
            && (
              <Button
                id="selectDate"
                type="primary"
                styleOverride={pageStyles[dateOptionButtonStyle]}
                onClick={this.setChooseTime}
              >
                {translation('SELECT DATE AND TIME')}
              </Button>
            )
          }
        </div>
        {
          timeErrorMsg
          && (
            <div className={classes.titleContainer}>
              <Typography>{timeErrorMsg}</Typography>
              {
                hours && (
                  <HoursComponent
                    hours={hours}
                    translation={translation}
                    textcolorOverride={pageStyles.hoursText.color}
                    location={orderLocation}
                    deliveryOption={currentOrder.deliveryOption}
                  />
                )
              }
            </div>
          )
        }
      </div>
    );
  }

  renderChooseDateTime() {
    const {
      classes,
      currentOrder,
      storeLocations,
      translation,
      width,
    } = this.props;
    const {
      timeErrorMsg,
      dateIndex,
      timeIndex,
    } = this.state;

    const { useUppercaseTitle } = FeatureFlags.MenuPage.OrderFlowDialog.TimeSelector;
    const title = translation('MenuPage.timeSelectorTitle');

    const orderLocation = get(currentOrder, 'location');
    const hours = get(currentOrder, 'location.hours');
    const mobileFriendlyStyleProps = { classes, width };
    const timeSubTextStyle = Functions.getMobileFriendlyStyle(mobileFriendlyStyleProps, 'largerDialogText');
    const minuteInterval = getOrderTimeSlot(OrderHelper.getLocationWithConfigurations(currentOrder, storeLocations));

    return (
      <Fragment>
        <div className={classes.titleContainer}>
          <Typography className={timeSubTextStyle}>
            { useUppercaseTitle ? title.toUpperCase() : title }
          </Typography>
        </div>
        {
          timeErrorMsg
          && (
            <div className={classes.titleContainer}>
              <Typography>{timeErrorMsg}</Typography>
              {
                hours && (
                  <HoursComponent
                    hours={hours}
                    translation={translation}
                    textcolorOverride={pageStyles.hoursText.color}
                    location={orderLocation}
                    deliveryOption={currentOrder.deliveryOption}
                  />
                )
              }
            </div>
          )
        }
        <DateTimeDropdown
          translation={translation}
          maxDate={Functions.getMaxDate()}
          minutesStep={minuteInterval}
          disablePast
          onChange={
            (selectedValue, setDateIndex, setTimeIndex) => this.handleDateChange(selectedValue, setDateIndex, setTimeIndex)
          }
          currentDateIndex={dateIndex}
          currentTimeIndex={timeIndex}
        />
      </Fragment>
    );
  }

  renderTimeSuggestions = () => {
    const {
      classes,
      dialogLoading,
      timeSlot: {
        suggestions: timeSlotSuggestions,
      },
      translation,
    } = this.props;

    if (dialogLoading !== 0) return <CircularProgress />;

    const TimeSuggestions = ({ times }) => times.map((time) => {
      const suggestionText = DateHelper.getSuggestedTimeIntervalString(time, translation('TimeIntervals.at'));

      return (
        <Button
          onClick={() => this.handleTimeSuggestionClicked(time)}
          styleOverride={{ margin: '5px' }}
          type="primary"
          text={suggestionText}
        />
      );
    });

    return (
      <div className={classes.timeSuggestionsContent}>
        { this.renderIcons('warning') }
        <div className={classes.timeSuggestionsTitle}>{ translation('TimeIntervals.modalTitle') }</div>
        <div className={classes.timeSuggestionsDescription}>{ translation('TimeIntervals.modalDescription') }</div>
        <TimeSuggestions times={timeSlotSuggestions} />
        <Button
          onClick={this.handleClose}
          style={{ margin: '5px' }}
          text={translation('CANCEL')}
          type="secondary"
        />
      </div>
    );
  }

  renderDeliveryOptionProductError() {
    const { translation, open, dialogLoading } = this.props;
    const { invalidatedOrderItems, attemptedDeliveryOptionChange } = this.state;

    return (
      <InvalidOrderItemsDialog
        invalidatedOrderItems={invalidatedOrderItems}
        deliveryOption={attemptedDeliveryOptionChange}
        open={open}
        onCloseDialog={this.handleClose}
        translation={translation}
        dialogLoading={dialogLoading}
        handleChange={this.handleAttemptToMoveForward}
        navigateBack={this.moveBack}
      />
    );
  }

  renderDeliveryOptionStep() {
    const {
      classes,
      translation,
      width,
    } = this.props;

    const deliveryOptionTextStyle = Functions.getMobileFriendlyStyle({ classes, width }, 'dialogText');

    return (
      <div className={classes.deliveryOptionContainer}>
        <div className={classes.titleContainer}>
          <Typography className={deliveryOptionTextStyle}>
            {translation('MenuPage.deliveryOptionSelectorTitle')}
          </Typography>
        </div>
        {this.renderDeliveryOptionButtons()}
      </div>
    );
  }

  renderIcons = (icon) => {
    const { classes } = this.props;

    const iconProps = {
      className: classes.genericIcon,
    };
    const icons = {
      back: ArrowBackIcon,
      car: DriveEtaIcon,
      forward: ArrowForwardIcon,
      person: DirectionsWalkIcon,
      restaurant: RestaurantIcon,
      // room-service uses hyphens like this, others might
      'room-service': RoomServiceIcon,
      warning: WarningIcon,
    };
    if (!Object.keys(icons).includes(icon)) return null;
    const MappedIcon = icons[icon];
    return <MappedIcon {...iconProps} />;
  }

  renderDeliveryOptionButtonLabel = (deliveryOption) => {
    const { classes, translation } = this.props;
    const { icon, name } = deliveryOption;

    return (
      <div className={classes.deliveryOptionButtonLabelContainer}>
        { this.renderIcons(icon) }
        { translation(name) }
      </div>
    );
  }

  renderDeliveryOptionButtons = () => {
    const {
      classes,
      width,
    } = this.props;

    const mobileFriendlyStyleProps = { classes, width };

    const buttonContainerStyle = Functions.getMobileFriendlyStyle(mobileFriendlyStyleProps, 'buttonContainer');
    const deliveryOptionButtonStyle = Functions.getMobileFriendlyStyle(mobileFriendlyStyleProps, 'deliveryOptionButtonStyle', true);

    return (
      <div className={buttonContainerStyle}>
        {
          deliveryOptionArray.map((deliveryOption, i) => {
            if (deliveryOption.flag) {
              return (
                <Button
                  id={this.getButtonId(deliveryOption)}
                  key={this.generateKey(i)}
                  styleOverride={pageStyles[deliveryOptionButtonStyle]}
                  onClick={this.handleClickDeliveryOptionButton(deliveryOption.name)}
                >
                  { this.renderDeliveryOptionButtonLabel(deliveryOption) }
                </Button>
              );
            }
            return null;
          })
        }
      </div>
    );
  }

  renderCurrentStepFunctions = {
    deliveryOption: this.renderDeliveryOptionStep.bind(this),
    invalidOrderItems: this.renderDeliveryOptionProductError.bind(this),
    location: this.showDialogForLocationStep.bind(this),
    desiredTime: this.renderTimeSelector.bind(this),
    chooseDateTime: this.renderChooseDateTime.bind(this),
    timeSuggestion: FeatureFlags.MenuPage.OrderFlowDialog.shouldUseOrderThrottling ? this.renderTimeSuggestions : null,
  }

  renderCurrentStep() {
    const currentStep = this.getCurrentStepName();
    const renderFunction = this.renderCurrentStepFunctions[currentStep];

    return renderFunction ? renderFunction() : null;
  }

  renderLocationDialog(allowDialogToClose = true) {
    const {
      open,
      user,
      currentOrder,
      actions,
      translation,
      history,
      storeLocations,
      loading,
    } = this.props;

    let selectedDeliveryOption;
    let selectedLocationId;
    if (currentOrder) {
      selectedDeliveryOption = currentOrder.deliveryOption;
      selectedLocationId = this.getSelectedLocationOrAddressId();
    }

    const distances = this.getDistancesObject(storeLocations);
    const sortedLocations = this.sortLocations(storeLocations, distances);

    return (
      <OrderLocationDialog
        user={user}
        open={open}
        onCloseDialog={() => this.handleClose()}
        deliveryOption={selectedDeliveryOption}
        selectedLocationId={selectedLocationId}
        actions={actions}
        translation={translation}
        history={history}
        storeLocations={sortedLocations}
        handleChange={locId => this.updateOrderLocation(locId)}
        closeAfterChange={false}
        onChangeDialog={() => this.setState({ forceShowNewAddressDialog: true })}
        loading={loading}
        navigateBack={this.goBackFromLocationToDeliveryOptionStep}
        allowDialogClose={allowDialogToClose}
      />
    );
  }

  renderDeliveryAddressDialog = (allowDialogToClose = true) => {
    const {
      open,
      user,
      currentOrder,
      actions,
      translation,
    } = this.props;

    return (
      <DeliveryAddressDialog
        open={open}
        user={user}
        actions={actions}
        handleClose={() => this.handleClose()}
        closeAfterChange={false}
        translation={translation}
        currentOrderId={currentOrder.id}
        updateSelectedAddressId={addressId => this.updateOrderLocation(addressId)}
        navigateBack={this.goBackFromLocationToDeliveryOptionStep}
        allowDialogClose={allowDialogToClose}
      />
    );
  }

  render() {
    const {
      open, dialogLoading, classes, currentOrder,
    } = this.props;
    const stepPros = this.getProps();
    const {
      dialogContentStyle,
      dialogContent2,
      dialogTitle,
    } = stepPros;

    const allowDialogClose = Functions.allowDialogClose(currentOrder) === true;

    return (
      <DialogView
        open={open}
        titleAlignClose={false}
        handleClose={this.handleClose}
        disableBackdropClick={!allowDialogClose}
        disableEscapeKeyDown={!allowDialogClose}
        titleHasCloseBtn={allowDialogClose}
        hasDialogContent
        hasDialogContent2={false}
        hasDialogErrorContent={false}
        renderDialogContent={() => this.renderCurrentStep()}
        renderDialogContent2={() => dialogContent2}
        hasDialogActions
        renderActionBtn={() => this.renderActionButtons()}
        dialogCloseIconColor={dialogTitle.dialogTitleStyle.color}
        dialogBodyContainerStyle={classes.bodyContainer}
        dialogContentStyle={dialogContentStyle}
        loading={!!dialogLoading}
      />
    );
  }
}

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
  dialogLoading: getDialogLoading(state),
  products: getProducts(state),
  storeLocations: getLocations(state),
  timeSlot: getTimeSlot(state),
  unavailableCategoriesByDeliveryOption: getUnavailableCategoriesByDeliveryOption(state),
  userAddresses: getCurrentUserAddresses(state),
});

OrderFlowDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  currentOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  storeLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  userAddresses: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  dialogLoading: PropTypes.number,
  productId: PropTypes.number,
  loading: PropTypes.number.isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
  unavailableCategoriesByDeliveryOption: PropTypes.objectOf(PropTypes.object),
  toggleProductDialog: PropTypes.func.isRequired,
};

OrderFlowDialog.defaultProps = {
  user: null,
  productId: null,
  dialogLoading: 0,
  userAddresses: null,
  products: null,
  unavailableCategoriesByDeliveryOption: null,
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(withWidth()(OrderFlowDialog))));
