import i18n from 'i18next';
import get from 'lodash/get';
import { reactI18nextModule } from 'react-i18next';
import * as bundleFlags from '../../feature-flags.json';
import versionInfo from '../config/version.json';
import translationEN from '../locales/en/translation.json';
import translationES from '../locales/es/translation.json';
import ResourcesAPI from '../services/api/ResourcesApi';
import * as Actions from '../actions/Actions';
import { store } from '../config/configureStore';
import { customAssign } from '../services/functions/Functions';

// eslint-disable-next-line no-undef
const setIsAvailable = isAvailable => store.dispatch(Actions.setIsFetchedTranslationsAvailable(isAvailable));

// Translations
const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
};

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

class Translation {
  static instance = null;

  isLanguageReady = false;

  static getInstance() {
    if (Translation.instance == null) {
      Translation.instance = new Translation();
      this.instance.init();
    }
    return this.instance;
  }

  init() {
    this.readFile();
  }

  // Using english by default.
  // eslint-disable-next-line class-methods-use-this
  readFile(sideEffectFunc) {
    const translationObj = get(store.getState(), 'translations.translations', {});
    const combined = customAssign(translationEN, translationObj);
    // Initialize i18n again, with the combined translations
    // TO-DO: Figure out a better way to do this, without passing all parameters again.
    i18n
      .use(reactI18nextModule) // passes i18n down to react-i18next
      .init({
        en: {
          translation: combined,
        },
        es: {
          translation: translationES,
        },
        lng: 'en',
        interpolation: {
          escapeValue: false, // react already safes from xss
        },
      });
    if (sideEffectFunc && typeof sideEffectFunc === 'function') sideEffectFunc(true);
  }

  downloadFile(attempts) {
  // TO-DO: Disable with feature flag.
    if (!bundleFlags.functionality.enableUpdateLanguage) return setIsAvailable(false);
    ResourcesAPI.getResource(null, ['translation', 'EN', 'version', versionInfo.version]).then((res) => {
      if (res && res.data && res.data.translationValues) {
        const setTranslationResult = store.dispatch(Actions.setTranslations(res.data.translationValues));
        if (setTranslationResult && setTranslationResult.updatedTranslations) {
          console.log('Language is downloded successfully');
          this.readFile(setIsAvailable);
        }
      } else {
        console.log('Cannot parse translation data');
        if (attempts >= 3) {
          setIsAvailable(false);
        } else {
          this.downloadFile(attempts + 1);
        }
      }
    }).catch((error) => {
      console.log('Error while fetching translations', error);
      if (attempts >= 3) {
        setIsAvailable(false);
      } else {
        this.downloadFile(attempts + 1);
      }
    });
  }
}

export const TranslationClass = Translation;

export default i18n;
