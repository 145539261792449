import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Immutable from 'seamless-immutable';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Typography,
  Card,
  CardContent,
  LinearProgress,
  Icon,
  Divider,
  withWidth,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

import PageTitle from '../core/components/PageTitle';
import TabBar from '../core/components/TabBar';
import * as Functions from '../../services/functions/Functions';
import * as Actions from '../../actions/Actions';
import * as Routes from '../../services/routes/Routes.json';
import * as FeatureFlags from '../../../feature-flags.json';
import { DELIVERY, PICKUP, deliveryOptionIcons, statusIndicatorLabels, curbsideOrderStatuses } from '../../services/constants/Constants';
import { getCompany, getCurrentOrder, getProducts, getUser, getUserOrders, getUsers, getLocations } from '../../selectors';
import DateHelper from '../../services/helpers/DateHelper';
import Button from '../../components/core/components/Button';
import * as ResponsiveStyles from '../../../jsonStyles/components/ordersPage/OrdersPage.style.json';
import styles from '../../css/ordersPage/OrdersPage.scss';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

const orderTabsContent = [
  {
    tabName: 'UPCOMING_ORDERS',
    isUpcoming: true,
    noOrderMessage: 'OrdersPage.noUpcoming',
  },
  {
    tabName: 'COMPLETED_ORDERS',
    isUpcoming: false,
    noOrderMessage: 'OrdersPage.noRecent',
  },
];

responsiveStyles.card = {
  ...responsiveStyles.card,
  [theme.breakpoints.down('sm')]: {
    width: ResponsiveStyles.card.width,
    minWidth: ResponsiveStyles.card.minWidth,
    height: ResponsiveStyles.card.height,
  },
};

responsiveStyles.orders = {
  ...responsiveStyles.orders,
  [theme.breakpoints.down('sm')]: {
    padding: ResponsiveStyles.orders.padding,
  },
};

responsiveStyles.instructionsSection = {
  ...responsiveStyles.instructionsSection,
  [theme.breakpoints.down('sm')]: {
    width: ResponsiveStyles.instructionsSection.width,
  },
};

responsiveStyles.addressSection = {
  ...responsiveStyles.addressSection,
  [theme.breakpoints.down('sm')]: {
    minHeight: ResponsiveStyles.addressSection.minHeight,
  },
};

responsiveStyles.locationAddress = {
  ...responsiveStyles.locationAddress,
  [theme.breakpoints.down('sm')]: {
    width: ResponsiveStyles.locationAddress.width,
  },
};

const removeUnderscores = text => text && text.replace(/_/g, ' ');

const CardHeading = (props) => {
  const {
    classes,
    company,
    translation,
    order,
    isUpcoming,
    isCurbside,
  } = props;

  return (
    <div>
      <div className={classes.cardHeadingContainer}>
        <div className={classes.titleWithIconContainer}>
          <Icon className={classes.icon}>{deliveryOptionIcons[order.deliveryOption]}</Icon>
          <Typography className={classes.cardHeadingText}>
            {isCurbside ? translation('OrdersPage.curbsidePickup') : removeUnderscores(order.deliveryOption)}
          </Typography>
        </div>
        <Typography className={classes.cardHeadingText}>
          {Functions.getFormattedPrice(order.price, company)}
        </Typography>
      </div>
      <div>
        <Typography className={classes.orderNumber}>
          {`#${order.id}`}
        </Typography>
      </div>
      {
        isUpcoming
        && (
          <div className={classes.orderStatusContainer}>
            <Typography className={classes.orderStatus}>
              {`${translation('OrdersPage.orderStatus')}:\u00A0`}
            </Typography>
            <Typography className={classes.orderStatusType}>
              {removeUnderscores(order.orderStatus)}
            </Typography>
          </div>
        )
      }
    </div>
  );
};

const DateTime = (props) => {
  const {
    classes,
    translation,
    title,
    dateTime,
  } = props;

  return (
    <div className={classes.dateTimeContainer}>
      <div className={classes.titleWithIconContainer}>
        <Icon className={classes.smallIcon}>schedule</Icon>
        <Typography className={classes.title}>
          {`${title} ${translation('OrdersPage.time')}`}
        </Typography>
      </div>
      <Typography className={classes.dateTime}>
        {DateHelper.getSuggestedTimeIntervalString(dateTime, '-')}
      </Typography>
    </div>
  );
};

const OrderItems = (props) => {
  const {
    classes,
    items,
  } = props;

  return (
    <div>
      {
        items && items.map((item, i) => (
          <Typography key={Functions.generateKey(i)} className={classes.orderItem}>
            {`${item.quantity} x ${item.productItem.name}`}
          </Typography>
        ))
      }
    </div>
  );
};

const Address = (props) => {
  const {
    classes,
    title,
    address,
    streetAddress,
    titleIcon,
  } = props;

  return (
    <div className={classes.addressContainer}>
      <div className={classes.titleWithIconContainer}>
        <Icon className={classes.smallIcon}>{titleIcon}</Icon>
        <Typography className={classes.title}>
          {title}
        </Typography>
      </div>
      <Typography className={classes.locationAddress}>
        {streetAddress || address}
      </Typography>
    </div>
  );
};

const OrderCardButton = (props) => {
  const {
    classes,
    buttonText,
    buttonIcon,
    addItemsToCart,
    order,
  } = props;

  return (
    <div className={classes.buttonContainer}>
      <Button
        type="primary"
        text={buttonText}
        icon={buttonIcon}
        className={classes.orderButton}
        onClick={() => addItemsToCart(order)}
      />
    </div>
  );
};

const CurbsideStepper = (props) => {
  const {
    currentStep,
  } = props;

  return (
    <Stepper alternativeLabel activeStep={currentStep} connector={<StepConnector />}>
      {
        statusIndicatorLabels.map((label, i) => (
          <Step key={Functions.generateKey(i)}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))
      }
    </Stepper>
  );
};

const CurbsideSection = (props) => {
  const {
    classes,
    translation,
    order,
    user,
    updateCurbsideOrderStatus,
    locations,
  } = props;

  let currentStep;
  switch (order.orderStatus) {
    case 'RECEIVED':
      currentStep = 0;
      break;
    case 'PROCESSED':
      currentStep = 0;
      break;
    case 'READY':
      currentStep = 1;
      break;
    case 'ARRIVED_FOR_PICKUP':
      currentStep = 2;
      break;
    case 'OUT_FOR_DELIVERY':
      currentStep = 2;
      break;
    default:
      currentStep = 0;
  }

  const handleNotifyLocation = () => {
    const mutableOrder = Immutable.asMutable(order, { deep: true });
    mutableOrder.orderStatus = 'ARRIVED_FOR_PICKUP';
    updateCurbsideOrderStatus(user, mutableOrder);
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      window.location.reload(true);
    }, 400);
  };

  const customerHasNotifiedStore = (orderStatus) => {
    return curbsideOrderStatuses.indexOf(orderStatus)
      >= curbsideOrderStatuses.indexOf('ARRIVED_FOR_PICKUP');
  };

  const orderHasReachedPickUpStage = (orderStatus) => {
    return curbsideOrderStatuses.indexOf(orderStatus)
      >= curbsideOrderStatuses.indexOf('READY');
  };

  const storeIsNotified = customerHasNotifiedStore(order.orderStatus);

  const getCurbsideInstructions = (placedOrder) => {
    const currentLocation = locations.find(loc => loc.id === placedOrder.location.id);
    if (currentLocation && currentLocation.CURBSIDE_INSTRUCTIONS && currentLocation.CURBSIDE_INSTRUCTIONS.length !== 0) {
      return currentLocation.CURBSIDE_INSTRUCTIONS;
    }
    return translation('OrdersPage.curbsideInstructions');
  };

  return (
    <div className={classes.curbsideContainer}>
      <CurbsideStepper
        {...props}
        currentStep={currentStep}
      />
      <div className={classes.instructionsSection}>
        <Typography className={classes.instructionsHeading}>
          {`${translation('OrdersPage.curbsidePickup')} ${translation('OrdersPage.instructions')}`}
        </Typography>
        <Typography className={classes.instructionsBody}>
          {getCurbsideInstructions(order)}
        </Typography>
      </div>
      {
        orderHasReachedPickUpStage(order.orderStatus)
        && (
          <div className={classes.notifyButtonContainer}>
            <Button
              type={storeIsNotified ? 'tertiary' : 'primary'}
              text={storeIsNotified ? translation('OrdersPage.headingOut') : translation('OrdersPage.atTheStore')}
              icon={storeIsNotified ? 'thumb_up' : null}
              className={classes.notifyButton}
              disabled={storeIsNotified}
              onClick={() => handleNotifyLocation()}
            />
          </div>
        )
      }
    </div>
  );
};

const Order = (props) => {
  const {
    classes,
    order,
    translation,
    isUpcoming,
    actions,
    user,
    currentOrder,
    history,
  } = props;

  const { showTrackOrderButton } = FeatureFlags.OrdersPage.upcomingOrders;
  const isCurbside = order.isCurbsidePickUp;

  const onAddToCart = async (prevOrder) => {
    const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });
    const isReorder = true;
    // If mutableOrder.id is null, send null value to addToCart to create new order
    const orderId = mutableOrder.id;
    const setDefaultTip = !currentOrder.items || currentOrder.items.length === 0;
    // If the current order doesn't have basic info, we'll use the one from prevOrder
    if (!Functions.checkBasicOrderInfo(mutableOrder)) {
      mutableOrder.deliveryOption = prevOrder.deliveryOption;
      mutableOrder.address = prevOrder.address;
      mutableOrder.location = prevOrder.location;
      actions.updateOrder(user, { ...mutableOrder, isASAP: true }, orderId);
    }
    try {
      await Functions.addToCart(
        actions,
        user,
        mutableOrder,
        prevOrder.items,
        orderId,
        history,
        isReorder,
      );
    } catch (error) {
      console.log('Error adding to cart', error);
    }
    if (setDefaultTip) {
      Functions.setDefaultTipAmount(currentOrder, actions, user);
    }
  };

  const onTrackOrder = () => {
    if (order.deliveryOption === DELIVERY && isUpcoming && order.deliveryTrackingUrl) {
      // eslint-disable-next-line no-undef
      window.open(order.deliveryTrackingUrl);
    }
  };

  const call = (phoneNum) => {
    // eslint-disable-next-line no-undef
    window.open(`tel:${phoneNum}`, '_self');
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <CardHeading
          order={order}
          {...props}
          isUpcoming={isUpcoming}
          isCurbside={isCurbside}
        />
        <div>
          {
            order.isCurbsidePickUp && isUpcoming
            && (
              <div className={classes.sectionContainer}>
                <CurbsideSection
                  {...props}
                />
              </div>
            )
          }
          <div className={classes.sectionContainer}>
            <DateTime
              title={translation('OrdersPage.order')}
              dateTime={order.createdAt}
              {...props}
            />
            <DateTime
              title={removeUnderscores(order.deliveryOption)}
              dateTime={order.desiredTime}
              {...props}
            />
          </div>
          <Divider />
          <div className={classes.sectionContainer}>
            <OrderItems
              {...props}
              items={order.items}
            />
          </div>
          <Divider />
          <div className={classes.addressSection}>
            <div className={classes.pickupAddressSection}>
              <Address
                title={
                  order.deliveryOption === PICKUP
                    ? translation('OrdersPage.pickupLocation')
                    : translation('OrdersPage.locationPrepared')
                }
                address={order.location && order.location.address}
                deliveryOption={order.deliveryOption}
                {...props}
                titleIcon="restaurant_menu_icon"
              />
              {
                (isCurbside || order.deliveryOption === PICKUP)
                && (
                  <div className={classes.callButtonContainer}>
                    <Button
                      className={classes.callButton}
                      onClick={() => call(order.location.phone)}
                      text={translation('OrdersPage.call')}
                    />
                  </div>
                )
              }
            </div>
            {
              order.deliveryOption === DELIVERY
              && (
                <Address
                  title={translation('OrdersPage.deliveryAddress')}
                  streetAddress={order.address.streetAddress}
                  deliveryOption={order.deliveryOption}
                  {...props}
                  titleIcon="directions_car"
                />
              )
            }
          </div>
        </div>
        {
          isUpcoming
            ? (showTrackOrderButton && order.deliveryOption === DELIVERY)
              && (
                <OrderCardButton
                  buttonText={translation('OrdersPage.trackOrder')}
                  addItemsToCart={() => onTrackOrder()}
                  {...props}
                />
            )
            : (
              <OrderCardButton
                buttonText={translation('OrdersPage.orderAgain')}
                buttonIcon="shopping_cart"
                addItemsToCart={onAddToCart}
                {...props}
              />
            )
        }
      </CardContent>
    </Card>
  );
};

const OrdersList = (props) => {
  const {
    classes,
    translation,
    orderList,
    noOrderMessage,
    isUpcoming,
  } = props;

  return (
    <div>
      {
        orderList.length === 0
          ? (
            <Typography className={classes.noOrders}>
              {translation(noOrderMessage)}
            </Typography>
          )
          : (
            <div className={classes.orders}>
              {
                orderList.map((order, i) => (
                  order.items && order.items.length > 0
                  && (
                    <Order
                      {...props}
                      order={order}
                      isUpcoming={isUpcoming}
                      key={Functions.generateKey(i)}
                    />
                  )
                ))
              }
            </div>
          )
      }
    </div>
  );
};

const PageHeader = (props) => {
  const { classes, translation } = props;
  return (
    <div className={classes.heading}>
      <PageTitle
        title={translation('OrdersPage.recentOrders')}
        containerWidth={responsiveStyles.heading.width}
      />
    </div>
  );
};

const OrdersComponent = (props) => {
  const {
    loading,
    translation,
    classes,
    orders,
    history,
    user,
    users,
    actions,
    products,
  } = props;

  if (!user) history.push(Routes.path.menuPage);

  useEffect(() => {
    Functions.getComponentResources(actions, user, products, users, loading);
  }, []);

  const getOrderList = (upcoming) => {
    const upcomingOrders = [];
    const completedOrders = [];

    if (orders) {
      orders.map((order) => {
        if (['COMPLETED', 'CANCELED'].includes(order.orderStatus)) completedOrders.push(order);
        if (!(['COMPLETED', 'CANCELED', 'NEW'].includes(order.orderStatus))) upcomingOrders.push(order);
      });
    }

    if (upcoming) return upcomingOrders;
    return completedOrders;
  };

  const getTabs = () => {
    if (user && user.guestToken) {
      return (
        [{
          id: 'UPCOMING_ORDERS',
          label: translation('OrdersPage.upcoming'),
        }]
      );
    }
    return (
      [{
        id: 'UPCOMING_ORDERS',
        label: translation('OrdersPage.upcoming'),
      }, {
        id: 'COMPLETED_ORDERS',
        label: translation('OrdersPage.completed'),
      }]
    );
  };

  const [selectedTab, setSelectedTabId] = useState('UPCOMING_ORDERS');

  const setSelectedTab = (index) => {
    setSelectedTabId(index);
  };

  return (
    loading !== 0
      ? <LinearProgress />
      : (
        <div className={classes.main}>
          <PageHeader
            {...props}
          />
          <TabBar
            selected={selectedTab}
            onClickSelected={value => setSelectedTab(value)}
            tabs={getTabs()}
          />
          {
            orderTabsContent.map((tab, i) => (
              <div key={Functions.generateKey(i)}>
                {
                  selectedTab === tab.tabName
                  && (
                    <OrdersList
                      {...props}
                      orderList={getOrderList(tab.isUpcoming)}
                      noOrderMessage={tab.noOrderMessage}
                      isUpcoming={tab.isUpcoming}
                    />
                  )
                }
              </div>
            ))
          }
        </div>
      )
  );
};

CardHeading.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  company: PropTypes.objectOf(PropTypes.any).isRequired,
  translation: PropTypes.func.isRequired,
  order: PropTypes.objectOf(PropTypes.any),
  isUpcoming: PropTypes.bool,
  isCurbside: PropTypes.bool,
};

CardHeading.defaultProps = {
  order: {},
  isUpcoming: false,
  isCurbside: false,
};

DateTime.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
};

DateTime.defaultProps = {
  dateTime: '',
};

OrderItems.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  items: PropTypes.arrayOf(PropTypes.any),
};

OrderItems.defaultProps = {
  items: [],
};

Address.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  address: PropTypes.string,
  streetAddress: PropTypes.string,
  titleIcon: PropTypes.string,
};

Address.defaultProps = {
  address: '',
  streetAddress: '',
  titleIcon: '',
};

OrderCardButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonIcon: PropTypes.string,
  addItemsToCart: PropTypes.func,
  order: PropTypes.objectOf(PropTypes.any),
};

OrderCardButton.defaultProps = {
  buttonIcon: '',
  addItemsToCart: null,
  order: {},
};

Order.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  company: PropTypes.objectOf(PropTypes.any).isRequired,
  order: PropTypes.objectOf(PropTypes.any),
};

Order.defaultProps = {
  order: {},
};

OrdersList.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  orderList: PropTypes.arrayOf(PropTypes.object),
  noOrderMessage: PropTypes.string.isRequired,
  isUpcoming: PropTypes.bool.isRequired,
};

OrdersList.defaultProps = {
  orderList: [],
};

PageHeader.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
};

OrdersComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  loading: PropTypes.number.isRequired,
  translation: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  users: PropTypes.objectOf(PropTypes.any),
  currentOrder: PropTypes.objectOf(PropTypes.any),
  products: PropTypes.arrayOf(PropTypes.object),
  orders: PropTypes.arrayOf(PropTypes.object),
};

OrdersComponent.defaultProps = {
  user: null,
  users: null,
  currentOrder: {},
  products: null,
  orders: null,
};

CurbsideStepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

CurbsideSection.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  order: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any),
  updateCurbsideOrderStatus: PropTypes.func,
  locations: PropTypes.arrayOf(PropTypes.object),
};

CurbsideSection.defaultProps = {
  order: null,
  user: null,
  updateCurbsideOrderStatus: null,
  locations: [],
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
  user: getUser(state).user,
  orders: getUserOrders(state),
  company: getCompany(state),
  users: getUsers(state),
  products: getProducts(state),
  locations: getLocations(state),
});

const mapDispatchToProps = dispatch => ({
  createOrder: (user, order) => dispatch(Actions.createOrder(user, order)),
  updateCurbsideOrderStatus: (user, order) => dispatch(Actions.updateCurbsideOrderStatus(user, order)),
});

const OrdersPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(responsiveStyles),
)(withRouter(withWidth()(OrdersComponent)));

export default OrdersPage;

