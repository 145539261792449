import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import Routes from '../../services/routes/Routes.json';
import TabBar from '../core/components/TabBar';
import PageTitle from '../core/components/PageTitle';
import styles from '../../css/accountPage/AccountPage.scss';

import SettingsPage from '../settingsPage/SettingsPage';
import PaymentMethodPage from '../paymentMethodPage/PaymentMethodPage';
import AddNewCardPage from '../addNewCardPage/AddNewCardPage';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

// eslint-disable-next-line react/prefer-stateless-function
class AccountPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'PAYMENT_METHODS',
      shouldRenderAddCard: false,
    };
  }

  async componentDidMount() {
    const {
      user, history,
    } = this.props;

    if (!user) {
      history.push(Routes.path.menuPage);
    }
  }

  componentDidUpdate() {
    const {
      user, history,
    } = this.props;

    if (!user) {
      history.push(Routes.path.menuPage);
    }
  }

  onPressAddCard = () => this.setState({ shouldRenderAddCard: true });

  onPressCancel = () => this.setState({ shouldRenderAddCard: false });

  getTabs = () => {
    const { translation } = this.props;
    return ([{
      id: 'PAYMENT_METHODS',
      label: translation('AccountPage.paymentMethods'),
    },
    {
      id: 'PROFILE',
      label: translation('AccountPage.profile'),
    }]);
  }

  renderPaymentMethods = () => {
    const {
      user, loading, translation, actions,
    } = this.props;
    const { shouldRenderAddCard } = this.state;

    return shouldRenderAddCard ? (
      <AddNewCardPage
        user={user}
        loading={loading}
        translation={translation}
        actions={actions}
        onPressCancel={this.onPressCancel}
      />
    ) : (
      <PaymentMethodPage
        user={user}
        actions={actions}
        loading={loading}
        translation={translation}
        onPressAddCard={this.onPressAddCard}
      />
    );
  }

  renderProfile = () => {
    const {
      user, loading, translation, actions,
    } = this.props;
    return <SettingsPage user={user} loading={loading} translation={translation} actions={actions} />;
  }

  renderTab = () => {
    const { selectedTab } = this.state;
    if (selectedTab === 'PAYMENT_METHODS') {
      return this.renderPaymentMethods();
    }
    return this.renderProfile();
  }

  render() {
    const { classes, translation } = this.props;
    const { selectedTab } = this.state;

    return (
      <div className={classes.main}>
        <div className={classes.titleContainer}>
          <PageTitle title={translation('AccountPage.title')} />
        </div>
        <TabBar
          tabs={this.getTabs()}
          selected={selectedTab}
          onClickSelected={value => this.setState({ selectedTab: value, shouldRenderAddCard: false })}
        />
        {this.renderTab()}
      </div>
    );
  }
}

AccountPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  loading: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
};

AccountPage.defaultProps = {
  user: null,
};

export default connect(null, null)(withStyles(responsiveStyles)(withRouter(AccountPage)));
