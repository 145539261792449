import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { TextField, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import get from 'lodash/get';

import Button from '../../core/components/Button';
import DialogView from '../../core/components/DialogView';
import styles from '../../../css/checkout/subComponents/DeliveryAddressDialog.scss';
import * as FeatureFlags from '../../../../feature-flags.json';
import { getDialogLoading, getCurrentOrder } from '../../../selectors';
import { getMobileFriendlyStyle } from '../../../services/functions/Functions';
import ResponsiveStyles from '../../../../jsonStyles/components/checkout/subComponents/DeliveryAddressDialog.json';
import AutoCompleteAddress from './AutoCompleteAddress';

const responsiveStyles = typeof styles === 'function' ? styles() : styles;

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

responsiveStyles.textFieldInnerInputRoot = {
  ...responsiveStyles.textFieldInnerInputRoot,
  [theme.breakpoints.down('sm')]: {
    backgroundColor: ResponsiveStyles.textFieldInnerInputRoot.backgroundColor,
  },
};

responsiveStyles.actionBtnStyle = {
  ...responsiveStyles.actionBtnStyle,
  [theme.breakpoints.down('sm')]: {
    padding: ResponsiveStyles.actionBtnStyle.padding,
    margin: ResponsiveStyles.actionBtnStyle.margin,
  },
};

responsiveStyles.dialogContentText = {
  ...responsiveStyles.dialogContentText,
  [theme.breakpoints.down('sm')]: {
    margin: ResponsiveStyles.dialogContentText.margin,
  },
};


const dialogContentFields = [
  {
    key: 'nickname',
    title: 'DeliveryAddressDialog.dialogContent.nickname',
    fieldWidth: '100%',
    isRequired: true,
  },
  {
    key: 'streetAddress',
    title: 'DeliveryAddressDialog.dialogContent.streetAddress',
    fieldWidth: '100%',
    isRequired: true,
  },
  {
    key: 'city',
    title: 'DeliveryAddressDialog.dialogContent.city',
    fieldWidth: '100%',
    isRequired: true,
  },
  {
    key: 'unit',
    title: 'DeliveryAddressDialog.dialogContent.unit',
    fieldWidth: '100%',
    details: [
      'DeliveryAddressDialog.dialogContent.addressDetails.apt',
      'DeliveryAddressDialog.dialogContent.addressDetails.suite',
      'DeliveryAddressDialog.dialogContent.addressDetails.floor',
      'DeliveryAddressDialog.dialogContent.addressDetails.room',
    ],
    isRequired: false,
  },
  {
    key: 'postalCode',
    title: 'DeliveryAddressDialog.dialogContent.postalCode',
    fieldWidth: '100%',
    isRequired: true,
  },
  {
    key: 'buzzNumber',
    title: 'DeliveryAddressDialog.dialogContent.buzzNumber',
    fieldWidth: '100%',
    isRequired: false,
  },
];

class DeliveryAddressDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  async componentDidMount() {
    this.getBrowserLocation();
  }

  getBrowserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setState({
            browserCoordinates: {
              lat: position.coords.latitude,
              long: position.coords.longitude
            }
          });
        },
        (error) => {
          console.log('Could not get browser geolocation, using default coordinates')
        }
      );
    }
  }

  getInitialState = () => (
    {
      nickname: '',
      streetAddress: '',
      unit: '',
      city: '',
      postalCode: '',
      buzzNumber: '',
      browserCoordinates: null,
      nicknameLeftBlank: false,
      streetAddressLeftBlank: false,
      cityLeftBlank: false,
      postalCodeLeftBlank: false,
    }
  );

  getDialogContent = () => {
    const { classes, translation } = this.props;

    return (
      <div className={classes.dialogContentContainer}>
        <div className={classes.dialogContentTextContainer}>
          <Typography className={classes.dialogContentText}>
            {translation('DeliveryAddressDialog.dialogContent.text')}
          </Typography>
        </div>
        {
          dialogContentFields.map((field) => {
            return (
              this.renderTextField(field, false, true)
            );
          })
        }
      </div>
    );
  }

  navigateBack = () => this.props.navigateBack()

  getActionButton = () => {
    const { classes, translation, dialogLoading } = this.props;
    const isDisabled = this.disableButton();

    return (
      <div className={classes.actionButtonContainer}>
        <Button
          variant="text"
          overrideClass
          type="primary"
          className={classes.backButtonStyle}
          onClick={this.navigateBack}
          startIcon={<ArrowBackIcon className={classes.backArrowIcon} />}
          text={translation('BACK')}
        />
        <Button
          type="primary"
          onClick={() => this.handleClickAdd()}
          disabled={isDisabled}
          overrideClass
          className={classes.addButtonOverride}
        >
          {
            dialogLoading !== 0
            ? <CircularProgress />
            : `${translation('DeliveryAddressDialog.addButton')}`
          }
        </Button>
      </div>
    );
  }

  disableButton = () => {
    const {
      nickname,
      streetAddress,
      city,
      postalCode,
    } = this.state;
    const { dialogLoading } = this.props;
    // unit can be null if it's a house and buzz number is optional
    return (dialogLoading !== 0) || !nickname || !streetAddress || !city || !postalCode;
  };

  handleChange = (fieldKey, event) => {
    const { target } = event;
    this.setState({
      [fieldKey]: target.value,
    });
  };

  handleClickAdd = async () => {
    const {
      actions,
      user,
      currentOrderId,
      handleClose,
      updateSelectedAddressId,
      closeAfterChange,
      currentOrder,
    } = this.props;
    const {
      nickname,
      streetAddress,
      unit,
      city,
      postalCode,
      buzzNumber,
    } = this.state;
    const addressObj = {
      nickname,
      street_address: streetAddress,
      unit_number: unit,
      city,
      postal_code: postalCode,
      buzzer_number: buzzNumber,
    };
    try {
      let addAddressResponse;
      if (user) {
        addAddressResponse = await actions.addAddress(user.token, addressObj, 'users', user.id, 'addresses');
        if (!addAddressResponse || addAddressResponse.error) {
          return;
        }
        await actions.getAllResources(
          user.token,
          ['users', user.id, 'addresses'],
        );
      } else {
        addAddressResponse = addressObj;
      }
      const updatedOrder = {
        ...currentOrder,
        address: addAddressResponse,
      };
      let updateOrderResponse;
      if (!user) {
        const addressChecked = await actions.checkAddress(user, addAddressResponse, ['locations', 'checkAddress']);
        if (addressChecked.location) updatedOrder.location = addressChecked.location;
        if (addressChecked.error) throw addressChecked.error;
      }

      if (!currentOrderId) {
        updateOrderResponse = await actions.updateOrder(null, updatedOrder, currentOrderId);
      } else {
        updateOrderResponse = await actions.updateOrder(user, updatedOrder, currentOrderId);
      }
      if (user && currentOrderId && (!updateOrderResponse || updateOrderResponse.error)) return;
      updateSelectedAddressId(user ? addAddressResponse.id : null);
      if (closeAfterChange) handleClose();
    } catch (error) {
      console.log('API call error', error);
    }
  };

  setAddress = (place) => {
    const formattedAddress = get(place, 'formatted_address');
    const addressComponents = get(place, 'address_components');
    // Since the format is "street address, city, province/state, country",
    // we'll assume the second term is going to be the address' city.
    const streetAddress = formattedAddress.split(',')[0];
    const city = formattedAddress.split(',')[1];
    // The place result also contains the postal code
    const postalCodeAddressComponent = addressComponents.find(component => component.types.includes('postal_code'))
    const postalCode = get(postalCodeAddressComponent, 'short_name');
    this.setState({ streetAddress, city, postalCode });
  }

  handleBlur = (field, inputText) => {
    if (this.isEmptyTextField(inputText)) this.setState({ [`${field}LeftBlank`]: true });
  }

  // Check for empty field or field with only blank spaces
  isEmptyTextField = (text) => {
    const filteredText = text !== undefined ? text.replace(/\s/g, '') : '';
    return filteredText === '';
  }

  renderTextField = (prop, isSelect, useFieldTitle) => {
    const {
      classes,
      translation,
      width,
    } = this.props;

    let fieldTitle = translation(prop.title);

    if (fieldTitle === translation('DeliveryAddressDialog.dialogContent.postalCode')
      && FeatureFlags.CheckoutDrawer.DeliveryAddressDialog.provinceOrStateDropdown.state) {
      fieldTitle = translation('DeliveryAddressDialog.dialogContent.zipCode');
    }

    if (prop.details) {
      const details = prop.details.map(detail => translation(detail));
      fieldTitle = Array.join(details, '/');
    }

    if (prop.optionalText) {
      fieldTitle = `${fieldTitle} (${translation(prop.optionalText)})`;
    }

    const textFieldStyle = getMobileFriendlyStyle({ classes, width }, 'textFieldStyle');

    if (prop.key === 'streetAddress') {
      return (
        <div className={textFieldStyle} style={{ width: prop.fieldWidth }}>
          <AutoCompleteAddress
            id={prop.key}
            key={prop.key}
            select={isSelect}
            addressValue={this.state.streetAddress}
            label={useFieldTitle ? fieldTitle : translation(prop.title)}
            placeholder={useFieldTitle ? fieldTitle : translation(prop.title)}
            onPlaceSelected={place => this.setAddress(place)}
            className={textFieldStyle}
            style={responsiveStyles.autoCompleteAddress}
            InputProps={this.getTextFieldInnerInputProps()}
            variant={responsiveStyles.textFieldStyle.variant}
            FormHelperTextProps={{ className: classes.textFieldHelperText }}
            helperText={this.renderHelperText(prop.isRequired, width)}
            emptyAddress={this.state.streetAddressLeftBlank}
            onAddressBlur={this.handleBlur}
            checkText={this.isEmptyTextField}
          />
        </div>
      );
    }

    return (
      <TextField
        id={prop.key}
        key={prop.key}
        select={isSelect}
        value={this.state[`${prop.key}`]}
        label={useFieldTitle ? fieldTitle : translation(prop.title)}
        placeholder={useFieldTitle ? fieldTitle : translation(prop.title)}
        onChange={event => this.handleChange(prop.key, event)}
        className={textFieldStyle}
        style={{ width: prop.fieldWidth }}
        variant={responsiveStyles.textFieldStyle.variant}
        helperText={this.renderHelperText(prop.isRequired, width)}
        InputProps={this.getTextFieldInnerInputProps()}
        FormHelperTextProps={{ className: classes.textFieldHelperText }}
        error={prop.isRequired && this.isEmptyTextField(this.state[`${prop.key}`]) && this.state[`${prop.key}LeftBlank`]}
        id="outlined-error-helper-text"
        onBlur={() => { this.handleBlur(prop.key, this.state[`${prop.key}`]); }}
      />
    );
  }

  getTextFieldInnerInputProps = () => {
    const { classes } = this.props;
    const innerInputClasses = { root: classes.textFieldInnerInputRoot };
    return {
      classes: innerInputClasses,
    };
  }

  renderHelperText = (isRequired, width) => {
    const { translation } = this.props;
    const { hideTextFieldHelperTextOnMobile } = FeatureFlags.CheckoutDrawer.DeliveryAddressDialog;
    if (isRequired) {
      if (hideTextFieldHelperTextOnMobile && isWidthDown('sm', width)) return '';
      return translation('DeliveryAddressDialog.dialogContent.required');
    }
    // Render white text so all fields appear with the same margins
    return ' ';
  }

  render() {
    const {
      classes, open, handleClose, allowDialogClose,
    } = this.props;

    return (
      <DialogView
        open={open}
        titleAlignClose={false}
        handleClose={() => handleClose()}
        disableBackdropClick={!allowDialogClose}
        disableEscapeKeyDown={!allowDialogClose}
        dialogTitleStyle={classes.dialogTitleStyle}
        dialogBodyContainerStyle={classes.dialogBodyContainerStyle}
        dialogContentStyle={classes.dialogContentStyle}
        titleHasCloseBtn={allowDialogClose}
        hasDialogContent
        hasDialogContent2={false}
        renderDialogContent={() => this.getDialogContent()}
        hasDialogActions
        actionBtnStyle={classes.actionBtnStyle}
        renderActionBtn={() => this.getActionButton()}
        dialogCloseIconColor={responsiveStyles.dialogTitleStyle.altColor}
      />
    );
  }
}

DeliveryAddressDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  translation: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  currentOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  navigateBack: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  currentOrderId: PropTypes.number,
  closeAfterChange: PropTypes.bool,
  dialogLoading: PropTypes.number,
  allowDialogClose: PropTypes.bool,
};

DeliveryAddressDialog.defaultProps = {
  closeAfterChange: true,
  user: null,
  currentOrderId: null,
  dialogLoading: 0,
  allowDialogClose: true,
};

const mapStateToProps = state => ({
  dialogLoading: getDialogLoading(state),
  currentOrder: getCurrentOrder(state),
});

export default connect(mapStateToProps)(withStyles(responsiveStyles)(withWidth()(DeliveryAddressDialog)));
