import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { LinearProgress, Typography } from '@material-ui/core';
import { getCurrentOrder, getCompany } from '../../selectors';
import Routes from '../../services/routes/Routes.json';
import SquarePaymentComponent from '../paymentPage/subComponents/SquarePaymentComponent';
import ToastPaymentComponent from '../paymentPage/subComponents/ToastPaymentComponent';
import CraverCreditCardForm from '../core/components/CraverCreditCardForm';
import styles from '../../css/addNewCardPage/AddNewCardPage.scss';
import ResponsiveStyles from '../../../jsonStyles/components/addNewCardPage/AddNewCardPage.style.json';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.inputSection = {
  ...responsiveStyles.inputSection,
  [theme.breakpoints.down('sm')]: {
    width: ResponsiveStyles.inputSection.width,
  },
};

class AddNewCardPage extends React.Component {
  componentDidMount() {
    const { user, history } = this.props;
    if (!user) {
      history.push(Routes.path.menuPage);
    }
  }

  handleAddCard = async () => {
    const { onPressCancel } = this.props;
    onPressCancel();
  };

  renderCreditCardForm = () => {
    const {
      user, actions, translation, currentOrder, company, onPressCancel,
    } = this.props;
    const paymentIntegration = get(company, 'paymentIntegration', 'NONE');
    const commonProps = {
      user, actions, translation, currentOrder,
    };
    switch (paymentIntegration) {
      case 'SQUARE':
        return <SquarePaymentComponent {...commonProps} handleAddCard={this.handleAddCard} isAddNewCardPage onPressCancel={onPressCancel} />;
      case 'TOAST':
        return <ToastPaymentComponent {...commonProps} handleAddCard={this.handleAddCard} />;
      default:
        return <CraverCreditCardForm {...commonProps} handleAddCard={this.handleAddCard} onPressCancel={onPressCancel} />;
    }
  }

  render() {
    const {
      loading,
      classes,
      translation,
    } = this.props;

    return (
      <div>
        {
          loading !== 0 && <LinearProgress />
        }
        <div className={classes.pageContent}>
          <div className={classes.inputSection}>
            <Typography>{translation('AddNewCardPage.title')}</Typography>
            {
              this.renderCreditCardForm()
            }
          </div>
        </div>
      </div>
    );
  }
}

AddNewCardPage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  loading: PropTypes.number.isRequired,
  translation: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
};

AddNewCardPage.defaultProps = {
  user: null,
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
  company: getCompany(state),
});

export default connect(mapStateToProps, null)(withStyles(responsiveStyles)(withRouter(AddNewCardPage)));
