import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
} from '@material-ui/core';

import Button from '../../core/components/Button';
import * as Routes from '../../../services/routes/Routes.json';
import styles from '../../../css/core/components/AppDrawer.scss';
import * as FeatureFlags from '../../../../feature-flags.json';
import NotificationIconComponent from './NotificationIconComponent';
import RewardDialog from '../components/RewardDialog';

const staticMenu = [
  {
    title: 'AppDrawer.staticMenu.menu',
    link: Routes.path.menuPage,
  },
  {
    title: 'AppDrawer.staticMenu.locations',
    link: Routes.path.locationsPage,
  },
];

const fullLoggedInMenu = [
  {
    title: 'AppDrawer.loggedInMenu.orderHistory',
    link: Routes.path.ordersPage,
    icon: 'history',
  },
  {
    title: 'AppDrawer.loggedInMenu.myRewards',
    link: 'rewards',
    icon: 'star',
  },
  {
    title: 'AppDrawer.loggedInMenu.notifications',
    link: Routes.path.notificationsPage,
    icon: 'notifications',
  },
  {
    title: 'AppDrawer.loggedInMenu.paymentMethods',
    link: Routes.path.paymentMethodPage,
    icon: 'payment',
  },
  {
    title: 'AppDrawer.loggedInMenu.settings',
    link: Routes.path.settingsPage,
    icon: 'settings',
  },
  {
    title: 'AppDrawer.loggedInMenu.account',
    link: Routes.path.accountPage,
    icon: 'person',
  },
  {
    title: 'AppDrawer.loggedInMenu.signOut',
    link: '',
    icon: 'input',
    signOut: 'signOut',
  },
];

class AppDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openRewardsDialog: false,
    };
  }

  getListItemIcon = (menuItemIcon) => {
    const { classes } = this.props;
    if (menuItemIcon === 'notifications') {
      return (
        <NotificationIconComponent
          iconStyleProps={classes.notificationIconStyle}
        />
      );
    }
    return (
      <ListItemIcon>
        <Icon>{menuItemIcon}</Icon>
      </ListItemIcon>
    );
  }

  handleClickOption = (route) => {
    const { onHandleDrawerClose } = this.props;

    onHandleDrawerClose();
    if (route === 'rewards') {
      this.setState({ openRewardsDialog: true });
      return;
    }
    this.props.history.push(route);
  }

  handleDialogClose = () => {
    this.setState({ openRewardsDialog: false });
  }

  render() {
    const { openRewardsDialog } = this.state;
    const {
      classes,
      translation,
      user,
      actions,
      handleClickAuthAppDrawer,
      handleClickDrawerOptions,
      externalOptions,
      appDrawerOpen,
      onHandleDrawerClose,
    } = this.props;

    let loggedInMenu = (FeatureFlags.CoreView.HeaderComponent.hidePaymentOptions)
      ? fullLoggedInMenu.filter(menu => menu.link !== Routes.path.paymentMethodPage)
      : fullLoggedInMenu;

    if (FeatureFlags.disableRewards) loggedInMenu = loggedInMenu.filter(menu => menu.link !== Routes.path.rewardsPage);
    loggedInMenu = FeatureFlags.enableAccountPage
      ? loggedInMenu.filter(menu => menu.link !== Routes.path.settingsPage)
      : loggedInMenu.filter(menu => menu.link !== Routes.path.accountPage);

    if (user && user.guestToken) loggedInMenu = loggedInMenu.filter(menu => menu.link === Routes.path.ordersPage);
    const useSignupButtonPrimary = FeatureFlags.CoreView.AppDrawerComponent.useSignupButtonPrimary;
    const allOptions = (FeatureFlags.CoreView.HeaderComponent.showExtraLinksAppDrawer)
      ? staticMenu.concat(externalOptions) : staticMenu;


    const sideList = (
      <div className={classes.appDrawer}>
        <List className={classes.staticMenu}>
          {
            allOptions.map(menuItem => (
              menuItem.isExternal
              ? (
                <ListItem
                  button
                  component="a"
                  href={menuItem.href}
                  target={menuItem.target}
                >
                  <ListItemText
                    primary={translation(menuItem.title)}
                    classes={{ primary: classes.staticMenuText }}
                  />
                </ListItem>
              )
              : (
                <ListItem
                  button
                  key={menuItem.title}
                  onClick={() => this.handleClickOption(menuItem.link)}
                >
                  <ListItemText
                    primary={translation(menuItem.title)}
                    classes={{ primary: classes.staticMenuText }}
                  />
                </ListItem>
              )
            ))
          }
        </List>
        <List className={classes.variableMenu}>
          {
            user
              ? (
                loggedInMenu.map(menuItem => (
                  <ListItem
                    button
                    key={menuItem.title}
                    onClick={
                      menuItem.signOut
                        ? () => handleClickAuthAppDrawer(user)
                        : () => this.handleClickOption(menuItem.link)
                    }
                  >
                    {this.getListItemIcon(menuItem.icon)}
                    <ListItemText primary={translation(menuItem.title)} />
                  </ListItem>
                ))
              )
              : (
                <div className={classes.loggedOut}>
                  <Button
                    fullWidth
                    type={useSignupButtonPrimary ? 'primary' : 'secondary'}
                    onClick={() => handleClickAuthAppDrawer(user)}
                    text={translation('AppDrawer.loggedOut.signIn')}
                  />
                  <Typography className={classes.notMember}>
                    {`${translation('AppDrawer.loggedOut.notAMember')}?`}
                  </Typography>
                  <Typography
                    className={classes.createAccount}
                    onClick={() => handleClickDrawerOptions(translation('AppDrawer.loggedOut.createAccount'))}
                  >
                    {translation('AppDrawer.loggedOut.createAccount')}
                  </Typography>
                </div>
              )
          }
        </List>
      </div>
    );

    return (
      <div>
        <SwipeableDrawer
          onOpen={() => {}}
          open={appDrawerOpen}
          onClose={onHandleDrawerClose}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={onHandleDrawerClose}
            onKeyDown={onHandleDrawerClose}
          >
            {sideList}
          </div>
        </SwipeableDrawer>
        <RewardDialog
          open={openRewardsDialog}
          handleClose={() => this.handleDialogClose()}
          actions={actions}
          user={user}
          translation={translation}
        />
      </div>
    );
  }
}

AppDrawer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  externalOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  handleClickAuthAppDrawer: PropTypes.func.isRequired,
  handleClickDrawerOptions: PropTypes.func.isRequired,
  onHandleDrawerClose: PropTypes.func.isRequired,
  appDrawerOpen: PropTypes.bool.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
};

AppDrawer.defaultProps = {
  user: null,
};

export default withStyles(styles)(AppDrawer);
