import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import styles from '../../../css/core/components/RedirectLinkComponent.scss';

const RedirectLinkComponent = (props) => {
  const {
    classes,
    href,
    linkText,
  } = props;
  return (
    <Link href={href} to={href} className={classes.redirectLink}>
      <Typography className={classes.linkText}>
        &#8592; {linkText}
      </Typography>
    </Link>
  );
};

RedirectLinkComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  linkText: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default withStyles(styles)(RedirectLinkComponent);

