import get from 'lodash/get';
import * as bundleFlags from '../../../feature-flags.json';
import ResourcesAPI from '../../services/api/ResourcesApi';
import versionInfo from '../../config/version.json';
import { store } from '../../config/configureStore';
import * as Actions from '../../actions/Actions';
import { customAssign } from '../functions/Functions';

const setIsAvailable = () => store.dispatch(Actions.setIsFetchedFeatureFlagsAvailable(true));
class FeatureFlag {
  values = undefined;
  isReady = false;

  static instance = null;

  static getInstance() {
    if (FeatureFlag.instance == null) {
      FeatureFlag.instance = new FeatureFlag();
      this.instance.init();
    }
    return this.instance;
  }

  init() {
    this.values = bundleFlags;
    this.readFile();
  }

  // eslint-disable-next-line class-methods-use-this
  readFile(sideEffectFunc) {
    if (bundleFlags.functionality.enableUpdateFeatureFlag) {
      const featureFlagObj = get(store.getState(), 'featureFlags.featureFlags', {});
      const combined = customAssign(bundleFlags, featureFlagObj);
      store.dispatch(Actions.setFeatureFlags(combined));
    }
    if (sideEffectFunc && typeof sideEffectFunc === 'function') sideEffectFunc(true);
  }

  downloadFile(attempts) {
    if (bundleFlags.functionality.enableUpdateFeatureFlag) {
      // TODO after CRV-14727: add parameter to fetch WEB flags only
      ResourcesAPI.getResource(null, ['featureflag', versionInfo.version]).then((res) => {
        if (res && res.data && res.data.featureFlags) {
          const setFeatureFlagsResult = store.dispatch(Actions.setFeatureFlags(res.data.featureFlags));
          if (setFeatureFlagsResult && setFeatureFlagsResult.updatedFeatureFlags) {
            console.log('feature flag is downloded successfully');
            this.readFile(setIsAvailable);
          }
        } else {
          console.log('Cannot parse feature flag data');
          if (attempts >= 3) {
            setIsAvailable(false);
          } else {
            this.downloadFile(attempts + 1);
          }
        }
      }).catch(() => {
        console.log('Error fetching feature flags');
        if (attempts >= 3) {
          setIsAvailable(false);
        } else {
          this.downloadFile(attempts + 1);
        }
      });
    }
  }
}

export const FeatureFlagClass = FeatureFlag;
