
    module.exports = function cssToMuiLoader(theme) {
      
      return {
        
        'default': {'display': `flex`,'justifyContent': `center`,'padding': `8px`,},'primary': {'display': `flex`,'justifyContent': `center`,'padding': `8px`,'backgroundColor': `#d18a22`,'borderColor': `transparent`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#a76e1b`,'borderColor': `#a76e1b`,'color': `#ffffff`,
        },},'primary-selected': {'display': `flex`,'justifyContent': `center`,'padding': `8px`,'backgroundColor': `#d18a22`,'borderColor': `transparent`,'color': `#ffffff`,'backgroundColor': `#a76e1b`,'borderColor': `#a76e1b`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#a76e1b`,'borderColor': `#a76e1b`,'color': `#ffffff`,
        },},'secondary': {'display': `flex`,'justifyContent': `center`,'padding': `8px`,'borderColor': `#d18a22`,'backgroundColor': `#ffffff`,'color': `#d18a22`,'&:hover': {
          'backgroundColor': `#fff2e0`,'borderColor': `#fff2e0`,'color': `#d18a22`,
        },},'secondary-selected': {'display': `flex`,'justifyContent': `center`,'padding': `8px`,'borderColor': `#d18a22`,'backgroundColor': `#ffffff`,'color': `#d18a22`,'backgroundColor': `#fff2e0`,'borderColor': `#fff2e0`,'color': `#d18a22`,'&:hover': {
          'backgroundColor': `#fff2e0`,'borderColor': `#fff2e0`,'color': `#d18a22`,
        },},'tertiary': {'display': `flex`,'justifyContent': `center`,'padding': `8px`,'borderColor': `#d18a22`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#a76e1b`,'color': `#ffffff`,
        },},'tertiary-selected': {'display': `flex`,'justifyContent': `center`,'padding': `8px`,'borderColor': `#d18a22`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'backgroundColor': `#70a0c3`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#a76e1b`,'color': `#ffffff`,
        },},'disabled': {'borderColor': `#e7e7e7`,'backgroundColor': `#e7e7e7`,'color': `#9e9e9e`,},'buttonIcon': {'margin': `0 4px 0 0`,'color': `#ffffff`,'&:hover': {
          'color': `#ffffff`,
        },},'fullWidth': {'width': `100%`,},'borderRadius': {'borderRadius': `5px`,},'borderStyle': {'borderStyle': `solid`,'borderWidth': `1px`,},
        
      };
    };
  