import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { Divider, Paper, Typography } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';

import Button from '../../core/components/Button';
import { getCurrentOrder } from '../../../selectors';
import PaymentItemComponent from '../subComponents/PaymentItemComponent';
import PaymentDetailsComponent from '../subComponents/PaymentDetailsComponent';
import styles from '../../../css/paymentPage/components/OrderSummaryComponent.scss';
import * as FeatureFlags from '../../../../feature-flags.json';
import * as ResponsiveStyles from '../../../../jsonStyles/components/paymentPage/components/OrderSummaryComponent.style.json';
import {
  isDesktopMode, getCurrencySymbol,
} from '../../../services/functions/Functions';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

class OrderSummaryComponent extends Component {
  onClickPlaceOrder = () => {
    const { type, openTipDialog, processPayment } = this.props;
    if (
      (type === 'payNow' || type === 'newCard')
      && (FeatureFlags.PaymentPage.enableTipModal === true)
    ) {
      openTipDialog();
    } else {
      processPayment();
    }
  }

  disableButton = () => {
    const { type, selectedCardId, paymentOption } = this.props;

    switch (type) {
      case 'payNow':
        return !selectedCardId;
      case 'payAtDoor':
        return !selectedCardId;
      case 'newCard':
        return !paymentOption
              || !paymentOption.cardNumber.trim()
              || !paymentOption.cvc.trim()
              || !paymentOption.expiryDate.trim()
              || !paymentOption.name.trim()
              || !paymentOption.streetNameNumber.trim()
              || !paymentOption.phoneNumber.trim();
      default: return true;
    }
  };

  render() {
    const {
      classes,
      translation,
      currentOrder,
      type,
    } = this.props;
    const totalPrice = currentOrder.totalPrice || 0;
    const isDisabled = this.disableButton();
    const isDesktop = isDesktopMode(this.props.width);

    return (
      <Paper className={isDesktop ? classes.paper : classes.paperMobile}>
        {/* ORDER ITEMS */}
        <PaymentItemComponent
          orderItems={currentOrder.items}
          translation={translation}
        />
        <Divider className={classes.divider} />
        {/* PICK UP/DELIVERY DETAILS */}
        <PaymentDetailsComponent
          translation={translation}
          currentOrder={currentOrder}
        />
        <Divider className={classes.divider} />
        {
          currentOrder.note
          && (
          <div className={classes.noteContainer}>
            <Typography className={classes.noteTitle}>
              {`${translation('CheckoutDrawer.orderDetails.note')}`}
            </Typography>
            <Typography className={classes.note}>
              {currentOrder.note}
            </Typography>
            <Divider className={classes.divider} />
          </div>
          )
        }
        <div className={classes.finalize}>
          <div className={classes.payAmountContainer}>
            <Typography className={classes.payText}>
              {translation('OrderSummaryComponent.payAmount')}
            </Typography>
            <Typography className={classes.payAmount}>
              {`${getCurrencySymbol()}${totalPrice.toFixed(2)}`}
            </Typography>
          </div>
          <Button
            type="primary"
            onClick={() => this.onClickPlaceOrder()}
            fullWidth
            disabled={isDisabled}
            text={type === 'payNow' || type === 'newCard'
            ? translation('OrderSummaryComponent.payNow')
            : translation('OrderSummaryComponent.placeOrder')}
          />
        </div>
      </Paper>
    );
  }
}

OrderSummaryComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  currentOrder: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string.isRequired,
  selectedCardId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  paymentOption: PropTypes.objectOf(PropTypes.any),
  openTipDialog: PropTypes.func.isRequired,
  processPayment: PropTypes.func.isRequired,
  width: PropTypes.string,
};

OrderSummaryComponent.defaultProps = {
  selectedCardId: null,
  paymentOption: null,
  currentOrder: {},
  width: null,
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
});

export default connect(mapStateToProps)(withStyles(responsiveStyles)(withRouter(withWidth()(OrderSummaryComponent))));
