import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import styles from '../../../css/core/components/HalfWholePizzaToggle.scss';
import { LEFT, RIGHT, WHOLE } from '../../../services/constants/Constants';

class HalfWholePizzaToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSection: (props.selectedSection)
        ? props.selectedSection
        : WHOLE,
    };
  }

  sectionSelected = (selectedSection) => {
    const { enableSectionSelection, handleChange, id } = this.props;
    if (enableSectionSelection) {
      this.setState({ selectedSection });
      handleChange(id, selectedSection);
    }
  }

  renderButton = () => {
    const { selectedSection } = this.state;
    const { translation, classes, enableSectionSelection } = this.props;

    const leftStyle = (selectedSection === LEFT && enableSectionSelection)
      ? classes.selectedLeft
      : classes.unSelectedLeft;
    const rightStyle = (selectedSection === RIGHT && enableSectionSelection)
      ? classes.selectedRight
      : classes.unSelectedRight;
    const wholeStyle = (selectedSection === WHOLE)
      ? classes.selectedWhole
      : classes.unSelectedWhole;

    const toggleButtons = [
      {
        side: LEFT,
        outerStyle: classes.halfCircleLeft,
        innerStyle: leftStyle,
        buttonText: 'HalfWholePizzaToggle.left',
      },
      {
        side: WHOLE,
        outerStyle: classes.fullCircle,
        innerStyle: wholeStyle,
        buttonText: 'HalfWholePizzaToggle.whole',
      },
      {
        side: RIGHT,
        outerStyle: classes.halfCircleRight,
        innerStyle: rightStyle,
        buttonText: 'HalfWholePizzaToggle.right',
      },
    ];

    return (
      toggleButtons.map((button) => {
        return (
          <div className={button.outerStyle} key={button.side}>
            <button className={button.innerStyle} onClick={() => this.sectionSelected(button.side)}>
              <Typography
                className={selectedSection === button.side
                  ? classes.enabledText
                  : classes.disabledText}
              >
                {translation(button.buttonText)}
              </Typography>
            </button>
          </div>
        );
      })
    );
  }

  render() {
    const { classes, enableSectionSelection } = this.props;
    return (
      enableSectionSelection
      && (
        <div className={classes.container}>
          {this.renderButton()}
        </div>
      )
    );
  }
}

HalfWholePizzaToggle.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  enableSectionSelection: PropTypes.bool.isRequired,
  selectedSection: PropTypes.string,
  id: PropTypes.number.isRequired,
};

HalfWholePizzaToggle.defaultProps = {
  selectedSection: '',
};

export default withStyles(styles)(HalfWholePizzaToggle);

