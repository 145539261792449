import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  withStyles,
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import Immutable from 'seamless-immutable';
import get from 'lodash/get';
import {
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Icon,
} from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AlarmIcon from '@material-ui/icons/Alarm';
import add from 'date-fns/add';

import Button from '../../core/components/Button';
import DialogView from '../../core/components/DialogView';
import MapComponent from '../../locationsPage/subComponents/MapComponent';
import {
  getCurrentOrder,
  getDialogLoading,
  getCurrentUserAddresses,
  getProducts,
} from '../../../selectors';
import styles from '../../../css/checkout/subComponents/OrderLocationDialog.scss';
import {
  DELIVERY,
  DINE_IN,
  CATERING,
  PICKUP,
  WEEKDAYARRAY,
} from '../../../services/constants/Constants';
import {
  getMobileFriendlyStyle,
  getOutOfStockItems,
  getResponsiveStyles,
  getDayWorkingHours,
  getTime,
  isMobileMode,
  getFirstTableNumber,
  availableDeliveryOptions,
  locationIsOpen24HoursForDeliveryOption,
} from '../../../services/functions/Functions';
import * as FeatureFlags from '../../../../feature-flags.json';
import * as ResponsiveStyles from '../../../../jsonStyles/components/checkout/subComponents/OrderLocationDialog.json';

const pageStyles = typeof styles === 'function' ? styles() : styles;

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const responsiveStyles = getResponsiveStyles(pageStyles, theme, ResponsiveStyles);

const deliveryOptionText = {
  'DELIVERY': 'CheckoutDrawer.orderLocationDialog.delivery',
  'PICKUP': 'CheckoutDrawer.orderLocationDialog.pickup',
  'DINE_IN': 'CheckoutDrawer.orderLocationDialog.dine_in',
  'CATERING': 'CheckoutDrawer.orderLocationDialog.catering',
};

class OrderLocationDialog extends React.Component {
  constructor(props) {
    super(props);
    const firstStoreLocationId = get(props, 'storeLocations[0].id');
    this.state = {
      selectedLocationId: props.selectedLocationId || firstStoreLocationId,
      invalidLocationId: null,
    };
  }

  componentDidUpdate(prevProps) {
    const firstStoreLocationId = get(this.props, 'storeLocations[0].id');
    const prevPropsFirstStoreLocationId = get(prevProps, 'storeLocations[0].id');
    // If there is a delay in sorting locations and the selectedLocationId is not previously set
    if (prevPropsFirstStoreLocationId !== firstStoreLocationId && !this.props.selectedLocationId) {
      this.setState({ selectedLocationId: firstStoreLocationId });
    }
  }

  getSelectedLocation = (locations) => {
    const { selectedLocationId } = this.state;
    if (!locations) return null;
    return locations.find(location => location.id === selectedLocationId);
  }

  setOrderLocation = async (locations) => {
    const {
      deliveryOption,
      actions,
      user,
      currentOrder,
      handleChange,
      closeAfterChange,
    } = this.props;

    const selectedLocation = this.getSelectedLocation(locations);

    const updatedOrder = this.getUpdatedOrder(deliveryOption, selectedLocation, currentOrder);
    handleChange(selectedLocation.id);
    try {
      const response = await actions.updateOrder(closeAfterChange ? user : null, updatedOrder, currentOrder.id);

      if (response && !response.error) {
        this.handleClose();
      } else {
        const dialogObj = {
          dialog: 'locationSelect',
          openOrderLocationDialog: true,
        };
        if (closeAfterChange) this.props.onCloseDialog(dialogObj);
      }
    } catch (error) {
      console.log('API error for setOrderLocation', error);
    }
  }

  getUpdatedOrder = (deliveryOption, selectedLocation, currentOrder) => {
    if ([PICKUP, DINE_IN].includes(deliveryOption)) {
      return this.updateOrderToPickup(selectedLocation, currentOrder, deliveryOption);
    }
    // Handle delivery/catering locations
    return this.updateOrderToDelivery(selectedLocation, currentOrder, deliveryOption);
  }

  getOneDayHours = (dateObj, hours, deliveryOption, iterationCount) => {
    const { translation } = this.props;
    if (Array.isArray(hours) && hours.length === 0) return translation('HoursComponent.locationUnavailableHours');

    // At 8 iterations you've cycled through the entire week
    if (iterationCount === 8) return '';
    const todayWorkingHours = getDayWorkingHours(dateObj, hours);

    // If a day has no hours defined, but other days might
    if (!todayWorkingHours && iterationCount < 8) {
      const nextDay = add(dateObj, { days: 1 });
      return this.getOneDayHours(nextDay, hours, deliveryOption, iterationCount + 1);
    }
    const {
      open, close, pickupsClosed, deliveryOpen, deliveryClose, deliveriesClosed,
    } = todayWorkingHours[0];

    // Delivery and Catering right now cannot show the location hours from the addresses dialog here.
    // For them, the location info currently comes after you select the address, because the API
    // needs to check what location's delivery zone the address falls within.
    const isDeliveryOrCatering = [DELIVERY, CATERING].includes(deliveryOption);

    const isClosedToday = isDeliveryOrCatering
      ? deliveriesClosed
      : pickupsClosed;

    if (!isClosedToday) {
      const todayWeekday = WEEKDAYARRAY[dateObj.getDay()];
      const abbreviated = todayWeekday.slice(0, 3);

      if (locationIsOpen24HoursForDeliveryOption(todayWorkingHours[0], deliveryOption)) {
        const open24HoursText = `${abbreviated} ${translation('HoursComponent.open24Hours')}`;
        return open24HoursText;
      }
      const formattedOpen = isDeliveryOrCatering ? getTime(deliveryOpen) : getTime(open);
      const formattedClose = isDeliveryOrCatering ? getTime(deliveryClose) : getTime(close);
      if (!formattedOpen || !formattedClose) return translation('HoursComponent.locationUnavailableHours');
      return `${abbreviated} ${formattedOpen} - ${formattedClose}`;
    }

    const nextDay = add(dateObj, { days: 1 });
    return this.getOneDayHours(nextDay, hours, deliveryOption, iterationCount + 1);
  }

  // Replace currentOrder.deliveryOption with 'PICKUP' or 'DINE_IN'
  // Replace currentOrder.location with selectedLocation
  updateOrderToPickup = (selectedLocation, currentOrder, deliveryOption) => {
    const pickupOrder = Immutable.asMutable(currentOrder, { deep: true });
    pickupOrder.deliveryOption = deliveryOption;
    pickupOrder.location = Immutable.asMutable(selectedLocation, { deep: true });
    if (pickupOrder.location) {
      delete pickupOrder.location['delivery-option-messages'];
    }
    if (pickupOrder.deliveryOption === DINE_IN && selectedLocation.tableNumbers) {
      // Make sure our order has a table number set.
      // If there's no table number, set the first one.
      if (!pickupOrder.tableNumber) pickupOrder.tableNumber = getFirstTableNumber(selectedLocation);
      // If it already has one, check if the current table number is valid for the location.
      const selectedLocationTables = selectedLocation.tableNumbers.split(',');
      if (!selectedLocationTables.includes(pickupOrder.tableNumber)) pickupOrder.tableNumber = getFirstTableNumber(selectedLocation);
    }
    return pickupOrder;
  }

  // Replace currentOrder.deliveryOption with 'DELIVERY' or 'CATERING'
  // Add obj selectedLocation to currentOrder
  updateOrderToDelivery = (selectedLocation, currentOrder, deliveryOption) => {
    const deliveryOrder = Immutable.asMutable(currentOrder, { deep: true });
    deliveryOrder.deliveryOption = deliveryOption;
    deliveryOrder.address = selectedLocation;
    return deliveryOrder;
  }

  handleClose = () => {
    const { onCloseDialog } = this.props;
    onCloseDialog();
  }

  handleChange = (location) => {
    const { currentOrder, products } = this.props;
    // IN HERE
    // if location.id is having out of stock items, set state to render error message
    // else set the selectedLocationId
    const outOfStockItems = getOutOfStockItems(location.id, currentOrder, products);
    if (outOfStockItems.length > 0) {
      this.setState({ invalidLocationId: location.id });
    } else {
      this.setState({
        selectedLocationId: location.id,
      });
    }
  }

  handleClickAdd = () => {
    const dialogObj = {
      dialog: 'newAddress',
      openDeliveryAddressDialog: true,
    };
    if (this.props.onChangeDialog) {
      this.props.onChangeDialog();
    } else {
      this.props.onCloseDialog(dialogObj);
    }
  }

  generateKey = index => (`key${index}`);

  dialogContent = (deliveryOption, locations) => {
    const {
      classes,
      currentOrder,
      loading,
      products,
      translation,
      width,
    } = this.props;
    const { invalidLocationId } = this.state;
    const currentLocation = this.getSelectedLocation(locations);
    const outOfStockItems = getOutOfStockItems(invalidLocationId, currentOrder, products);

    const locationsContainerStyle = getMobileFriendlyStyle({ classes, width }, 'locationsContainer');
    const hideMapComponentOnMobile = FeatureFlags.CheckoutDrawer.OrderLocationDialog.hideMapComponentOnMobile
      && isMobileMode(width);

    return (
      invalidLocationId
        ? (
          <div>
            <div className={classes.outOfStockMessageContainer}>
              <Typography className={classes.outOfStockDialogMessage}>
                {translation('CheckoutDrawer.orderLocationDialog.outOfStockMessage')}
              </Typography>
            </div>
            <div className={classes.outOfStockItems}>
              {
                loading !== 0
                ? <CircularProgress />
                : (
                    outOfStockItems.map((item, index) => (
                      <Typography
                        className={classes.outOfStockItem}
                        key={this.generateKey(index)}
                      >
                        {item.productItem.name}
                      </Typography>
                    ))
                  )
              }
            </div>
            {
              loading === 0
              && (
                <div className={classes.outOfStockActionButtons}>
                  {this.renderOutOfStockActionButtons(outOfStockItems)}
                </div>
              )
            }
          </div>
        )
        : (
          <div>
            <div>
              {
                !hideMapComponentOnMobile && locations && currentLocation
                && this.renderLocationMap(currentLocation)
              }
            </div>
            <div className={locationsContainerStyle}>
              {
                locations
                && locations.map(location => (
                  this.renderLocationDetails(location, deliveryOption)
                ))
              }
              {
                this.renderAddButton(deliveryOption)
              }
            </div>
          </div>
        )
    );
  }

  showOneDayHours = (location, deliveryOption, classes) => {
    const { hours } = location;
    // Delivery and Catering right now cannot show the location hours from the addresses dialog here.
    // For them, the location info currently comes after you select the address, because the API
    // needs to check what location's delivery zone the address falls within.
    if (!Array.isArray(hours)) return null;
    let oneDayHours = '';

    const today = new Date();
    oneDayHours = this.getOneDayHours(today, hours, deliveryOption, 0);

    return (
      <div className={classes.locationAddressSection}>
        {
          oneDayHours
          && (
            <div>
              <AlarmIcon className={classes.clockIcon} />
            </div>
          )
        }
        <div>
          <Typography>
            {oneDayHours}
          </Typography>
        </div>
      </div>
    );
  }

  handleRemoveItems = async (outOfStockItems) => {
    const {
      actions, user, currentOrder,
    } = this.props;
    const mutableOrder = Immutable.asMutable(currentOrder, { deep: true });

    outOfStockItems.forEach(async (item) => {
      // items excluding removed item
      mutableOrder.items = mutableOrder.items.filter(cItem => cItem.id !== item.id);

      try {
        await actions.deleteOrderItem(user, item.id, currentOrder.id);
      } catch (error) {
        console.log('API delete order item error', error);
      }
    });
    await actions.updateOrder(user, mutableOrder, currentOrder.id);
    this.setState({ invalidLocationId: null });
  }

  navigateBack = () => this.props.navigateBack()

  renderPickupAddress = (location, deliveryOption) => {
    const { classes, width } = this.props;
    const { phone } = location;

    const addressSplit = location.address
      ? location.address.split(', ')
      : [];
    const street = addressSplit[0] || '';
    const city = addressSplit[1] || '';
    const province = addressSplit[2] || '';
    const postalCode = addressSplit[3] ? `| ${addressSplit[3]}` : '';

    const address = (
      <Fragment>
        {this.renderText(street)}
        {this.renderText(`${city}, ${province} ${postalCode}`)}
      </Fragment>
    );

    return (
      <div
        className={classes.locationAddressSubContainer}
        onClick={() => this.handleChange(location)}
      >
        {
          isMobileMode(width)
            ? (
              <Fragment>
                {this.renderAddressWithLocationIconForMobile(address, classes)}
                <div className={classes.locationAddressSection}>
                  {phone && <PhoneIcon className={classes.phoneIcon} />}
                  {this.renderText(phone, classes.paddedText)}
                </div>
                {this.showOneDayHours(location, deliveryOption, classes)}
              </Fragment>
            )
            : (
              <Fragment>
                {address}
                {this.renderText(phone, classes.paddedText)}
                {this.showOneDayHours(location, deliveryOption, classes)}
              </Fragment>
            )
        }
      </div>
    );
  }

  renderDeliveryAddress = (location) => {
    const { classes, width } = this.props;

    const {
      nickname, unitNumber, streetAddress, city, postalCode,
    } = location;
    const unitNumberString = unitNumber ? `${unitNumber} - ` : '';
    const address = (
      <Fragment>
        {!isMobileMode(width) && this.renderText(nickname, classes.largerBoldText)}
        {this.renderText(`${unitNumberString}${streetAddress}`)}
        {this.renderText(`${city} | ${postalCode}`)}
      </Fragment>
    );

    return (
      <div
        className={classes.locationAddressSubContainer}
        onClick={() => this.handleChange(location)}
      >
        {
          isMobileMode(width)
            ? (
              <Fragment>
                {this.renderAddressWithLocationIconForMobile(address, classes)}
              </Fragment>
            )
            : address
        }
      </div>
    );
  }

  renderLocationDetails = (location, deliveryOption) => {
    const { classes } = this.props;
    const { selectedLocationId } = this.state;

    // Store Location: name
    // User Address: nickname
    const name = location.name || location.nickname;

    const containerStyle = selectedLocationId === location.id
      ? classes.locationAddressSelectedContainer
      : classes.locationAddressUnselectedContainer;

    return (
      <div
        key={`${name} ${location.id}`}
        className={containerStyle}
      >
        <FormControl className={classes.formControl}>
          <RadioGroup
            aria-label={name}
            name={name}
            value={`${this.state.selectedLocationId}`}
            onChange={() => this.handleChange(location)}
            className={classes.radioGroup}
          >
            <FormControlLabel
              value={`${location.id}`}
              control={
                <Radio className={classes.formControlLabel} />
              }
              label={this.renderText(name, classes.largerBoldText)}
            />
            {this.renderAddress(location, deliveryOption)}
          </RadioGroup>
        </FormControl>
      </div>
    );
  }

  renderAddress = (location, deliveryOption) => {
    if ([PICKUP, DINE_IN].includes(deliveryOption)) {
      return this.renderPickupAddress(location, deliveryOption);
    }

    return this.renderDeliveryAddress(location, deliveryOption);
  }

  renderAddButton = (deliveryOption) => {
    const { classes, translation } = this.props;
    return (
      (deliveryOption === 'DELIVERY' || deliveryOption === 'CATERING')
        && (
          <div className={classes.addNewAddressContainer}>
            <div
              className={classes.addButton}
              onClick={() => this.handleClickAdd()}
            >
              <Icon>control_point</Icon>
              <Typography className={classes.addButtonText}>
                {translation('CheckoutDrawer.orderLocationDialog.addNew')}
              </Typography>
            </div>
          </div>
        )
    );
  }

  renderLocationMap = (location) => {
    const { translation, history } = this.props;

    return (
      <MapComponent
        key={location.id}
        location={location}
        translation={translation}
        history={history}
        containerStyle={responsiveStyles.mapContainer}
        mapStyle={responsiveStyles.mapStyle}
      />
    );
  }

  renderAddressWithLocationIconForMobile = (address, classes) => (
    <div className={classes.locationAddressSection}>
      <div>
        <LocationOnIcon className={classes.locationPinIcon} />
      </div>
      <div>
        {address}
      </div>
    </div>
  )

  renderText = (text, style) => (
    <Typography className={style}>
      {text}
    </Typography>
  );

  renderActionButtons = (locations) => {
    const { classes, translation } = this.props;
    const { invalidLocationId, selectedLocationId } = this.state;

    if (invalidLocationId) {
      return (
        <div />
      );
    }

    const multipleDeliveryOptions = availableDeliveryOptions().length > 1;

    return (
      <div className={classes.actionButtonContainer}>
        {
          multipleDeliveryOptions
          && (
            <Button
              variant="text"
              overrideClass
              type="primary"
              className={classes.backButtonStyle}
              onClick={this.navigateBack}
              startIcon={<ArrowBackIcon className={classes.backArrowIcon} />}
              text={translation('BACK')}
            />
          )
        }
        <Button
          id="confirmLocation"
          styleOverride={responsiveStyles.confirmButton}
          disabled={selectedLocationId === 0}
          type="primary"
          onClick={() => this.setOrderLocation(locations)}
          text={translation('CheckoutDrawer.orderLocationDialog.confirm')}
        />
      </div>
    );
  }

  renderOutOfStockActionButtons = (outOfStockItems) => {
    const { classes, translation } = this.props;

    return (
      <div className={classes.actionButtonContainer}>
        <Button
          type="secondary"
          onClick={() => {
            this.handleClose();
          }}
          text={translation('CANCEL')}
        />
        <Button
          type="primary"
          onClick={() => {
            this.handleRemoveItems(outOfStockItems);
          }}
          text={translation('REMOVE')}
        />
      </div>
    );
  }

  render() {
    const {
      deliveryOption,
      open,
      classes,
      translation,
      userAddresses,
      storeLocations,
      dialogLoading,
      width,
      allowDialogClose,
    } = this.props;
    const { invalidLocationId } = this.state;
    const locations = ([PICKUP, DINE_IN].includes(deliveryOption))
      ? storeLocations
      : userAddresses;
    const dialogTitleText = invalidLocationId
      ? translation('CheckoutDrawer.orderLocationDialog.productsOutOfStock')
      : translation(deliveryOptionText[deliveryOption]);

    const mobileFriendlyStyleProps = { classes, width };
    const buttonContainerStyle = getMobileFriendlyStyle(mobileFriendlyStyleProps, 'buttonContainerStyle');
    const dialogTitleStyle = allowDialogClose
      ? getMobileFriendlyStyle(mobileFriendlyStyleProps, 'dialogTitleStyle')
      : getMobileFriendlyStyle(mobileFriendlyStyleProps, 'dialogTitleWithPadding');

    return (
      <DialogView
        open={open}
        titleAlignClose={false}
        titleHasCloseBtn={allowDialogClose}
        handleClose={() => this.handleClose()}
        dialogCloseIconColor={classes.dialogCloseIconColor}
        disableBackdropClick={!allowDialogClose}
        disableEscapeKeyDown={!allowDialogClose}
        dialogPaperStyle={
          invalidLocationId
            ? responsiveStyles.dialogOutOfStockPaperStyle
            : responsiveStyles.dialogPaperStyle
        }
        dialogBodyContainerStyle={classes.dialogBodyContainerStyle}
        dialogContentStyle={classes.dialogContentStyle}

        dialogTitleText={dialogTitleText}
        dialogTitleStyle={dialogTitleStyle}

        hasDialogContent
        renderDialogContent={() => this.dialogContent(deliveryOption, locations)}

        hasDialogContent2={false}

        hasDialogActions
        actionBtnStyle={buttonContainerStyle}
        renderActionBtn={() => this.renderActionButtons(locations)}
        loading={!!dialogLoading}
      />
    );
  }
}

OrderLocationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  deliveryOption: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  currentOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  dialogLoading: PropTypes.number.isRequired,
  loading: PropTypes.number.isRequired,
  navigateBack: PropTypes.func.isRequired,
  selectedLocationId: PropTypes.number,
  userAddresses: PropTypes.arrayOf(PropTypes.object),
  storeLocations: PropTypes.arrayOf(PropTypes.any),
  closeAfterChange: PropTypes.bool,
  onChangeDialog: PropTypes.func,
  user: PropTypes.objectOf(PropTypes.any),
  products: PropTypes.arrayOf(PropTypes.object),
  allowDialogClose: PropTypes.bool,
};

OrderLocationDialog.defaultProps = {
  user: null,
  userAddresses: null,
  storeLocations: null,
  closeAfterChange: true,
  onChangeDialog: null,
  selectedLocationId: null,
  products: null,
  allowDialogClose: true,
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
  dialogLoading: getDialogLoading(state),
  userAddresses: getCurrentUserAddresses(state),
  products: getProducts(state),
});

export default connect(mapStateToProps)(withStyles(responsiveStyles)(withWidth()(OrderLocationDialog)));
