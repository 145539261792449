import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { TextField, Typography } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import * as ResponsiveStyles from '../../../../jsonStyles/components/product/subComponents/InstructionsComponent.style.json';
import styles from '../../../css/product/subComponents/InstructionsComponent.scss';
import { getMobileFriendlyStyle } from '../../../services/functions/Functions';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.content = {
  ...responsiveStyles.content,
  [theme.breakpoints.down('sm')]: {
    flexDirection: ResponsiveStyles.content.flexDirection,
  },
};

responsiveStyles.instructionContainer = {
  ...responsiveStyles.instructionContainer,
  [theme.breakpoints.down('sm')]: {
    maxWidth: ResponsiveStyles.instructionContainer.maxWidth,
    width: ResponsiveStyles.instructionContainer.width,
  },
};

responsiveStyles.textFieldContainer = {
  ...responsiveStyles.textFieldContainer,
  [theme.breakpoints.down('sm')]: {
    width: ResponsiveStyles.textFieldContainer.width,
    height: ResponsiveStyles.textFieldContainer.height,
    maxWidth: ResponsiveStyles.textFieldContainer.maxWidth,
    marginTop: ResponsiveStyles.textFieldContainer.marginTop,
    paddingLeft: ResponsiveStyles.textFieldContainer.paddingLeft,
  },
};

const InstructionsComponent = (props) => {
  const {
    classes,
    handleOnChange,
    translation,
    width,
    dialogView,
  } = props;

  const instructionContainerStyles = getMobileFriendlyStyle({ classes, width }, 'instructionContainer');

  return (
    <div className={dialogView ? classes.dialogContent : classes.content}>
      <div className={dialogView ? classes.dialogInstructionContainer : instructionContainerStyles}>
        <Typography className={classes.instructionTitle}>
          {translation('InstructionsComponent.instructionTitle')}
        </Typography>
        <Typography className={classes.instructionDescription}>
          {translation('InstructionsComponent.instructionDescription')}
        </Typography>
      </div>
      <div className={dialogView ? classes.dialogTextFieldContainer : classes.textFieldContainer}>
        <TextField
          onChange={handleOnChange}
          multiline={responsiveStyles.textFieldStyle.multiline === 'true'}
          rows={responsiveStyles.textFieldStyle.rows}
          className={classes.textFieldStyle}
          inputProps={{ className: classes.inputMultilineStyle }}
          InputProps={{
            className: classes.inputElementStyle,
            disableUnderline: responsiveStyles.inputElementStyle.disableUnderline === 'true',
          }}
        />
      </div>
    </div>
  );
};

InstructionsComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleOnChange: PropTypes.func.isRequired,
  translation: PropTypes.func.isRequired,
};

export default withStyles(responsiveStyles)(withWidth()(InstructionsComponent));
