import {
  DAY_TO_NUMBER,
  GETDAY_FULLDAYS,
  HOURS_TYPE,
  SHORT_DAYS,
  DELIVERY,
  PICKUP,
  BUSINESS,
} from '../constants/Constants';
import DateHelper from '../helpers/DateHelper';
import * as FeatureFlags from '../../../feature-flags.json';

export const CLOSED = 'Closed';
// TODO: need a way to get the value from the i18n library without requiring passing in the translation function... these will be constant for the app
// const CLOSED = FeatureFlags.HoursComponent.lowerCaseClosed
//   ? i18n.t('HoursComponent.closed')
//   : i18n.t('HoursComponent.closed').toUpperCase();

export const OPEN_24_HOURS = 'Open 24 Hours';
// const OPEN_24_HOURS = FeatureFlags.HoursComponent.lowerCase24Hours
//   ? i18n.t('HoursComponent.open24Hours')
//   : i18n.t('HoursComponent.open24Hours').toUpperCase();

const getCurrentWeeksHolidays = (holidayHours = []) => {
  const today = new Date(Date.now());
  today.setHours(0, 0, 0, 0); // midnight
  const weekStartingToday = new Date();
  weekStartingToday.setDate(today.getDate() + 10); // midnight 10 days from 0000 hours this morning
  const filteredHolidays = holidayHours.filter((day) => {
    const dateInMs = day.date * 1000;
    return (today.getTime() <= dateInMs && dateInMs <= weekStartingToday.getTime());
  });

  const filteredHolidaysWithDate = [];
  for (let i = 0; i < filteredHolidays.length; i++) {
    const holidayDateInMs = filteredHolidays[i].date * 1000;
    const holidayAsDate = new Date(holidayDateInMs);

    filteredHolidaysWithDate[i] = {
      ...filteredHolidays[i],
      day: GETDAY_FULLDAYS[holidayAsDate.getDay()],
    };
  }

  return filteredHolidaysWithDate;
};

const getAMPMHoursString = (openTimeString = '', closeTimeString = '', isOpen24Hours) => {
  if (isOpen24Hours) return OPEN_24_HOURS;
  let openingHour = DateHelper.convertToAmPm(openTimeString, true);
  const closingHour = DateHelper.convertToAmPm(closeTimeString, true);

  // If both openingHour and closingHour are AM/PM, remove the first suffix
  if (openingHour && closingHour && openingHour.split(' ')[1] === closingHour.split(' ')[1]) {
    openingHour = `${openingHour.split(' ').slice(0, -1).join()}`;
  }

  if (!openingHour && !closingHour) return CLOSED;
  const combinedHours = `${openingHour} - ${closingHour}`;
  const casedHours = FeatureFlags.HoursComponent.lowerCaseHours ? combinedHours.toLowerCase() : combinedHours;
  const casedAndSpacedHours = FeatureFlags.HoursComponent.compactHours ? casedHours.replace(/\s/g, '') : casedHours;
  return casedAndSpacedHours;
};

const getFormattedTypeHours = (type, time = {}) => {
  const {
    deliveryOpen, deliveryClose, deliveryOpenAllDay,
    pickupOpen, pickupClose, pickupOpenAllDay, businessOpenAllDay,
  } = time;
  let startCloseTime;
  switch (type) {
    case (DELIVERY):
      startCloseTime = getAMPMHoursString(deliveryOpen, deliveryClose, deliveryOpenAllDay);
      break;
    case (PICKUP):
      startCloseTime = getAMPMHoursString(pickupOpen, pickupClose, pickupOpenAllDay);
      break;
    case (BUSINESS):
    default:
      startCloseTime = getAMPMHoursString(time.open, time.close, businessOpenAllDay);
      break;
  }
  return startCloseTime;
};

const getHolidayHoursDateString = (holiday) => {
  if (!holiday || !holiday.date) return 'Invalid Date';
  const holidayDateInMs = holiday.date * 1000;
  const holidayAsDate = new Date(holidayDateInMs);
  const options = {
    weekday: 'short', month: 'numeric', day: 'numeric',
  };
  return holidayAsDate.toLocaleDateString('en-US', options);
};

const setHoursToClosed = (hours) => {
  if (!hours) return;
  // business is closed, replace "open hours" with closed message (this is an issue with the API
  // returning multiple values even though only the closed on counts - which is actually caused by
  // the admin panel - which is being addressed.)
  while (hours.length > 0) {
    hours.pop();
  }
  hours.push(CLOSED);
};

const groupHoursSetsByDay = (dayMap, fieldHours, operatingHours = [], type) => {
  if (!dayMap) return undefined;

  const updatedMap = JSON.parse(JSON.stringify(dayMap));
  operatingHours.map((hr) => {
    const formattedTypeHours = getFormattedTypeHours(type, hr);
    const currentDayAsNumber = DAY_TO_NUMBER[hr.day];
    const currentDay = updatedMap[currentDayAsNumber];
    const hours = currentDay && currentDay[fieldHours] ? currentDay[fieldHours] : [];
    if (fieldHours === HOURS_TYPE.HOLIDAYS) {
      if (hours.length === 0) {
        hours.push(getHolidayHoursDateString(hr));
      }
      if (hours.length > 1 && hours.join().includes(CLOSED) && formattedTypeHours !== CLOSED) {
        // multiple hour sets for this day (ex: open 10am-12pm, 1-5pm), and at least one set is open, show the open hours
        hours.filter(h => h !== CLOSED);
      }
      if ((hours.length === 1 && formattedTypeHours === CLOSED) || formattedTypeHours !== CLOSED) {
        hours.push(formattedTypeHours);
      }
      // regular hours
    } else if (hours.length > 0 && (hours.join().includes(CLOSED) || formattedTypeHours === CLOSED)) {
      setHoursToClosed(hours);
    } else {
      hours.push(formattedTypeHours);
    }
    if (!updatedMap[DAY_TO_NUMBER[hr.day]]) {
      updatedMap[DAY_TO_NUMBER[hr.day]] = {};
    }
    updatedMap[DAY_TO_NUMBER[hr.day]][fieldHours] = hours;

    return {};
  });
  return updatedMap;
};

const groupConsecutiveDaysWithSameOpenTimes = (dayMap) => {
  if (!dayMap || Object.keys(dayMap).length < 7) return undefined;

  const groupedDays = [];
  const currentGroup = {};
  currentGroup.days = [SHORT_DAYS[0]];
  currentGroup.hours = dayMap[0].hours || [];
  currentGroup.holidays = dayMap[0].holidays ? [dayMap[0].holidays] : [];
  for (let i = 1; i < 7; i++) {
    const day = dayMap[i];
    if (currentGroup.hours && day.hours && currentGroup.hours.join() === day.hours.join()) {
      currentGroup.days.push(SHORT_DAYS[i]);
      const currentHolidayCount = currentGroup.holidays.length;
      if (day.holidays && (currentHolidayCount === 0 || currentGroup.holidays[currentHolidayCount - 1] !== 'Closed')) {
        currentGroup.holidays.push(day.holidays);
      }
    } else {
      groupedDays.push(Object.assign({}, currentGroup));
      currentGroup.days = [SHORT_DAYS[i]];
      currentGroup.hours = day.hours || [];
      currentGroup.holidays = day.holidays ? [day.holidays] : [];
    }
  }
  groupedDays.push(Object.assign({}, currentGroup));
  return groupedDays;
};

const getDayHoursString = (groupedDays = '', hours = []) => {
  if (!groupedDays || hours.length === 0) return undefined;
  const groupedHours = hours.join(', ');
  return `${groupedDays}: ${groupedHours}`;
};

const getFirstLastDayOfGroup = (days = []) => {
  const daysLength = days.length;
  if (daysLength === 0) return undefined;
  return `${days[0]}${daysLength > 1 ? `-${days[daysLength - 1]}` : ''}`;
};

const getRegularHoursString = (days, hours) => {
  const firstLastDay = getFirstLastDayOfGroup(days);
  return getDayHoursString(firstLastDay, hours);
};

const getHolidayHoursList = (listOfHolidays = []) => {
  const formattedHolidays = [];

  for (let i = 0; i < listOfHolidays.length; i++) {
    const holidayDate = listOfHolidays[i][0];
    const holidayHours = listOfHolidays[i].slice(1);
    formattedHolidays[i] = getDayHoursString(holidayDate, holidayHours);
  }
  return formattedHolidays;
};

const formatGroupedHoursForDisplay = (groupedDayMap = []) => {
  const formattedGroupedHours = [];

  for (let i = 0; i < groupedDayMap.length; i++) {
    formattedGroupedHours[i] = {};
    formattedGroupedHours[i].hours = getRegularHoursString(groupedDayMap[i].days, groupedDayMap[i].hours);
    formattedGroupedHours[i].holidays = getHolidayHoursList(groupedDayMap[i].holidays);
  }
  return formattedGroupedHours;
};

export const getGroupedHoursForDeliveryType = (location = {}, type) => {
  const operatingHours = location.hours || [];
  const holidayHours = location.holidayHours || [];
  const filteredHolidays = getCurrentWeeksHolidays(holidayHours);
  let dayMap = {};
  dayMap = groupHoursSetsByDay(dayMap, HOURS_TYPE.HOURS, operatingHours, type);
  dayMap = groupHoursSetsByDay(dayMap, HOURS_TYPE.HOLIDAYS, filteredHolidays, type);
  const groupedDayMap = groupConsecutiveDaysWithSameOpenTimes(dayMap);
  const formattedGroupedDayMap = formatGroupedHoursForDisplay(groupedDayMap);
  return formattedGroupedDayMap;
};

export const HourUtils = {
  formatGroupedHoursForDisplay,
  getAMPMHoursString,
  getCurrentWeeksHolidays,
  getDayHoursString,
  getFirstLastDayOfGroup,
  getFormattedTypeHours,
  getGroupedHoursForDeliveryType,
  getHolidayHoursDateString,
  getHolidayHoursList,
  getRegularHoursString,
  groupConsecutiveDaysWithSameOpenTimes,
  groupHoursSetsByDay,
  setHoursToClosed,
};
