import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Typography,
  Icon,
  IconButton,
} from '@material-ui/core';
import Immutable from 'seamless-immutable';

import ReadMore from '../../core/components/ReadMore';
import styles from '../../../css/menuPage/components/ProductBoxComponent.scss';
import * as FeatureFlags from '../../../../feature-flags.json';
import { productIcons } from '../../../services/constants/Constants';
import FeaturePizzaIcon from '../../../assets/images/feature@3x.png';
import ImagePlaceholder from '../../../assets/images/image-placeholder.png';
import * as ResponsiveStyles from '../../../../jsonStyles/components/menuPage/components/ProductBoxComponent.style.json';
import { getCurrencySymbol, productIsOutOfStock } from '../../../services/functions/Functions';
import ResourceConstants from '../../../services/constants/ResourceConstants.json';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.media = {
  ...responsiveStyles.media,
  [theme.breakpoints.down('sm')]: {
    width: ResponsiveStyles.media.width,
    height: ResponsiveStyles.media.height,
    objectFit: ResponsiveStyles.media.objectFit,
    borderRadius: ResponsiveStyles.media.borderRadius,
    padding: ResponsiveStyles.media.padding,
  },
};

responsiveStyles.cardWithDescription = {
  ...responsiveStyles.cardWithDescription,
  [theme.breakpoints.down('sm')]: {
    width: ResponsiveStyles.cardWithDescription.width,
    height: ResponsiveStyles.cardWithDescription.height,
    minHeight: ResponsiveStyles.cardWithDescription.minHeight,
  },
};

responsiveStyles.cardNoDescription = {
  ...responsiveStyles.cardNoDescription,
  [theme.breakpoints.down('sm')]: {
    width: ResponsiveStyles.cardNoDescription.width,
    height: ResponsiveStyles.cardNoDescription.height,
    minHeight: ResponsiveStyles.cardNoDescription.minHeight,
  },
};

responsiveStyles.titleContainer = {
  ...responsiveStyles.titleContainer,
  [theme.breakpoints.down('sm')]: {
    height: ResponsiveStyles.titleContainer.height,
    fontSize: ResponsiveStyles.titleContainer.fontSize,
  },
};

responsiveStyles.subheader = {
  ...responsiveStyles.subheader,
  [theme.breakpoints.down('sm')]: {
    height: ResponsiveStyles.subheader.height,
  },
};

responsiveStyles.description = {
  ...responsiveStyles.description,
  [theme.breakpoints.down('sm')]: {
    height: ResponsiveStyles.description.height,
  },
};

responsiveStyles.productContainer = {
  ...responsiveStyles.productContainer,
  [theme.breakpoints.down('sm')]: {
    width: ResponsiveStyles.productContainer.width,
  },
};

responsiveStyles.header = {
  ...responsiveStyles.header,
  [theme.breakpoints.down('sm')]: ResponsiveStyles.header,
};

responsiveStyles.cardHeaderContainer = {
  ...responsiveStyles.cardHeaderContainer,
  [theme.breakpoints.down('sm')]: {
    padding: ResponsiveStyles.cardHeaderContainer.padding,
    width: ResponsiveStyles.cardHeaderContainer.width,
  },
};

class ProductBoxComponent extends Component {
  getLowestPrice = (items) => {
    const mutableItems = Immutable.asMutable(items, { deep: true });
    const sortedItems = mutableItems.sort((a, b) => a.price - b.price);
    return sortedItems[0].price.toFixed(2);
  }

  parseDescription = (description) => {
    const { translation } = this.props;
    const desc = description && description.replace(translation('ProductBoxComponent.vegetarian'), '').trim();
    return desc;
  }

  renderOutOfStockOverlay = () => {
    const { classes, translation } = this.props;
    return (
      <div className={classes.oufOfStockOverlay}>
        <IconButton
          color={classes.outOfStockIcon.color}
          aria-label="warning"
        >
          <Icon
            color="error"
            fontSize="large"
          >
            warning
          </Icon>
        </IconButton>
        <Typography className={classes.outOfStockText}>
          {translation('ProductBoxComponent.outOfStock')}
        </Typography>
      </div>
    );
  }

  render() {
    const {
      classes, translation, product, handleClick, currentOrderLocationId,
    } = this.props;
    const { showProductImage, showProductDescription } = FeatureFlags.ProductBoxComponent;
    const iconList = get(product, 'icons');
    return (
      <div id={`product${product.id}`} className={classes.productContainer}>
        {
          FeatureFlags.ProductBoxComponent.featureProduct.show
          && <img src={FeaturePizzaIcon} alt="Feature Pizza" className={classes.featurePizzaIcon} />
        }
        <Card
          className={showProductDescription ? classes.cardWithDescription : classes.cardNoDescription}
          onClick={() => {
            if (!productIsOutOfStock(product, currentOrderLocationId)) handleClick(product.id);
          }}
        >
          {productIsOutOfStock(product, currentOrderLocationId) && this.renderOutOfStockOverlay()}
          <div className={classes.cardHeaderContainer}>
            {
              showProductImage
                && (
                  <CardMedia
                    className={classes.media}
                    component="img"
                    src={
                      product.images && product.images.length > 0
                        ? product.images[0].src
                        : ImagePlaceholder
                    }
                  />
                )
            }
            <CardHeader
              title={
                <div className={classes.titleContainer}>
                  {FeatureFlags.ProductBoxComponent.useUppercaseProductName ? product.name.toUpperCase() : product.name}
                  <div className={classes.iconsContainer}>
                    {
                      productIcons.map((pIcon) => {
                        if (iconList && iconList.includes(pIcon.key)) {
                          return (
                            <img
                              key={pIcon.key}
                              src={pIcon.icon}
                              className={classes.productIcon}
                              alt={translation(pIcon.translation)}
                              title={translation(pIcon.translation)}
                            />
                          );
                        }
                        return null;
                      })
                    }
                  </div>
                </div>
            }
              subheader={
              product.items.length > 1
                ? `${getCurrencySymbol()}${this.getLowestPrice(product.items)} ${translation('ProductBoxComponent.andUp')}`
                : product.items.length > 0 && product.items[0] && `${getCurrencySymbol()}${product.items[0].price.toFixed(2)}`
            }
              classes={{
              content: classes.header,
              title: classes.titleText,
              subheader: classes.subheader,
            }}
            />
          </div>
          {
              showProductDescription
              && (
                <CardContent classes={{ root: classes.removeTopPadding }}>
                  <ReadMore
                    charLimit={ResourceConstants.descriptionCharacterLimit}
                    textClass={classes.description}
                    text={this.parseDescription(product.description)}
                    translation={translation}
                  />
                </CardContent>
              )
            }
        </Card>
      </div>
    );
  }
}

ProductBoxComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  handleClick: PropTypes.func.isRequired,
  currentOrderLocationId: PropTypes.number,
};

ProductBoxComponent.defaultProps = {
  currentOrderLocationId: null,
};

export default withStyles(responsiveStyles)(ProductBoxComponent);
