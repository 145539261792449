import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Hidden } from '@material-ui/core';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

import * as FeatureFlags from '../../../../feature-flags.json';
import ContrastLogo from '../../../assets/images/logoContrast.svg';
import PrimaryLogo from '../../../assets/images/primary_logo.svg';
import styles from '../../../css/orderStatusPage/subComponents/ThankYouComponent.scss';
import * as ResponsiveStyles from '../../../../jsonStyles/components/orderStatusPage/subComponents/ThankYouComponent.style.json';
import { getMobileFriendlyStyle } from '../../../services/functions/Functions';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.thankYouBox = {
  ...responsiveStyles.thankYouBox,
  [theme.breakpoints.down('sm')]: {
    margin: ResponsiveStyles.thankYouBox.margin,
    width: ResponsiveStyles.thankYouBox.width,
  },
};

responsiveStyles.thankYou = {
  ...responsiveStyles.thankYou,
  [theme.breakpoints.down('sm')]: {
    fontSize: ResponsiveStyles.thankYou.fontSize,
  },
};

responsiveStyles.currentStatus = {
  ...responsiveStyles.currentStatus,
  [theme.breakpoints.down('sm')]: {
    fontSize: ResponsiveStyles.currentStatus.fontSize,
  },
};

responsiveStyles.receipt = {
  ...responsiveStyles.receipt,
  [theme.breakpoints.down('sm')]: {
    fontSize: ResponsiveStyles.receipt.fontSize,
  },
};

responsiveStyles.orderNum = {
  ...responsiveStyles.orderNum,
  [theme.breakpoints.down('sm')]: {
    fontSize: ResponsiveStyles.orderNum.fontSize,
  },
};

const Logo = FeatureFlags.OrderStatusPage.ThankYouComponent.usePrimaryLogo ? PrimaryLogo : ContrastLogo;

const logo = {
  src: Logo,
  alt: 'CoreView.header.logoAlt',
};

const ThankYouComponent = (props) => {
  const {
    classes,
    orderPlaced,
    translation,
    user,
    width,
  } = props;

  const mobileFriendlyStyleProps = { classes, width };
  const greetingStyles = getMobileFriendlyStyle(mobileFriendlyStyleProps, 'greeting');

  return (
    <div className={classes.thankYouBox}>
      <Hidden smDown>
        <div className={classes.logo}>
          <img src={logo.src} alt={logo.alt} className={classes.logoImage} />
        </div>
      </Hidden>
      <div>
        <div className={greetingStyles}>
          <Typography className={classes.thankYou}>
            {`${translation('OrderStatusPage.thankYou')} `}
            {
              FeatureFlags.OrderStatusPage.ThankYouComponent.useUppercaseUserName
                ? user.name.toUpperCase().split(' ')[0]
                : user.name.split(' ')[0]
            }!
          </Typography>
        </div>
        <div>
          <Typography className={classes.currentStatus}>
            {translation('OrderStatusPage.orderPlaced')}
          </Typography>
          <Typography className={classes.receipt}>
            {`${translation('OrderStatusPage.receipt')} `}{user.email}
          </Typography>
          <Typography className={classes.orderNum}>
            {`${translation('OrderStatusPage.orderNum')}: `}{orderPlaced.id}
          </Typography>
        </div>
      </div>
    </div>
  );
};

ThankYouComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  translation: PropTypes.func.isRequired,
  orderPlaced: PropTypes.objectOf(PropTypes.any),
};

ThankYouComponent.defaultProps = {
  user: null,
  orderPlaced: null,
};

export default withStyles(responsiveStyles)(withWidth()(ThankYouComponent));
