
    module.exports = function cssToMuiLoader(theme) {
      
      return {
        
        'btn': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,},'btnPrimary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#a76e1b`,'color': `#ffffff`,
        },},'btnSecondary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#ffffff`,'color': `#d18a22`,'&:hover': {
          'backgroundColor': `#fff2e0`,'color': `#d18a22`,
        },},'btnTertiary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#70a0c3`,'color': `#ffffff`,
        },},'linkText': {'color': `#d18a22`,'cursor': `pointer`,'fontWeight': `bold`,'textDecoration': `underline`,'margin': `0 8px`,'fontSize': `0.75rem`,'fontFamily': `'Lato-Bold', sans-serif`,},'newCard': {'display': `flex`,'flexDirection': `column`,'justifyContent': `space-around`,'minHeight': `400px`,},'title': {'fontSize': `18px`,'fontWeight': `bold`,'color': `#282828`,'marginBottom': `16px`,},'subTitle': {'fontSize': `12px`,'fontStyle': `italic`,'color': `gray`,'marginBottom': `16px`,},'details': {'display': `flex`,'flexDirection': `row`,'justifyContent': `space-between`,},'cardNumber': {'width': `50%`,},'address': {'width': `50%`,},'city': {'width': `50%`,},'unit': {'width': `47.5%`,},'postalCode': {'width': `47.5%`,},'cvc': {'width': `15%`,},'expiryDate': {'width': `30%`,},'textField': {'margin': `normal`,'variant': `outlined`,'marginBottom': `3%`,},'formFooter': {'display': `flex`,'justifyContent': `space-between`,},
        
      };
    };
  