import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import Button from '../../core/components/Button';
import DialogView from '../../core/components/DialogView';
import styles from '../../../css/paymentPage/components/DeleteDialog.scss';

const pageStyles = typeof styles === 'function' ? styles() : styles;

class DeleteDialog extends Component {
  getDialogContent = () => {
    const {
      classes,
      translation,
      cardType,
      cardName,
    } = this.props;
    return (
      <div className={classes.dialogContentContainer}>
        <WarningIcon className={classes.dialogImage} />
        <Typography className={classes.emphasizedText}>
          {translation('DeleteDialog.title')}
        </Typography>
        <Typography className={classes.normalText}>
          {`${translation('DeleteDialog.content')} ${cardType}: "${cardName}"`}
        </Typography>
      </div>
    );
  };

  getActionButton = () => {
    const { translation, handleClick } = this.props;
    return (
      <Button
        type="primary"
        styleOverride={pageStyles.deleteButton}
        onClick={handleClick}
        text={translation('DeleteDialog.button')}
      />
    );
  };

  render() {
    const {
      open,
      classes,
      handleCloseDeleteDialog,
    } = this.props;

    return (
      <DialogView
        open={open}
        titleHasCloseBtn
        titleAlignClose={false}
        handleClose={handleCloseDeleteDialog}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        dialogTitleStyle={classes.dialogTitleStyle}
        dialogBodyContainerStyle={classes.dialogBodyContainerStyle}
        dialogContentStyle={classes.dialogContentStyle}
        hasDialogContent
        hasDialogContent2={false}
        renderDialogContent={() => this.getDialogContent()}
        hasDialogActions
        actionBtnStyle={classes.actionBtnStyle}
        renderActionBtn={() => this.getActionButton()}
        dialogCloseIconColor={pageStyles.dialogCloseIconStyle.color}
        dialogPaperStyle={pageStyles.dialogPaperStyle}
      />
    );
  }
}

DeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleCloseDeleteDialog: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  cardType: PropTypes.string.isRequired,
  cardName: PropTypes.string,
};

DeleteDialog.defaultProps = {
  cardName: '',
};

export default withStyles(styles)(DeleteDialog);
