import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { TranslationClass } from '../i18n/i18n';
import * as Actions from '../actions/Actions';
import * as Routes from '../services/routes/Routes.json';
import CoreRoute from './core/utils/CoreRoute';
import MenuPage from './menuPage/MenuPage';
import LocationsPage from './locationsPage/LocationsPage';
import PaymentMethodPage from './paymentMethodPage/PaymentMethodPage';
import AddNewCardPage from './addNewCardPage/AddNewCardPage';
import SettingsPage from './settingsPage/SettingsPage';
import AccountPage from './accountPage/AccountPage';
import MessageSnackbar from './core/components/MessageSnackbar';
import OrderStatusPage from './orderStatusPage/OrderStatusPage';
import PaymentPage from './paymentPage/PaymentPage';
import OrdersPage from './ordersPage/OrdersPage';
import NotificationsPage from './notificationsPage/NotificationsPage';
import NotificationPage from './notificationsPage/NotificationPage';
import { FeatureFlagClass } from '../services/functions/FeatureFlag';

import {
  getLoading,
  getDialogLoading,
  getUser,
  getSuccessMessage,
  getErrorMessage,
  getResources,
  getCurrentOrder,
  getLocations,
} from '../selectors';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCategoryId: null,
    };
  }

  componentDidMount() {
    const { actions, user } = this.props;
    // eslint-disable-next-line no-undef
    if (!user.user && localStorage.user) {
      // eslint-disable-next-line no-undef
      actions.setUser(JSON.parse(localStorage.getItem('user')));
    }
    this.getUpdatedRemoteContent();
  }

  getUpdatedRemoteContent = () => {
    FeatureFlagClass.getInstance().downloadFile(0);
    TranslationClass.getInstance().downloadFile(0);
  }

  handleBackButtonClick = (categoryId) => {
    this.setState({ currentCategoryId: categoryId });
  }

  generateKey = index => (`key${index}`);

  render() {
    const {
      actions, loading, dialogLoading, user, errors, success, t, resources, currentOrder,
    } = this.props;
    const { currentCategoryId } = this.state;

    const coreViewProps = {
      actions,
      loading,
      dialogLoading,
      user: user.user,
      translation: t,
      rememberUser: user.rememberUser,
      currentOrder,
      locations: getLocations(this.props),
    };

    const homePaths = [Routes.path.homePage, Routes.path.menuPage];

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <BrowserRouter>
          <div className="App">
            <Switch>
              {
                homePaths.map((path, i) =>
                (
                  <CoreRoute key={this.generateKey(i)} exact path={path} coreview={coreViewProps} render={() => <MenuPage actions={actions} loading={loading} user={user.user} translation={t} products={resources.products} categories={resources.categories} currentCategoryId={currentCategoryId} />} />
                ))
              }
              <CoreRoute exact path={Routes.path.locationsPage} coreview={coreViewProps} render={() => <LocationsPage actions={actions} loading={loading} translation={t} />} />
              <CoreRoute exact path={Routes.path.menuPage} coreview={coreViewProps} render={() => <MenuPage actions={actions} loading={loading} user={user.user} translation={t} products={resources.products} categories={resources.categories} />} />
              <CoreRoute exact path={Routes.path.addNewCardPage} coreview={coreViewProps} render={() => <AddNewCardPage user={user.user} loading={loading} translation={t} actions={actions} />} />
              <CoreRoute exact path={Routes.path.paymentMethodPage} coreview={coreViewProps} render={() => <PaymentMethodPage user={user.user} actions={actions} loading={loading} translation={t} />} />
              <CoreRoute exact path={Routes.path.ordersPage} coreview={coreViewProps} render={() => <OrdersPage actions={actions} loading={loading} translation={t} />} />
              <CoreRoute exact path={Routes.path.settingsPage} coreview={coreViewProps} render={() => <SettingsPage user={user.user} loading={loading} translation={t} actions={actions} />} />
              <CoreRoute exact path={Routes.path.accountPage} coreview={coreViewProps} render={() => <AccountPage user={user.user} loading={loading} translation={t} actions={actions} />} />
              <CoreRoute exact path={Routes.path.paymentPage} coreview={coreViewProps} render={() => <PaymentPage user={user.user} actions={actions} loading={loading} translation={t} />} />
              <CoreRoute exact path={Routes.path.orderStatusPage} coreview={coreViewProps} render={() => <OrderStatusPage user={user.user} actions={actions} loading={loading} translation={t} />} />
              <CoreRoute exact path={Routes.path.notificationsPage} coreview={coreViewProps} render={() => <NotificationsPage user={user.user} translation={t} />} />
              <CoreRoute exact path={`${Routes.path.notificationsPage}/:notificationId`} coreview={coreViewProps} render={() => <NotificationPage user={user.user} actions={actions} loading={loading} translation={t} />} />
            </Switch>
            <MessageSnackbar
              openMessageSnackbar={!!(errors || success)}
              errorMsg={errors}
              successMsg={success}
              actions={actions}
              translation={t}
            />
          </div>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: getUser(state),
  loading: getLoading(state),
  dialogLoading: getDialogLoading(state),
  errors: getErrorMessage(state),
  success: getSuccessMessage(state),
  resources: getResources(state),
  currentOrder: getCurrentOrder(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
});

App.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  resources: PropTypes.objectOf(PropTypes.any),
  currentOrder: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.number.isRequired,
  dialogLoading: PropTypes.number.isRequired,
  errors: PropTypes.string,
  success: PropTypes.string,
  t: PropTypes.func.isRequired,
};

App.defaultProps = {
  resources: {},
  currentOrder: {},
  errors: '',
  success: '',
  user: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(withNamespaces()(App)));
