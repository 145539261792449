
    module.exports = function cssToMuiLoader(theme) {
      
      return {
        
        'btn': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,},'btnPrimary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#a76e1b`,'color': `#ffffff`,
        },},'btnSecondary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#ffffff`,'color': `#d18a22`,'&:hover': {
          'backgroundColor': `#fff2e0`,'color': `#d18a22`,
        },},'btnTertiary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#70a0c3`,'color': `#ffffff`,
        },},'linkText': {'color': `#d18a22`,'cursor': `pointer`,'fontWeight': `bold`,'textDecoration': `underline`,'margin': `0 8px`,'fontSize': `0.75rem`,'fontFamily': `'Lato-Bold', sans-serif`,'color': `#d18a22`,'cursor': `pointer`,'textDecoration': `underline`,'fontStyle': `italic`,'alignSelf': `center`,'fontFamily': `'Lato-Bold', sans-serif`,},'linkTextMobile': {'color': `#d18a22`,'cursor': `pointer`,'fontWeight': `bold`,'textDecoration': `underline`,'margin': `0 8px`,'fontSize': `0.75rem`,'fontFamily': `'Lato-Bold', sans-serif`,'color': `#d18a22`,'cursor': `pointer`,'textDecoration': `underline`,'fontStyle': `italic`,'alignSelf': `center`,'fontFamily': `'Lato-Bold', sans-serif`,'margin': `auto 0 auto auto`,},'container': {'padding': `10px 6vw`,'display': `flex`,'alignItems': `center`,'backgroundColor': `#e3e3e3`,},'infoContainer': {'display': `flex`,'flexDirection': `row`,},'infoSubContainer': {'display': `flex`,'flexDirection': `row`,},'infoSubContainerMobile': {'display': `flex`,'flexDirection': `row`,'flexDirection': `column`,},'infoSubContainerItem': {'display': `flex`,'flexDirection': `row`,},'infoSubContainerItemMobile': {'display': `flex`,'flexDirection': `row`,'&:nth-of-type(2)': {
          'paddingTop': `5px`,
        },},'infoIcon': {'color': `#000000`,'margin': `auto 10px auto 0`,'padding': `2px 0`,},'infoText': {'color': `#000000`,'margin': `auto 10px auto 0`,'padding': `2px 0`,'fontFamily': `'Lato-Regular', sans-serif`,},
        
      };
    };
  