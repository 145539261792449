/* eslint-disable prefer-template */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import 'babel-polyfill';
import { persistor, store } from './config/configureStore';
import App from './components/App';
import customizedThemes from './css/customMuiStyles';
import './i18n/i18n';
import versionInfo from './config/version.json';


Sentry.init({
  dist: versionInfo.version,
  dsn: 'https://69ef4501d2f14466be97005c4412a69f@o104974.ingest.sentry.io/1819074',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.2,
  release: 'craver-web@' + versionInfo.version,
});

Sentry.setTags({
  // eslint-disable-next-line no-undef
  companyName: window.companyName,
  version: versionInfo.version,
});

let theme = createMuiTheme(customizedThemes);
theme = responsiveFontSizes(theme);

const WebFont = require('webfontloader');

WebFont.load({
  custom: {
    families: ['Museo 300', 'Museo 500', 'Museo 700'],
    urls: [window.font_host + '/fonts.css'],
  },
});

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
        <App />
      </PersistGate>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('app'),
);
