import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'seamless-immutable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { LinearProgress, TableBody, TableRow, Typography } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';

import LinkText from '../core/components/LinkText';
import * as FeatureFlags from '../../../feature-flags.json';
import styles from '../../css/paymentPage/PaymentPage.scss';
import { getCurrentOrder, getCurrentUserPaymentOptions, getResources } from '../../selectors';
import Config from '../../../config.json';
import * as Routes from '../../services/routes/Routes.json';
import PageTitle from '../core/components/PageTitle';
import PaymentMethodComponent from './components/PaymentMethodComponent';
import PayNowComponent from './components/PayNowComponent';
import PayAtDoorComponent from './components/PayAtDoorComponent';
import DeleteDialog from './components/DeleteDialog';
import TipDialog from './subComponents/TipDialog';
import OrderSummaryComponent from './components/OrderSummaryComponent';
import RedirectLinkComponent from '../core/components/RedirectLinkComponent';
import * as Functions from '../../services/functions/Functions';

const pageStyles = typeof styles === 'function' ? styles() : styles;

const CheckoutDrawerLink = (props) => {
  const { classes, actions, menuLinkProps } = props;
  return (
    <LinkText
      className={classes.linkText}
      onClick={() => actions.toggleComponent('CheckoutDrawer')}
      text={menuLinkProps.text}
    />
  );
};

const RegularLink = props => (
  <RedirectLinkComponent
    href={props.menuLinkProps.hrefLink}
    linkText={props.menuLinkProps.text}
  />
);

class PaymentPage extends Component {
  constructor(props) {
    super(props);

    const { user, location } = props;
    let updatedPoints = user.points;
    if (location && location.state && location.state.points >= 0) {
      updatedPoints = location.state.points;
    }

    this.state = {
      activeTab: 'payNow',
      selectedCardId: null,
      newCard: {},
      type: 'payNow',
      cardToDelete: null,
      openDeleteDialog: false,
      openTipDialog: false,
      points: updatedPoints,
    };
  }

  async componentDidMount() {
    const {
      actions, user, history, resources,
    } = this.props;
    const apiToken = user.token;
    if (user) {
      try {
        const response = await actions.getAllResources(apiToken, ['users', user.id, 'payment_options']);

        if (response && !response.error) {
          const data = response.response;
          if (data) {
            this.setState({
              selectedCardId: data[0] ? data[0].id : null,
            });
          }
        }
      } catch (error) {
        console.log('API call error while getting payment options', error);
      }
    } else {
      history.push(Routes.path.menuPage);
    }
    if (!resources.companies) {
      try {
        actions.getAllResources(user.token, ['companies']);
      } catch (error) {
        console.log('API call error while getting companies', error);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { user, history } = this.props;
    if (prevProps.user !== user) {
      if (!user) {
        history.push(Routes.path.menuPage);
      }
    }
  }

  getTabContent = (selectedTab) => {
    const {
      translation, paymentOptions, currentOrder, user, actions,
    } = this.props;
    const { selectedCardId } = this.state;

    switch (selectedTab) {
      case 'payNow':
        return (
          <PayNowComponent
            translation={translation}
            selectedCardId={selectedCardId}
            handleClickSelect={(cardId, type) => this.handleClickCard(cardId, type)}
            handleChange={newCard => this.handleChangeInTextField(newCard)}
            handleOpenDeleteDialog={cardId => this.handleOpenDeleteDialog(cardId)}
            handleAddCard={newCard => this.handleAddCard(newCard)}
            user={user}
            actions={actions}
          />
        );
      case 'payAtDoor':
        return (
          <PayAtDoorComponent
            translation={translation}
            selectedCardId={selectedCardId}
            handleClickSelect={(cardId, type) => this.handleClickCard(cardId, type)}
          />
        );
      default:
        return (
          <TableBody>
            <TableRow>
              <Typography>
                {translation('PaymentPage.noPaymentOption')}
              </Typography>
            </TableRow>
          </TableBody>
        );
    }
  };

  getPaymentOption = (cardId) => {
    const { paymentOptions } = this.props;
    const { type, newCard } = this.state;

    if (type === 'newCard') {
      return newCard;
    }
    return paymentOptions && paymentOptions.find(option => option.id === cardId);
  };

  handleAddCard = (paymentOption) => {
    const { actions, user } = this.props;
    if (paymentOption && paymentOption.id) {
      this.setState({ type: 'payNow', selectedCardId: paymentOption.id });
      try {
        actions.getAllResources(user.token, ['users', user.id, 'payment_options']);
      } catch (error) {
        console.log('API call error while getting companies', error);
      }
    }
  }

  processPayment = async () => {
    const {
      actions,
      user,
      currentOrder,
      history,
    } = this.props;
    const { selectedCardId, type, points } = this.state;
    const paymentOption = this.getPaymentOption(selectedCardId);

    let paymentMethod;
    switch (type) {
      case 'payNow':
        // TODO: Need to get the cvn from user!
        paymentMethod = {
          id: paymentOption.id,
          nonce: paymentOption.vaultKey,
          cvn: '123',
        };
        break;
      case 'payAtDoor':
        paymentMethod = {
          name: selectedCardId,
        };
        break;
      case 'newCard':
        paymentMethod = {
          name: paymentOption.name,
          cardNumber: paymentOption.cardNumber,
          cvc: paymentOption.cvc,
          expiryMonth: paymentOption.expiryDate.slice(0, 2),
          expiryYear: paymentOption.expiryDate.slice(2),
          streetNumber: paymentOption.streetNameNumber.split(/ (.*)/)[0],
          streetName: paymentOption.streetNameNumber.split(/ (.*)/)[1],
        };
        break;
      default: break;
    }

    if (user && paymentMethod && currentOrder.id) {
      try {
        // Create paymentOption if we're using a new card.
        if (type === 'newCard') {
          const newCardResponse = await actions
            .addResource(
              user.token, paymentMethod,
              'users', user.id,
              'payment_options',
            );
          if (!newCardResponse || newCardResponse.error) return;
          paymentMethod = {
            id: newCardResponse.id,
            nonce: newCardResponse.vaultKey,
            cvn: '123',
          };
        }

        this.setState({ openTipDialog: false });

        const paymentResponse = await actions
          .makePayment(user, paymentMethod, [
            'users', user.id,
            'orders', currentOrder.id,
            'place',
          ]);
        if (!paymentResponse || paymentResponse.error) return;

        // Update user's points (user.points) if order was successfully placed
        // Only update redux state, no API calls needed. Need to make a copy
        // of the existing user object to make sure no other changes are made.
        const updatedUserObj = Immutable.asMutable(user, { deep: true });
        updatedUserObj.points = points;
        const updateUserResponse = actions.updateUser(user.token, updatedUserObj);
        if (!updateUserResponse || updateUserResponse.error) return;

        history.push(Routes.path.orderStatusPage);
      } catch (error) {
        console.log('API call error', error);
      }
    }
  };

  handleClickCard = (selectedCardId, type) => {
    // TODO: Reset text fields in NewCardComponent
    this.setState({ selectedCardId, type });
  };

  handleChangeInTextField = (newCard) => {
    // When user enters some text or selects the checkbox for new card information,
    // existing payment options should no longer be selected by default
    this.setState({ selectedCardId: null, type: 'newCard', newCard });
  };

  handleOpenDeleteDialog = (cardId) => {
    this.setState({ openDeleteDialog: true, cardToDelete: cardId });
  };

  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteDialog: false });
  };

  handleClickDeleteCard = async () => {
    const { actions, user } = this.props;
    const { cardToDelete } = this.state;
    if (user) {
      this.handleCloseDeleteDialog();
      try {
        await actions.deleteResource(user, ['users', user.id, 'payment_options', cardToDelete]);
      } catch (error) {
        console.log('API call error', error);
      }
    }
  };

  render() {
    const {
      loading,
      classes,
      translation,
      user,
      actions,
      currentOrder,
    } = this.props;
    const {
      selectedCardId,
      activeTab,
      type,
      openDeleteDialog,
      openTipDialog,
      cardToDelete,
    } = this.state;
    const paymentOptionToDelete = this.getPaymentOption(cardToDelete);
    const totalPrice = currentOrder.totalPrice || 0;
    const menuLinkProps = { hrefLink: '/checkout', text: translation('RedirectLinkComponent.checkoutLink') };
    const isDesktop = Functions.isDesktopMode(this.props.width);
    const LinkComponent = CheckoutDrawerLink;

    return (
      loading !== 0
        ? <LinearProgress />
        : user && (
          <div className={classes.pageContent}>
            <PageTitle title={translation('PaymentPage.title')} />
            <div className={isDesktop ? classes.body : classes.bodyMobile}>
              <PaymentMethodComponent
                translation={translation}
                getContent={selectedTab => this.getTabContent(selectedTab)}
                activeTab={activeTab}
                setActiveTab={tab => this.setState({ activeTab: tab })}
              />
              {
                openDeleteDialog
                  && (
                    <DeleteDialog
                      open={openDeleteDialog}
                      translation={translation}
                      cardType={paymentOptionToDelete && paymentOptionToDelete.cardType}
                      cardName={paymentOptionToDelete && paymentOptionToDelete.cardNumber}
                      handleClick={() => this.handleClickDeleteCard()}
                      handleCloseDeleteDialog={() => this.handleCloseDeleteDialog()}
                    />
                  )
              }
              <OrderSummaryComponent
                actions={actions}
                user={user}
                translation={translation}
                paymentOption={this.getPaymentOption(selectedCardId)}
                selectedCardId={selectedCardId}
                activeTab={activeTab}
                type={type}
                openTipDialog={() => this.setState({ openTipDialog: true })}
                processPayment={() => this.processPayment()}
              />
              {
                openTipDialog
                  && (
                    <TipDialog
                      open={openTipDialog}
                      user={user}
                      actions={actions}
                      processPayment={() => this.processPayment()}
                      handleClose={() => this.setState({ openTipDialog: false })}
                      translation={translation}
                      totalPrice={totalPrice}
                      currentOrderId={currentOrder.id}
                    />
                  )
              }
            </div>
            <div className={classes.redirectLinkContainerStyle}>
              <LinkComponent {...this.props} menuLinkProps={menuLinkProps} />
            </div>
          </div>
        )
    );
  }
}

PaymentPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  paymentOptions: PropTypes.arrayOf(PropTypes.any),
  currentOrder: PropTypes.objectOf(PropTypes.any),
  width: PropTypes.string,
};

PaymentPage.defaultProps = {
  location: {},
  user: null,
  paymentOptions: [],
  currentOrder: {},
  width: '',
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
  paymentOptions: getCurrentUserPaymentOptions(state),
  resources: getResources(state),
});

export default connect(mapStateToProps)(withStyles(styles)(withRouter(withWidth()(PaymentPage))));
