import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import withWidth from '@material-ui/core/withWidth';
import {
  Hidden,
  Typography,
  AppBar,
} from '@material-ui/core';

import Button from '../../core/components/Button';
import * as Routes from '../../../services/routes/Routes.json';
import * as FeatureFlags from '../../../../feature-flags.json';
import styles from '../../../css/core/components/HeaderComponent.scss';
import ShoppingCart from './ShoppingCart';
import NotificationIconComponent from './NotificationIconComponent';
import AnnouncementSnackbar from '../components/AnnouncementSnackbar';
import AppDrawer from '../components/AppDrawer';
import * as Functions from '../../../services/functions/Functions';
import * as JsonResponsiveStyles from '../../../../jsonStyles/components/core/components/HeaderComponent.style.json';
import CheckoutDrawer from '../../checkout/CheckoutDrawer';
import PaymentDrawer from '../../core/components/PaymentDrawer';

// Return styles object when testing
let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.appBar = {
  ...responsiveStyles.appBar,
  [theme.breakpoints.down('sm')]: JsonResponsiveStyles.appBar,
};

class HeaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  onSandwichClick = () => {
    const { handleDrawerOpen } = this.props;
    handleDrawerOpen();
  }

  /**
   * Parse emergency title and message from currentOrder.location if both values
   * have been set else return null
   * @param {*} currentOrder
   */
  getEmergencyAnnouncement = () => {
    const { currentOrder } = this.props;
    const announcement = { title: '', message: '' };
    if (currentOrder && currentOrder.location) {
      if (currentOrder.location.emergencyTitle && currentOrder.location.emergencyMessage) {
        announcement.title = currentOrder.location.emergencyTitle;
        announcement.message = currentOrder.location.emergencyMessage;
      }
    }
    return announcement;
  };

  renderAnnouncement = () => {
    const { actions } = this.props;
    return (
      FeatureFlags.AnnouncementSnackbar.show
       && (
       <AnnouncementSnackbar
         openMessageSnackbar
         actions={actions}
         message={this.getEmergencyAnnouncement()}
       />
       )
    );
  }

  render() {
    const { anchorEl } = this.state;
    const {
      actions,
      handleClickOptions,
      handleClickAuthentication,
      options,
      externalOptions,
      logo,
      user,
      selectedPage,
      classes,
      translation,
      appDrawerOpen,
      history,
      handleDrawerClose,
      width,
    } = this.props;
    const isDesktop = Functions.isDesktopMode(this.props.width);
    const useAltLink = FeatureFlags.CoreView.HeaderComponent.useAlternateLogoLink;
    let allOptions = (FeatureFlags.CoreView.HeaderComponent.showExtraLinks)
      ? options.concat(externalOptions) : options;
    if (user && user.guestToken) {
      allOptions = Functions.filterGuestOptions(allOptions);
    }
    return (
      <div>
        <AppDrawer
          appDrawerOpen={appDrawerOpen}
          translation={translation}
          history={history}
          externalOptions={externalOptions}
          handleClickAuthAppDrawer={handleClickAuthentication}
          handleClickDrawerOptions={handleClickOptions}
          user={user}
          onHandleDrawerClose={handleDrawerClose}
          actions={actions}
        />
        <AppBar
          position="absolute"
          className={classes.appBar}
        >
          {
            !isDesktop
            && (
              <IconButton
                className={classes.menuButton}
                color="primary"
                aria-label="Menu"
                onClick={() => this.onSandwichClick()}
              >
                <Icon style={responsiveStyles.menuButton}>menu</Icon>
              </IconButton>
            )
          }
          <a href={useAltLink ? Routes.path.altLogoLink : Routes.path.menuPage}>
            <img
              src={logo.src}
              alt={translation(logo.alt)}
              className={isDesktop ? classes.logo : classes.logo_mobile}
            />
          </a>
          <Hidden smDown>
            <div className={classes.optionsContainer}>
              {
                allOptions.map(option => (
                  option.isExternal
                  ? (
                    <div
                      key={option.id}
                      className={classes.optionButton}
                    >
                      <a
                        style={{ color: responsiveStyles.selectedPage.default, textDecoration: 'none' }}
                        href={option.href}
                        target={option.target}
                      >
                        {translation(option.title)}
                      </a>
                    </div>
                  ) : (
                    <Typography
                      key={option.id}
                      className={classes.optionButton}
                      style={{
                        fontWeight: selectedPage === translation(option.key)
                        && responsiveStyles.selectedPage.fontWeight,
                        color: selectedPage === translation(option.key)
                          && responsiveStyles.selectedPage.selected,
                      }}
                      onClick={() => handleClickOptions(translation(option.key))}
                    >
                      {
                        option.src
                        ? <img src={option.src} alt={translation(option.alt)} />
                        : null
                      }
                      {translation(option.title)}
                    </Typography>
                  )
                ))
              }
            </div>
          </Hidden>
          <div className={classes.accountContainer}>
            <div className={classes.accountButtonContainer}>
              {
                // TODO-REFACTOR: Make this a functional component to improve readability
                user
                  && (
                    <Fragment>
                      <div className={classes.loggedInUserContainer}>
                        <Typography className={classes.loggedInUserWelcomeText}>
                          {translation('HeaderComponent.welcomeText')}
                        </Typography>
                        <Typography className={classes.loggedInUserText} >
                          {user.name}
                        </Typography>
                      </div>
                      <div className={classes.loggedInUserContainer}>
                        <Typography className={classes.loggedInUserWelcomeText}>
                          {translation('HeaderComponent.notYou')}
                        </Typography>
                        <div
                          className={classes.SignOutButton}
                          id="signOut"
                        >
                          <Typography
                            onClick={() => handleClickAuthentication(user)}
                            className={classes.SignOutText}
                          >
                            {translation('HeaderComponent.signOut')}
                          </Typography>
                        </div>
                      </div>
                    </Fragment>
                  )
                }
              {
                !user && !Functions.isMobileMode(width)
                && (
                  <Typography
                    onClick={() => handleClickAuthentication(user)}
                    className={classes.createAccountText}
                  >
                    {translation('HeaderComponent.signIn')}
                  </Typography>
                )
              }
            </div>
            {
              user && !user.guestToken && !Functions.isMobileMode(width)
              && (
                <NotificationIconComponent
                  iconStyleProps={classes.notificationIconStyle}
                />
              )
            }
            <ShoppingCart
              onHandleClick={handleClickAuthentication}
              onHandleOpenCheckoutDrawer={() => actions.toggleComponent('CheckoutDrawer')}
              user={user}
            />
          </div>
          {FeatureFlags.AnnouncementSnackbar.show && this.renderAnnouncement()}
          <CheckoutDrawer
            handleClickAuthentication={handleClickAuthentication}
            history={history}
            actions={actions}
            user={user}
            isDesktop={isDesktop}
            translation={translation}
          />
          {FeatureFlags.CoreView.PaymentDrawer.show && (
            <PaymentDrawer
              history={history}
              actions={actions}
              user={user}
              isDesktop={isDesktop}
              translation={translation}
            />
          )}
        </AppBar >
      </div>
    );
  }
}

HeaderComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleClickOptions: PropTypes.func.isRequired,
  handleClickAuthentication: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  externalOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  logo: PropTypes.objectOf(PropTypes.string).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  headersubMenuOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedPage: PropTypes.string,
  translation: PropTypes.func.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  appDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
  currentOrder: PropTypes.objectOf(PropTypes.any),
  width: PropTypes.string,
  handleDrawerClose: PropTypes.func.isRequired,
};

HeaderComponent.defaultProps = {
  user: null,
  selectedPage: '',
  currentOrder: {},
  width: null,
};

export default withStyles(responsiveStyles)(withWidth()(HeaderComponent));
