import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import responsiveFontSizes from '@material-ui/core/styles/responsiveFontSizes';
import withWidth from '@material-ui/core/withWidth';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import * as Functions from '../../../services/functions/Functions';
import styles from '../../../css/checkout/subComponents/InvalidOrderItemsDialog.scss';
import * as ResponsiveStyles from '../../../../jsonStyles/components/checkout/subComponents/InvalidOrderItemsDialog.json';
import DialogView from '../../core/components/DialogView';
import Logo from '../../../assets/images/primary_logo.svg';
import * as Selectors from '../../../selectors/index';

const pageStyles = typeof styles === 'function' ? styles() : styles;

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const responsiveStyles = Functions.getResponsiveStyles(pageStyles, theme, ResponsiveStyles);

const renderDialogContent = dialogContentProps => () => {
  const {
    invalidatedOrderItems,
    translation,
    classes,
    width,
    deliveryOption,
    itemIdToWithProductNameMap,
  } = dialogContentProps;

  const invalidatedItemNames = Object.keys(invalidatedOrderItems).map((orderItemId) => {
    const { itemId, name } = invalidatedOrderItems[orderItemId];
    const descriptiveName = Functions.getDescriptiveItemName(itemId, itemIdToWithProductNameMap, name);
    return <Typography className={classes.itemName}>{descriptiveName}</Typography>;
  });

  const mainBody = translation('MenuPage.deliveryOptionInvalidProduct');
  const errorMessage = `${mainBody} ${deliveryOption}.`;

  const dialogTextStyle = Functions.getMobileFriendlyStyle({ classes, width }, 'deliveryOptionInvalidProductTitle');

  return (
    <div className={classes.dialogContentContainer}>
      <div className={classes.topMessageContainer}>
        <Typography className={dialogTextStyle}>
          {errorMessage}
        </Typography>
      </div>
      <div className={classes.outerCenteringFixedHeightDiv}>
        <div className={classes.itemNameScrollMaxHeightDiv}>
          {invalidatedItemNames}
        </div>
      </div>
    </div>
  );
};

const renderActionButtons = actionButtonProps => () => {
  const {
    translation,
    classes,
    navigateBack,
    handleChange,
  } = actionButtonProps;

  return (
    <div className={classes.actionButtonContainer}>
      <Button
        className={classes.backButton}
        onClick={navigateBack}
      >
        <ArrowBackIcon className={classes.buttonIcon} />
        {translation('BACK')}
      </Button>
      <Button
        className={classes.nextButton}
        onClick={handleChange}
      >
        {translation('REMOVE')}
        <ArrowForwardIcon className={classes.buttonIcon} />
      </Button>
    </div>
  );
};

const InvalidOrderItemsDialog = (props) => {
  const {
    invalidatedOrderItems,
    deliveryOption,
    open,
    onCloseDialog,
    translation,
    handleChange,
    dialogLoading,
    navigateBack,
    classes,
    width,
    itemIdToWithProductNameMap,
  } = props;

  const commonProps = { translation, classes, width };
  const dialogContentProps = { ...commonProps, invalidatedOrderItems, deliveryOption, itemIdToWithProductNameMap };
  const actionButtonProps = { ...commonProps, navigateBack, handleChange };

  return (
    <DialogView
      open={open}
      titleAlignClose={false}
      handleClose={onCloseDialog}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      subHeaderImageContainerStyle={classes.subHeaderImageContainerStyle}
      subHeaderImageStyle={classes.subHeaderImageStyle}
      titleHasCloseBtn
      dialogTitleSubheaderImage={Logo}
      dialogTitleSubheaderImageAlt={classes.dialogTitleSubheaderImageAlt}
      hasDialogContent
      hasDialogContent2={false}
      hasDialogErrorContent={false}
      renderDialogContent={renderDialogContent(dialogContentProps)}
      hasDialogActions
      renderActionBtn={renderActionButtons(actionButtonProps)}
      dialogCloseIconColor={classes.dialogTitleStyle.color}
      dialogBodyContainerStyle={classes.bodyContainer}
      dialogContentStyle={classes.dialogContentStyle}
      loading={!!dialogLoading}
    />
  );
};

InvalidOrderItemsDialog.propTypes = {
  invalidatedOrderItems: PropTypes.objectOf(PropTypes.object),
  deliveryOption: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
  translation: PropTypes.func,
  handleChange: PropTypes.func,
  dialogLoading: PropTypes.number,
  navigateBack: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  width: PropTypes.string.isRequired,
  itemIdToWithProductNameMap: PropTypes.objectOf(PropTypes.string),
};

InvalidOrderItemsDialog.defaultProps = {
  invalidatedOrderItems: [],
  onCloseDialog: null,
  translation: null,
  dialogLoading: 0,
  navigateBack: null,
  handleChange: null,
  itemIdToWithProductNameMap: null,
};

const mapStateToProps = state => ({ itemIdToWithProductNameMap: Selectors.getitemIdToWithProductNameMap(state) });

export default connect(mapStateToProps)(withStyles(responsiveStyles)(withWidth()(InvalidOrderItemsDialog)));

