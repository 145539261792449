
    module.exports = function cssToMuiLoader(theme) {
      
      return {
        
        'iconButton': {'zIndex': `130`,'position': `fixed`,'backgroundColor': `#dcdcdc`,'color': `#000`,'&:hover': {
          'transition': `0.3s`,'color': `#397ba6`,'backgroundColor': `#dcdcdc`,
        },},
        
      };
    };
  