import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Typography, InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

import Button from '../core/components/Button';
import { invertMonthDict } from '../../services/constants/Constants';
import DialogView from '../core/components/DialogView';
import TextBoxComponent from '../core/components/TextBoxComponent';
import TextComponent from '../core/components/TextComponent';
import styles from '../../css/authentication/SignUpDialog.scss';
import * as FeatureFlags from '../../../feature-flags.json';
import * as ResponsiveStyles from '../../../jsonStyles/components/authentication/SignUpDialog.style.json';
import { isValidEmail } from '../../services/functions/Functions';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.actionBtnStyle = {
  ...responsiveStyles.actionBtnStyle,
  [theme.breakpoints.down('sm')]: {
    width: ResponsiveStyles.actionBtnStyle.width,
  },
};

responsiveStyles.loginSignUpButton = {
  ...responsiveStyles.loginSignUpButton,
  [theme.breakpoints.down('sm')]: {
    width: ResponsiveStyles.loginSignUpButton.width,
    height: ResponsiveStyles.loginSignUpButton.height,
  },
};

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  phoneNumber: '',
  error: '',
  birthMonth: '',
  birthDay: '',
};
const signUpFields = [
  {
    autoFocus: true,
    type: 'TextBox',
    title: 'First Name',
    fieldKey: 'firstName',
    fieldType: '',
  },
  {
    autoFocus: false,
    type: 'TextBox',
    title: 'Last Name',
    fieldKey: 'lastName',
    fieldType: '',
  },
  {
    autoFocus: false,
    type: 'TextBox',
    title: 'E-mail',
    fieldKey: 'email',
    fieldType: '',
  },
  {
    autoFocus: false,
    type: 'TextBox',
    title: 'Password',
    fieldKey: 'password',
    fieldType: 'password',
  },
  {
    autoFocus: false,
    type: 'TextBox',
    title: 'Phone Number',
    fieldKey: 'phoneNumber',
    fieldType: '',
  },
  {
    autoFocus: false,
    type: 'Text',
    title: 'We might need your phone number to confirm your order',
    fieldKey: 'phoneNumberText',
  },
];

const birthDateFields = [
  {
    autoFocus: false,
    type: 'Text',
    title: 'birthDayText',
    fieldKey: 'birthDayText',
  },
  {
    autoFocus: true,
    type: 'DayMonth',
    title: 'Birth Date',
    fieldKey: 'birthDate',
    fieldType: '',
  },
];

// TODO: Terms of use page
class SignUpDialog extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  renderTextBox = (field) => {
    return (
      <TextBoxComponent
        key={field.fieldKey}
        {...field}
        field={field}
        selectedValue={this.state[`${field.fieldKey}`]}
        handleChange={
          (fieldItem, selectedItems) => this.handleChange(fieldItem, selectedItems)
        }
        fieldAutoFocus={field.autoFocus}
      />
    );
  }

  renderText = (field) => {
    const { classes, translation } = this.props;
    return (
      <TextComponent
        key={field.fieldKey}
        title={translation(`SignUpDialog.fieldTitles.${field.fieldKey}`)}
        style={classes.dialogContentTextStyle}
      />
    )
  }

  renderDayMonthPicker = (field) => {
    const { classes, translation } = this.props;
    const birthMonthText = translation('SignUpDialog.fieldTitles.birthMonth');
    const birthDayText = translation('SignUpDialog.fieldTitles.birthDay');
    return (
      <div className={classes.dayMonthPicker}>
        <FormControl className={classes.picker}>
          <InputLabel id="monthDropDown">{birthMonthText}</InputLabel>
          <Select
            labelId="monthDropDown"
            id="monthDropDown"
            value={this.state.birthMonth}
            onChange={(event) => this.handleChange({fieldKey: 'birthMonth'}, event.target.value)}
          >
            {
              Object.keys(invertMonthDict).map((month) =>
                <MenuItem value={month}>
                  {translation(`months.${invertMonthDict[month]}`)}
                </MenuItem>
              )
            }
          </Select>
        </FormControl>
        <FormControl className={classes.picker}>
          <InputLabel id="dayDropdown">{birthDayText}</InputLabel>
          <Select
            labelId="dayDropdown"
            id="dayDropdown"
            value={this.state.birthDay}
            onChange={(event) => this.handleChange({fieldKey: 'birthDay'}, event.target.value)}
          >
            {
              [...Array(31).keys()].map((index) =>
                <MenuItem value={index+1}>
                  {index+1}
                </MenuItem>
              )
            }
          </Select>
        </FormControl>
      </div>
    );
  }

  renderFormField = {
    "TextBox": this.renderTextBox,
    "Text": this.renderText,
    "DayMonth": this.renderDayMonthPicker,
  }

  getProps = () => {
    const { classes, translation } = this.props;
    const formFields = (FeatureFlags.SignupDialog.useBirthDate) ? signUpFields.concat(birthDateFields) : signUpFields;
    return (
      {
        dialogTitle: {
          dialogTitleStyle: classes.dialogTitleStyle,
          dialogTitleText: '',
          dialogTitleSubheader: '',
          dialogTitleSubheaderImageAlt: translation('CoreView.header.logoAlt'),
          subHeaderImageContainerStyle: classes.subHeaderImageContainerStyle,
          subHeaderImageStyle: classes.subHeaderImageStyle,
        },
        dialogBodyContainerStyle: classes.dialogBodyContainerStyle,
        dialogContentStyle: classes.dialogContentStyle,
        dialogContent: (
          <form className={classes.formContainer} noValidate autoComplete="off">
            {
              formFields.map((field) => {
                return (this.renderFormField[field.type](field))
              })
            }
          </form>
        ),
        dialogContent2: (
          <div className={classes.dialogContent2}>
            <Typography>
              <a target="_blank" rel="noopener noreferrer" href="https://craverapp.com/terms-and-conditions/" className={classes.dialogContent2Link}>{translation('SignUpDialog.dialogContent2.termsOfUse')}</a>
            </Typography>
          </div>
        ),
        dialogActions: {
          actionBtnStyle: classes.actionBtnStyle,
          actionButtons: (
            <Button
              id="createAccount"
              fullWidth
              type="primary"
              onClick={this.handleCreateAccount}
              variant={responsiveStyles.loginSignUpButton.variant}
              disabled={this.disableButton()}
              text={translation('SignUpDialog.dialogActions.createAccount')}
            />
          ),
        },
      });
  };

  handleChange = (field, selectedItem) => {
    this.setState({
      [field.fieldKey]: selectedItem,
    });
  };

  handleCreateAccount = async () => {
    const { actions, translation } = this.props;
    const {
      firstName, lastName, email, password, phoneNumber, birthDay, birthMonth,
    } = this.state;

    // Only check email on front-end, API will handle phone number validation
    if (!isValidEmail(email)) {
      actions.setErrorSnackbarMessage(translation('SignUpDialog.errorTexts.invalidEmail'));
      return;
    }

    const userObj = {
      name: `${firstName} ${lastName}`,
      email,
      password,
      telephone: phoneNumber,
      birthDay,
      birthMonth,
    };

    const response = await actions.signUpUser(userObj);

    if (!response.error) {
      this.handleClose({ dialog: 'signup', eventSource: 'signUpSuccess' });
    }
  };

  handleClose = (dialogObj = { eventSource: 'closeIcon' }) => {
    this.setState(initialState);
    this.props.handleClose(dialogObj);
  }

  disableButton = () => {
    const {
      firstName, lastName, email, password, phoneNumber,
    } = this.state;
    return isEmpty(firstName && lastName && email && password && phoneNumber);
  };

  render() {
    const { open } = this.props;
    const stepPros = this.getProps();
    const {
      dialogTitle,
      dialogContent,
      dialogContent2,
      dialogActions,
      dialogBodyContainerStyle,
      dialogContentStyle,
    } = stepPros;

    return (
      <DialogView
        open={open}
        titleAlignClose={false}
        handleClose={() => this.handleClose()}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        dialogTitleStyle={dialogTitle.dialogTitleStyle}
        subHeaderImageContainerStyle={dialogTitle.subHeaderImageContainerStyle}
        subHeaderImageStyle={dialogTitle.subHeaderImageStyle}
        dialogBodyContainerStyle={dialogBodyContainerStyle}
        dialogContentStyle={dialogContentStyle}
        titleHasCloseBtn
        dialogTitleText={dialogTitle.dialogTitleText}
        dialogTitleSubheaderImage={dialogTitle.dialogTitleSubheaderImage}
        dialogTitleSubheaderImageAlt={dialogTitle.dialogTitleSubheaderImageAlt}
        hasDialogContent
        hasDialogContent2
        hasDialogErrorContent={false}
        renderDialogContent={() => dialogContent}
        renderDialogContent2={() => dialogContent2}
        hasDialogActions
        actionBtnStyle={dialogActions.actionBtnStyle}
        renderActionBtn={() => dialogActions.actionButtons}
        dialogCloseIconColor={dialogTitle.dialogTitleStyle.color}
      />
    );
  }
}

SignUpDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
};

export default withStyles(responsiveStyles)(SignUpDialog);
