const handleResponse = (request, isPlain) =>
  fetch(request).then((response) => {
    if (response.status > 399) {
      if (response.status === 502) {
        return Promise.reject(new Error('Cannot process your request. Please try again in a few seconds.'));
      }
    }
    if (response.status === 204) {
      return (response.text()).then(data => ({ data, response }));
    }
    return (!isPlain ? response.json() : response.text()).then(data => ({ data, response }));
  })
    .then(({ data, response }) => {
      if (!response.ok) {
        const error = {
          message: !isPlain ? data.message : data,
          status: data.status,
        };
        return Promise.reject(error);
      }
      const body = {
        data,
        token: response.headers.get('api_token'),
      };
      return Promise.resolve(body);
    }).catch((error) => {
      if (error.message === 'Failed to fetch') {
        return Promise.reject(new Error('Server is not responding'));
      }
      return Promise.reject(error);
    });

export default handleResponse;
