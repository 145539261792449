
    module.exports = function cssToMuiLoader(theme) {
      
      return {
        
        'btn': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,},'btnPrimary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#a76e1b`,'color': `#ffffff`,
        },},'btnSecondary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#ffffff`,'color': `#d18a22`,'&:hover': {
          'backgroundColor': `#fff2e0`,'color': `#d18a22`,
        },},'btnTertiary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#70a0c3`,'color': `#ffffff`,
        },},'linkText': {'color': `#d18a22`,'cursor': `pointer`,'fontWeight': `bold`,'textDecoration': `underline`,'margin': `0 8px`,'fontSize': `0.75rem`,'fontFamily': `'Lato-Bold', sans-serif`,},'dayMonthPicker': {'display': `flex`,'flexDirection': `row`,'width': `100%`,'margin': `8px 0`,},'picker': {'width': `50%`,'display': `flex`,'flexDirection': `column`,},'dialogTitleStyle': {'padding': `0`,'textAlign': `center`,'color': `#282828`,'fontSize': `30px`,'variant': `title`,},'dialogBodyContainerStyle': {'width': `80%`,'maxWidth': `400px`,},'dialogContentStyle': {'textAlign': `center`,'width': `100%`,'padding': `0`,'color': `#282828`,},'dialogContentTextStyle': {'marginTop': `24px`,'fontSize': `14px`,'fontStyle': `italic`,},'subHeaderImageContainerStyle': {'padding': `0px 80px 0 80px`,'fontSize': `14px`,'fontWeight': `bold`,'variant': `subheading`,},'subHeaderImageStyle': {'width': `200px`,'maxWidth': `30vw`,'maxHeight': `100px`,},'actionBtnStyle': {'margin': `45px auto 25px auto`,'width': `auto`,'padding': `0px`,},'formContainer': {'display': `flex`,'flexWrap': `wrap`,'flexDirection': `column`,'alignItems': `center`,},'dialogContent2': {'display': `flex`,'flexDirection': `row`,'justifyContent': `center`,'marginBottom': `50px`,},'dialogContent2Link': {'color': `#d18a22`,'fontFamily': `'Lato-Regular', sans-serif`,},
        
      };
    };
  