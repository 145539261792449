import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import {
  string,
  func,
  objectOf,
} from 'prop-types';

import styles from '../../../css/core/components/LinkText.scss';

const componentStyles = typeof styles === 'function' ? styles() : styles;

const LinkText = (props) => {
  const {
    onClick, className, text, style, classes, id,
  } = props;
  return (
    <Typography
      id={id}
      onClick={onClick}
      className={className || classes.linkText}
      style={style}
    >
      {text}
    </Typography>
  );
};

LinkText.propTypes = {
  classes: objectOf(string),
  text: string.isRequired,
  className: string,
  onClick: func.isRequired,
  style: string,
  id: string,
};

LinkText.defaultProps = {
  classes: {},
  className: null,
  style: null,
  id: null,
};

export default withStyles(componentStyles)(LinkText);
