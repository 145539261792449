import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Typography, IconButton, Icon, Badge } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import { connect } from 'react-redux';

import * as Routes from '../../../services/routes/Routes.json';
import { getCurrentOrder } from '../../../selectors';
import styles from '../../../css/core/components/ShoppingCart.scss';
import * as FeatureFlags from '../../../../feature-flags.json';
import { getMobileFriendlyStyle, getCurrencySymbol, getOrderPrice } from '../../../services/functions/Functions';

const pageStyles = typeof styles === 'function' ? styles() : styles;

class ShoppingCart extends React.Component {
  handleClick = () => {
    const {
      onHandleClick, user, onHandleOpenCheckoutDrawer,
    } = this.props;
    if (!user) {
      const updateCoreViewState = {
        cartIconWasPressed: true,
      };
      onHandleClick(null, updateCoreViewState);
      return;
    }
    onHandleOpenCheckoutDrawer();
  }

  renderCheckoutIcon = (props) => {
    const {
      itemsQuantity,
      mobileFriendlyStyleProps,
    } = props;

    const checkoutIconStyles = getMobileFriendlyStyle(mobileFriendlyStyleProps, 'checkoutIcon');

    return (
      <Badge badgeContent={itemsQuantity} color={pageStyles.cartBadge.color}>
        <Icon className={checkoutIconStyles}>shopping_cart</Icon>
      </Badge>
    );
  }

  renderCheckoutText = (props) => {
    const { classes } = this.props;
    const {
      mobileFriendlyStyleProps,
      orderPrice,
    } = props;

    const checkoutTextStyles = getMobileFriendlyStyle(mobileFriendlyStyleProps, 'checkoutText');

    return (
      <div className={classes.checkoutContainer}>
        <Typography variant="h5" className={checkoutTextStyles}>
          {`${getCurrencySymbol()}${orderPrice.toFixed(2)}`}
        </Typography>
      </div>
    );
  }

  render() {
    const {
      classes,
      currentOrder,
      width,
    } = this.props;

    const orderPrice = getOrderPrice(currentOrder);
    let itemsQuantity = 0;

    if (currentOrder) {
      itemsQuantity = currentOrder.items ? currentOrder.items.length : 0;
    }

    const mobileFriendlyStyleProps = { classes, width };
    const shoppingCartLabelStyles = getMobileFriendlyStyle(mobileFriendlyStyleProps, 'shoppingCartLabel');

    const checkoutLabelProps = {
      itemsQuantity,
      mobileFriendlyStyleProps,
      orderPrice,
    };

    return (
      <div id="headerCart" className={classes.shoppingCartContainer}>
        <IconButton
          className={classes.redirectLink}
          onClick={this.handleClick}
        >
          <div className={shoppingCartLabelStyles}>
            { this.renderCheckoutIcon(checkoutLabelProps) }
            { this.renderCheckoutText(checkoutLabelProps) }
          </div>
        </IconButton>
      </div>
    );
  }
}

ShoppingCart.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  onHandleClick: PropTypes.func.isRequired,
  currentOrder: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any),
  onHandleOpenCheckoutDrawer: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
});

ShoppingCart.defaultProps = {
  currentOrder: {},
  user: null,
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(withWidth()(ShoppingCart))));
