import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import { TextField, Typography } from '@material-ui/core';
import Button from '../core/components/Button';
import DialogView from '../core/components/DialogView';
import TextBoxComponent from '../core/components/TextBoxComponent';
import styles from '../../css/authentication/ResetPasswordDialog.scss';
import * as ResponsiveStyles from '../../../jsonStyles/components/authentication/ResetPasswordDialog.style.json';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.actionBtnStyle = {
  ...responsiveStyles.actionBtnStyle,
  [theme.breakpoints.down('sm')]: {
    width: ResponsiveStyles.actionBtnStyle.width,
  },
};

const textFieldInputs = [
  {
    autoFocus: true,
    id: 'recoveryToken1',
    position: 1,
  },
  {
    autoFocus: false,
    id: 'recoveryToken2',
    position: 2,
  },
  {
    autoFocus: false,
    id: 'recoveryToken3',
    position: 3,
  },
  {
    autoFocus: false,
    id: 'recoveryToken4',
    position: 4,
  },
];

const dialogContent = [
  {
    step: 0,
    dialogContentText: [
      {
        id: 0, type: 'normal', text: 'ResetPasswordDialog.step1.instruction.text1',
      },
    ],
    fields: [
      {
        type: 'TextBox',
        title: 'E-mail',
        fieldKey: 'email',
      },
    ],
    dialogAction: 'ResetPasswordDialog.step1.button',
    dialogContent2: 'ResetPasswordDialog.step1.instruction.text2',
  },
  {
    step: 1,
    dialogContentText: [
      {
        id: 0, type: 'emailVerify', text: 'ResetPasswordDialog.step2.instruction.text1',
      },
      {
        id: 1, type: 'normal', text: 'ResetPasswordDialog.step2.instruction.text2',
      },
    ],
    textFieldInputs,
    fields: [
      {
        type: 'TextBox',
        title: 'New Password',
        fieldKey: 'newPassword',
        fieldType: 'password', // for material ui text field type prop
      },
    ],
    dialogAction: 'ResetPasswordDialog.step2.button',
    dialogContent2: 'ResetPasswordDialog.step2.newToken',
  },
];

const initialState = {
  step: 0,
  email: '',
  newPassword: '',
  recoveryToken: '',
  recoveryToken1: '',
  recoveryToken2: '',
  recoveryToken3: '',
  recoveryToken4: '',
};

class ResetPasswordDialog extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  getDialogContent = () => {
    const { classes, translation } = this.props;
    const { step, email } = this.state;
    const currentDialog = dialogContent[step];
    const instructions = currentDialog.dialogContentText;
    const tokenInputs = currentDialog.textFieldInputs;

    return (
      <div className={classes.dialogContentContainer}>
        {
          instructions && instructions.length > 0
          && (
            instructions.map(instruction => (
              instruction.type === 'emailVerify'
                ? (
                  <div key={instruction.id}>
                    <Typography style={responsiveStyles.disableLineBreak}>
                      {translation(instruction.text)}
                    </Typography>
                    &nbsp;
                    <Typography className={classes.emphasizedInlineText}>{email}</Typography>
                    <Typography style={responsiveStyles.disableLineBreak}>.</Typography>
                  </div>
                )
                : <Typography key={instruction.id}>{translation(instruction.text)}</Typography>
              ))
          )
        }
        {
          tokenInputs
          && (
            <div className={classes.textFieldContainer}>
              {
                textFieldInputs.map(fieldInput => (
                  <TextField
                    key={fieldInput.id}
                    autoFocus={fieldInput.autoFocus}
                    className={classes.textFieldStyle}
                    inputProps={{
                      className: classes.inputStyle,
                      maxLength: 1,
                    }}
                    InputProps={{
                      disableUnderline: true,
                      inputMode: 'numeric',
                    }}
                    inputRef={input => this.setInputRef(input, fieldInput.position)}
                    id={fieldInput.id}
                    value={this.state[fieldInput.id]}
                    onChange={e => this.handleTokenInputChange(e, fieldInput.id)}
                  />
                ))
              }
            </div>
          )
        }
        <form className={classes.formContainer} noValidate autoComplete="off">
          {
            currentDialog.fields.map((field) => {
              const FieldType = this.getComponentTypeTag(field.type);

              return (
                <FieldType
                  key={field.fieldKey}
                  {...field}
                  field={field}
                  fieldAutoFocus={false}
                  selectedValue={this.state[`${field.fieldKey}`]}
                  handleChange={
                    (fieldItem, selectedItems) => this.handleChange(fieldItem, selectedItems)
                  }
                />
              );
            })
          }
        </form>
      </div>
    );
  }

  getButtonId = (step) => {
    switch (step) {
      case 0:
        return 'getCode';
      case 1:
        return 'resetPassword';
      default:
        return '';
    }
  }

  getActionButton = () => {
    const { translation } = this.props;
    const { step } = this.state;
    const currentDialog = dialogContent[step];

    return (
      <Button
        type="primary"
        fullWidth
        onClick={this.getNextStep(step)}
        id={this.getButtonId(step)}
        disabled={this.disableButton()}
        text={translation(currentDialog.dialogAction)}
      />
    );
  }

  getDialogContent2 = () => {
    const { classes, translation } = this.props;
    const { step } = this.state;
    const currentDialog = dialogContent[step];

    return (
      <div className={classes.dialogContent2}>
        <Typography>
          {
            step === 1
              ? (
                <a href="#" className={classes.dialogContent2Link} onClick={() => this.sendRecoveryToken()}>
                  {translation(currentDialog.dialogContent2)}
                </a>
              )
              : translation(currentDialog.dialogContent2)
          }
        </Typography>
      </div>
    );
  };

  getComponentTypeTag = (fieldType) => {
    switch (fieldType) {
      case 'TextBox':
        return TextBoxComponent;
      default:
        return TextBoxComponent;
    }
  };

  getNextStep = (step) => {
    switch (step) {
      case 0:
        return () => this.handleGetCode();
      case 1:
        return () => this.handleResetPassword();
      default:
        return () => this.handleInvalidNextStep();
    }
  };

  setInputRef = (input, position) => {
    switch (position) {
      case 2:
        this.token2Input = input;
        break;
      case 3:
        this.token3Input = input;
        break;
      case 4:
        this.token4Input = input;
        break;
      default:
        this.token1Input = input;
        break;
    }
  };

  getRecoveryTokenFromInputFields = () => {
    const {
      recoveryToken1, recoveryToken2, recoveryToken3, recoveryToken4,
    } = this.state;
    const recoveryToken = `${recoveryToken1}${recoveryToken2}${recoveryToken3}${recoveryToken4}`;
    if (recoveryToken) {
      this.setState({
        recoveryToken,
      });
    }
    return recoveryToken;
  };

  handleChange = (field, selectedItem) => {
    this.setState({
      [field.fieldKey]: selectedItem,
    });
  };

  handleClose = (openVerifyDialog, userEmail, closeDialogOptions = { eventSource: 'closeIcon' }) => {
    const defaultCloseOptions = {
      dialog: 'reset',
      openVerifyDialog,
      userEmail,
    };
    const finalCloseDialogOptions = Object.assign(defaultCloseOptions, closeDialogOptions);
    this.setState(initialState);
    this.props.handleClose(finalCloseDialogOptions);
  };

  handleGetCode = () => {
    this.sendRecoveryToken();
  };

  handleResetPassword = () => {
    const recoveryToken = this.getRecoveryTokenFromInputFields();
    this.resetPassword(recoveryToken);
  };

  handleInvalidNextStep = () => {
    this.props.actions.clearMessage();
    this.setState({ step: 0 });
  };

  handleTokenInputChange = (e, fieldName) => {
    if (!this.state[fieldName]) {
      switch (fieldName) {
        case 'recoveryToken1':
          this.token2Input.focus();
          break;
        case 'recoveryToken2':
          this.token3Input.focus();
          break;
        case 'recoveryToken3':
          this.token4Input.focus();
          break;
        default:
          break;
      }
    }

    this.setState({
      [fieldName]: e.target.value,
      errorText: '',
    });
  };

  sendRecoveryToken = async () => {
    const { email } = this.state;
    const { actions } = this.props;
    const userTokenObj = { email };

    const response = await actions.sendResetPasswordToken(userTokenObj, ['users', 'forgot_password']);

    if (response && !response.error) {
      this.setState({ step: 1 });
    }
  };

  resetPassword = async (recoveryToken) => {
    const {
      email, newPassword,
    } = this.state;
    const { actions, sendUserToCheckout, currentOrder } = this.props;
    const userTokenObj = {
      email,
      password: newPassword,
      token: recoveryToken,
    };

    const response = await actions.resetPassword(userTokenObj, ['users', 'recover']);
    if (response && !response.error) {
      const { loggedInUser, unverifiedUser } = response;
      if (loggedInUser) {
        if (currentOrder && currentOrder.items) {
          await actions.createOrder(loggedInUser, currentOrder);
        }
        sendUserToCheckout();
        this.handleClose(false, undefined, { eventSource: 'resetSuccess' });
      } else if (unverifiedUser) {
        this.handleClose(true, unverifiedUser.email, { eventSource: 'unverifiedUserOnReset' });
      }
    } else if (response.error) {
      this.setState({ step: 1 });
    }
  };

  disableButton = () => {
    const {
      step,
      email,
      newPassword,
      recoveryToken1,
      recoveryToken2,
      recoveryToken3,
      recoveryToken4,
    } = this.state;

    const disableStep0Button = step === 0 && !email;
    const disableStep1Button = step === 1
      && isEmpty(newPassword
        && recoveryToken1
        && recoveryToken2
        && recoveryToken3
        && recoveryToken4);
    return disableStep0Button || disableStep1Button;
  };

  render() {
    const { open, classes, dialogLoading } = this.props;

    return (
      <DialogView
        open={open}
        titleAlignClose={false}
        handleClose={() => this.handleClose()}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        dialogTitleStyle={classes.dialogTitleStyle}
        dialogBodyContainerStyle={classes.dialogBodyContainerStyle}
        dialogContentStyle={classes.dialogContentStyle}
        titleHasCloseBtn
        hasDialogContent
        hasDialogContent2
        hasDialogErrorContent={false}
        renderDialogContent={() => this.getDialogContent()}
        renderDialogContent2={() => this.getDialogContent2()}
        hasDialogActions
        actionBtnStyle={classes.actionBtnStyle}
        renderActionBtn={() => this.getActionButton()}
        dialogCloseIconColor={responsiveStyles.dialogTitleStyle.color}
        loading={!!dialogLoading}
      />
    );
  }
}

ResetPasswordDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  dialogLoading: PropTypes.number,
  sendUserToCheckout: PropTypes.func.isRequired,
  currentOrder: PropTypes.objectOf(PropTypes.any).isRequired,
};

ResetPasswordDialog.defaultProps = {
  dialogLoading: 0,
};

export default withStyles(responsiveStyles)(ResetPasswordDialog);
