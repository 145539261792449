import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import {
  objectOf,
  string,
  bool,
  func,
  any,
} from 'prop-types';

import {
  getCurrentOrder,
  getCurrentOrderItems,
} from '../../../selectors';
import { priceAndTaxSummary } from '../../../services/constants/Constants';
import styles from '../../../css/core/components/SubTotalSummary.scss';
import * as Functions from '../../../services/functions/Functions';
import * as FeatureFlags from '../../../../feature-flags.json';

// eslint-disable-next-line no-unused-vars
let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

const PriceItem = (props) => {
  const {
    item, currentOrder, translation, classes, id,
  } = props;
  let itemAmount = item.getValueAmount(currentOrder);
  if (Number.isNaN(itemAmount)) itemAmount = 0;
  const discountSign = item.isDiscount ? '-' : '';
  if (item.optional && !itemAmount) return null;
  const priceText = `${discountSign}${Functions.getFormattedPrice(itemAmount)}`;
  return (
    <div id={id} className={classes.priceItem} >
      <Typography className={item.isTotal && classes.totalPriceText}>{translation(item.title)}</Typography>
      <Typography className={item.isTotal && classes.totalPriceText}>{priceText}</Typography>
    </div>
  );
};

const PriceAndTaxItems = (props) => {
  const { showTotal } = props;
  const { tipAmount, ...priceAndTaxSummaryNoTip } = priceAndTaxSummary;
  const priceAndTaxSummaryItems = FeatureFlags.CheckoutDrawer.enableTipping ? priceAndTaxSummary : priceAndTaxSummaryNoTip;
  if (showTotal) {
    priceAndTaxSummaryItems.total = {
      isTotal: true,
      title: 'CheckoutDrawer.summary.total',
      getValueAmount: order => order.totalPrice,
    };
  } else {
    delete priceAndTaxSummaryItems.total;
  }
  return Object.keys(priceAndTaxSummaryItems).map(key => <PriceItem {...props} id={key} item={priceAndTaxSummaryItems[key]} key={key} />);
};

const SubTotalSummary = (props) => {
  const { classes } = props;
  return (
    <div className={classes.priceSummary}>
      <PriceAndTaxItems {...props} />
    </div>
  );
};

PriceItem.propTypes = {
  item: objectOf(any).isRequired,
  classes: objectOf(string).isRequired,
  currentOrder: objectOf(any).isRequired,
  translation: func.isRequired,
  id: string.isRequired,
};

PriceItem.defaultProps = {
};

SubTotalSummary.propTypes = {
  classes: objectOf(string).isRequired,
  currentOrder: objectOf(any).isRequired,
  translation: func.isRequired,
  showTotal: bool,
};

SubTotalSummary.defaultProps = {
  showTotal: false,
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
  currentOrderItems: getCurrentOrderItems(state),
});

const EnhancedSubTotalSummary = compose(
  connect(mapStateToProps),
  withStyles(responsiveStyles),
)(SubTotalSummary);
export default EnhancedSubTotalSummary;
