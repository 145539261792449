
    module.exports = function cssToMuiLoader(theme) {
      
      return {
        
        'btn': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,},'btnPrimary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#a76e1b`,'color': `#ffffff`,
        },},'btnSecondary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#ffffff`,'color': `#d18a22`,'&:hover': {
          'backgroundColor': `#fff2e0`,'color': `#d18a22`,
        },},'btnTertiary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#70a0c3`,'color': `#ffffff`,
        },},'linkText': {'color': `#d18a22`,'cursor': `pointer`,'fontWeight': `bold`,'textDecoration': `underline`,'margin': `0 8px`,'fontSize': `0.75rem`,'fontFamily': `'Lato-Bold', sans-serif`,},'appBar': {'height': `100px`,'flexDirection': `row`,'justifyContent': `space-between`,'alignItems': `center`,'padding': `0 10vw`,'backgroundColor': `#ffffff`,'flexGrow': `1`,},'optionsContainer': {'display': `flex`,'flexDirection': `row`,'justifyContent': `center`,'alignItems': `center`,},'optionButton': {'color': `#000000`,'flex': `'1 0 auto'`,'margin': `0 4px`,'width': `auto`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `15px`,'&:hover': {
          'cursor': `pointer`,'color': `#d18a22`,
        },},'selectedPage': {'default': `#000000`,'selected': `#d18a22`,'fontWeight': `bold`,},'menu': {'top': `70px`,},'logo': {'marginTop': `0px`,'marginLeft': `20px`,'width': `auto`,'height': `80px`,'maxWidth': `180px`,},'logo_mobile': {'marginTop': `0px`,'marginLeft': `0px`,'width': `auto`,'maxWidth': `120px`,'height': `70px`,},'accountContainer': {'display': `flex`,'flexDirection': `row`,'justifyContent': `space-between`,'alignItems': `center`,},'createAccountContainer': {'flexDirection': `column`,'alignItems': `center`,'marginRight': `18px`,},'nonMemberText': {'color': `#000000`,'fontSize': `14px`,'fontWeight': `300`,'fontFamily': `'Lato-Regular', sans-serif`,},'createAccountText': {'color': `#d18a22`,'fontSize': `14px`,'cursor': `pointer`,'textDecoration': `underline`,'fontFamily': `'Lato-Regular', sans-serif`,'&:hover': {
          'color': `#d18a22`,
        },},'btnSecondaryLoggedIn': {'maxWidth': `100px`,'maxHeight': `35px`,'borderRadius': `3px`,'backgroundColor': `#ffffff`,'fontSize': `14px`,'color': `#d18a22`,},'loggedInUserContainer': {'display': `flex`,'flexDirection': `row`,'paddingRight': `10px`,'width': `auto`,},'loggedInUserWelcomeText': {'color': `#000000`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `0.8rem`,'textAlign': `right`,},'loggedInUserText': {'textTransform': `none`,'color': `#000000`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `0.8rem`,'width': `100%`,'textAlign': `left`,},'menuButton': {'color': `#000000`,},'SignOutButton': {'padding': `0px`,'width': `auto`,'height': `auto`,},'SignOutText': {'textDecoration': `underline`,'textTransform': `none`,'color': `#000000`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `0.8rem`,'cursor': `pointer`,},'expandIcon': {'color': `#000000`,},'accountButtonContainer': {'width': `auto`,'display': `flex`,'flexDirection': `column`,'justifyContent': `space-evenly`,},'accountButton': {'width': `100px`,},'notificationIconStyle': {'color': `#282828`,'paddingRight': `8px`,'paddingBottom': `5px`,},
        
      };
    };
  