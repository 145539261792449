import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { Button, Icon, Typography } from '@material-ui/core';
import styles from '../../../css/menuPage/components/OrderInfoBanner.scss';
import * as FeatureFlags from '../../../../feature-flags.json';
import {
  checkBasicOrderInfo,
  getMobileFriendlyStyle,
  getTime,
  formatEpoch,
  isDesktopMode,
  renderDesiredTime,
} from '../../../services/functions/Functions';
import { getCurrentOrder } from '../../../selectors';
import { PICKUP, DELIVERY, DINE_IN, CATERING } from '../../../services/constants/Constants';
import * as JsonResponsiveStyles from '../../../../jsonStyles/components/menuPage/components/OrderInfoBanner.style.json';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.container = {
  ...responsiveStyles.container,
  [theme.breakpoints.down('sm')]: JsonResponsiveStyles.container,
};

const getOptionIcon = (props) => {
  const { currentOrder, classes } = props;
  const { deliveryOption } = currentOrder;

  const icons = {};
  icons[PICKUP] = 'directions_walk';
  icons[DELIVERY] = 'directions_car';
  icons[CATERING] = 'directions_car';
  icons[DINE_IN] = 'directions_walk';

  return deliveryOption
    ? (
      <Icon className={classes.infoIcon}>
        {icons[currentOrder.deliveryOption]}
      </Icon>
    )
    : null;
};

const renderAddressOrLocation = (props) => {
  // eslint-disable-next-line react/prop-types
  const { classes, currentOrder } = props;

  let addressOrLocation = '';
  const isDelivery = [DELIVERY, CATERING].includes(currentOrder.deliveryOption);

  // We will only assign a value if the order
  // has location for pickup or address for delivery.
  if ((isDelivery && currentOrder.address) || (!isDelivery && currentOrder.location)) {
    addressOrLocation = isDelivery
      ? currentOrder.address.streetAddress
      : currentOrder.location.name;
  }

  return (
    <Typography className={classes.infoText}>{addressOrLocation}</Typography>
  );
};

const renderEmptyOrder = (props) => {
  // eslint-disable-next-line react/prop-types
  const {
    classes,
    handleClick,
    translation,
    width,
  } = props;

  
  const linkTextStyles = getMobileFriendlyStyle({ classes, width }, 'linkText');

  return (
    <div className={classes.infoContainer}>
      <Typography className={classes.infoText}>{translation('MenuPage.orderInfoBanner.infoTextEmpty')}</Typography>
      <Typography
        id="selectHere"
        onClick={() => handleClick()}
        className={linkTextStyles}
      >
        {translation('MenuPage.orderInfoBanner.linkTextEmpty')}
      </Typography>
    </div>
  );
};

const renderOrder = (props) => {
  // eslint-disable-next-line react/prop-types
  const {
    classes,
    currentOrder,
    handleClick,
    isDesktop,
    translation,
    width,
  } = props;

  const mobileFriendlyStyleProps = { classes, width };
  const infoSubContainerStyles = getMobileFriendlyStyle(mobileFriendlyStyleProps, 'infoSubContainer');
  const infoSubContainerItemStyles = getMobileFriendlyStyle(mobileFriendlyStyleProps, 'infoSubContainerItem');
  const linkTextStyles = getMobileFriendlyStyle(mobileFriendlyStyleProps, 'linkText');

  return (
    <div className={classes.infoContainer}>
      <div className={infoSubContainerStyles}>
        <div className={infoSubContainerItemStyles}>
          { getOptionIcon(props) }
          <Typography className={classes.infoText}>{translation(currentOrder.deliveryOption)}</Typography>
          { renderAddressOrLocation(props) }
        </div>
        { isDesktop && <Typography className={classes.infoText}>|</Typography> }
        <div className={infoSubContainerItemStyles}>
          <Icon className={classes.infoIcon}>access_time</Icon>
          { renderDesiredTime(props) }
        </div>
      </div>
      <Typography
        onClick={() => handleClick()}
        className={linkTextStyles}
        id="editOrder"
      >
        { translation('MenuPage.orderInfoBanner.linkTextDefault') }
      </Typography>
    </div>
  );
};

const OrderInfoBanner = (props) => {
  const {
    classes,
    currentOrder,
    width,
  } = props;
  const hasAllInfo = checkBasicOrderInfo(currentOrder);
  const isDesktop = isDesktopMode(width);

  return (
    <div className={classes.container}>
      {
        hasAllInfo
        ? renderOrder({ ...props, isDesktop, width })
        : renderEmptyOrder(props)
      }
    </div>
  );
};

OrderInfoBanner.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  currentOrder: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
});

export default connect(mapStateToProps)(withStyles(responsiveStyles)(withWidth()(OrderInfoBanner)));
