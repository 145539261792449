import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import * as FeatureFlags from '../../../../feature-flags.json';
import * as ResponsiveStyles from '../../../../jsonStyles/components/paymentPage/subComponents/PaymentItemComponent.style.json';
import styles from '../../../css/paymentPage/subComponents/PaymentItemComponent.scss';
import ImagePlaceholder from '../../../assets/images/image-placeholder.png';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.itemImage = {
  ...responsiveStyles.itemImage,
  [theme.breakpoints.down('sm')]: {
    height: ResponsiveStyles.itemImage.height,
    width: ResponsiveStyles.itemImage.width,
  },
};

/**
 * Get name from productItem.name without the size prefix
 * @param {*} productItem the productItem object
 */
const getName = (productItem) => {
  const name = get(productItem, 'name', '');
  const { attribute_value } = productItem;
  return name.replace(attribute_value, '');
};

const PaymentItemComponent = (props) => {
  const { classes, translation, orderItems } = props;
  const showProductImage = FeatureFlags.PaymentItemComponent
    && FeatureFlags.PaymentItemComponent.showProductImage;

  return (
    <div className={classes.itemsContainer}>
      <Typography className={classes.title}>
        {translation('PaymentItemComponent.orderItems')}
      </Typography>
      {
        orderItems
        && orderItems.map(orderItem => (
          <div className={classes.itemContainer} key={orderItem.id}>
            {
              showProductImage
                && (
                  <div>
                    <img
                      className={classes.itemImage}
                      src={
                        orderItem.productItem.images && orderItem.productItem.images.length > 0
                          ? orderItem.productItem.images[0].src
                          : ImagePlaceholder
                      }
                      alt={translation('PaymentItemComponent.productImageAlt')}
                    />
                  </div>
                )
            }
            <div className={classes.textContainer}>
              <Typography className={classes.itemName}>{getName(orderItem.productItem)}</Typography>
              <Typography className={classes.itemQuantity}>
                {translation('PaymentItemComponent.quantity')}: {orderItem.quantity}
              </Typography>
            </div>
          </div>
        ))
      }
    </div>
  );
};

PaymentItemComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  orderItems: PropTypes.arrayOf(PropTypes.object),
};

PaymentItemComponent.defaultProps = {
  orderItems: [],
};

export default (withStyles(responsiveStyles)(PaymentItemComponent));
