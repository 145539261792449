
    module.exports = function cssToMuiLoader(theme) {
      
      return {
        
        'productContainer': {'position': `relative`,},'featurePizzaIcon': {'position': `absolute`,'width': `122px`,'height': `131px`,'objectFit': `contain`,},'basicCard': {'display': `flex`,'flexDirection': `column`,'alignItems': `center`,'justifyContent': `space-between`,'padding': `0% 0%`,'marginBottom': `5%`,'backgroundColor': `#ffffff`,'width': `auto`,'boxShadow': `0 1px 2px 0 rgba(0, 0, 0, 0.3)`,'borderRadius': `5`,'&:hover': {
          'cursor': `pointer`,
        },},'cardWithDescription': {'display': `flex`,'flexDirection': `column`,'alignItems': `center`,'justifyContent': `space-between`,'padding': `0% 0%`,'marginBottom': `5%`,'backgroundColor': `#ffffff`,'width': `auto`,'boxShadow': `0 1px 2px 0 rgba(0, 0, 0, 0.3)`,'borderRadius': `5`,'minHeight': `410px`,'&:hover': {
          'cursor': `pointer`,
        },},'cardNoDescription': {'display': `flex`,'flexDirection': `column`,'alignItems': `center`,'justifyContent': `space-between`,'padding': `0% 0%`,'marginBottom': `5%`,'backgroundColor': `#ffffff`,'width': `auto`,'boxShadow': `0 1px 2px 0 rgba(0, 0, 0, 0.3)`,'borderRadius': `5`,'minHeight': `330px`,'&:hover': {
          'cursor': `pointer`,
        },},'media': {'width': `100%`,'height': `180px`,'objectFit': `cover`,'borderRadius': `0%`,},'header': {'display': `flex`,'flexDirection': `column`,'alignItems': `center`,'padding': `1rem 0 0 0`,'height': `auto`,'width': `100%`,},'titleContainer': {'display': `flex`,'justifyContent': `center`,'alignItems': `center`,'fontSize': `1.2rem`,'flexDirection': `column`,},'titleText': {'fontFamily': `'Lato-Regular', sans-serif`,'margin': `0`,'fontWeight': `bold`,'textAlign': `center`,'color': `#282828`,'fontSize': `18px`,},'productIcon': {'paddingLeft': `5px`,'maxWidth': `22px`,'maxHeight': `14px`,},'subheader': {'color': `#d18a22`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `16px`,'fontWeight': `bold`,},'removeTopPadding': {'paddingTop': `0`,'textAlign': `center`,},'description': {'color': `#282828`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `12px`,'textAlign': `center`,'fontStyle': `normal`,'height': `auto`,},'oufOfStockOverlay': {'display': `flex`,'flexDirection': `column`,'justifyContent': `center`,'alignItems': `center`,'position': `absolute`,'backgroundColor': `rgba(0, 0, 0, 0.8)`,'width': `100%`,'minHeight': `100%`,'zIndex': `99`,},'outOfStockIcon': {'color': `#ffffff`,},'outOfStockText': {'color': `#ffffff`,'fontSize': `1.3rem`,'fontFamily': `'Lato-Regular', sans-serif`,'textAlign': `center`,},'cardHeaderContainer': {'display': `flex`,'flexDirection': `column`,'alignItems': `center`,'width': `85%`,'padding': `16px 16px 8px 16px`,},'iconsContainer': {'display': `flex`,'flexDirection': `row`,},
        
      };
    };
  