import React, { useState, useEffect } from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { objectOf, string, number } from 'prop-types';
import styles from '../../../css/core/components/ScrollToTop.scss';

const pageStyles = typeof styles === 'function' ? styles() : styles;

function ScrollToTop(props) {
  const { classes, showBelow } = props;
  const [show, setShow] = useState(!showBelow);

  const handleScroll = () => {
    // eslint-disable-next-line no-undef
    if (window.scrollY > showBelow) {
      if (!show) setShow(true);
    } else setShow(false);
  };

  useEffect(() => {
    if (showBelow) {
      // eslint-disable-next-line no-undef
      window.addEventListener('scroll', handleScroll);
      // eslint-disable-next-line no-undef
      return () => window.removeEventListener('scroll', handleScroll);
    }
    return undefined;
  });

  const handleClick = () => {
    // eslint-disable-next-line no-undef
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    show
    && (
      <IconButton
        onClick={handleClick}
        className={classes.iconButton}
      >
        <ExpandLessIcon />
      </IconButton>
    )
  );
}

ScrollToTop.propTypes = {
  classes: objectOf(string).isRequired,
  showBelow: number.isRequired,
};

export default withStyles(pageStyles)(ScrollToTop);
