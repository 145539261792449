import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import {
  Typography,
  Icon,
  Badge,
} from '@material-ui/core';
import {
  func,
  objectOf,
  string,
  any,
} from 'prop-types';

import * as Actions from '../../../actions/Actions';
import { getCurrentOrder } from '../../../selectors';
import styles from '../../../css/menuPage/components/FloatingCartButton.scss';
import { getCurrencySymbol, getOrderPrice } from '../../../services/functions/Functions';

// eslint-disable-next-line no-unused-vars
let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

const FloatingCartButton = (props) => {
  const { classes, currentOrder, toggleCheckoutDrawer } = props;
  const itemsQuantity = get(currentOrder, 'items.length', 0);
  const orderPrice = getOrderPrice(currentOrder);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      id="floatingCart"
      role="button"
      className={classes.container}
      onClick={() => toggleCheckoutDrawer()}
      tabIndex={0}
    >
      <Badge badgeContent={itemsQuantity} color="primary" classes={{ badge: classes.cartBadge }} >
        <Icon className={classes.checkoutTextStyles}>shopping_cart</Icon>
      </Badge>
      <Typography className={classes.checkoutTextStyles}>
        {`${getCurrencySymbol()}${orderPrice.toFixed(2)}`}
      </Typography>
    </div>
  );
};

FloatingCartButton.propTypes = {
  classes: objectOf(string).isRequired,
  currentOrder: objectOf(any).isRequired,
  toggleCheckoutDrawer: func.isRequired,
};

FloatingCartButton.defaultProps = {
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
});

const mapDispatchToProps = dispatch => ({
  toggleCheckoutDrawer: () => dispatch(Actions.toggleComponent('CheckoutDrawer')),
});

const EnhancedFloatingCartButton = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(responsiveStyles),
)(FloatingCartButton);
export default EnhancedFloatingCartButton;
