
    module.exports = function cssToMuiLoader(theme) {
      
      return {
        
        'btn': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,},'btnPrimary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#a76e1b`,'color': `#ffffff`,
        },},'btnSecondary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#ffffff`,'color': `#d18a22`,'&:hover': {
          'backgroundColor': `#fff2e0`,'color': `#d18a22`,
        },},'btnTertiary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#70a0c3`,'color': `#ffffff`,
        },},'linkText': {'color': `#d18a22`,'cursor': `pointer`,'fontWeight': `bold`,'textDecoration': `underline`,'margin': `0 8px`,'fontSize': `0.75rem`,'fontFamily': `'Lato-Bold', sans-serif`,},'sideBar': {'width': `250px`,'flexShrink': `0`,},'drawerToolbarContainer': {'padding': `40px`,'backgroundColor': `#000000`,'display': `flex`,'flexDirection': `column`,'justifyContent': `space-between`,'height': `100%`,},'drawerToolbarElement': {'color': `#ffffff`,'paddingLeft': `0`,},'drawerToolbarElementText': {'color': `#ffffff`,},'drawerToolbarElementTextSelected': {'color': `#e31b23`,},'optionsText': {'color': `#9b9b9b`,'fontSize': `12px`,'marginBottom': `12px`,},'copyrightText': {'color': `#9b9b9b`,'fontSize': `12px`,},'divider': {'marginTop': `4px`,'marginBottom': `4px`,'backgroundColor': `#9b9b9b`,},'iconButton': {'marginLeft': `18px`,'padding': `0`,},'iconButtonTypography': {'color': `#000000`,'fontSize': `16px`,},'drawerToolBarSection': {'justifyContent': `center`,'alignItems': `center`,'textAlign': `center`,'marginTop': `12px`,'marginBottom': `12px`,},'nonMemberText': {'color': `#000000`,'fontSize': `14px`,'fontWeight': `300`,},'createAccountText': {'color': `#000000`,'fontSize': `14px`,},'createAccountContainer': {'flexDirection': `column`,'alignItems': `center`,'textAlign': `center`,},'accountContainer': {'display': `flex`,'flexDirection': `row`,'justifyContent': `center`,'alignItems': `center`,},'loggedInUserContainer': {'display': `flex`,'flexDirection': `row`,'paddingRight': `15px`,'paddingLeft': `15px`,},'loggedInUserWelcomeText': {'color': `#000000`,},'loggedInUserText': {'textDecoration': `underline`,'color': `#000000`,},
        
      };
    };
  