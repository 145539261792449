import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';

import Button from '../../core/components/Button';
import styles from '../../../css/paymentPage/components/PayNowComponent.scss';
import PaymentBoxComponent from '../subComponents/PaymentBoxComponent';
import NewCardComponent from '../subComponents/NewCardComponent';
import SquarePaymentComponent from '../subComponents/SquarePaymentComponent';
import SquareGiftCardComponent from '../subComponents/SquareGiftCardComponent';
import ToastPaymentComponent from '../subComponents/ToastPaymentComponent';
import AmexIcon from '../../../assets/images/american-express-1.png';
import CreditCardIcon from '../../../assets/images/credit-card.svg';
import GiftCardIcon from '../../../assets/images/primary_logo.svg';
import InteracIcon from '../../../assets/images/interac_icon.png';
import MasterIcon from '../../../assets/images/master.svg';
import VisaIcon from '../../../assets/images/visa.svg';
import SelectedMasterIcon from '../../../assets/images/master-selected.svg';
import SelectedVisaIcon from '../../../assets/images/visa_selected.svg';
import * as ResponsiveStyles from '../../../../jsonStyles/components/paymentPage/components/PayNowComponent.style.json';
import {
  getCurrentOrder, getCurrentUserPaymentOptions, getResources,
} from '../../../selectors';
import {
  getMobileFriendlyStyle,
  isDesktopMode,
} from '../../../services/functions/Functions';
import TabBar from '../../core/components/TabBar';
import * as FeatureFlags from '../../../../feature-flags.json';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.cardName = {
  ...responsiveStyles.cardName,
  [theme.breakpoints.down('sm')]: {
    fontSize: ResponsiveStyles.cardName.fontSize,
  },
};

responsiveStyles.cardDetails = {
  ...responsiveStyles.cardDetails,
  [theme.breakpoints.down('sm')]: {
    fontSize: ResponsiveStyles.cardDetails.fontSize,
  },
};

class PayNowComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewCardForm: false,
      showNewPaymentButton: true,
      paymentType: 'NONE',
      currentTab: 'CREDIT_CARD',
    };
  }

  componentDidMount() {
    this.getPaymentType();
  }

  onClickDisplayForm = () => {
    const { showNewCardForm, showNewPaymentButton } = this.state;
    if (showNewCardForm === false) this.setState({ showNewCardForm: true, showNewPaymentButton: false });
  };

  getPaymentType() {
    const { resources } = this.props;
    if (resources.companies) {
      const company = resources.companies;
      this.setState({ paymentType: company.paymentIntegration });
    }
  }

  getPaymentImage = (card, isSelected) => {
    if (card.fundingSource === 'debit') {
      return InteracIcon;
    }
    if (card.cardType) {
      switch (card.cardType.toLowerCase()) {
        case 'amex':
          return AmexIcon;
        case 'gift_card':
          return GiftCardIcon;
        case 'mastercard':
          return isSelected ? SelectedMasterIcon : MasterIcon;
        case 'visa':
          return isSelected ? SelectedVisaIcon : VisaIcon;
        default:
          // This is just the default credit card icon
          return CreditCardIcon;
          break;
      }
    }
  };

  getCardContent = (paymentOption, isSelected) => {
    const {
      classes,
      width,
    } = this.props;

    const {
      cardNumber,
      cardType,
      expiryDate,
      name,
    } = paymentOption;

    const isDesktop = isDesktopMode(width);
    const cardDetails = isDesktop
      ? [ `${expiryDate} | ${cardNumber}` ]
      : [ cardNumber, expiryDate ];

    return (
      <div>
        <Typography
          classes={{ root: classes.cardName }}
          style={{ color: isSelected && responsiveStyles.selected.color }}
        >
          {paymentOption.name || paymentOption.cardType}
        </Typography>
        {
          cardDetails.map((detail) =>
            (
              <Typography
                classes={{ root: classes.cardDetails }}
                style={{ color: isSelected && responsiveStyles.selected.color }}
              >
                { detail }
              </Typography>
            )
          )
        }
      </div>
    );
  };

  getTabs = () => ([{
    id: 'CREDIT_CARD',
    label: this.props.translation('PaymentMethodComponent.creditCard'),
  }, {
    id: 'GIFT_CARD',
    label: this.props.translation('PaymentMethodComponent.giftCard'),
  }]);

  renderGiftCardForm = () => {
    const {
      currentOrder,
    } = this.props;
    const hasSquareLocation = currentOrder.location && currentOrder.location.squareId;

    if (hasSquareLocation) return this.renderSquareGiftCardForm();

    // Render some other non-square gift card form
    // Since we don't have any we'll return null for now.
    return null;
  }

  renderPaymentForm = () => {
    const { paymentType } = this.state;
    const {
      classes,
      translation,
      selectedCardId,
      currentOrder,
      handleChange,
      actions,
      user,
    } = this.props;
    const hasSquareLocation = currentOrder.location && currentOrder.location.squareId;

    switch (paymentType) {
      case 'SQUARE':
        if (hasSquareLocation) return this.renderSquareForm();
        break;
      case 'TOAST':
        return this.renderToastForm();
      default:
        return (
          <NewCardComponent
            translation={translation}
            handleChange={handleChange}
            selectedCardId={selectedCardId}
            actions={actions}
            handleAddCard={this.props.handleAddCard}
            user={user}
          />
        );
    }
  }

  renderToastForm = () => {
    const { user, translation, actions } = this.props;
    return (
      <ToastPaymentComponent
        translation={translation}
        actions={actions}
        user={user}
        handleAddCard={this.props.handleAddCard}
      />
    );
  }

  renderSquareForm = () => {
    const { classes, translation, actions, currentOrder, user } = this.props;
    const { showNewCardForm } = this.state;

    return (
      showNewCardForm
        && (
          <div>
            <Typography className={classes.sqInstructions}>
              {translation('PayNowComponent.squareInstructions')}
            </Typography>
            <SquarePaymentComponent
              translation={translation}
              user={user}
              actions={actions}
              currentOrder={currentOrder}
            />
          </div>
        )
    );
  };

  renderSquareGiftCardForm() {
    const {
      translation,
      currentOrder,
      actions,
      user,
    } = this.props;
    return (
      <SquareGiftCardComponent
        translation={translation}
        user={user}
        actions={actions}
        currentOrder={currentOrder}
      />
    );
  }

  render() {
    const {
      classes,
      currentOrder,
      handleChange,
      handleClickSelect,
      handleOpenDeleteDialog,
      paymentOptions,
      selectedCardId,
      translation,
      width,
    } = this.props;
    const { showNewPaymentButton, currentTab } = this.state;

    const hasSquareLocation = currentOrder.location && currentOrder.location.squareId;
    const mobileFriendlyStyleProps = { classes, width };
    const bodyTableCellStyles = getMobileFriendlyStyle(mobileFriendlyStyleProps, 'bodyTableCell');

    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={responsiveStyles.placeHolderTableCellStyle} />
            <TableCell className={bodyTableCellStyles}>
              <div className={classes.existingPaymentOptions}>
                {
                  paymentOptions
                  && paymentOptions.length > 0
                  && paymentOptions.map((option) => {
                    const isSelected = option.id === selectedCardId;
                    return (
                      <PaymentBoxComponent
                        key={option.id}
                        translation={translation}
                        paymentOptionId={option.id}
                        selectedCardId={selectedCardId}
                        handleClickSelect={handleClickSelect}
                        image={this.getPaymentImage(option, isSelected)}
                        imageAlt={option.fundingSource}
                        hasMoreOptionsButton
                        hasCardContent
                        cardContent={this.getCardContent(option, isSelected)}
                        handleOpenDeleteDialog={handleOpenDeleteDialog}
                        type="payNow"
                      />
                    );
                  })
                }
              </div>
            </TableCell>
            <TableCell style={responsiveStyles.placeHolderTableCellStyle} />
          </TableRow>
          <TableRow>
            <TableCell style={responsiveStyles.placeHolderTableCellStyle} />
            <TableCell className={bodyTableCellStyles}>
              {
                hasSquareLocation && showNewPaymentButton
                && (
                  <div className={classes.addCardButtonContainer}>
                    <Button
                      type="primary"
                      onClick={() => this.onClickDisplayForm()}
                      text={translation('PayNowComponent.addNewCard')}
                    />
                  </div>
                )
              }
              {
                FeatureFlags.PayNowComponent.enableGiftCard
                && (
                  <TabBar
                    selected={currentTab}
                    onClickSelected={index => this.setState({ currentTab: index })}
                    tabs={this.getTabs()}
                  />
                )
              }
              { currentTab === 'CREDIT_CARD'
                && this.renderPaymentForm()
              }
              {
                currentTab === 'GIFT_CARD'
                && this.renderGiftCardForm()
              }
            </TableCell>
            <TableCell style={responsiveStyles.placeHolderTableCellStyle} />
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

PayNowComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  handleAddCard: PropTypes.func.isRequired,
  selectedCardId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  paymentOptions: PropTypes.arrayOf(PropTypes.any),
  handleClickSelect: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleOpenDeleteDialog: PropTypes.func.isRequired,
  currentOrder: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any),
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  resources: PropTypes.objectOf(PropTypes.any).isRequired,
};

PayNowComponent.defaultProps = {
  selectedCardId: null,
  paymentOptions: [],
  currentOrder: {},
  user: null,
};

const mapStateToProps = state => ({
  currentOrder: getCurrentOrder(state),
  paymentOptions: getCurrentUserPaymentOptions(state),
  resources: getResources(state),
});

export default connect(mapStateToProps)(withStyles(responsiveStyles)(withWidth()(PayNowComponent)));
