import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Table, TableHead, TableBody, TableCell, TableRow, Typography, Icon } from '@material-ui/core';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { DINE_IN } from '../../../services/constants/Constants';
import * as Functions from '../../../services/functions/Functions';
import styles from '../../../css/orderStatusPage/subComponents/StatusComponent.scss';
import * as ResponsiveStyles from '../../../../jsonStyles/components/orderStatusPage/subComponents/StatusComponent.style.json';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.headerTableCellStyle = {
  ...responsiveStyles.headerTableCellStyle,
  [theme.breakpoints.down('sm')]: {
    fontSize: ResponsiveStyles.headerTableCellStyle.fontSize,
  },
};

responsiveStyles.tableCellDeliveryOption = {
  ...responsiveStyles.tableCellDeliveryOption,
  [theme.breakpoints.down('sm')]: {
    fontSize: ResponsiveStyles.tableCellDeliveryOption.fontSize,
  },
};

responsiveStyles.headerTableEstimated = {
  ...responsiveStyles.headerTableEstimated,
  [theme.breakpoints.down('sm')]: {
    fontSize: ResponsiveStyles.headerTableEstimated.fontSize,
  },
};

const showTableNumber = (orderPlaced, classes, translation) => {
  const tableNumberNAMessage = translation('OrderStatusPage.tableNumberNotAvailable');
  const tableNumber = orderPlaced.tableNumber || tableNumberNAMessage;
  const tableNumberTitle = translation('OrderStatusPage.tableNumber');
  return (
    <TableRow>
      <TableCell style={responsiveStyles.placeHolderTableCellStyle} />
      <TableCell className={classes.tableCellTableNumberTitle}>{tableNumberTitle}</TableCell>
      <TableCell className={classes.tableCellTableNumber}>{tableNumber}</TableCell>
      <TableCell style={responsiveStyles.placeHolderTableCellStyle} />
    </TableRow>
  );
};

const StatusComponent = (props) => {
  const {
    classes,
    translation,
    orderPlaced,
  } = props;
  const { deliveryOption } = orderPlaced;
  const formattedDeliveryOption = deliveryOption === DINE_IN
    ? 'DINE IN'
    : deliveryOption;

  return (
    <div className={classes.statusInfo}>
      <Table className={classes.tableStyle}>
        <TableHead>
          <TableRow>
            <TableCell style={responsiveStyles.placeHolderTableCellStyle} />
            <TableCell className={classes.headerTableCellStyle}>
              {translation('OrderStatusPage.statusHeading')}
            </TableCell>
            <TableCell className={classes.headerTableEstimated}>
              {`${translation('OrderStatusPage.estimatedTime')}: `}
              {!isEmpty(orderPlaced) && Functions.formatDesiredTime(orderPlaced.desiredTime)}
            </TableCell>
            <TableCell style={responsiveStyles.placeHolderTableCellStyle} />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={responsiveStyles.placeHolderTableCellStyle} />
            <TableCell className={classes.tableCellDeliveryOption}>
              {formattedDeliveryOption}
            </TableCell>
            <TableCell className={classes.tableCellAddress}>
              <div className={classes.deliveryAddressIcon}>
                {
                  orderPlaced.address
                  ? (
                    <div className={classes.deliveryDetails}>
                      <div>
                        <Icon className={classes.icon}>directions_car</Icon>
                      </div>
                      <div>
                        <Typography className={classes.locationAddress}>
                          {orderPlaced.address.streetAddress},
                          {orderPlaced.address.unitNumber}
                        </Typography>
                        <Typography className={classes.locationAddress}>
                          {orderPlaced.address.city},
                          {orderPlaced.address.postal_code}
                        </Typography>
                      </div>
                    </div>
                  ) : null
                }
              </div>
            </TableCell>
            <TableCell style={responsiveStyles.placeHolderTableCellStyle} />
          </TableRow>
          {deliveryOption === DINE_IN && showTableNumber(orderPlaced, classes, translation)}
        </TableBody>
      </Table>
    </div>
  );
};

StatusComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  orderPlaced: PropTypes.objectOf(PropTypes.any),
};

StatusComponent.defaultProps = {
  orderPlaced: null,
};

export default withStyles(responsiveStyles)(StatusComponent);

