
    module.exports = function cssToMuiLoader(theme) {
      
      return {
        
        'card': {'backgroundColor': `#134F66`,'color': `#0D0D25`,'display': `flex`,'flexDirection': `column`,'alignItems': `center`,'justifyContent': `space-around`,'width': `205px`,'height': `178px`,'padding': `1% 0.5%`,'margin': `0 10px 3% 0`,'cursor': `pointer`,'boxShadow': `none`,},'cardMobile': {'backgroundColor': `#134F66`,'color': `#0D0D25`,'display': `flex`,'flexDirection': `column`,'alignItems': `center`,'justifyContent': `space-around`,'width': `205px`,'height': `178px`,'padding': `1% 0.5%`,'margin': `0 10px 3% 0`,'cursor': `pointer`,'boxShadow': `none`,'flexDirection': `row`,'margin': `0 0 3% 0`,'position': `relative`,'width': `100%`,},'root': {'padding': `0`,},'rootMobile': {'padding': `0`,'width': `40%`,},'selected': {'background': `#49B84C`,'color': `#D9F0DA`,},'moreOptionsButton': {'width': `20px`,'height': `20px`,'marginTop': `20px`,},'moreOptionsButtonContainer': {'position': `absolute`,'top': `5px`,'right': `20px`,},'paymentImageContainer': {'paddingLeft': `20px`,'height': `auto`,},'paymentImage': {'height': `90px`,'width': `100%`,},'contentPadding': {'paddingTop': `0`,'paddingBottom': `0`,'&:last-child': {
          'paddingBottom': `0`,
        },},'optionIcon': {'fontSize': `14px`,},'optionText': {'margin': `0 9px`,'fontSize': `14px`,},'iconButtonStyle': {'padding': `0`,},'actionMenuStyle': {'backgroundColor': `#13AF36`,'color': `#A3AF37`,},
        
      };
    };
  