import React from 'react';
import PropTypes, { arrayOf, objectOf, string, any } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Icon, Badge } from '@material-ui/core';

import * as Routes from '../../../services/routes/Routes.json';
import { getNotifications, getUser } from '../../../selectors';
import { checkForNotifications } from '../../../services/helpers/CustomHooks';
import styles from '../../../css/core/components/NotificationIconComponent.scss';

const pageStyles = typeof styles === 'function' ? styles() : styles;

const getUnreadMessageCount = (notifications) => {
  return notifications
    ? notifications.filter(note => note.isRead === false).length
    : 0;
};

const NotificationCountIcon = (props) => {
  const { notifications, iconStyleProps } = props;
  return (
    <Badge
      badgeContent={getUnreadMessageCount(notifications)}
      color={pageStyles.notificationBadge.color}
    >
      <Icon className={iconStyleProps}>notifications</Icon>
    </Badge>
  );
};

const NotificationBell = (props) => {
  const { user, history, classes } = props;
  checkForNotifications(user.user);

  const handleClick = () => {
    history.push(Routes.path.notificationsPage);
  };

  return (
    <div className={classes.notificationIconContainer}>
      <IconButton
        className={classes.redirectLink}
        onClick={handleClick}
      >
        <NotificationCountIcon
          {...props}
        />
      </IconButton>
    </div>
  );
};

NotificationBell.propTypes = {
  classes: objectOf(string).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
};

NotificationBell.defaultProps = {
  user: null,
};

NotificationCountIcon.propTypes = {
  notifications: arrayOf(objectOf(any)),
  iconStyleProps: PropTypes.string,
};

NotificationCountIcon.defaultProps = {
  notifications: [],
  iconStyleProps: '',
};

const mapStateToProps = state => ({
  notifications: getNotifications(state),
  user: getUser(state),
});

const NotificationIconComponent = compose(
  connect(mapStateToProps),
  withStyles(pageStyles),
)(withRouter(NotificationBell));

export default NotificationIconComponent;
