import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import classNames from 'classnames';

import * as FeatureFlags from '../../../../feature-flags.json';
import * as ResponsiveStyles from '../../../../jsonStyles/components/core/components/CircleFill.style.json';
import circleFillLogo from '../../../assets/images/circleFillLogo.png';
import styles from '../../../css/core/components/CircleFill.scss';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

const CircleFill = (props) => {
  const {
    fillHeight,
    classes,
  } = props;
  const fillHeightPercentage = `${fillHeight}%`;

  return (
    <div className={classes.container}>
      <div className={classes.circle}>
        <div className={classNames(classes.circleFill)} style={{ height: fillHeightPercentage }} />
        <img
          alt="CircleFill-logo"
          className={classes.circleFillLogo}
          src={circleFillLogo}
        />
      </div>
    </div>
  );
};

CircleFill.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  fillHeight: PropTypes.number.isRequired,
};

export default (withStyles(responsiveStyles)(withRouter(CircleFill)));
