import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { withStyles } from '@material-ui/core/styles';
import {
  Checkbox, FormControlLabel, TextField, Typography, Tooltip,
} from '@material-ui/core';

import Button from '../../core/components/Button';
import styles from '../../../css/paymentPage/subComponents/NewCardComponent.scss';

const pageStyles = typeof styles === 'function' ? styles() : styles;

const newCardFields = [
  {
    key: 'name', title: 'NewCardComponent.newCard.name',
  },
  {
    key: 'cardDetails',
    details: [
      {
        key: 'cardNumber',
        title: 'NewCardComponent.newCard.cardDetails.cardNumber',
        fieldStyle: pageStyles.cardNumber,
        maxLength: { maxLength: 16 },
        tooltip: '',
      },
      {
        key: 'cvc',
        title: 'NewCardComponent.newCard.cardDetails.cvc',
        fieldStyle: pageStyles.cvc,
        maxLength: { maxLength: 4 },
        tooltip: 'NewCardComponent.tooltips.cvc',
      },
      {
        key: 'expiryDate',
        title: 'NewCardComponent.newCard.cardDetails.expiryDate',
        fieldStyle: pageStyles.expiryDate,
        mask: 'NewCardComponent.newCard.cardDetails.dateFormatMMYY',
        maxLength: { maxLength: 4 },
        tooltip: '',
      },
    ],
  },
  {
    key: 'streetAddress',
    title: 'NewCardComponent.newCard.address',
    details: [
      {
        key: 'streetNameNumber',
        title: 'NewCardComponent.newCard.address',
        fieldStyle: pageStyles.address,
      },
      {
        key: 'unit',
        title: 'NewCardComponent.newCard.unit',
        fieldStyle: pageStyles.unit,
      },
    ],
    unitDetails: [
      'NewCardComponent.newCard.unitDetails.apt',
      'NewCardComponent.newCard.unitDetails.suite',
      'NewCardComponent.newCard.unitDetails.floor',
      'NewCardComponent.newCard.unitDetails.room',
    ],
  },
  {
    key: 'geographicalArea',
    details: [
      {
        key: 'city',
        title: 'NewCardComponent.newCard.city',
        fieldStyle: pageStyles.city,
      },
      {
        key: 'postalCode',
        title: 'NewCardComponent.newCard.postalCode',
        fieldStyle: pageStyles.postalCode,
      },
    ],
  },
  {
    key: 'phoneNumber',
    title: 'NewCardComponent.newCard.phoneNumber',
    maxLength: { maxLength: 14 },
  },
];

const initialState = {
  name: '',
  cardNumber: '',
  cvc: '',
  expiryDate: '',
  streetNameNumber: '',
  unit: '',
  city: '',
  postalCode: '',
  phoneNumber: '',
  isDefault: 0,
  validation: {
    name: '',
    cardNumber: '',
    cvc: '',
    expiryDate: '',
    phoneNumber: '',
  },
  defaultCardSelected: true,
};

const TextInputMask = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      showMask
      guide={false}
    />
  );
};

class NewCardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidUpdate(prevProps) {
    const { selectedCardId } = this.props;
    if (prevProps.selectedCardId !== selectedCardId) {
      if (selectedCardId) {
        // After entering some text into the New Card Component text fields,
        // if user manually selects an existing payment option again, the
        // text fields should reset to initial state
        this.setState(initialState);
      }
    }
  }

  handleChange = fieldKey => (event) => {
    const { target } = event;
    if (fieldKey !== 'isDefault') {
      this.validateField(fieldKey, target.value);
      this.setState({
        [fieldKey]: target.value,
      }, () => this.handleExistingPaymentOptionSelection());
    } else {
      this.setState({
        [fieldKey]: target.checked,
      }, () => this.handleExistingPaymentOptionSelection());
    }
  };

  handleAddCard = async () => {
    const { name, cardNumber, cvc, expiryDate, streetNameNumber } = this.state;
    const { user, actions } = this.props;
    const paymentMethod = {
      name,
      cardNumber,
      cvc,
      expiryMonth: expiryDate.slice(0, 2),
      expiryYear: expiryDate.slice(2),
      streetNumber: streetNameNumber.split(/ (.*)/)[0],
      streetName: streetNameNumber.split(/ (.*)/)[1],
    };
    const newCardResponse = await actions
      .addResource(
        user.token, paymentMethod,
        'users', user.id,
        'payment_options',
      );
    if (!newCardResponse || newCardResponse.error) return;
    this.props.handleAddCard();
  }

  validateExpiryDate = (value) => {
    const { translation } = this.props;

    const expiryDateOnlyNumbers = /^\d{4}$/;
    if (!value.match(expiryDateOnlyNumbers)) {
      return translation('NewCardComponent.invalidErrorMessages.expiryDate.nonDigits');
    }
    const month = value.substring(0, 2);
    if (month > 12 || month < 1) {
      return translation('NewCardComponent.invalidErrorMessages.expiryDate.invalidMonth');
    }
    const year = value.substring(2, 4);
    const expiryDate = new Date(`20${year}-${month}-01`);
    const currentDate = new Date();
    if (expiryDate < currentDate) {
      return translation('NewCardComponent.invalidErrorMessages.expiryDate.expired');
    }
    return '';
  };

  /**
   * This is a simple checksum validation known as the Luhn (or Mod-10) Algorithm
   * It catches most simple user errors with accidental digit duplication or
   * transposition and prevents invalid numbers from being processed
   * @param {*} value the credit card number as string
   */
  validateCreditCard = (value) => {
    // Only accept digits
    if (/[^0-9]$/.test(value)) return false;
    // Strip non-numeric
    const ccValue = value.replace(/\D/g, '');

    // Luhn Algorithm
    let nCheck = 0;
    let nDigit = 0;
    let bEven = false;

    for (let n = ccValue.length - 1; n >= 0; n -= 1) {
      const checkDigit = ccValue.charAt(n);
      nDigit = parseInt(checkDigit, 10);

      if (bEven) {
        if ((nDigit *= 2) > 9) nDigit -= 9;
      }
      nCheck += nDigit;
      bEven = !bEven;
    }
    return (nCheck % 10) === 0;
  }

  validateField = (fieldKey, value) => {
    const { translation } = this.props;
    const { validation } = this.state;

    // Validation Tests
    const validCvc = /^\d{3,4}$/;
    const validatedExpiryDate = fieldKey === 'expiryDate' && this.validateExpiryDate(value);
    const validPhoneNumber = /^\(\d{3}\) \d{3}-\d{4}$/;
    let fieldInvalidMsg = '';

    switch (fieldKey) {
      case 'cardNumber':
        fieldInvalidMsg = this.validateCreditCard(value) ? '' : translation('NewCardComponent.invalidErrorMessages.cardNumber');
        break;
      case 'cvc':
        fieldInvalidMsg = value.match(validCvc) ? '' : translation('NewCardComponent.invalidErrorMessages.cvc');
        break;
      case 'expiryDate':
        fieldInvalidMsg = validatedExpiryDate;
        break;
      case 'phoneNumber':
        fieldInvalidMsg = value.match(validPhoneNumber) ? '' : translation('NewCardComponent.invalidErrorMessages.phoneNumber');
        break;
      default:
        return; // Terminate function so that no extra fields are appended to the state
    }

    this.setState({
      validation: {
        ...validation,
        [`${fieldKey}`]: fieldInvalidMsg,
      },
    });
  };

  handleExistingPaymentOptionSelection = () => {
    const { handleChange } = this.props;
    const {
      name,
      cardNumber,
      cvc,
      expiryDate,
      streetNameNumber,
      unit,
      city,
      postalCode,
      phoneNumber,
      isDefault,
      defaultCardSelected,
    } = this.state;

    const newCard = {
      name,
      cardNumber,
      cvc,
      expiryDate,
      streetNameNumber,
      unit,
      city,
      postalCode,
      phoneNumber,
    };

    if (name || cardNumber || cvc || expiryDate || streetNameNumber || unit
      || city || postalCode || phoneNumber || isDefault) {
      if (defaultCardSelected) {
        this.setState({ defaultCardSelected: false });
      }
      handleChange(newCard);
    } else {
      this.setState({ defaultCardSelected: true });
    }
  };

  renderTextField = (prop, useMask, unitTitle) => {
    const { translation, classes } = this.props;
    const { validation } = this.state;

    return (
      <TextField
        key={prop.key}
        value={this.state[`${prop.key}`]}
        label={
          (unitTitle && prop.key === 'unit')
            ? unitTitle
            : translation(prop.title)
        }
        placeholder={
          (unitTitle && prop.key === 'unit')
            ? unitTitle
            : translation(prop.mask || prop.title)
        }
        onChange={this.handleChange(prop.key)}
        className={classes.textField}
        style={prop.fieldStyle}
        variant={pageStyles.textField.variant}
        error={!!validation[`${prop.key}`]}
        helperText={validation[`${prop.key}`]}
        InputProps={
          useMask
            ? { inputComponent: TextInputMask }
            : { inputProps: prop.maxLength }
        }
      />
    );
  }

  render() {
    const { classes, translation } = this.props;
    const { isDefault } = this.state;
    return (
      <div className={classes.newCard}>
        <Typography className={classes.title}>{translation('NewCardComponent.title')}</Typography>
        <Typography className={classes.subTitle}>{translation('NewCardComponent.requiredFields')}</Typography>
        {
          newCardFields.map((field) => {
            let unitTitle;
            if (field.unitDetails) {
              const details = field.unitDetails.map(detail => translation(detail));
              unitTitle = details.join('/');
            }

            if ((field.details && field.details.length > 0)
              || (field.key === 'cardDetails' || field.key === 'streetAddress' || field.key === 'geographicalArea')) {
              return (
                <div className={classes.details} key={field.key}>
                  {
                    field.details.map(detail => (
                      <Tooltip title={translation(detail.tooltip)} key={detail.key}>
                        {this.renderTextField(detail, false, unitTitle)}
                      </Tooltip>
                    ))
                  }
                </div>
              );
            }

            if (field.key === 'phoneNumber') {
              return (
                this.renderTextField(field, true)
              );
            }
              return (
                this.renderTextField(field)
              );
          })
        }
        <div className={classes.formFooter}>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!isDefault}
                onChange={this.handleChange('isDefault')}
              />
            }
            label={translation('NewCardComponent.newCard.makeDefault')}
          />
          <Button
            type="primary"
            onClick={() => this.handleAddCard()}
          >
            {translation('save')}
          </Button>
        </div>
      </div>
    );
  }
}

NewCardComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleAddCard: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  selectedCardId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

NewCardComponent.defaultProps = {
  selectedCardId: null,
  user: null,
};

export default withStyles(styles)(NewCardComponent);
