import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Typography } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';

import Button from '../../core/components/Button';
import DialogView from '../../core/components/DialogView';
import styles from '../../../css/paymentPage/subComponents/TipDialog.scss';
import { getMobileFriendlyStyle, getCurrencySymbol } from '../../../services/functions/Functions';
import { tipOptions, smartTipOptions, defaultTipIndex, smartTipThreshold } from '../../../services/constants/Constants';
import * as FeatureFlags from '../../../../feature-flags.json';

const pageStyles = typeof styles === 'function' ? styles() : styles;

const mask = (input) => {
  const digits = input.split('');
  const validDigits = digits.filter((c) => c >= '0' && c <= '9');
  if (validDigits.length < 3) {
    return ['$', /\d/, '.', /\d/, /\d/];
  }
  let mask = [];
  for (let i = validDigits.length - 1; i >= 0; i--) {
    mask.push(/\d/);
    // The decimal point should be shifted one place to the right for new digit to be pushed (e.g. 1.23 -> 12.3_)
    if (i === validDigits.length - 2) {
      mask.push('.');
    }
  }
  // mask length on exit of the loop should be validDigits.length + 1
  mask.push('$');
  return mask.reverse();
};

const TextInputMask = (props) => {
  const { inputRef, ...others } = props; // Exclude passing inputRef to the masked input
  return (
    <MaskedInput {...others} mask={mask} />
  );
};

class TipDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState = () => {
    const initialState = {
      selectedTipOptionId: defaultTipIndex,
      tipAmount: FeatureFlags.PaymentPage.enableSmartTip && (this.props.totalPrice <= smartTipThreshold)
        ? smartTipOptions[defaultTipIndex].value
        : this.calculateTipAmount(tipOptions[defaultTipIndex].value),
      customizedTipAmount: '', // Need this separate field to keep track of the customized tip Text Field
    };
    return initialState;
  };

  getDialogContent = () => {
    const {
      classes,
      translation,
      width,
      totalPrice,
    } = this.props;
    const { selectedTipOptionId } = this.state;

    const mobileFriendlyStyleProps = { classes, width };
    const dialogContentTextStyles = getMobileFriendlyStyle(mobileFriendlyStyleProps, 'dialogContentText');
    const tipDialogOptions = FeatureFlags.PaymentPage.enableSmartTip && (totalPrice <= smartTipThreshold)
      ? smartTipOptions
      : tipOptions;
    return (
      <div className={classes.dialogContentContainer}>
        <Typography className={dialogContentTextStyles}>
          {translation('TipDialog.dialogContent.text')}
        </Typography>
        {
          tipDialogOptions.map((tipOption) => {
            const isSelected = tipOption.id === selectedTipOptionId;
            let tipOptionComponent;
            if (tipOption.key === 'customizeAmount') {
              tipOptionComponent = this.renderCustomizeTipOption(tipOption, isSelected);
            } else {
              tipOptionComponent = this.renderDefaultTipOption(tipOption, isSelected);
            }
            return tipOptionComponent;
          })
        }
      </div>
    );
  }

  getActionButton = () => {
    const { classes, translation, totalPrice } = this.props;

    return (
      <Button
        type="primary"
        onClick={() => this.handleClickMakePayment()}
        disabled={this.disableButton()}
        text={`${translation('TipDialog.makePayment')} (${getCurrencySymbol()} ${(this.getTotalAmount() || totalPrice).toFixed(2)})`}
      />
    );
  }

  getTotalAmount = () => {
    const { totalPrice = 0 } = this.props;
    const { tipAmount } = this.state;
    const numericTipAmount = typeof tipAmount === 'string' ? tipAmount.replace(/\$/g, '') : tipAmount;
    // Need to parseFloat because tipAmount is an empty string if customized amount is selected but no user input
    const tip = parseFloat(numericTipAmount || 0);
    return totalPrice + tip;
  };

  disableButton = () => {
    const { selectedTipOptionId, customizedTipAmount } = this.state;
    // numericTipAmount is NaN if customizedTipAmount is empty or no meaningful input (i.e. no numbers)
    const numericTipAmount = parseFloat(customizedTipAmount);
    return selectedTipOptionId === 'customize' && !numericTipAmount;
  };

  calculateTipAmount = (tipPercentage) => {
    const { totalPrice } = this.props;
    return (totalPrice * tipPercentage) / 100;
  };

  handleClickTipOption = (tipOptionId) => {
    this.setState({
      selectedTipOptionId: tipOptionId,
      tipAmount: FeatureFlags.PaymentPage.enableSmartTip && (this.props.totalPrice <= smartTipThreshold)
        ? smartTipOptions[tipOptionId].value
        : this.calculateTipAmount(tipOptions[tipOptionId].value),
      customizedTipAmount: '',
    });
  };

  handleClickCustomizedTipOption = () => {
    this.setState({
      selectedTipOptionId: 'customize',
    });
  };

  handleChange = (event) => {
    const { target } = event;
    const tip = target.value.replace(/\$/g, ''); // Remove dollar sign
    const decimalPointIndex = tip.indexOf('.');
    let tipWithoutLeadingZero = tip;
    // 0.01 is correct, but when the user continues to enter another digit,
    // it becomes 00.11 (decimalPoint is at index 2 -> we need to get rid
    // of the first digit if it's a zero in this case)
    if (decimalPointIndex > 1 && tip.charAt(0) === '0') {
      tipWithoutLeadingZero = tip.substring(1);
    }
    this.setState({
      selectedTipOptionId: 'customize',
      tipAmount: tipWithoutLeadingZero,
      customizedTipAmount: tipWithoutLeadingZero,
    });
  };

  handleClickMakePayment = async () => {
    const {
      actions,
      user,
      currentOrderId,
      processPayment,
    } = this.props;
    const { tipAmount } = this.state;

    if (currentOrderId) {
      const updatedOrder = {
        tipAmount,
      };
      try {
        const response = await actions.updateOrder(user, updatedOrder, currentOrderId);
        if (!response || response.error) return;

        // No need to call handleClose because the user is redirected to the Order
        // Status Page immediately if payment was successful. Hence both TipDialog
        // and its parent component (OrderSummaryComponent) are already unmounted
        // when it returns to this point. If call handleClose() after
        // processPayment(), OrderSummaryComponent will have a setState on
        // unmounted component warning.
        await processPayment();
      } catch (error) {
        console.log('API call error', error);
      }
    }
  };

  handleClose = () => {
    const { handleClose } = this.props;
    this.setState(this.getInitialState(), () => handleClose());
  };

  renderCustomizeTipOption = (tipOption, isSelected) => {
    const { classes, translation } = this.props;

    return (
      <TextField
        key={tipOption.id}
        className={classes.tipOptionStyle}
        style={{
          color: isSelected && pageStyles.selected.color,
          backgroundColor: isSelected && pageStyles.selected.background,
        }}
        inputProps={{
          className: classes.textFieldInputTextProps,
          style: {
            color: isSelected && pageStyles.selected.color,
          },
        }}
        InputProps={{
          inputComponent: TextInputMask,
          disableUnderline: pageStyles.textFieldInputBoxProps.disableUnderline === 'true'
        }}
        placeholder={translation(tipOption.text)}
        value={this.state.customizedTipAmount}
        onClick={() => this.handleClickCustomizedTipOption()}
        onChange={e => this.handleChange(e)}
      />
    );
  };

  renderDefaultTipOption = (tipOption, isSelected) => {
    const { classes, translation, totalPrice } = this.props;
    const tipValue = FeatureFlags.PaymentPage.enableSmartTip && (totalPrice <= smartTipThreshold)
      ? `${tipOption.key}`
      : `${tipOption.key} - ${getCurrencySymbol()}${this.calculateTipAmount(tipOption.value).toFixed(2)}`;
    return (
      <Button
        key={tipOption.id}
        className={classes.tipOptionStyle}
        style={{
          color: isSelected && pageStyles.selected.color,
          backgroundColor: isSelected && pageStyles.selected.background,
        }}
        onClick={() => this.handleClickTipOption(tipOption.id)}
      >
        {
          tipOption.key === 'noTip'
            ? translation(tipOption.text)
            : tipValue
        }
      </Button>
    );
  };

  render() {
    const { classes, open } = this.props;

    return (
      <DialogView
        open={open}
        titleAlignClose={false}
        handleClose={() => this.handleClose()}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        dialogTitleStyle={classes.dialogTitleStyle}
        dialogBodyContainerStyle={classes.dialogBodyContainerStyle}
        dialogContentStyle={classes.dialogContentStyle}
        titleHasCloseBtn
        hasDialogContent
        hasDialogContent2={false}
        renderDialogContent={() => this.getDialogContent()}
        hasDialogActions
        actionBtnStyle={classes.actionBtnStyle}
        renderActionBtn={() => this.getActionButton()}
        dialogCloseIconColor={pageStyles.dialogTitleStyle.color}
      />
    );
  }
}

TipDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  processPayment: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  translation: PropTypes.func.isRequired,
  totalPrice: PropTypes.number.isRequired,
  currentOrderId: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(withWidth()(TipDialog));
