import isNil from 'lodash/isNil';

import * as FeatureFlags from '../../../feature-flags.json';
import DateHelper from './DateHelper';

const DEFAULT_TIME_SLOT_INTERVAL = 5;

const doesHaveOrderThrottling = (location) => {
  const {
    ORDER_LIMIT_PER_TIME_SLOT,
    ORDER_TIME_SLOT,
  } = location;
  return FeatureFlags.MenuPage.OrderFlowDialog.shouldUseOrderThrottling
    && !isNil(ORDER_LIMIT_PER_TIME_SLOT)
    && !isNil(ORDER_TIME_SLOT);
};

const getFirstIntervalInNextHour = (date) => {
  const nextHourDate = DateHelper.addMinutesToDate(date, 60);
  return DateHelper.getBeginningOfHour(nextHourDate);
};

const getOrderTimeSlot = (location) => {
  if (location && doesHaveOrderThrottling(location)) return parseInt(location.ORDER_TIME_SLOT, 10);
  return DEFAULT_TIME_SLOT_INTERVAL;
};

const getTimeSlotsForDate = (date, timeSlotInterval) => {
  const beginningOfHour = DateHelper.getBeginningOfHour(date);
  const numIntervals = 60 / timeSlotInterval;

  const timeSlots = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < numIntervals; i++) {
    const timeSlot = DateHelper.addMinutesToDate(beginningOfHour, timeSlotInterval * i);
    timeSlots.push(timeSlot);
  }

  return timeSlots;
};

// Returns nearest time slot in the future
const getNearestTimeSlot = (date, timeSlotInterval) => {
  const timeSlots = getTimeSlotsForDate(date, timeSlotInterval);

  // This eslint rule is disabled because this is a copy / paste from mobile
  // eslint-disable-next-line no-restricted-syntax
  for (const timeSlot of timeSlots) {
    if (DateHelper.isAfter(timeSlot, date)) return timeSlot;
  }

  // if we make it here, it means we need the first interval in the next hour
  return getFirstIntervalInNextHour(date);
};

export {
  doesHaveOrderThrottling,
  getNearestTimeSlot,
  getOrderTimeSlot,
};
