
    module.exports = function cssToMuiLoader(theme) {
      
      return {
        
        'paymentMethodPage': {'display': `flex`,'flexGrow': `1`,'justifyContent': `space-around`,'flexWrap': `wrap`,'paddingTop': `48px`,'paddingBottom': `7rem`,'flexDirection': `column`,'alignItems': `center`,},'pageTitle': {'width': `100%`,},'paymentMethodsContainer': {'width': `90vw`,},'noPaymentOptionsCardContainer': {'display': `flex`,'flexDirection': `column`,'alignItems': `center`,'alignSelf': `center`,},'noPaymentOptionsCard': {'width': `40vw`,'height': `30vh`,'margin': `12px`,'padding': `0px`,'boxShadow': `0 1px 2px 0 rgba(0, 0, 0, 0.3)`,'borderRadius': `4px`,'backgroundColor': `#ffffff`,'justifyContent': `flex-start`,'flexDirection': `column`,'display': `flex`,},'noPaymentOptionsCardContent': {'display': `flex`,'flexDirection': `column`,'justifyContent': `space-around`,'height': `100%`,},'noPaymentOptionsText': {'textAlign': `center`,'fontFamily': `Lato-Bold`,},'cardMediaContainer': {'display': `flex`,'flexDirection': `row`,'justifyContent': `center`,'alignItems': `center`,'height': `10vh`,},'cardMedia': {'width': `7vw`,'height': `10vh`,'objectFit': `contain`,},'cardButton': {'display': `flex`,'justifyContent': `center`,},'grid': {'spacing': `3`,'xsItem': `12`,'smItem': `6`,},'gridItem': {'minHeight': `20vh`,},'buttonContainer': {'display': `flex`,'width': `49%`,'marginTop': `3vh`,},'googlePayContainer': {'height': `100%`,'& *': {
          'cursor': `default`,'pointerEvents': `none`,
        },},'addNewCardButton': {'width': `170px`,'height': `45px`,},
        
      };
    };
  