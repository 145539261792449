
    module.exports = function cssToMuiLoader(theme) {
      
      return {
        
        'btn': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,},'btnPrimary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#a76e1b`,'color': `#ffffff`,
        },},'btnSecondary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#ffffff`,'color': `#d18a22`,'&:hover': {
          'backgroundColor': `#fff2e0`,'color': `#d18a22`,
        },},'btnTertiary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#70a0c3`,'color': `#ffffff`,
        },},'linkText': {'color': `#d18a22`,'cursor': `pointer`,'fontWeight': `bold`,'textDecoration': `underline`,'margin': `0 8px`,'fontSize': `0.75rem`,'fontFamily': `'Lato-Bold', sans-serif`,},'dialogTitleStyle': {'paddingTop': `0`,'paddingRight': `0`,'paddingLeft': `0`,'textAlign': `center`,'fontSize': `30px`,'variant': `title`,},'dialogContentStyle': {'textAlign': `center`,'width': `100%`,'padding': `0`,'display': `flex`,'flexDirection': `column`,'alignItems': `center`,},'dialogContentContainer': {'width': `100%`,'display': `flex`,'flexDirection': `column`,'alignItems': `center`,},'dialogContentText': {'fontSize': `20px`,'fontWeight': `500`,'marginBottom': `8%`,'color': `#ffffff`,},'dialogContentTextMobile': {'fontSize': `20px`,'fontWeight': `500`,'marginBottom': `8%`,'color': `#ffffff`,'fontSize': `15px`,},'tipOptionStyle': {'width': `100%`,'height': `45px`,'fontSize': `14px`,'margin': `auto`,'backgroundColor': `#e7e7e7`,'color': `#000000`,'variant': `contained`,'marginBottom': `4%`,},'selected': {'background': `#000000`,'color': `#ffffff`,},'actionBtnStyle': {'boxSizing': `border-box`,'margin': `10px auto 70px auto`,'width': `100%`,},'dialogBodyContainerStyle': {'width': `80%`,'maxWidth': `401px`,},'textFieldContainer': {'display': `flex`,'flexDirection': `row`,'justifyContent': `center`,'marginTop': `45px`,'width': `100%`,'height': `100%`,},'textFieldStyle': {'borderRadius': `1px`,'border': `2px solid black`,'width': `15%`,'height': `0`,'paddingBottom': `14%`,'margin': `0 5%`,'display': `flex`,'flexDirection': `column`,'justifyContent': `center`,},'textFieldInputTextProps': {'boxSizing': `border-box`,'height': `45px`,'textAlign': `center`,'color': `#ffffff`,},'textFieldInputBoxProps': {'disableUnderline': `true`,},'makePaymentButton': {'width': `100%`,'height': `50px`,'fontSize': `14px`,'margin': `auto`,'backgroundColor': `#ffffff`,'color': `#d18a22`,},
        
      };
    };
  