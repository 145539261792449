import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

import { withRouter } from 'react-router-dom';
import { Typography, withWidth, Card, CardMedia, CardContent, CardActionArea } from '@material-ui/core';
import { get } from 'lodash';
import Immutable from 'seamless-immutable';
import {
  func,
  objectOf,
  string,
  bool,
  any,
  arrayOf,
  number,
} from 'prop-types';

import { getResources } from '../../../selectors';
import * as Functions from '../../../services/functions/Functions';
import * as FeatureFlags from '../../../../feature-flags.json';
import DialogView from '../../core/components/DialogView';
import styles from '../../../css/core/components/RewardDialog.scss';
import * as ResponsiveStyles from '../../../../jsonStyles/components/core/components/RewardDialog.style.json';
import CircleFill from './CircleFill';

const pageStyles = typeof styles === 'function' ? styles() : styles;
let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.pointsRewards = {
  ...responsiveStyles.pointsRewards,
  [theme.breakpoints.down('sm')]: {
    width: ResponsiveStyles.pointsRewards.width,
  },
};

responsiveStyles.rewardCard = {
  ...responsiveStyles.rewardCard,
  [theme.breakpoints.down('sm')]: {
    width: ResponsiveStyles.rewardCard.width,
    height: ResponsiveStyles.rewardCard.height,
  },
};

const RewardTiers = (props) => {
  const {
    classes,
    translation,
    userPoints,
    sortedTiers,
  } = props;

  return (
    <div className={classes.pointsRewards}>
      {
        sortedTiers.map(reward => (
          <Card
            square
            key={reward.points}
            className={classes.rewardCard}
          >
            <CardActionArea
              className={classes.rewardCardActionArea}
              disabled
            >
              <CardMedia
                component="img"
                className={
                  userPoints >= reward.points
                    ? classes.rewardCardPicture
                    : classes.rewardCardPictureDisabled
                }
                image={reward.image}
              />
              <CardContent className={classes.rewardCardContent}>
                <Typography
                  className={
                    userPoints >= reward.points
                      ? classes.rewardPointsText
                      : classes.rewardPointsTextDisabled
                  }
                >
                  {`${reward.points} ${translation('RewardDialog.points').toUpperCase()}`}
                </Typography>
                <Typography className={classes.rewardDescriptionText}>
                  {reward.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))
      }
    </div>
  );
};

const CirclePointsComponent = (props) => {
  const {
    classes,
    translation,
    userPoints,
    sortedTiers,
  } = props;

  const calcFill = () => {
    const fillByMaxReward
      = FeatureFlags.RewardDialog
        ? FeatureFlags.RewardDialog.fillByMaxReward
        : false;

    const fillHeight = fillByMaxReward
      ? Functions.calculateNormalFillHeight(sortedTiers, userPoints)
      : Functions.calculateProportionalFillHeight(sortedTiers, userPoints);

    return fillHeight;
  };

  return (
    <div className={classes.userPoints}>
      <CircleFill fillHeight={calcFill()} />
      <Typography className={classes.pointsAmountText}>
        {`${userPoints} ${translation('RewardDialog.points')}`}
      </Typography>
      <Typography className={classes.pointsSubtitle}>
        {translation('RewardDialog.pointsSubtitle')}
      </Typography>
    </div>
  );
};

const RewardComponent = (props) => {
  const {
    open,
    classes,
    actions,
    user,
  } = props;
  const { users } = props.resources;

  const userPoints = users ? users.points : 0;

  const handleClose = () => {
    props.handleClose();
  };

  const renderActionButtons = () => {
    // Placeholder for required function: will use in future for dialog action buttons
  };

  if (user) {
    useEffect(() => {
      actions.getAllResources(user.token, ['tiers']);
    }, []);
  }

  const getSortedTiers = (tiers = []) => {
    const mutableTiers = Immutable.asMutable(tiers, { deep: true });
    return mutableTiers.sort(Functions.compareValues('points', 'asc'));
  };

  const allTiers = get(props.resources, 'tiers', []);
  const sortedTiers = getSortedTiers(allTiers);

  const getDialogContent = () => {
    return (
      <div className={classes.dialogContentContainer}>
        <CirclePointsComponent
          {...props}
          userPoints={userPoints}
          sortedTiers={sortedTiers}
        />
        <div className={classes.pointsRewards}>
          {
            FeatureFlags.RewardDialog.showRewardsTiers
            && (
              <RewardTiers
                {...props}
                userPoints={userPoints}
                sortedTiers={sortedTiers}
              />
            )
          }
        </div>
      </div>
    );
  };

  return (
    <DialogView
      open={open}
      handleClose={() => handleClose()}
      titleAlignClose={false}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      dialogTitleStyle={classes.dialogTitleStyle}
      subHeaderImageContainerStyle={classes.subHeaderImageContainerStyle}
      subHeaderImageStyle={classes.subHeaderImageStyle}
      titleHasCloseBtn
      hasHeaderImage
      hasDialogContent
      hasDialogContent2={false}
      hasDialogErrorContent={false}
      renderDialogContent={() => getDialogContent()}
      hasDialogActions={false}
      actionBtnStyle={classes.actionBtnStyle}
      renderActionBtn={() => renderActionButtons()}
      dialogCloseIconColor={pageStyles.dialogCloseIcon.color}
      dialogBodyContainerStyle={classes.bodyContainer}
      dialogContentStyle={classes.dialogContentStyle}
    />
  );
};


RewardComponent.propTypes = {
  classes: objectOf(string).isRequired,
  open: bool.isRequired,
  actions: objectOf(func).isRequired,
  resources: objectOf(any).isRequired,
  handleClose: func.isRequired,
  user: objectOf(any),
};

RewardComponent.defaultProps = {
  user: null,
};

CirclePointsComponent.propTypes = {
  classes: objectOf(string).isRequired,
  translation: func.isRequired,
  userPoints: number,
  sortedTiers: arrayOf(objectOf(any)),
};

CirclePointsComponent.defaultProps = {
  userPoints: 0,
  sortedTiers: [],
};

RewardTiers.propTypes = {
  classes: objectOf(string).isRequired,
  translation: func.isRequired,
  userPoints: number,
  sortedTiers: arrayOf(objectOf(any)),
};

RewardTiers.defaultProps = {
  userPoints: 0,
  sortedTiers: [],
};

const mapStateToProps = state => ({
  resources: getResources(state),
});

const RewardDialog = compose(
  connect(mapStateToProps),
  withStyles(responsiveStyles),
)(withRouter(withWidth()(RewardComponent)));

export default RewardDialog;
