import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Immutable from 'seamless-immutable';
import { LinearProgress } from '@material-ui/core';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

import { getLocations } from '../../selectors';
import LocationComponent from './subComponents/LocationComponent';
import styles from '../../css/locationsPage/LocationsPage.scss';
import * as ResponsiveStyles from '../../../jsonStyles/components/locationsPage/LocationsPage.style.json';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.locationsPage = {
  ...responsiveStyles.locationsPage,
  [theme.breakpoints.down('sm')]: {
    padding: ResponsiveStyles.locationsPage.padding,
  },
};

class LocationsPage extends Component {
  async componentDidMount() {
    const { actions, locations } = this.props;

    if (!locations) {
      try {
        // eslint-disable-next-line no-undef
        await actions.getResource(window.companyApiKey, ['locations']);
      } catch (error) {
        console.log('There is an API error', error);
      }
    }
  }

  render() {
    const {
      locations,
      classes,
      loading,
      translation,
      history,
      actions,
    } = this.props;
    let sortedLocations = Immutable.asMutable(locations, { deep: true });
    if (locations) {
      sortedLocations.sort((l1, l2) => {return l1.name.localeCompare(l2.name)})
    }

    return (
      loading !== 0
        ? <LinearProgress />
        : (
          <div className={classes.locationsPage}>
            {
              locations && sortedLocations.map(location =>
              (
                <LocationComponent
                  key={location.id}
                  location={location}
                  translation={translation}
                  history={history}
                  actions={actions}
                />

              ))
            }
          </div>
        )
    );
  }
}

LocationsPage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  loading: PropTypes.number.isRequired,
  translation: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object),
};

LocationsPage.defaultProps = {
  locations: null,
};

const mapStateToProps = state => ({
  locations: getLocations(state),
});

export default withStyles(responsiveStyles)(withRouter(connect(mapStateToProps)(LocationsPage)));
