import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import styles from '../../../css/core/components/FooterComponent.scss';
import versionInfo from '../../../config/version.json';
import * as ResponsiveStyles from '../../../../jsonStyles/components/core/components/FooterComponent.style.json';
import { addCurrentCopyrightYear } from '../../../services/functions/Functions';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.optionsText = {
  ...responsiveStyles.optionsText,
  [theme.breakpoints.down('sm')]: {
    fontSize: ResponsiveStyles.optionsText.fontSize,
  },
};

responsiveStyles.optionsStyle = {
  ...responsiveStyles.optionsStyle,
  [theme.breakpoints.down('sm')]: {
    flexDirection: ResponsiveStyles.optionsStyle.flexDirection,
  },
};

const FooterComponent = (props) => {
  const { classes, options, translation } = props;
  const optionLength = options.length;

  // TODO: Assign appropriate links to terms of use, privacy statement, and legal disclaimer
  return (
    <footer className={classes.footer}>
      <div className={classes.footerContent}>
        <div className={classes.optionsStyle}>
          {
            options.map((item, index) => (
              <Typography variant="caption" key={item.id} className={classes.optionsText}>
                <a href={item.ref} className={classes.optionsText}>{translation(item.title)}</a>
                {index !== optionLength - 1 ? '\u00A0|\u00A0' : ''}
              </Typography>
             ))
          }
        </div>
        <Typography variant="caption" className={classes.copyrightText}>
          {addCurrentCopyrightYear(translation('FooterComponent.copyright'))}
          {` - ${translation('FooterComponent.version')} ${versionInfo.version}  ${translation('FooterComponent.build')} ${versionInfo.build}`}
        </Typography>
      </div>
    </footer>
  );
};

FooterComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  translation: PropTypes.func.isRequired,
};

export default withStyles(responsiveStyles)(FooterComponent);
