import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { usePlacesWidget } from 'react-google-autocomplete';
import {
  bool,
  func,
  objectOf,
  string,
} from 'prop-types';

import Config from '../../../../config.json';

const AutoCompleteAddress = (props) => {
  const {
    onPlaceSelected,
    onAddressBlur,
    emptyAddress,
    checkText,
  } = props;

  const [inputValue, setInputValue] = useState();
  const [inputShrink, setInputShrink] = useState(false);

  const getPlaceAddress = place => place.formatted_address.split(',')[0];

  const { ref: materialRef } = usePlacesWidget({
    apiKey: Config.general.googleMapsApiKey,
    options: {
      types: ['address'],
    },
    onPlaceSelected: (place) => {
      onPlaceSelected(place);
      setInputValue(getPlaceAddress(place));
    },
  });

  return (
    <TextField
      {...props}
      value={inputValue}
      onChange={(event) => { setInputValue(event.target.value); }}
      fullWidth
      onClick={() => { if (!inputShrink) setInputShrink(true); }}
      onBlur={() => {
        if (!inputValue) setInputShrink(false);
        onAddressBlur('streetAddress');
      }}
      inputRef={materialRef}
      InputLabelProps={{ shrink: inputShrink }}
      error={emptyAddress && checkText(inputValue)}
    />
  );
};

AutoCompleteAddress.defaultProps = {
  style: {},
  onPlaceSelected: null,
  onAddressBlur: null,
  emptyAddress: false,
  checkText: null,
};

AutoCompleteAddress.propTypes = {
  style: objectOf(string),
  onPlaceSelected: func,
  onAddressBlur: func,
  emptyAddress: bool,
  checkText: func,
};

export default (AutoCompleteAddress);
