import React from 'react';
import isEqual from 'lodash/isEqual';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import CoreView from '../CoreView';

class CoreRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentToRender: this.props.render(),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props, nextProps)) {
      this.setState({
        componentToRender: nextProps.render(),
      });
    }
  }

  render() {
    const { coreview, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={() => (
          <CoreView
            actions={coreview.actions}
            user={coreview.user}
            translation={coreview.translation}
            rememberUser={coreview.rememberUser}
            currentOrder={coreview.currentOrder}
            locations={coreview.locations}
          >
            {this.state.componentToRender}
          </CoreView>
        )}
      />
    );
  }
}

CoreRoute.propTypes = {
  user: PropTypes.object,
  render: PropTypes.func.isRequired,
  coreview: PropTypes.object,
};

CoreRoute.defaultProps = {
  user: null,
  coreview: {
    actions: null,
    user: null,
    resources: null,
    users: null,
    rememberUser: false,
    currentOrder: null,
  },
};

export default CoreRoute;
