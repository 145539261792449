import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Typography } from '@material-ui/core';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

class AddSubtractToggle extends Component {
  onRemoveItem = () => {
    const { id, handleChange, quantity } = this.props;
    handleChange(id, quantity - 1);
  }

  onAddItem = () => {
    const { id, handleChange, quantity } = this.props;
    handleChange(id, quantity + 1);
  }

  render() {
    const {
      max,
      containerStyle,
      iconStyle,
      quantity,
      iconDisabledStyle,
      textStyle,
      forceDisable,
      useCircleIcons,
    } = this.props;

    const maxButttonDisabled = (max && quantity >= max) || forceDisable;
    const UsedAddIcon = useCircleIcons ? AddCircle : AddIcon;
    const UsedRemoveIcon = useCircleIcons ? RemoveCircle : RemoveIcon;

    return (
      <div className={containerStyle}>
        <IconButton
          id="subtractItemQuantity"
          onClick={this.onRemoveItem}
          disabled={quantity <= 1}
        >
          <UsedRemoveIcon className={quantity > 1 ? iconStyle : iconDisabledStyle} />
        </IconButton>
        <Typography className={textStyle}>
          {quantity}
        </Typography>
        <IconButton
          id="addItemQuantity"
          onClick={this.onAddItem}
          disabled={maxButttonDisabled}
        >
          <UsedAddIcon className={maxButttonDisabled ? iconDisabledStyle : iconStyle} />
        </IconButton>
      </div>
    );
  }
}

AddSubtractToggle.propTypes = {
  id: PropTypes.number.isRequired,
  max: PropTypes.number,
  containerStyle: PropTypes.string,
  iconStyle: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  quantity: PropTypes.number,
  iconDisabledStyle: PropTypes.string,
  textStyle: PropTypes.string,
  forceDisable: PropTypes.bool,
  useCircleIcons: PropTypes.bool,
};

AddSubtractToggle.defaultProps = {
  max: null,
  containerStyle: '',
  iconStyle: '',
  iconDisabledStyle: '',
  textStyle: '',
  quantity: 1,
  forceDisable: false,
  useCircleIcons: true,
};

export default AddSubtractToggle;
