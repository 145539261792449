import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Icon, Typography, Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from '../../../css/core/components/AnnouncementSnackbar.scss';

const pageStyles = typeof styles === 'function' ? styles() : styles;

class AnnouncementSnackbar extends Component {
  state = {
    open: this.props.openMessageSnackbar,
  };

  handleClose = (event) => {
    const { actions } = this.props;
    this.setState({ open: false });
    actions.clearMessage();
  }

  isMessageEmpty = () => {
    const { message } = this.props;
    if (message.title === '' && message.message === '') return true;
    return false;
  }

  render() {
    const { classes, message } = this.props;

    return (
      !this.isMessageEmpty()
      && (
        <Snackbar
          anchorOrigin={{
            vertical: pageStyles.snackbar.anchorOriginVertical,
            horizontal: pageStyles.snackbar.anchorOriginHorizontal,
          }}
          open={this.state.open}
          ContentProps={{
            'aria-describedby': 'message-id',
            classes: { root: classes.root },
          }}
          action={[
            <IconButton
              key="close"
              aria-label={pageStyles.iconButton.ariaLabel}
              color={pageStyles.iconButton.color}
              className={classes.iconButton}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
          message={
            <div id="message-id" className={classes.messageContainer}>
              <Icon className={classes.warningIcon}>info_icon</Icon>
              <Typography className={classes.messageText}>
                {`${message.title} ${message.message}`}
              </Typography>
            </div>
          }
        />
      )
    );
  }
}

AnnouncementSnackbar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  openMessageSnackbar: PropTypes.bool.isRequired,
  message: PropTypes.objectOf(PropTypes.any),
};

AnnouncementSnackbar.defaultProps = {
  message: {},
};

export default withStyles(styles)(AnnouncementSnackbar);
