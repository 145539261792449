import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableHead, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

import Button from '../../core/components/Button';
import styles from '../../../css/orderStatusPage/subComponents/LocationPrepComponent.scss';
import * as ResponsiveStyles from '../../../../jsonStyles/components/orderStatusPage/subComponents/LocationPrepComponent.style.json';
import { getMobileFriendlyStyle, parseAddress } from '../../../services/functions/Functions';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.headerTableLocation = {
  ...responsiveStyles.headerTableLocation,
  [theme.breakpoints.down('sm')]: {
    fontSize: ResponsiveStyles.headerTableLocation.fontSize,
  },
};

responsiveStyles.locationAddress = {
  ...responsiveStyles.locationAddress,
  [theme.breakpoints.down('sm')]: {
    fontSize: ResponsiveStyles.locationAddress.fontSize,
  },
};

responsiveStyles.locationName = {
  ...responsiveStyles.locationName,
  [theme.breakpoints.down('sm')]: {
    fontSize: ResponsiveStyles.locationName.fontSize,
  },
};

responsiveStyles.callButton = {
  ...responsiveStyles.callButton,
  [theme.breakpoints.down('sm')]: {
    fontSize: ResponsiveStyles.callButton.fontSize,
    width: ResponsiveStyles.callButton.width,
    height: ResponsiveStyles.callButton.height,
  },
};

const LocationPrepComponent = (props) => {
  const {
    classes,
    orderPlaced,
    translation,
    width,
  } = props;

  const mobileFriendlyStyleProps = { classes, width };
  const locationInfoStyles = getMobileFriendlyStyle(mobileFriendlyStyleProps, 'locationInfo');

  return (
    orderPlaced
      && (
      <div className={locationInfoStyles}>
        <Table className={classes.tableStyle}>
          <TableHead>
            <TableRow>
              <TableCell style={responsiveStyles.placeHolderTableCellStyle} />
              <TableCell className={classes.headerTableLocation}>
                {translation('OrderStatusPage.locationHeading')}
              </TableCell>
              <TableCell className={classes.headerTableCellStyle} />
              <TableCell style={responsiveStyles.placeHolderTableCellStyle} />
            </TableRow>
          </TableHead>
          <TableBody>
            {
              orderPlaced.location
              ? (
                <TableRow>
                  <TableCell style={responsiveStyles.placeHolderTableCellStyle} />
                  <TableCell className={classes.tableCellDeliveryOption}>
                    <Typography className={classes.locationName}>
                      {(orderPlaced.location.name).toUpperCase()}
                    </Typography>
                    <Typography className={classes.locationAddress}>
                      {parseAddress(orderPlaced.location.address)}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCellAddress}>
                    <a className={classes.telLink} href={`tel:${orderPlaced.location.phone}`}>
                      <Button
                        type="primary"
                        className={classes.callButton}
                        text={orderPlaced.location.phone}
                      />
                    </a>
                  </TableCell>
                  <TableCell style={responsiveStyles.placeHolderTableCellStyle} />
                </TableRow>
              )
              : <TableRow />
            }
          </TableBody>
        </Table>
      </div>
      )
  );
};

LocationPrepComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  orderPlaced: PropTypes.objectOf(PropTypes.any),
};

LocationPrepComponent.defaultProps = {
  orderPlaced: null,
};

export default withStyles(responsiveStyles)(withWidth()(LocationPrepComponent));
