
    module.exports = function cssToMuiLoader(theme) {
      
      return {
        
        'btn': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,},'btnPrimary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#a76e1b`,'color': `#ffffff`,
        },},'btnSecondary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#ffffff`,'color': `#d18a22`,'&:hover': {
          'backgroundColor': `#fff2e0`,'color': `#d18a22`,
        },},'btnTertiary': {'borderRadius': `3px`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `14px`,'maxHeight': `35px`,'maxWidth': `100px`,'variant': `contained`,'padding': `10px 0`,'backgroundColor': `#d18a22`,'color': `#ffffff`,'&:hover': {
          'backgroundColor': `#70a0c3`,'color': `#ffffff`,
        },},'linkText': {'color': `#d18a22`,'cursor': `pointer`,'fontWeight': `bold`,'textDecoration': `underline`,'margin': `0 8px`,'fontSize': `0.75rem`,'fontFamily': `'Lato-Bold', sans-serif`,},'dialogTitleStyle': {'padding': `0`,'textAlign': `center`,'color': `#282828`,'fontSize': `30px`,'variant': `title`,},'subHeaderImageStyle': {'width': `200px`,'maxWidth': `30vw`,'maxHeight': `100px`,},'actionBtnStyle': {'margin': `15px auto 0px auto`,'width': `auto`,'padding': `0`,},'actionBtnStyleMobile': {'margin': `15px auto 0px auto`,'width': `auto`,'padding': `0`,'margin': `10px auto 10px auto`,},'dialogBodyContainerStyle': {'width': `80%`,'maxWidth': `400px`,},'dialogContentStyle': {'textAlign': `center`,'width': `100%`,'padding': `0`,'color': `#282828`,'marginBottom': `50px`,},'subHeaderImageContainerStyle': {'padding': `0px 80px 0 80px`,'fontSize': `14px`,'fontWeight': `bold`,'variant': `subheading`,},'inputStyle': {'fontSize': `16px`,'textAlign': `left`,},'formContainer': {'display': `flex`,'flexWrap': `wrap`,'flexDirection': `column`,'alignItems': `center`,},'formGroupStyle': {'marginTop': `13px`,'row': `false`,},'dialogContent2': {'display': `flex`,'flexDirection': `row`,'justifyContent': `center`,'marginBottom': `0px`,},'orSeparator': {'display': `flex`,'flexDirection': `row`,'margin': `0 0 16px 0`,},'separatorLine': {'width': `100%`,'borderBottom': `solid 2px #282828`,'height': `1px`,'margin': `10px 3px 10px 3px`,},'promptContainer': {'display': `flex`,'flexDirection': `row`,'justifyContent': `center`,'&:first-of-type': {
          'padding': `10px 0`,
        },},'dialogContent2Link': {'color': `#d18a22`,'fontFamily': `'Lato-Regular', sans-serif`,},
        
      };
    };
  