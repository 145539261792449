
    module.exports = function cssToMuiLoader(theme) {
      
      return {
        
        'locationInfo': {'height': `45%`,'backgroundColor': `#ffffff`,'borderRadius': `5px`,'boxShadow': `0 1px 2px 0 rgba(0, 0, 0, 0.3)`,},'locationInfoMobile': {'height': `45%`,'backgroundColor': `#ffffff`,'borderRadius': `5px`,'boxShadow': `0 1px 2px 0 rgba(0, 0, 0, 0.3)`,'margin': `15px 0 0 0`,},'tableStyle': {'overflow': `hidden`,'marginBottom': `16px`,},'placeHolderTableCellStyle': {'padding': `0`,'width': `8px`,'border': `none`,},'headerTableLocation': {'fontWeight': `bold`,'color': `#282828`,'fontFamily': `'Lato-Regular', sans-serif`,'padding': `16px 0 0 0`,'width': `50px`,'fontSize': `smaller`,},'headerTableCellStyle': {'fontWeight': `bold`,'color': `#282828`,'fontFamily': `'Lato-Regular', sans-serif`,'padding': `16px 0 0 0`,'width': `50px`,},'locationName': {'fontWeight': `bold`,'color': `#282828`,'fontFamily': `'Lato-Regular', sans-serif`,'fontSize': `smaller`,},'tableCellDeliveryOption': {'padding': `16px 0 0 0`,'width': `50px`,'border': `none`,},'tableCellAddress': {'padding': `16px 0 0 0`,'width': `50px`,'paddingBottom': `12px`,'fontSize': `smaller`,'textAlign': `right`,'border': `none`,},'locationAddress': {'fontSize': `smaller`,'color': `#282828`,},'headerTableEstimated': {'textAlign': `right`,},'tableCellLocationPrep': {'paddingLeft': `0`,'paddingRight': `0`,'width': `100px`,},'callButton': {'width': `60%`,'height': `30px`,'fontSize': `10px`,'fontWeight': `500`,'color': `#ffffff`,'borderRadius': `3px`,'backgroundColor': `#d18a22`,'&:hover': {
          'backgroundColor': `#a76e1b`,
        },},'telLink': {'textDecoration': `none`,'display': `flex`,'justifyContent': `flex-end`,},
        
      };
    };
  