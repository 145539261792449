import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Card, CardContent, CardMedia, Typography, IconButton, Icon } from '@material-ui/core';
import { withStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import styles from '../../../css/paymentMethodPage/subComponents/PaymentMethodComponent.scss';
import * as ResponsiveStyles from '../../../../jsonStyles/components/paymentMethodPage/subComponents/PaymentMethodComponent.style.json';
import AmexIcon from '../../../assets/images/american-express-1.png';
import CreditCardIcon from '../../../assets/images/credit-card.svg';
import GiftCardIcon from '../../../assets/images/primary_logo.svg';
import MasterIcon from '../../../assets/images/master.svg';
import VisaIcon from '../../../assets/images/visa.svg';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.cardImage = {
  ...responsiveStyles.cardImage,
  [theme.breakpoints.down('sm')]: {
    maxHeight: ResponsiveStyles.cardImage.maxHeight,
  },
};

responsiveStyles.cardNumber = {
  ...responsiveStyles.cardNumber,
  [theme.breakpoints.down('sm')]: {
    fontSize: ResponsiveStyles.cardNumber.fontSize,
  },
};

class PaymentMethodComponent extends Component {
  onClickDelete = () => {
    const { paymentOption, onClickDelete } = this.props;
    onClickDelete(paymentOption);
  }

  getImage = () => {
    const { paymentOption } = this.props;
    switch (paymentOption.cardType.toLowerCase()) {
      case 'amex':
        return AmexIcon;
      case 'gift_card':
        return GiftCardIcon;
      case 'mastercard':
        return MasterIcon;
      case 'visa':
        return VisaIcon;
      default:
        return CreditCardIcon;
    }
  }

  render() {
    const { paymentOption, classes, translation } = this.props;
    return (
      <Card
        key={paymentOption.id}
        className={classes.card}
      >
        <CardContent style={responsiveStyles.cardContent}>
          <CardMedia
            component="img"
            src={this.getImage()}
            className={classes.cardImage}
          />
          <div className={classes.cardInfo} >
            <Typography className={classes.cardNumber}>{paymentOption.cardNumber}</Typography>
            <Typography className={classes.expiryDate}>{`${translation('PaymentMethodPage.expire')} ${paymentOption.expiryDate}`}</Typography>
          </div>
          <IconButton
            className={classes.iconButton}
            onClick={this.onClickDelete}
          >
            <Icon className={classes.icon}>delete</Icon>
          </IconButton>
        </CardContent>
      </Card>
    );
  }
}

PaymentMethodComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translation: PropTypes.func.isRequired,
  paymentOption: PropTypes.objectOf(PropTypes.any),
  onClickDelete: PropTypes.func,
};

PaymentMethodComponent.defaultProps = {
  paymentOption: null,
  onClickDelete: () => {},
};

export default (withStyles(responsiveStyles)(withRouter(PaymentMethodComponent)));
