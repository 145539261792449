import * as jsonConstants from '../constants/ResourceConstants.json';
import { getRedeemedAmount, getItemTotal } from '../functions/Functions';

/* eslint-disable global-require */
export const provinces = [
  {
    value: 'AB',
  },
  {
    value: 'BC',
  },
  {
    value: 'MB',
  },
  {
    value: 'NB',
  },
  {
    value: 'NL',
  },
  {
    value: 'NS',
  },
  {
    value: 'NT',
  },
  {
    value: 'NU',
  },
  {
    value: 'ON',
  },
  {
    value: 'PE',
  },
  {
    value: 'QC',
  },
  {
    value: 'SK',
  },
  {
    value: 'YT',
  },
];

export const states = [
  {
    value: 'AL',
  },
  {
    value: 'AK',
  },
  {
    value: 'AZ',
  },
  {
    value: 'AR',
  },
  {
    value: 'CA',
  },
  {
    value: 'CO',
  },
  {
    value: 'CT',
  },
  {
    value: 'DE',
  },
  {
    value: 'DC',
  },
  {
    value: 'FL',
  },
  {
    value: 'GA',
  },
  {
    value: 'HI',
  },
  {
    value: 'ID',
  },
  {
    value: 'IL',
  },
  {
    value: 'IN',
  },
  {
    value: 'IA',
  },
  {
    value: 'KS',
  },
  {
    value: 'KY',
  },
  {
    value: 'LA',
  },
  {
    value: 'ME',
  },
  {
    value: 'MD',
  },
  {
    value: 'MA',
  },
  {
    value: 'MN',
  },
  {
    value: 'MS',
  },
  {
    value: 'MO',
  },
  {
    value: 'MT',
  },
  {
    value: 'NE',
  },
  {
    value: 'NV',
  },
  {
    value: 'NH',
  },
  {
    value: 'NJ',
  },
  {
    value: 'NL',
  },
  {
    value: 'NM',
  },
  {
    value: 'NY',
  },
  {
    value: 'NC',
  },
  {
    value: 'ND',
  },
  {
    value: 'OH',
  },
  {
    value: 'OK',
  },
  {
    value: 'OR',
  },
  {
    value: 'PA',
  },
  {
    value: 'RI',
  },
  {
    value: 'SC',
  },
  {
    value: 'SD',
  },
  {
    value: 'TN',
  },
  {
    value: 'TX',
  },
  {
    value: 'UT',
  },
  {
    value: 'VT',
  },
  {
    value: 'VA',
  },
  {
    value: 'WA',
  },
  {
    value: 'WV',
  },
  {
    value: 'WI',
  },
  {
    value: 'WY',
  },
  {
    value: 'PR',
  },
];

// Pizza Sides
export const LEFT = 'Left';
export const RIGHT = 'Right';
export const WHOLE = 'Whole';

export const DELIVERY = 'DELIVERY';
export const PICKUP = 'PICKUP';
export const DINE_IN = 'DINE_IN';
export const CATERING = 'CATERING';
export const GROUP = 'GROUP';

export const BUSINESS = 'BUSINESS';

export const deliveryOptionsArray = [DELIVERY, PICKUP, DINE_IN, CATERING];
export const usesAddresses = [DELIVERY, CATERING];

// Threshold for splitting tabBar into separate sections.
export const tabSplitThreshold = 6;

export const WEEKDAYARRAY = [
  'SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY',
];

export const ITEM_HEIGHT = 48;

export const MAX_HEIGHT_MULTIPLIER = 10;

export const TAB_CHAR_LIMIT = 20;

export const authenticationStillInProgressEvents = [
  'signUpSuccess',
  'clickResetFromLogin',
  'verifyFail',
  'unverifiedUserOnReset',
  'unverifiedUserOnLogin',
  'clickGuestCheckout',
];

/* Product icons for special cases, i.e. Vegetarian, etc */
export const productIcons = [
  {
    key: 'vegetarian',
    icon: require('../../assets/images/productIcons/vegetarian.png'),
    translation: 'ProductBoxComponent.vegetarian',
  },
  {
    key: 'gluten-free',
    icon: require('../../assets/images/productIcons/gluten-free.png'),
    translation: 'ProductBoxComponent.glutenFree',
  },
  {
    key: 'vegan',
    icon: require('../../assets/images/productIcons/vegan.png'),
    translation: 'ProductBoxComponent.vegan',
  },
];

export const {
  maxDateOffset, tipOptions, smartTipOptions, defaultTipIndex, smartTipThreshold, statusIndicatorLabels,
  curbsideOrderStatuses,
} = jsonConstants;


export const deliveryOptionIcons = {
  PICKUP: 'directions_walk',
  DELIVERY: 'directions_car',
  CATERING: 'directions_car',
  DINE_IN: 'directions_walk',
};

export const DEPRECATED_priceAndTaxSummary = [
  {
    key: 'subtotal', title: 'CheckoutDrawer.summary.subtotal',
  },
  {
    key: 'redeemed', title: ['CheckoutDrawer.summary.redeemed.redeem', 'CheckoutDrawer.summary.redeemed.points'],
  },
  {
    key: 'coupons', title: 'CheckoutDrawer.summary.couponDiscount',
  },
  {
    key: 'delivery', title: 'CheckoutDrawer.summary.delivery',
  },
  {
    key: 'serviceFee', title: 'CheckoutDrawer.summary.serviceFee',
  },
  {
    key: 'catering', title: 'CheckoutDrawer.summary.catering',
  },
  {
    key: 'tax', title: 'CheckoutDrawer.summary.tax',
  },
  {
    key: 'total', title: 'CheckoutDrawer.summary.total',
  },
  {
    key: 'tipAmount', title: 'CheckoutDrawer.summary.tip',
  },
];

export const priceAndTaxSummary = {
  price: {
    title: 'CheckoutDrawer.summary.subtotal',
    getValueAmount: order => getItemTotal(order),
  },
  redeemAmount: {
    title: ['CheckoutDrawer.summary.redeemed.redeem', 'CheckoutDrawer.summary.redeemed.points'],
    optional: true,
    isDiscount: true,
    getValueAmount: order => getRedeemedAmount(order),
  },
  deliveryFee: {
    title: 'CheckoutDrawer.summary.delivery',
    optional: true,
    getValueAmount: order => order.deliveryFee,
  },
  serviceFee: {
    title: 'CheckoutDrawer.summary.serviceFee',
    getValueAmount: order => order.calculatedServiceFees,
  },
  cateringFee: {
    title: 'CheckoutDrawer.summary.catering',
    optional: true,
    getValueAmount: order => order.cateringFee,
  },
  couponValue: {
    title: 'CheckoutDrawer.summary.couponDiscount',
    optional: true,
    isDiscount: true,
    getValueAmount: order => order.couponValue,
  },
  totalRewardsValue: {
    title: 'CheckoutDrawer.summary.totalRewardsValue',
    optional: true,
    isDiscount: true,
    getValueAmount: order => order.totalRewardsValue,
  },
  tax: {
    title: 'CheckoutDrawer.summary.tax',
    getValueAmount: order => order.tax,
  },
  tipAmount: {
    title: 'CheckoutDrawer.summary.tip',
    getValueAmount: order => order.tipAmount,
  },
};

export const invertMonthDict = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const StepDirection = Object.freeze({
  FORWARD: 1,
  BACKWARD: -1,
});

export const SHORT_DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

// we assert that Days of the week starts on Monday
export const DAY_TO_NUMBER = {
  MONDAY: 0, TUESDAY: 1, WEDNESDAY: 2, THURSDAY: 3, FRIDAY: 4, SATURDAY: 5, SUNDAY: 6,
};

// the new Date(), getDay assumes week starts on Sunday, used to get the corresponding day of the week.
export const GETDAY_FULLDAYS = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

export const HOURS_TYPE = { HOURS: 'hours', HOLIDAYS: 'holidays' };

export const SCROLL_TO_TOP_OFFSET = 50;

export const GIFT_CARD = 'gift-card';
