import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import withWidth from '@material-ui/core/withWidth';
import styles from '../../../css/paymentPage/subComponents/PaymentBoxComponent.scss';
import { 
  getMobileFriendlyStyle,
  isDesktopMode,
} from '../../../services/functions/Functions';

const pageStyles = typeof styles === 'function' ? styles() : styles;

const optionButtons = [
  {
    id: 0, key: 'delete', title: 'PaymentBoxComponent.delete',
  },
];

class PaymentBoxComponent extends Component {
  state = {
    anchorEl: null,
  };

  onClickSelectPaymentOption = (optionId) => {
    const { selectedCardId, handleClickSelect, type } = this.props;
    if (optionId !== selectedCardId) {
      handleClickSelect(optionId, type);
    }
  };

  onClickMoreOptions = (event, cardId) => {
    const { selectedCardId, handleClickSelect } = this.props;
    if (cardId !== selectedCardId) {
      handleClickSelect(cardId);
    }
    this.setState({ anchorEl: event.currentTarget });
  };

  onClickDelete = (event, cardId) => {
    const { handleOpenDeleteDialog } = this.props;
    handleOpenDeleteDialog(cardId);
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      cardContent,
      classes,
      hasCardContent,
      hasMoreOptionsButton,
      image,
      imageAlt,
      paymentOptionId,
      selectedCardId,
      translation,
      width,
    } = this.props;
    const { anchorEl } = this.state;

    const isSelected = paymentOptionId === selectedCardId;
    const mobileFriendlyStyleProps = { classes, width };
    const cardStyles = getMobileFriendlyStyle(mobileFriendlyStyleProps, 'card');
    const rootStyles = getMobileFriendlyStyle(mobileFriendlyStyleProps, 'root');

    return (
      <Fragment>
        <Card
          className={cardStyles}
          style={{ backgroundColor: isSelected && pageStyles.selected.background }}
          onClick={() => this.onClickSelectPaymentOption(paymentOptionId)}
        >
          <CardHeader
            action={
              hasMoreOptionsButton
                && (
                  <IconButton
                    className={classes.iconButtonStyle}
                    onClick={event => this.onClickMoreOptions(event, paymentOptionId)}
                  >
                    <Icon
                      className={classes.moreOptionsButton}
                      style={{ color: isSelected && pageStyles.selected.color }}
                    >
                      more_vert
                    </Icon>
                  </IconButton>
                )
            }
            title={
              <img
                src={image}
                alt={imageAlt}
                className={classes.paymentImage}
              />
            }
            classes={{
              action: !isDesktopMode(width) && classes.moreOptionsButtonContainer,
              root: rootStyles,
              title: classes.paymentImageContainer,
            }}
          />
          {
            hasCardContent
            && (
              <CardContent classes={{ root: classes.contentPadding }}>
                {cardContent}
              </CardContent>
            )
          }
        </Card>
        {
          <Menu
            anchorEl={anchorEl}
            classes={{
              list: classes.actionMenuStyle,
            }}
            open={Boolean(anchorEl)}
            onClose={() => this.handleClose()}
          >
            {
              optionButtons.map(option => (
                <MenuItem
                  key={option.id}
                  onClick={event => this.onClickDelete(event, selectedCardId)}
                >
                  <Icon className={classes.optionIcon}>{option.key}</Icon>
                  <Typography className={classes.optionText}>
                    {translation(option.title)}
                  </Typography>
                </MenuItem>
              ))
            }
          </Menu>
        }
      </Fragment>
    );
  }
}

PaymentBoxComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleClickSelect: PropTypes.func.isRequired,
  handleOpenDeleteDialog: PropTypes.func,
  translation: PropTypes.func.isRequired,
  paymentOptionId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  selectedCardId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  image: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  hasMoreOptionsButton: PropTypes.bool,
  hasCardContent: PropTypes.bool,
  cardContent: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string.isRequired,
};

PaymentBoxComponent.defaultProps = {
  handleOpenDeleteDialog: () => {},
  selectedCardId: null,
  hasMoreOptionsButton: false,
  hasCardContent: false,
  cardContent: {},
  imageAlt: '',
};

export default withStyles(styles)(withWidth()(PaymentBoxComponent));
