import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  withStyles,
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import {
  Typography,
  Divider,
} from '@material-ui/core';
import {
  func,
  objectOf,
  string,
  any,
  bool,
} from 'prop-types';

import LinkText from './LinkText';
import Button from './Button';
import { getFormattedPrice, getProductFamily } from '../../../services/functions/Functions';
import { getCurrentOrder, getCompany, getProducts, getUser } from '../../../selectors';
import * as Actions from '../../../actions/Actions';
import styles from '../../../css/core/components/OrderItemList.scss';
import * as ResponsiveStyles from '../../../../jsonStyles/components/core/components/OrderItemList.style.json';

// Return styles object when testing
let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.itemList = {
  ...responsiveStyles.itemList,
  [theme.breakpoints.down('sm')]: ResponsiveStyles.itemList,
};

const CustomizationList = (props) => {
  const { classes, translation, options } = props;
  if (isEmpty(options)) return null;
  const addOnsText = translation('addOns');
  return (
    <Typography className={classes.optionListText}>
      {`${addOnsText} :`}
      {
        options.map(option => (
            `${option.quantity}x ${option.name}`
        ))
      }
    </Typography>
  );
};

const RedeemedPrice = (props) => {
  const {
    enableEdit,
    orderItem,
    classes,
    company,
    translation,
    handleUnRedeem,
  } = props;
  const { totalPrice, totalRedeemedAmount } = orderItem;
  const redeemedPrice = totalPrice - totalRedeemedAmount;
  return (
    <div className={classes.priceWithButton}>
      <div className={classes.priceContainer}>
        <Typography
          className={classes.crossedOutPriceText}
        >
          {getFormattedPrice(totalPrice, company)}
        </Typography>
        <Typography
          className={classes.priceText}
        >
          {getFormattedPrice(redeemedPrice, company)}
        </Typography>
      </div>
      {
        enableEdit && (
          <LinkText
            className={classes.linkText}
            onClick={() => handleUnRedeem(orderItem)}
            text={translation('OrderItemComponent.unRedeem')}
          />
        )
      }
    </div>
  );
};

const ItemPrice = (props) => {
  const {
    orderItem,
    classes,
    company,
    isCurrentItemRedeemed,
  } = props;
  if (isCurrentItemRedeemed) return <RedeemedPrice {...props} />;
  // Total price is the item's price, times quantity.
  const { totalPrice } = orderItem;
  return (
    <Typography
      className={classes.priceText}
    >
      {getFormattedPrice(totalPrice, company)}
    </Typography>
  );
};

const RedeemButton = (props) => {
  const {
    userPoints,
    handleRedeem,
    handleUnRedeem,
    isCurrentItemRedeemed,
    orderItem,
    translation,
    enableEdit
  } = props;
  if (!enableEdit) return null;
  const { redeemedPoints, quantity } = orderItem;
  const productItemPoints = get(orderItem, 'productItem.points', 0);

  const redeemedQuantity = productItemPoints ? redeemedPoints / productItemPoints : 0;

  // For the item to be redeemable, three conditions are needed:
  // 1) The product item has associated points
  // 2) The user currently has equal or more of said points
  // 3) The item hasn't been redeemed more times than what's in the cart (i.e: Can't redeem 3 times if the user only has 2x of said product)
  const redeemableItem = productItemPoints > 0
    && userPoints >= productItemPoints
    && redeemedQuantity < quantity;

  // If the item is not redeemable we can exit early
  if (!redeemableItem) return null;
  const redeemText = translation('OrderItemComponent.redeem');
  const pointsText = translation('RewardDialog.points');
  const buttonText = `${redeemText} ${productItemPoints} ${pointsText}`;

  return (
    <Button
      type="tertiary"
      onClick={() => handleRedeem(orderItem)}
      icon="info"
      text={buttonText}
    />
  );
};

const OrderItem = (props) => {
  const {
    orderItem,
    classes,
    handleEdit,
    handleRemove,
    redeemedItemsIds,
    products,
    translation,
    enableEdit,
    user,
  } = props;
  if (!orderItem) return null;
  const { productItem, quantity, options } = orderItem;
  const product = getProductFamily(products, productItem.id);
  const productItemValue = ((get(product, 'items.length') === 1) ? null : productItem.attribute_value);
  const isCurrentItemRedeemed = !isEmpty(redeemedItemsIds) && redeemedItemsIds.includes(orderItem.id);
  const showRedeemButton = enableEdit && user && !user.guestToken;

  return (
    <div className={classes.itemContainer}>
      <div className={classes.itemTitle}>
        <div>
          <Typography className={classes.productName}>
            {`x${quantity} ${get(product, 'name', '')}`}
          </Typography>
          <Typography className={classes.productItemValue}>
            {productItemValue}
          </Typography>
        </div>
        <div className={classes.priceAndRedeem}>
          <ItemPrice {...props} isCurrentItemRedeemed={isCurrentItemRedeemed} />
          {
            showRedeemButton && (
              <RedeemButton {...props} isCurrentItemRedeemed={isCurrentItemRedeemed} />
            )
          }
        </div>
      </div>
      <div className={classes.customizationList}>
        <CustomizationList {...props} options={options} />
      </div>
      {
        enableEdit && (
          <div className={classes.itemLinks}>
            <LinkText
              id="editCartItem"
              className={classes.linkText}
              onClick={() => handleEdit(orderItem)}
              text={translation('EDIT')}
            />
            <LinkText
              id="removeCartItem"
              className={classes.linkText}
              onClick={() => handleRemove(orderItem)}
              text={translation('REMOVE')}
            />
          </div>
        )
      }
    </div>
  );
};

const OrderItemList = (props) => {
  const {
    classes,
    currentOrder,
    orderItems,
    user,
    actions,
    isBelowMinimum,
  } = props;
  if (!orderItems) return null;

  const redeemedItems = orderItems.filter(({ redeemedPoints }) => redeemedPoints);
  const redeemedItemsIds = redeemedItems.map(({ id }) => id);

  const [userPoints, setUserPoints] = useState(get(user, 'points', 0));

  const productItemPoints = orderItem => get(orderItem, 'productItem.points', 0);

  const redeemPoints = (orderItem) => {
    setUserPoints(userPoints - productItemPoints(orderItem));
    actions.updateOrderItemsRedeemedPoints(user, currentOrder, orderItem, productItemPoints(orderItem));
  };

  const unRedeemPoints = (orderItem) => {
    setUserPoints(userPoints + productItemPoints(orderItem));
    actions.updateOrderItemsRedeemedPoints(user, currentOrder, orderItem, 0);
  };

  return (
    <div className={isBelowMinimum ? classes.itemListTopMargin : classes.itemList}>
      {
        orderItems.map(orderItem => (
          <OrderItem
            {...props}
            key={orderItem.id}
            orderItem={orderItem}
            redeemedItemsIds={redeemedItemsIds}
            handleRedeem={redeemPoints}
            handleUnRedeem={unRedeemPoints}
            userPoints={userPoints}
          />
        ))
      }
    </div>
  );
};

OrderItemList.propTypes = {
  classes: objectOf(string).isRequired,
  translation: func.isRequired,
  currentOrder: objectOf(any).isRequired,
  enableEdit: bool,
  isBelowMinimum: bool,
};

OrderItemList.defaultProps = {
  enableEdit: true,
  isBelowMinimum: false,
};

const mapStateToProps = state => ({
  user: getUser(state).user,
  company: getCompany(state),
  currentOrder: getCurrentOrder(state),
  products: getProducts(state),
});

const mapDispatchToProps = dispatch => ({
  createOrder: (user, order) => dispatch(Actions.createOrder(user, order)),
});

const EnhancedOrderItemList = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(responsiveStyles),
)(OrderItemList);
export default EnhancedOrderItemList;
