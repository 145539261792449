import handleResponse from '../ResponseHandler';
import { PICKUP, DELIVERY, DINE_IN, CATERING } from '../constants/Constants';

// eslint-disable-next-line no-undef
const baseUrl = window.api_host;
// eslint-disable-next-line no-undef
const companyApiKey = window.companyApiKey;

export function generatePath(resourcePath) {
  return resourcePath.join('/');
}

export function generateHeader(token) {
  const apiHeader = {};
  apiHeader.Authorization = token
    ? `Bearer ${token}`
    : `Bearer ${companyApiKey}`;
  apiHeader['Content-Type'] = 'application/json';
  return apiHeader;
}

function sendOrderRequest(apiToken, updatedResource, resourcePath, method) {
  let items = [];
  const order = {
    id: (updatedResource.id > 0) ? updatedResource.id : 0,
    desiredTime: updatedResource.desiredTime,
    deliveryOption: updatedResource.deliveryOption,
    orderStatus: updatedResource.orderStatus,
    coupons: updatedResource.coupons,
    isASAP: updatedResource.isASAP,
  };
  if (updatedResource.isCurbsidePickUp !== undefined) {
    order.isCurbsidePickUp = updatedResource.isCurbsidePickUp;
  }

  if (updatedResource.vehicleInformation) {
    order.vehicleInformation = updatedResource.vehicleInformation;
  }

  if ([DELIVERY, CATERING].includes(updatedResource.deliveryOption)) {
    order.address = updatedResource.address;
  } else {
    order.location = updatedResource.location;
  }
  if (updatedResource.note) {
    order.note = updatedResource.note;
  }
  if (updatedResource.tipAmount) {
    order.tipAmount = updatedResource.tipAmount;
  }
  if (updatedResource.coupons) {
    order.coupons = updatedResource.coupons;
  }
  if (updatedResource.deliveryOption === DINE_IN) {
    order.tableNumber = updatedResource.tableNumber;
  }

  const path = baseUrl + generatePath(resourcePath);
  const request = (
    new Request(path, {
      method,
      headers: generateHeader(apiToken),
      body: JSON.stringify(order),
    })
  );

  return handleResponse(request)
    .then((response) => {
      if (updatedResource.items) {
        return Promise.all(updatedResource.items.map((item) => {
          const newResourcePath = (item.id)
            ? [...resourcePath, "items", item.id]
            : (method === 'POST')
              ? [...resourcePath, response.data.id, "items"]
              : [...resourcePath, "items"];
          let path = baseUrl + generatePath(newResourcePath);

          return sendOrderItemRequest(apiToken, item, newResourcePath, (method === 'PUT') ? (item.id) ? 'PUT' : 'POST' : method)
            .then(orderItemResponse => items.push(orderItemResponse.data));
        }))
          .then(() => {
            // Sorting async responses that do not return sequentially
            // Pro: Save time with requests handled asynchronously
            // Con: Sorting takes time (in here we're assuming # of order
            // items is relatively smaller)
            response.data.items = items.sort((a, b) => a.id - b.id);
            return response;
          });
      }
      return response;
    });
}

function sendOrderItemRequest(user, updatedResource, resourcePath, method) {
  let subItems = [];
  const orderItem = {
    id: (updatedResource.id > 0) ? updatedResource.id : 0,
    quantity: updatedResource.quantity,
    redeemedPoints: updatedResource.redeemedPoints ? updatedResource.redeemedPoints : 0,
    productItem: updatedResource.productItem,
    options: (updatedResource.options !== undefined) ? updatedResource.options : [],
    subItems: (updatedResource.subItems !== undefined) ? updatedResource.subItems : [],
    note: updatedResource.note,
  };

  let path = baseUrl + generatePath(resourcePath);
  const request = (
    new Request(path, {
      method,
      headers: generateHeader(user),
      body: JSON.stringify(orderItem),
    })
  );

  return handleResponse(request).then(
    (response) => {
      if (updatedResource.subItems) {
        return Promise.all(updatedResource.subItems.map(
          (item) => {
            let newResourcePath = (item.id) ? [...resourcePath, 'subItems', item.id] : (method === 'POST') ? [...resourcePath, response.data.id, 'subItems'] : [...resourcePath, 'subItems'];
            const newMethod = (item.id) ? 'PUT' : 'POST';
            let path = baseUrl + generatePath(newResourcePath);
            const newRequest = (
              new Request(path, {
                method: newMethod,
                headers: generateHeader(user),
                body: JSON.stringify(item),
              }));

            return handleResponse(newRequest).then((response) => {
              subItems.push(response.data);
            });
          },
        )).then(() => {
          response.data.subItems = subItems;
          return response;
        });
      } else return response;
    },
  );
}

export default class ResourcesAPI {
  static addResource(apiToken, resource, resourcePath) {
    const path = baseUrl + generatePath(resourcePath);
    const request = (
      new Request(path, {
        method: 'POST',
        headers: generateHeader(apiToken),
        body: JSON.stringify(resource),
      })
    );
    return handleResponse(request);
  }

  static deleteResource(apiToken, resourcePath) {
    const path = baseUrl + generatePath(resourcePath);
    const request = (
      new Request(path, {
        method: 'DELETE',
        headers: generateHeader(apiToken),
      })
    );
    return handleResponse(request, true);
  }

  static updateResource(apiToken, resource, resourcePath) {
    const path = baseUrl + generatePath(resourcePath);
    const request = (
      new Request(path, {
        method: 'PUT',
        headers: generateHeader(apiToken),
        body: JSON.stringify(resource),
      })
    );
    return handleResponse(request);
  }

  static getAllResources(apiToken, resourcePath) {
    const path = baseUrl + generatePath(resourcePath);
    const request = (
      new Request(path, {
        method: 'GET',
        headers: generateHeader(apiToken),
      })
    );
    return handleResponse(request);
  }

  static getResource(apiToken, resourcePath, isPlain = false) {
    const path = baseUrl + generatePath(resourcePath);
    const request = (
      new Request(path, {
        method: 'GET',
        headers: generateHeader(apiToken),
      })
    );
    return handleResponse(request, isPlain);
  }

  static addOrder(apiToken, updatedResource, resourcePath) {
    const order = sendOrderRequest(apiToken, updatedResource, resourcePath, 'POST');
    return order;
  }

  static addOrderItem(user, updatedResource, resourcePath) {
    const orderItem = sendOrderItemRequest(user, updatedResource, resourcePath, 'POST');
    return orderItem;
  }

  static updateOrder(userToken, updatedResource, resourcePath) {
    return sendOrderRequest(userToken, updatedResource, resourcePath, 'PUT');
  }

  static updateOrderItem(user, updatedResource, resourcePath) {
    const orderItem = sendOrderItemRequest(user, updatedResource, resourcePath, 'PUT');
    return orderItem;
  }
}

