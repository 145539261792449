import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { withStyles, responsiveFontSizes, createMuiTheme } from '@material-ui/core/styles';
import styles from '../../../css/core/components/TextBoxComponent.scss';
import * as ResponsiveStyles from '../../../../jsonStyles/components/core/components/TextBoxComponent.style.json';

let theme = createMuiTheme();
const responsiveStyles = typeof styles === 'function' ? styles() : styles;
theme = responsiveFontSizes(theme);

responsiveStyles.inputStyle = {
  ...responsiveStyles.inputStyle,
  [theme.breakpoints.down('sm')]: {
    // Assigning value less than 16px will cause auto-zoom issue on iOS https://aytech.atlassian.net/browse/CRV-9463
    fontSize: ResponsiveStyles.inputStyle.fontSize,
  },
};

class TextBoxComponent extends React.Component {
  state = {
    textInput: this.props.selectedValue,
  }

  handleChange(event) {
    const { handleChange, field } = this.props;
    this.setState({
      textInput: event.target.value,
    }, () => handleChange(field, this.state.textInput));
  }

  render() {
    const {
      title, fieldKey, classes, fieldType, editable, multiline, rows, fieldAutoFocus,
    } = this.props;

    return (
      <TextField
        key={fieldKey}
        value={this.state.textInput}
        label={title}
        placeholder={title}
        onChange={e => this.handleChange(e)}
        className={classes.textFieldStyle}
        variant={responsiveStyles.textFieldStyle.variant}
        InputProps={{ disableUnderline: responsiveStyles.textFieldInputBoxProps.disableUnderline === 'true' }}
        FormHelperTextProps={{ className: classes.textFieldHelperText }}
        id={fieldKey}
        autoFocus={fieldAutoFocus}
        multiline={multiline}
        rows={rows}
        inputProps={
          multiline ? { className: classes.inputInformationStyle } : { className: classes.inputStyle }
        }
        type={fieldType}
        disabled={!editable}
      />
    );
  }
}

TextBoxComponent.propTypes = {
  handleChange: PropTypes.func.isRequired,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
  fieldKey: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  fieldType: PropTypes.string,
  selectedValue: PropTypes.string,
  editable: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.string,
  fieldAutoFocus: PropTypes.bool,
};

TextBoxComponent.defaultProps = {
  fieldType: 'string',
  selectedValue: '',
  editable: true,
  multiline: false,
  rows: '5',
  fieldAutoFocus: true,
};

export default withStyles(responsiveStyles)(TextBoxComponent);
