import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import styles from '../../../css/core/components/MessageSnackbar.scss';

const pageStyles = typeof styles === 'function' ? styles() : styles;

class MessageSnackbar extends React.Component {
  state = {
    open: this.props.openMessageSnackbar,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const isOpen = nextProps.openMessageSnackbar;
    const isMsgDiff = this.props.errorMsg !== nextProps.errorMsg || this.props.successMsg !== nextProps.successMsg;

    if (isOpen || isMsgDiff) {
      this.setState({
        open: nextProps.openMessageSnackbar,
      });
    }
  }

  getSnackBarContentClasses = () => {
    const { classes } = this.props;
    const isErrorOrSuccess = this.isErrorOrSuccess();
    if (!isErrorOrSuccess) return {};

    const rootClass = `${isErrorOrSuccess}SnackbarContentRoot`;
    return {
      root: classes[rootClass],
      action: classes.snackbarContentActionContainer,
    };
  }

  getErrorOrSuccessIcon = (isErrorOrSuccess) => {
    const { translation } = this.props;
    if (!isErrorOrSuccess) return null;
    const warningIcon = (
      <ErrorOutlineIcon
        aria-label={translation('MessageSnackbar.warningIconAriaLabel')}
      />
    );
    const successIcon = (
      <CheckCircleOutlinedIcon
        aria-label={translation('MessageSnackbar.successIconAriaLabel')}
      />
    );
    return isErrorOrSuccess === 'error' ? warningIcon : successIcon;
  }

  handleClose = (event, reason) => {
    const { actions } = this.props;
    this.setState({ open: false });
    actions.clearMessage();
  };

  isErrorOrSuccess = () => {
    const { errorMsg, successMsg } = this.props;
    if (errorMsg) return 'error';
    if (successMsg) return 'success';
    return null;
  }

  renderIconAndMessage = () => {
    const { classes, errorMsg, successMsg } = this.props;
    const isErrorOrSuccess = this.isErrorOrSuccess();
    return (
      <div className={classes.iconAndMessageContainer}>
        <div className={classes.iconContainer}>
          {this.getErrorOrSuccessIcon(isErrorOrSuccess)}
        </div>
        <div className={classes.messageContainer}>
          <span id="message-id">
            {errorMsg || successMsg}
          </span>
        </div>
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: pageStyles.snackbar.anchorOriginVertical,
          horizontal: pageStyles.snackbar.anchorOriginHorizontal,
        }}
        open={this.state.open}
        autoHideDuration={null}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
          classes: this.getSnackBarContentClasses(),
        }}
        message={this.renderIconAndMessage()}
        action={[
          <IconButton
            key="close"
            aria-label={pageStyles.iconButton.ariaLabel}
            color={pageStyles.iconButton.color}
            className={classes.iconButton}
            onClick={this.handleClose}
          >
            <CancelIcon />
          </IconButton>,
        ]}
      />
    );
  }
}

MessageSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  openMessageSnackbar: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string,
  successMsg: PropTypes.string,
  translation: PropTypes.func.isRequired,
};

MessageSnackbar.defaultProps = {
  errorMsg: '',
  successMsg: '',
};

export default withStyles(styles)(MessageSnackbar);
